import { useEffect, useMemo, useState } from 'react';
import { PhysiologicalStatesService } from '../../services/physiological-states/physiologicalStatesService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage physiological states fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de alergias.
----------------------------------------------------------------------------------------*/

const usePhysiologicalStates = () => {

    const service = useMemo(() => new PhysiologicalStatesService(), [])
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await service.listPhysiologicalStates();
            const fetchedData = response.getResponseData()?.data;

            if (fetchedData && fetchedData.physiologicalStates) {
                const mappedData = fetchedData.physiologicalStates?.map((data: any) => data);
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching physiological states: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getOptions = () => {
        return data.map((option: { id: string, name: string }) => ({
            value: option.id,
            label: option.name
        }));
    };

    const getList = (entityId?: string) => {
        if (entityId) return data.filter((state: any) => state.id === entityId);
        return data;
    };

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    };

    return {
        fetchPhysiologicalStates: fetchData,
        getPhysiologicalStateOptions: getOptions,
        getPhysiologicalStatesList: getList,
        getPhysiologicalStateId: getEntity,
        isLoadingPhysiologicalStates: isLoading
    };
}

export default usePhysiologicalStates;