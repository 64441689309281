import React, { FC, Fragment, useCallback, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import ReactSelect from 'react-select'
import useFetch from '../../../../hooks/useFetch'
import { Label } from '../../../../type/patients-type'
import { ProfileService } from '../../../../services/profile/profileService'
import { RoleService } from '../../../../services/auth/roleService'
import { CompanyService } from '../../../../services/companies/organizationService'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import { usePrivilege } from '../../../../components/priviledge/PriviledgeProvider'
import useWorkplaces from '../../../../hooks/api-calls/useWorkplaces'
import SearchableSelect from '../../../../components/select/SearchableSelect'
import classNames from 'classnames'

interface IUsersFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const UsersFilters: FC<IUsersFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { userCan } = usePrivilege();
    const { getWorkplacesList } = useWorkplaces();

    const [filterMenu, setFilterMenu] = useState(false);
    const [organizationsSelected, setOrganizationsSelected] = useState<any>([]);
    const [rolesSelected, setRolesSelected] = useState<any>([]);

    const userRolesService = new RoleService();
    const profileService = new ProfileService();
    const organizationsService = new CompanyService();

    const [profile] = useFetch(useCallback(async () => {
        const response = await profileService.getMyProfile();
        return response.getResponseData();
    }, []));

    const [roles] = useFetch(useCallback(async () => {
        if (!userCan('show', 'roles')) return;
        const response = await userRolesService.getRolesByOrganization(profile?.userRoles[0].company.id);
        return response.getResponseData();
    }, []));

    const [superRoles] = useFetch(useCallback(async () => {
        const response = await userRolesService.getRoles();
        return response.getResponseData();
    }, []));

    const [organizations] = useFetch(useCallback(async () => {
        if (!userCan('list', 'organizations')) return;
        const response = await organizationsService.getOrganizations();
        return response.getResponseData();
    }, []));

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const handleOrganizationChange = (e: any) => {
        setOrganizationsSelected(e);
        const organizations = e.map((organization: any) => organization.value);
        updateFilters({ company: organizations });
    };

    const handleRoleChange = (e: any) => {
        setRolesSelected(e);
        const roles = e.map((role: any) => role.value);
        updateFilters({ roles: roles });
    };

    // Obtener la lista de organizaciones
    const getOrganizationsOptions = () => {
        if (!organizations) return;
        const options = organizations.companies.map((organization: any) => {
            return { value: organization.id, label: organization.name }
        });
        return options;
    };

    // Obtener la lista de roles
    const getRolesOptions = () => {
        if (!roles && !superRoles) return;
        if (roles) {
            const options = roles.map((role: Label) => {
                return { value: role.id, label: role.name }
            });
            return options;
        }
        if (superRoles) {
            const options = superRoles.map((role: any) => {
                return {
                    value: role.id,
                    label: role.company !== null
                        ? (role.name + ' (' + role.company?.name + ')')
                        : (role.name + '')
                }
            });
            return options;
        }
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'> Filtros de Usuario </OffCanvasTitle>
                    <Button
                        color="storybook"
                        isLight
                        onClick={() => {
                            resetFilters()
                            setOrganizationsSelected([])
                            setRolesSelected([])
                        }}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Estado de cuenta'>
                                <Select
                                    id='active-select'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters.active.toString()}
                                    list={[
                                        { value: 3, label: 'Todos', text: 'Todos' },
                                        { value: 1, label: 'Activo', text: 'Activo' },
                                        { value: 0, label: 'Desactivado', text: 'Desactivado' }
                                    ]}
                                    ariaLabel='Selector de estado de cuenta'
                                    placeholder='Estado...'
                                />
                            </FormGroup>

                            {organizations && (
                                <FormGroup id='filter2' label='Organizaciones' className='mt-3'>
                                    <ReactSelect
                                        isMulti
                                        onChange={(e: any) => handleOrganizationChange(e)}
                                        value={organizationsSelected}
                                        id='organization-select' placeholder='Organizaciones...'
                                        options={getOrganizationsOptions()}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                borderRadius: '10px',
                                                boxShadow: state.isFocused ? '0 0 0 3px rgba(93, 236, 227, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                                                border: '0px !important',
                                                backgroundColor: '#f8f9fa',
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#30b7af' : 'white',
                                                color: state.isFocused ? 'black' : 'grey',
                                                '&:hover': {
                                                    backgroundColor: '#30b7af',
                                                    color: 'black',
                                                    borderColor: '#30b7af !important'
                                                }
                                            })
                                        }}
                                        noOptionsMessage={() => 'No hay organizaciones con ese nombre'}
                                    />
                                </FormGroup>
                            )}

                            {userCan('show', 'roles') && (roles || superRoles) && (
                                <FormGroup id='filter3' label='Roles' className='mt-3'>
                                    <ReactSelect
                                        isMulti
                                        onChange={(e: any) => handleRoleChange(e)}
                                        value={rolesSelected}
                                        id='role-select' placeholder='Roles...'
                                        options={getRolesOptions()}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                borderRadius: '10px',
                                                boxShadow: state.isFocused ? '0 0 0 3px rgba(93, 236, 227, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                                                border: '0px !important',
                                                backgroundColor: '#f8f9fa',
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#30b7af' : 'white',
                                                color: state.isFocused ? 'black' : 'grey',
                                                '&:hover': {
                                                    backgroundColor: '#30b7af',
                                                    color: 'black',
                                                    borderColor: '#30b7af !important'
                                                }
                                            })
                                        }}
                                        noOptionsMessage={() => 'No hay roles con ese nombre'}
                                    />
                                </FormGroup>
                            )}

                            <FormGroup id='filter4' label='Centros de trabajo' className={classNames("mt-3", { "d-none": !userCan('list_workplace', 'workplace') })}>
                                <SearchableSelect
                                    isMulti
                                    isClearable
                                    name='workplace-select'
                                    options={getWorkplacesList()}
                                    onChange={(e: any) => updateFilters({ workPlaces: e?.map((w: any) => w.value) })}
                                    value={filters.filter_filters.workPlaces?.map((w: string) => getWorkplacesList().find((workplace: any) => workplace.value == w))}
                                    placeholder='centros de trabajo'
                                />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default UsersFilters;