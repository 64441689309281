import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { handleConfirmationAlert } from '../../../components/ConfirmationAlert';
import ErrorMessage from '../../../components/ErrorMessage';
import Button from '../../../components/bootstrap/Button';
import { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import { CustomTable } from '../../../components/table/CustomTable';
import useAllergens from '../../../hooks/api-calls/useAllergens';
import useNutrients from '../../../hooks/api-calls/useNutrients';
import useFetch from '../../../hooks/useFetch';
import useFilters from '../../../hooks/useFilters';
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { AllergiesService } from '../../../services/allergies/allergiesService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import AllergiesFilters from './AllergiesFilters';
import AllergyModalForm from './components/AllergyModalForm';

interface GCAllergiesProps { }

const GCAllergies: React.FC<GCAllergiesProps> = ({ }) => {

    // STATES

    const [isOpen, setIsOpen] = React.useState(false);
    const [rowId, setRowId] = React.useState('');

    // HOOKS

    const { handleErrors } = useHandleErrors();
    const { getAllergenOptions } = useAllergens();
    const { getAllNutrientsData } = useNutrients();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3 });

    // FETCH DATA

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new AllergiesService()).listAllergies(filters));
        return response.getResponseData();
    }, [filters]));

    // FUNCTIONS
    
    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función para eliminar una alergia.
     * @EN Function to delete an allergy.
     * 
     * @param id 
     */
    //--------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new AllergiesService()).deleteAllergy(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Alergia eliminada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al eliminar la alergia');
        }
    };
    //--------------------------------------------------------------------------------------------------

    // RENDER

    if (error) return <ErrorMessage />;

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Alergias</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Alergia"
                        color="storybook"
                        icon="Add"
                        isLight
                        onClick={() => { setRowId(''); setIsOpen(true); }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <AllergiesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </CardHeader>
            <CardBody className="table-responsive">
                <CustomTable
                    title="Alergias"
                    data={data ? data?.allergies : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data ? data?.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Alimentos (evitar)",
                            keyValue: "foodsToAvoid",
                            render: (element: any) => {
                                return (<>
                                    {element.foodsToAvoid?.length > 0
                                        ? element.foodsToAvoid.map((food: any, index: number) => { return (food.name + (index < element.foodsToAvoid.length - 1 ? ', ' : '')) })
                                        : "-"
                                    }
                                </>)
                            }
                        },
                        {
                            name: "Grupos (evitar)",
                            keyValue: "foodGroupsToAvoid",
                            render: (element: any) => {
                                return (<>
                                    {element.foodGroupsToAvoid?.length > 0
                                        ? element.foodGroupsToAvoid.map((foodGroup: any, index: number) => { return (foodGroup.name + (index < element.foodGroupsToAvoid.length - 1 ? ', ' : '')) })
                                        : "-"
                                    }
                                </>)
                            }
                        },
                        {
                            name: "Alérgenos (evitar)",
                            keyValue: "allergensToAvoid",
                            render: (element: any) => {
                                return (<>
                                    {element.allergensToAvoid?.length > 0
                                        ? element.allergensToAvoid.map((allergen: any, index: number) => { return ((getAllergenOptions().find((a) => a.value === allergen)?.label || "Desconocido") + (index < element.allergensToAvoid.length - 1 ? ', ' : '')) })
                                        : "-"
                                    }
                                </>)    
                            }
                        },
                        {
                            name: "Nutrientes (evitar)",
                            keyValue: "nutrientsToAvoid",
                            render: (element: any) => {
                                return (<>
                                    {element.nutrientsToAvoid?.length > 0
                                        ? element.nutrientsToAvoid.map((nutrient: any, index: number) => { return ((getAllNutrientsData().find((n) => n.name === nutrient)?.nombre || "Desconocido") + (index < element.nutrientsToAvoid.length - 1 ? ', ' : '')) })
                                        : "-"
                                    }
                                </>)
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar alergia",
                            click: (item: any) => { setRowId(item.id); setIsOpen(true); },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar alergia",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar Alergia",
                                    text: "¿Está seguro que desea eliminar la alergia?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            </CardBody>

            {isOpen && <AllergyModalForm isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} rowId={rowId} />}
        </>
    );
};

export default GCAllergies;