import { useFormik } from "formik";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import Page from "../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import { useCallback } from "react";
import useFetch from "../../hooks/useFetch";
import SearchableSelect from "../../components/select/SearchableSelect";
import { TipsGroupsService } from "../../services/tips/groupsService";
import * as Yup from 'yup';

interface TipFormProps {
    submit: (values: any) => void;
    isLoading: boolean;
    tipData?: any;
}

interface TipForm {
    tip?: string;
    tipGroup: string;
    name: string;
    description: string;
}

const tipInitialValues: TipForm = {
    tip: '',
    tipGroup: '',
    name: '',
    description: '',
}

const TipSchema = Yup.object().shape({
    tipGroup: Yup.string().required('El grupo es obligatorio'),
    name: Yup.string().required('El nombre es obligatorio'),
    description: Yup.string(),
});

const TipForm: React.FC<TipFormProps> = ({ submit, isLoading, tipData }) => {
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();

    const mode = tipData ? 'Editar' : 'Crear';
    const buttonTitle = mode === 'Editar' ? 'Guardar Cambios' : 'Crear';

    const [tipGroups] = useFetch(useCallback(async () => {
        const response = (await (new TipsGroupsService()).listGroups());
        return response.getResponseData();
    }, []));

    const getTipGroupsList = () => {
        if (tipGroups) {
            return tipGroups.tip_groups?.map((group: any) => {
                return {
                    value: group.id,
                    label: group.name,
                    isSelected: group.id === tipData?.tipGroup
                }
            })
        }
        return [];
    }

    const groupSelected = getTipGroupsList()?.filter((option: { isSelected: boolean }) => option.isSelected);
    const defaultGroupValue = groupSelected[0] ? { value: groupSelected[0].value, label: groupSelected[0].label } : null;

    const formik = useFormik<TipForm>({
        initialValues: tipData || tipInitialValues,
        validationSchema: TipSchema,
        onSubmit: values => {
            tipData && (values.tip = id);
            submit(values);
        }
    });

    const verifyClass = (inputFieldID: keyof TipForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof TipForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Card>
                    <CardBody>
                        <CardTitle>Datos del consejo</CardTitle>

                        <div className="row mt-4 d-flex justify-content-between">
                            <div className="col-md-6">
                                <FormGroup label="Nombre" requiredInputLabel>
                                    <Input
                                        type="text"
                                        className={verifyClass('name')}
                                        id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    {showErrors('name')}
                                </FormGroup>
                            </div>

                            <div className="col-md-4">
                                {
                                    mode === 'Crear' && (
                                        <FormGroup label="Grupo" requiredInputLabel>
                                            <SearchableSelect
                                                name="tipGroup"
                                                isSearchable
                                                options={getTipGroupsList()}
                                                onChange={(e: any) => formik.setFieldValue('tipGroup', e.value)}
                                                placeholder='grupo'
                                                classname={verifyClass('tipGroup')}
                                            />
                                            {showErrors('tipGroup')}
                                        </FormGroup>
                                    )
                                }
                                {
                                    defaultGroupValue && (
                                        <FormGroup label="Grupo" requiredInputLabel>
                                            <SearchableSelect
                                                name="tipGroup"
                                                isSearchable
                                                options={getTipGroupsList()}
                                                onChange={(e: any) => formik.setFieldValue('tipGroup', e.value)}
                                                placeholder='grupo'
                                                classname={verifyClass('tipGroup')}
                                                defaultValue={defaultGroupValue}
                                            />
                                            {showErrors('tipGroup')}
                                        </FormGroup>
                                    )
                                }
                            </div>
                        </div>

                        <div className="row d-flex justify-content-between mt-3">
                            <div className="col-md-12">
                                <FormGroup label="Descripción">
                                    <textarea
                                        className={`form-control ${verifyClass('description')}`}
                                        id="description"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                        rows={6}
                                    />
                                    {showErrors('description')}
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </form>
        )
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Consejo</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : `${buttonTitle} `}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='sm' className="w-50">
                {(mode === "Editar" && !tipData) && <Spinner />}
                {(mode === "Editar" && tipData) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
        </>
    )
}

export default TipForm;