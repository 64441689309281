import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import { UserService } from "../../../services/users/userService";
import { User } from "../../../type/user-type";
import useFetch from "../../../hooks/useFetch";
import { RolePermission } from "../../../type/role-type";
import { toast } from "react-toastify";
import UserForm from "../UserForm";
import ErrorMessage from "../../../components/ErrorMessage";
import PermissionsModal from "./UserEditPermissions";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";

const UserEdit: FC = () => {

  const { handleErrors } = useHandleErrors();
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const { userCan } = usePrivilege();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userPermissions, setUserPermissions] = useState<RolePermission[] | null>(null);
  const [editPermissions, setEditPermissions] = useState<boolean>(false);

  const [data, loading, error] = useFetch(useCallback(async () => {
    const response = await (new UserService()).getUserById(id as string);
    return response.getResponseData() as User;
  }, [id]));

  const changePassword = async (password: string, confirmPassword: string) => {
    try {
      const response = await (new UserService()).changePassword(id, password, confirmPassword);
      const responseData = response.getResponseData();

      if (!responseData.success) {
        toast.error(responseData.message || "Error al cambiar la contraseña");
      }
    }
    catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleUpdate = async (values: any) => {
    setIsLoading(true);
    if (values.password !== "" && values.passwordConfirm !== "" && values.password === values.passwordConfirm) {
      await changePassword(values.password, values.passwordConfirm);
    }

    values.user = id;

    try {
      const response = await (new UserService()).editUser(values);
      const responseData = response.getResponseData();
      if (responseData.success) {
        navigate(-1);
        toast.success('Usuario editado correctamente');
      } else {
        handleErrors(responseData);
      }
    } catch (error: any) {
      toast.error("Error al editar el usuario");
    } finally {
      setIsLoading(false);
    }
  };

  const getContent = () => {
    if (loading) return <Loader />;

    if (error) return <ErrorMessage />;

    if (data !== null) {
      const userData = {
        name: data.name !== null ? data.name : "",
        last_name: data.lastName !== null ? data.lastName : "",
        email: data.email !== null ? data.email : "",
        dni: data.dni !== null ? data.dni : "",
        bankAccount: data.bankAccount !== null ? data.bankAccount : "",
        color: data.color !== null ? data.color : "",
        comments: data.comments !== null ? data.comments : "",
        address: data.address !== null ? data.address : "",
        telephone: data.telephone !== null ? data.telephone : "",
        role: data.userRoles[0].role !== null ? data.userRoles[0].role.id : "",
        roleName: data.userRoles[0].role !== null ? data.userRoles[0].role.name : "",
        password: "",
        passwordConfirm: "",
        dataProtection: false,
        company: data.userRoles[0].company !== null ? data.userRoles[0].company.id : "",
        companyName: data.userRoles[0].company !== null ? data.userRoles[0].company.name : "",
        birthday_date: data.birthdayDate !== null ? data.birthdayDate.date.slice(0, 10) : "",
        workplaces: data.workPlaces !== null ? data.workPlaces?.map((w: any) => w.workPlace?.id) : [],
      };

      return <UserForm isLoading={isLoading} submit={handleUpdate} userData={userData} profileImg={data.profileImg} />;
    }
  };

  return (
    <>
      {userCan('edit_permissions', 'users') && (
        <Button color="secondary" isLight icon="Security" onClick={() => { setEditPermissions(true) }}>
          Editar Permisos
        </Button>
      )}

      {editPermissions && data && (
        <PermissionsModal
          userId={id}
          companyId={data.userRoles[0].company?.id}
          userPermissions={data.userPermissions}
          isOpen={editPermissions} setIsOpen={setEditPermissions}
          newPermissions={userPermissions} setNewPermissions={setUserPermissions}
        />
      )}

      {getContent()}
    </>
  );
};

export default UserEdit;