import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/economic-activity';

export class EconomicActivityService extends RestServiceConnection {

    listEconomicAvtivities = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getEconomicAvtivity = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { id }
        }, true) as AxiosResponse;
        return this;
    }

    creatEconomicAvtivity = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: values
        }, true) as AxiosResponse;
        return this;
    }
}