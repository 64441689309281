import { useCallback, useState } from "react";
import Card, { CardBody, CardHeader, CardTitle } from "../../../../components/bootstrap/Card";
import Spinner from "../../../../components/bootstrap/Spinner";
import { handleConfirmationAlert } from "../../../../components/ConfirmationAlert";
import useFetch from "../../../../hooks/useFetch";
import useFilters from "../../../../hooks/useFilters";
import { SubHeaderLeft } from "../../../../layout/SubHeader/SubHeader";
import { PaymentMethodService } from "../../../../services/paymentMethods/paymentMethodService";
import Button from "../../../../components/bootstrap/Button";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import Input from "../../../../components/bootstrap/forms/Input";
import Icon from "../../../../components/icon/Icon";

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido')
});

const PaymentMethodList = () => {

    // STATES

    // const [currentPage, setCurrentPage] = useState(0); // Página actual
    // const itemsPerPage = 3; // Número de elementos por página

    // HOOKS

    const { filters } = useFilters({});

    // DATA FETCH

    const [data, loading, error , refetch] = useFetch(
        useCallback(async () => {
            const response = await new PaymentMethodService().listPaymentMethods(filters);
            return response.getResponseData();
        }, [filters])
    );

    // FUNCTIONS

    // const submit = async (values: any) => {
    //     const response = await (await new PaymentMethodService().createPaymentMethod(values)).getResponseData();
    //     if (response.success) {
    //         toast.success("Método de pago creado correctamente");
    //         formik.resetForm();
    //         refetch();
    //     } else {
    //         toast.error("Error al crear el método de pago");
    //     }
    // };

    // const handleDelete = async (id: string) => {
    //     try {
    //         const response = await (await new PaymentMethodService().deletePaymentMethod(id)).getResponseData();
    //         if (response.success) {
    //             toast.success("Método de pago eliminado correctamente");
    //             refetch();
    //         } else {
    //             toast.error("Error al eliminar el método de pago");
    //         }
    //     } catch (error) {
    //         toast.error("Error al eliminar el método de pago");
    //     }
    // };

    // FORMIK

    // const formik = useFormik({
    //     initialValues: {
    //         name: ""
    //     },
    //     validationSchema: ValidationSchema,
    //     onSubmit: async (values) => {
    //         submit(values);
    //     }
    // });

    // CALCULATE PAGINATION

    // const totalPages = data ? Math.ceil(data.data.length / itemsPerPage) : 0; // TOTAL PAGES
    // const startIndex = currentPage * itemsPerPage; // INITIAL INDEX OF THE PAGE
    // const currentItems = data ? data.data.slice(startIndex, startIndex + itemsPerPage) : []; // CURRENT ITEMS

    // RENDER

    return (
        <Card className="mb-3 col-md-3">
            <SubHeaderLeft className="ms-4 mt-4">
                <CardTitle>Métodos de Pago</CardTitle>
            </SubHeaderLeft>
            <CardBody>
                {data ? (
                    <div className="w-100">
                        {/* TABLE */}
                        <div className="row">
                            <div className="col-12 fw-bold text-muted">Nombre</div>
                            {data.data.length > 0 ? (
                                data.data.map((item: any) => (
                                    <div className="col-12 mt-2 d-flex justify-content-between align-items-center mt-2" key={item.id}>
                                        <span>{item.name}</span>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12 mt-3 text-center">No hay métodos de pago</div>
                            )}
                        </div>

                        {/* FORM */}
                        {/* <hr />
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-12 fw-bold text-muted">Nombre</div>
                                <div className="col-6 mt-2">
                                    <Input
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={formik.touched.name && formik.errors.name ? "is-invalid" : ""}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    )}
                                </div>
                                <div className="col-12 d-flex justify-content-end mt-4">
                                    <Button color="light" type="submit">
                                        Agregar Método de Pago
                                    </Button>
                                </div>
                            </div>
                        </form> */}
                    </div>
                ) : (
                    <Spinner />
                )}
            </CardBody>
        </Card>
    );
};

export default PaymentMethodList;