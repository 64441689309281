import classNames from 'classnames';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import FormLabel from '../../../components/FormLabel';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';

interface TechnicalSheetFormProps {
    formik: any;
    ration: { name: string, description: string, multiplier: number };
    setRation: (ration: { name: string, description: string, multiplier: number }) => void;
}

const TechnicalSheetForm: React.FC<TechnicalSheetFormProps> = ({ formik, ration, setRation }) => {
    return (
        <>
            <div className={classNames({ 'd-none': formik.values.rations.length === 0 })}>
                <div className='row'>
                    <FormLabel label='Nombre' cols={4} />
                    <FormLabel label='Multiplicador' cols={2} />
                    <FormLabel label='Descripción' cols={5} />
                </div>
                {formik.values.rations.map((r: any, index: number) => {
                    if (r.deleted) return null;
                    return (
                        <div key={index} className='row mb-4'>
                            <FormGroup className='col-md-4' error={formik.errors.rations && formik.errors.rations[index]?.name}>
                                <Input
                                    name={`rations[${index}].name`}
                                    value={r.name}
                                    onChange={(e: any) => {
                                        formik.setFieldValue(`rations[${index}].name`, e.target.value);
                                        if (r.exists) {
                                            formik.setFieldValue(`rations[${index}].updated`, true);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </FormGroup>

                            <FormGroup className='col-md-2' error={formik.errors.rations && formik.errors.rations[index]?.multiplier}>
                                <Input
                                    name={`rations[${index}].multiplier`}
                                    step={0.01}
                                    value={r.multiplier}
                                    onChange={(e: any) => {
                                        formik.setFieldValue(`rations[${index}].multiplier`, e.target.value);
                                        if (r.exists) {
                                            formik.setFieldValue(`rations[${index}].updated`, true);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </FormGroup>

                            <FormGroup className='col-md-5' error={formik.errors.rations && formik.errors.rations[index]?.description}>
                                <Input
                                    name={`rations[${index}].description`}
                                    value={r.description}
                                    onChange={(e: any) => {
                                        formik.setFieldValue(`rations[${index}].description`, e.target.value);
                                        if (r.exists) {
                                            formik.setFieldValue(`rations[${index}].updated`, true);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </FormGroup>

                            <div className="col-md-1 d-flex justify-content-end align-items-end">
                                <Button
                                    color="danger"
                                    size='sm'
                                    onClick={() => {
                                        if (r.exists) {
                                            formik.setFieldValue(`rations[${index}].deleted`, true);
                                            return;
                                        } else {
                                            const updatedRations = formik.values.rations.filter((r: any, i: number) => i !== index);
                                            formik.setFieldValue('rations', updatedRations);
                                        }
                                    }}
                                >
                                    <FaRegTrashAlt />
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className='row'>
                <FormGroup label='Nombre' className='col-md-4' requiredInputLabel>
                    <Input
                        name='name'
                        value={ration.name}
                        onChange={(e: any) => setRation({ ...ration, name: e.target.value })}
                    />
                </FormGroup>

                <FormGroup label='Multiplicador' className='col-md-2' requiredInputLabel>
                    <Input
                        name='multiplier'
                        step={0.01}
                        value={ration.multiplier}
                        onChange={(e: any) => setRation({ ...ration, multiplier: e.target.value })}
                    />
                </FormGroup>

                <FormGroup label='Descripción' className='col-md-5'>
                    <Input
                        name='description'
                        value={ration.description}
                        onChange={(e: any) => setRation({ ...ration, description: e.target.value })}
                    />
                </FormGroup>

                <div className='col-md-1 d-flex justify-content-end align-items-end'>
                    <Button
                        color='secondary'
                        size='sm'
                        isDisable={ration.name === '' || ration.multiplier < 0}
                        onClick={() => {
                            formik.setFieldValue('rations', [...formik.values.rations, ration]);
                            setRation({ name: '', description: '', multiplier: 1 });
                        }}
                    >
                        <MdAdd className='h-5 w-5' />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default TechnicalSheetForm;