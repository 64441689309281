import { APPOINTMENT_STATUSES } from '../../type/appointment-type';
import { RestServiceConnection } from '../restServiceConnection';

const APPOINTMENT_ENDPOINT = '/appointments';

export class AppointmentService extends RestServiceConnection {

    createAppointment = async (appointment: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/create',
            data: appointment
        }, true);
        return this;
    }

    updateAppointment = async (appointment: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/edit',
            data: appointment
        }, true);
        return this;
    }


    getTypes = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: APPOINTMENT_ENDPOINT + '/types',
        }, true);
        return this;
    }

    getAppointments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/list',
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    deleteAppointment = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/delete',
            data: {
                appointment: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    listClients = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/list-clients',
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    listUsers = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/list-users',
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    getStatusById = (status: number) => {
        const statusObj = APPOINTMENT_STATUSES.find((item) => item.id === status);

        return statusObj ? statusObj : null;
    }

    exportPDF = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/export-pdf',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    exportExcel = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/export-excel',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    //-----------------------------------------------------------------------------------------------
    // PATIENT METHODS
    //-----------------------------------------------------------------------------------------------

    updateAppointmentPatient = async (values: any) => {   
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/edit-by-patient',
            data: values,
        }, true);
        return this;
    }

    updateAppointmentConceptPatient = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/edit-concept',
            data: values,
        }, true);
        return this;
    }

    createAppointmentPatient = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/create-by-patient',
            data: values,
        }, true);
        return this;
    }

    confirmAppointmentPatient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/confirm',
            data: {
                appointment: id
            }
        }, true);
        return this;
    }

    doneAppointmentPatient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/done',
            data: {
                appointment: id
            }
        }, true);
        return this;
    }

    updatePaymentMethodPatient = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: APPOINTMENT_ENDPOINT + '/update-payment-method',
            data: values,
        }, true);
        return this;
    }
}