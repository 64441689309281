import moment from "moment";
import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTabItem, CardTitle } from "../../../components/bootstrap/Card";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import Documents from "../../../components/document/Documents";
import useFetch from "../../../hooks/useFetch";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import { administrationMenu } from "../../../menu";
import { UserService } from "../../../services/users/userService";
import { User } from "../../../type/user-type";
import PersonalInfoCard from "./PersonalInfoCard";
import UserHasMenus from "./UserHasMenus";
import UserHasPatients from "./UserHasPatients";

const UserProfile: FC = () => {

  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [data, loading] = useFetch(useCallback(async () => {
    const response = await (new UserService()).getUserById(id as string);
    return response.getResponseData() as User;
  }, []));

  if (loading) return <Loader />;
  if (!data) return null;

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="secondary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(-1)}
          />

          <SubheaderSeparator />

          <div className="d-flex align-items-center">
            <span className="fs-4 fw-bold me-4">{data.name + ' ' + (data.lastName ? data.lastName : '')}</span>
            <span className="border border-success border-2 text-success fw-bold px-3 py-2 rounded">
              {data.userRoles[0].role.name || 'Sin rol asignado'}
            </span>
            <span className="text-muted ms-4">Último acceso: {moment(data.lastLogin.date).format('DD/MM/YYYY HH:mm')}</span>
          </div>
        </SubHeaderLeft>
        <SubHeaderRight>
          <Button
            color="brand"
            icon="Edit"
            onClick={() => navigate(`${administrationMenu.users.path}/${id}/edit`, { replace: true })}
          >
            Editar
          </Button>
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <div className="row">
          <div className="col-lg-4">
            <PersonalInfoCard
              company={data.userRoles[0].company ? data.userRoles[0].company.name : 'Sin organización asignada'}
              email={data.email}
              dni={data.dni}
              profileImg={data.profileImg?.id}
              phone={data.telephone}
              address={data.address}
            />
          </div>

          <div className="col-lg-8">
            <Card hasTab>
              <CardTabItem id='patients' title='Pacientes' icon='Contacts'>
                <CardTitle>Pacientes asignados</CardTitle>
                <UserHasPatients id={data.id} />
              </CardTabItem>

              <CardTabItem id='menus' title='Menús nutricionales' icon='Contacts'>
                <CardTitle className="mb-4">Listado de plantillas y menús</CardTitle>
                <UserHasMenus id={data.id} />
              </CardTabItem>

              <CardTabItem id='documents' title='Documentos' icon='Article'>
                <Documents id={id} isPatient={false} />
              </CardTabItem>
            </Card>
          </div>
        </div>
      </Page>
    </Fragment>
  );
};

export default UserProfile;