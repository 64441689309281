import classNames from "classnames";
import { useFormik } from "formik";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useIdle } from "react-use";
import Accordion, { AccordionItem } from "../../../components/bootstrap/Accordion";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import SpinnerLogo from "../../../components/bootstrap/SpinnerLogo";
import Input from "../../../components/bootstrap/forms/Input";
import Icon from "../../../components/icon/Icon";
import NutritionalMenu from "../../../components/menu/NutritionalMenu";
import { MenuDataProvider } from "../../../components/menu/providers/MenuDataProvider";
import { MenuProvider } from "../../../components/menu/providers/MenuProvider";
import useFetch from "../../../hooks/useFetch";
import SubHeader from "../../../layout/SubHeader/SubHeader";
import { authMenu } from "../../../menu";
import { MenuService } from "../../../services/menus/menuService";
import { MultipliersService } from "../../../services/multipliers/MultipliersService";
import { PatientService } from "../../../services/patients/patientService";
import { Menu, MenusApiResponse } from "../../../type/clientMenus-type";
import BasalMetabolismMenu from "./components/BasalMetabolismMenu";
import EditWeightHeightMenu from "./components/EditWeightHeightMenu";
import MultipliersTable from "./components/MultipliersTable";
import ObjectivesMenuTable from "./components/ObjectivesMenuTable";
import PatientRestrictions from "./components/PatientRestrictions";

export interface MenuFormEdit {
    name: string;
    description?: string;
}

export const menuInitialValues: MenuFormEdit = {
    name: '',
    description: ''
}

const MenuView: FC = () => {

    const navigate = useNavigate();
    const { id = '', isTemplate = '' } = useParams<{ id: string, isTemplate: string }>();
    const isIdle = useIdle(500); // Tiempo de inactividad
    const menuService = new MenuService();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [openRestrictions, setOpenRestrictions] = useState(false);
    const [openEditWeightHeight, setOpenEditWeightHeight] = useState(false);
    const [isUpdated, setIsUpdated] = useState(0);

    const [data, loadingData, errorData, refecthData] = useFetch(useCallback(async () => {
        if (isTemplate !== '') {
            const response = (await menuService.getMenuDataById(id as string, null));
            return response.getResponseData() as Menu;
        } else {
            const response = (await menuService.getMenuDataClientByMenuId(id as string, null));
            return response.getResponseData() as Menu;
        }
    }, [id]));

    const [rations] = useFetch(useCallback(async () => {
        if (!data) return;
        const response = (await (new PatientService()).getPatientRations(moment().diff(data?.birthdayDate?.date, 'years')));
        return response.getResponseData();
    }, [data]));

    const [dataObjectives, loadingObjectives, errorObjectives, refetchDataObjectives] = useFetch(useCallback(async () => {
        const response = (await new MenuService().getObjectivesMenu(id, data.id));
        return response.getResponseData();
    }, [data]));

    const [dataMultipliers, loadingMultipliers, errorMultipliers, refetchDataMultipliers] = useFetch(useCallback(async () => {
        const response = (await new MultipliersService().getMultipliersByMenu(id));
        return response.getResponseData();
    }, [id, data]));

    // Envia el formulario a la api
    const handleUpdateMenu = async (values: any) => {
        values.menu = id as string;
        try {
            const response = await menuService.editMenu(values);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                //toast.success('Nombre del menú editado correctamente');
            } else {
                toast.error(responseData.message || "Error al editar el menú");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const formik = useFormik({
        initialValues: menuInitialValues,
        onSubmit: () => { }
    });

    // Actualiza el estado name
    useEffect(() => {
        if (data && data.menus?.length > 0 && isTemplate == '') {
            setName(data.menus[0].name);
            setDescription(data.menus[0].description ? data.menus[0].description : '');
        } else if (data && isTemplate !== '') {
            setName(data.name);
            setDescription(data.description);
        }
    }, [data]);

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            name: name || '',
            description: description || ''
        });
    }, [name]);

    // Actualiza el nombre del menú cuando se deja de escribir en el input
    useEffect(() => {
        if (isIdle) {
            setTimeout(() => handleUpdateMenu(formik.values), 2000);
        }
    }, [isIdle, formik.values]);

    return (
        data !== null && isTemplate == ''
            ? (
                <MenuProvider>
                    <SubHeader>
                        <div className="d-flex align-items-center w-100">
                            {/* Botón de regreso */}
                            <Button color="secondary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />

                            {/* Formulario centrado */}
                            <form onSubmit={formik.handleSubmit} className="d-flex flex-grow-1 mx-3" autoComplete="off">
                                <div className="me-2" style={{ width: "200px" }}>
                                    <Input
                                        id="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="flex-grow-1">
                                    <Input
                                        id="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </form>

                            <div className="d-flex ms-auto">
                                <Button
                                    color="primary"
                                    icon="Edit"
                                    onClick={() => setOpenEditWeightHeight(true)}
                                >
                                    Actualizar peso y altura
                                </Button>

                                <Button
                                    isLink
                                    color="link"
                                    icon="Search"
                                    onClick={() => setOpenRestrictions(true)}
                                    className="ms-2"
                                >
                                    Ver restricciones
                                </Button>
                            </div>
                        </div>
                    </SubHeader>

                    {/* Si existe un paciente, muestra los datos del paciente */}
                    {data && (
                        <>
                            <Card className="rounded-2 my-3">
                                <CardBody className="p-2">
                                    <div className="py-0 ps-4">
                                        <div className='row'>
                                            <div className='d-flex align-items-center w-auto p-0'>
                                                <a
                                                    className='fw-bold fs-6 mb-0 cursor-pointer text-secondary text-decoration-none'
                                                    href={authMenu.patients.path + `/${data.id}/view`}
                                                >
                                                    <Icon icon="Person" className="text-primary fs-3 mx-2" />
                                                    {(data.name + ' ' + (data.lastName || ''))}
                                                </a>
                                            </div>

                                            <div className="d-flex align-items-center w-auto ms-3">
                                                <div className='d-flex flex-column p-0'>
                                                    <div className='fw-bold fs-6 mb-0'>
                                                        <Icon icon="Email" className="text-primary fs-3 mx-2" />
                                                        {data.email
                                                            ? data.email
                                                            : <div className="text-muted">No proporcionado</div>
                                                        }
                                                    </div>

                                                    <div className='fw-bold fs-6 mb-0'>
                                                        <Icon icon="Phone" className="text-primary fs-3 mx-2" />
                                                        {data.telephone
                                                            ? data.telephone
                                                            : <div className="text-muted">No proporcionado</div>
                                                        }
                                                    </div>

                                                    <div className='d-flex align-items-center'>
                                                        <div className='fw-bold fs-6 mb-0'>
                                                            <Icon icon="Height" className="text-primary fs-3 mx-2" />
                                                            Altura:
                                                        </div>
                                                        <span className="fw-normal ms-2">{(data.menus?.length > 0 && data.menus[0]?.height) ? data.menus[0].height + ' cm' : 'No añadido al menú'}</span>
                                                    </div>

                                                    <div className='d-flex align-items-center'>
                                                        <Icon icon="Accessibility" className="text-primary fs-3 mx-2" />
                                                        <div className='fw-bold fs-6 mb-0'>Peso:</div>
                                                        <span className="fw-normal ms-2">{(data.menus?.length > 0 && data.menus[0]?.weight) ? data.menus[0].weight + ' kg' : 'No añadido al menú'}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center w-auto ms-3">
                                                <div className='d-flex flex-column p-0'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='fw-bold fs-6 mb-0'>
                                                            <Icon icon={data.sex === 'H' ? 'Male' : 'Female'} className="text-primary fs-3 mx-2" />
                                                            {data.sex === 'H' ? 'Hombre' : 'Mujer'}
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        {/* Icono a la izquierda */}
                                                        <Icon icon="Cake" className="text-primary fs-3 mx-2" />

                                                        {/* Texto alineado verticalmente */}
                                                        <div className="d-flex flex-column">
                                                            <span className="fw-bold fs-6">{moment().diff(data.birthdayDate?.date, 'years')} años</span>
                                                            <span className="fw-normal">{moment(data.birthdayDate?.date).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.clientAversions?.length < 1 })}>
                                                <span className='fw-bold'>Aversiones</span>
                                                {data?.clientAversions?.map((aversion: any) => (
                                                    <li key={aversion.id} style={{ fontSize: '12px' }}>
                                                        {aversion.foodGroup ? aversion.foodGroup?.name : aversion.food?.name}
                                                    </li>
                                                ))}
                                            </ul>

                                            <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.intolerances?.length < 1 })}>
                                                <span className='fw-bold'>Intolerancias</span>
                                                {data?.intolerances?.map((allergy: any) => (
                                                    <li key={allergy.id} style={{ fontSize: '12px' }}>
                                                        {allergy.intolerance?.name}
                                                    </li>
                                                ))}
                                            </ul>

                                            <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.allergies?.length < 1 })}>
                                                <span className='fw-bold'>Alergias</span>
                                                {data?.allergies?.map((allergy: any) => (
                                                    <li key={allergy.id} style={{ fontSize: '12px' }}>
                                                        {allergy.allergy?.name}
                                                    </li>
                                                ))}
                                            </ul>

                                            <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.clientHasPathologies?.length < 1 })}>
                                                <span className='fw-bold'>Patologías</span>
                                                {data?.clientHasPathologies?.map((p: any) => (
                                                    <li key={p.pathology.id} style={{ fontSize: '12px' }}>
                                                        {p.pathology?.name}
                                                    </li>
                                                ))}
                                            </ul>

                                            {data?.clientHasPhysiologicalStates?.length > 0 && (
                                                <div className={classNames("d-flex flex-column w-auto ms-3")}>
                                                    <span className='fw-bold'>Estado fisiológico</span>
                                                    <span style={{ fontSize: '12px' }}>{data?.clientHasPhysiologicalStates[0]?.physiologicalState?.name}</span>
                                                </div>
                                            )}

                                            <div className={classNames("d-flex flex-column w-auto ms-3", { "d-none": !rations || rations?.length < 1 })}>
                                                <span className='fw-bold'>Raciones ({rations?.name})</span>
                                                {rations?.foodGroupsConfig?.map((r: any, index: number) => {
                                                    let fg = r.foodGroups.map((fg: any) => fg).join(', ');
                                                    let qty = r.quantity > 1000 ? `${(r.quantity / 1000)} kg` : `${r.quantity} g`;
                                                    return (
                                                        <div key={index} style={{ fontSize: '12px' }}>
                                                            {fg}: {qty}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <Accordion id={'accordionMenuView'} className="mb-3">
                                <AccordionItem
                                    title="Datos relevantes para el menú"
                                    key={`menu`}
                                    id={`menu`}
                                    className={"col-md-12"}
                                >
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-md-6">
                                            <h4 className="fw-bold mb-3"> Metabolismo basal</h4>
                                            {(data.basalMetabolism !== null && data.menus?.length > 0 && data.menus[0].weight && data.menus[0].height)
                                                ? <BasalMetabolismMenu clientData={data} weight={data.menus[0].weight} height={data.menus[0].height} />
                                                : <div className="text-muted">No se ha configurado la formula del Metabolismo basal en la organización </div>
                                            }
                                        </div>
                                        <div className="col-md-3">
                                            <h4 className="fw-bold mb-3">Objetivos del menú</h4>
                                            <ObjectivesMenuTable clientId={data.id} menuId={id} isUpdated={isUpdated} setIsUpdated={setIsUpdated} dataObjectives={dataObjectives} refetchDataObjectives={refetchDataObjectives} />
                                        </div>
                                    </div>
                                </AccordionItem>
                            </Accordion>

                            <Accordion id={'accordionMenuView'} className="mb-3">
                                <AccordionItem
                                    title="Tabla de multiplicadores del menú"
                                    key={`menu`}
                                    id={`menu`}
                                    className={"col-md-12 "}
                                >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="fw-bold mb-3">Multiplicadores por grupo de alimentos</h4>
                                            <MultipliersTable dataMultipliers={dataMultipliers?.multiplier} refetchMultipliers={refetchDataMultipliers} rations={rations} />
                                        </div>
                                    </div>
                                </AccordionItem>
                            </Accordion>
                        </>
                    )}


                    {/* Provider que permite dar contexto para hacer drag & drop */}
                    <DndProvider backend={HTML5Backend}>
                        <MenuDataProvider>
                            {(data.menus?.length > 0) && <NutritionalMenu menuId={data.menus[0].id} client={data} isUpdated={isUpdated} dataObjectives={dataObjectives} refetchDataObjectives={refetchDataObjectives} />}
                        </MenuDataProvider>
                    </DndProvider>

                    {/* Modal que muestra las restricciones del paciente */}
                    <PatientRestrictions isOpen={openRestrictions} setIsOpen={setOpenRestrictions} data={data} />
                    {(data.menus?.length > 0) && <EditWeightHeightMenu isOpen={openEditWeightHeight} setIsOpen={setOpenEditWeightHeight} menuData={data.menus[0]} refecthData={refecthData} />}
                </MenuProvider>
            )
            : data !== null && isTemplate !== ''
                ? (
                    <>
                        <MenuProvider>
                            <SubHeader>
                                <div className="d-flex align-items-center w-100">
                                    {/* Botón de regreso */}
                                    <Button color="secondary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />

                                    {/* Formulario centrado */}
                                    <form onSubmit={formik.handleSubmit} className="d-flex flex-grow-1 mx-3" autoComplete="off">
                                        <div className="me-2" style={{ width: "200px" }}>
                                            <Input
                                                id="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                id="description"
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </SubHeader>
                            <DndProvider backend={HTML5Backend}>
                                <MenuDataProvider>
                                    <NutritionalMenu menuId={data.id} client={null} isUpdated={isUpdated} dataObjectives={dataObjectives} refetchDataObjectives={refetchDataObjectives} />
                                </MenuDataProvider>
                            </DndProvider>
                        </MenuProvider>
                    </>
                )
                : <SpinnerLogo />
    );
};

export default MenuView;