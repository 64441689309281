import { FC, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { authMenu } from "../../../menu";
import CardTab from "./components/PatientCardTab";

const PatientProfile: FC = () => {

    const navigate = useNavigate();
    const { id = "" } = useParams<{ id: string }>();

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="brand"
                        icon="Edit"
                        onClick={() => navigate(`${authMenu.patients.path}/${id}/edit`, { replace: true })}
                    >
                        Editar
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid" className="p-0">
                <CardTab />
            </Page>
        </Fragment>
    );
};

export default PatientProfile;