import React, { useState } from 'react';
import { SketchPicker, TwitterPicker, CompactPicker, BlockPicker } from 'react-color';

interface ColorPickerProps {
    initialColor?: string;
    onChange: (color: any) => void;
    useSketchPicker?: boolean;
    useTwitterPicker?: boolean;
    useCompactPicker?: boolean;
    useBlockPicker?: boolean;
}

const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1,
    } : {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
    };
};

const ColorPicker: React.FC<ColorPickerProps> = ({
    initialColor = '#ffffff',
    onChange,
    useSketchPicker = false,
    useTwitterPicker = false,
    useCompactPicker = false,
    useBlockPicker = false,
}) => {
    const [color, setColor] = useState(hexToRgb(initialColor));

    const handleColorChange = (newColor: any) => {
        setColor(newColor.rgb);
        onChange(newColor);
    };

    return (
        <div>
            {useSketchPicker && (
                <SketchPicker
                    color={color}
                    onChangeComplete={handleColorChange}
                />
            )}
            {useTwitterPicker && (
                <TwitterPicker
                    color={color}
                    onChangeComplete={handleColorChange}
                />
            )}
            {useCompactPicker && (
                <CompactPicker
                    color={color}
                    onChangeComplete={handleColorChange}
                />
            )}
            {useBlockPicker && (
                <BlockPicker
                    color={color}
                    onChangeComplete={handleColorChange}
                />
            )}
            {!useSketchPicker && !useTwitterPicker && !useCompactPicker && !useBlockPicker && (
                <SketchPicker
                    color={color}
                    onChangeComplete={handleColorChange}
                />
            )}
        </div>
    );
};

export default ColorPicker;
