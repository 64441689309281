import classNames from "classnames";
import { useFormik } from "formik";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { CiApple } from "react-icons/ci";
import { GiForkKnifeSpoon } from "react-icons/gi";
import { LiaTemperatureLowSolid } from "react-icons/lia";
import { PiChefHatDuotone } from "react-icons/pi";
import { toast } from "react-toastify";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import Swal from "sweetalert2";
import * as yup from "yup";
import AsyncImg from "../../../../components/AsyncImg";
import ErrorMessage from "../../../../components/ErrorMessage";
import { CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader } from "../../../../components/bootstrap/Modal";
import Spinner from "../../../../components/bootstrap/Spinner";
import { Loader } from "../../../../components/bootstrap/SpinnerLogo";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import PlaceholderImage from "../../../../components/extras/PlaceholderImage";
import Icon from "../../../../components/icon/Icon";
import { macronutrients } from "../../../../components/menu/NutritionalMenuValues";
import useAllergens from "../../../../hooks/api-calls/useAllergens";
import useFetch from "../../../../hooks/useFetch";
import { RecipeService } from "../../../../services/recipes/recipeService";
import { Recipe } from "../../../../type/recipes-type";
import { FixNumber } from "../../../../utils/FixNumber";
import useHandleErrors from "../../../../utils/hooks/useHandleErrors";
import { COLORS, DIFFICULTY, RATION_PRESENTATION, STORAGE_CONDITIONS } from "../../../../utils/mapping-collection";
import RecipeIngredients from "./RecipeIngredients";

interface EditRecipeModalProps {
    id: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

interface RecipeForm {
    name: string,
    duration: number,
    difficulty: string,
    foodGroup: string,
    storageConditions: string,
    presentation?: string,
    ingredients: { id: string, quantity: number }[],
}

const schema = yup.object({
    name: yup.string(),
    duration: yup.number().required('La duración es obligatoria'),
    difficulty: yup.string().required('La dificultad es obligatoria'),
    storageConditions: yup.string().nullable().notRequired(),
    presentation: yup.string().nullable().notRequired(),
    ingredients: yup.array().of(
        yup.object().shape({
            id: yup.string().required('El ingrediente es obligatorio'),
            quantity: yup.number().required('La cantidad es obligatoria'),
        })
    ),
});

const EditRecipeModal: React.FC<EditRecipeModalProps> = ({ id, isOpen, setIsOpen }) => {

    // HOOKS

    const { handleErrors } = useHandleErrors();
    const { groupNutrients } = useAllergens();

    // STATES

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nutrientsGroups, setNutrientsGroups] = useState<any>([]);
    const [ingredient, setIngredient] = useState<{ id: string[], quantity: number }>({ id: [], quantity: 0 });

    // FETCH DATA

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = (await (new RecipeService()).getRecipeById(id as string, null));
        return response.getResponseData() as Recipe;
    }, [id]));

    const [nutritionalValues] = useFetch(useCallback(async () => {
        const response = (await (new RecipeService()).getNutritionalValues(id));
        return response.getResponseData();
    }, [id]));

    // FUNCTIONS
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN Create a new recipe
     * @ES Crea una nueva receta
     * @param values 
     */
    const handleCreate = async (values: any) => {
        setIsLoading(true);

        values.ingredients = values.ingredients.map((ingredient: any, index: number) => {
            return {
                food: ingredient.id,
                quantity: ingredient.quantity,
                order_number: index,
            }
        });

        try {
            const response = await (await (new RecipeService()).createRecipe(values)).getResponseData();
            if (response.success) {
                toast.success("Receta creada correctamente");
                setIsOpen(false);
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error("Error al crear la receta");
        } finally {
            setIsLoading(false);
        }
    };
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN Show a swal to save the recipe with another name
     * @ES Muestra un swal para guardar la receta con otro nombre
     * @param values
     */
    const handleSaveAs = (values: any) => {
        const newValues = values;
        Swal.fire({
            title: "Guardar como",
            text: "Introduce el nombre de la nueva receta para evitar sobreescribir la original",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Guardar",
            confirmButtonColor: "#28a745",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: async (e) => {
                newValues.name = e;
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                handleCreate(newValues);
            }
        });
    };
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN Customized label for the pie chart
     * @ES Etiqueta personalizada para el gráfico circular
     * @param param0
     * @returns
     */
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        // Obtiene los nutrientes de los macronutrientes
        const data = nutrientsGroups.find((group: any) => group.group === 'Macronutrients')?.nutrients?.filter((nutrient: any) => macronutrients.includes(nutrient.name));

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
        const name = data[index]?.name === 'starch' ? 'HC' : data[index]?.nombre.replace(/\(\w+\)/, '');
        const value = data[index]?.value;
        const unitMeasure = data[index]?.nombre.match(/\(([^)]+)\)/)[1];

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {/* <tspan x={x < cx ? x + 22 : x - 22} dy="0em">{name}</tspan> */}
                <tspan x={x < cx ? x + 22 : x - 22} dy="0em">{`${value} ${unitMeasure}`}</tspan>
            </text>
        );
    };
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik<RecipeForm>({
        initialValues: {
            name: '',
            duration: 0,
            difficulty: 'Fácil',
            foodGroup: '',
            storageConditions: '',
            presentation: '',
            ingredients: [],
        },
        validationSchema: schema,
        onSubmit: values => handleSaveAs(values),
    });

    const verifyClass = (inputFieldID: keyof RecipeForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof RecipeForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
    };

    // USE EFFECT

    useEffect(() => {
        if (nutritionalValues) {
            setNutrientsGroups(groupNutrients(nutritionalValues));
        }
    }, [nutritionalValues]);

    useEffect(() => {
        if (data) {
            formik.setValues({
                name: data.name,
                duration: data.duration,
                difficulty: data.difficulty,
                foodGroup: data.foodGroup?.id,
                ingredients: data.ingredients?.map((ingredient: any) => { return { id: ingredient.food?.id, quantity: ingredient.quantity } }),
                storageConditions: data.storageConditions,
                presentation: data.presentation,
            });
        }
    }, [data]);

    // RENDER

    if (loading) return <Loader height='100vh' />;
    if (error) return <ErrorMessage />;
    if (!data) return null;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Información de la receta' isStaticBackdrop>
            <ModalHeader className="p-4">
                <CardLabel title="Nombre" className="bg-secondary text-white rounded py-3 px-5" style={{ width: 'fit-content' }}>
                    <CardTitle>{data.name}</CardTitle>
                </CardLabel>
                <div className="d-flex justify-content-end align-items-center">
                    <button
                        type="submit"
                        form='edit-recipe-modal'
                        title="Guardar receta"
                        disabled={isLoading}
                        className="me-5"
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                    >
                        {isLoading ? <Spinner color={'dark'} /> : <Icon icon="Save" color="dark" size={'2x'} />}
                    </button>

                    <Icon
                        icon="Close"
                        size={'2x'}
                        onClick={() => {
                            setIsOpen(false);
                            setIngredient({ id: [], quantity: 0 });
                        }}
                        title="Cerrar"
                        className="cursor-pointer"
                    />
                </div>
            </ModalHeader>

            <ModalBody className="row d-flex justify-content-between px-3">
                <form id='edit-recipe-modal' onSubmit={formik.handleSubmit} autoComplete="off">
                    <>
                        <div className="row">
                            <div className="col-md-3">
                                {data?.RecipeImage?.id
                                    ? <AsyncImg id={data.RecipeImage.id} isBackground styles="d-block img-fluid rounded object-cover" />
                                    : <PlaceholderImage width={300} height={200} className='d-block img-fluid rounded' />
                                }
                            </div>

                            <div className="col-md-5">
                                <ul className="fs-5">
                                    <li className="d-flex align-items-center">
                                        <Icon icon='AccessTime' size={'2x'} className="me-3" />
                                        <Input
                                            id='duration'
                                            type='number'
                                            onChange={formik.handleChange}
                                            value={formik.values.duration}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('duration')}
                                        />
                                        {showErrors('duration')}
                                    </li>

                                    <li className="d-flex align-items-center mt-2">
                                        <PiChefHatDuotone className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        <Select
                                            id="difficulty"
                                            onChange={(selectedOption: ChangeEvent<HTMLInputElement>) => {
                                                formik.handleChange(selectedOption);
                                                formik.setFieldValue('difficulty', selectedOption.target.value);
                                            }}
                                            value={formik.values.difficulty}
                                            placeholder='Elegir dificultad...'
                                            ariaLabel="Dificultad"
                                            onBlur={formik.handleBlur}
                                            list={DIFFICULTY}
                                        />
                                        {showErrors('difficulty')}
                                    </li>

                                    <li className={classNames("d-flex align-items-center mt-2", { 'd-none': !data.foodGroup })}>
                                        <CiApple className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        {data.foodGroup.name}
                                    </li>

                                    <li className={classNames("d-flex align-items-center mt-2", { 'd-none': !data.presentation })}>
                                        <GiForkKnifeSpoon className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        <Select
                                            id="presentation"
                                            onChange={(selectedOption: ChangeEvent<HTMLInputElement>) => {
                                                formik.handleChange(selectedOption);
                                                formik.setFieldValue('presentation', selectedOption.target.value);
                                            }}
                                            value={formik.values.presentation}
                                            placeholder='Elegir presentación...'
                                            ariaLabel="presentation"
                                            onBlur={formik.handleBlur}
                                            list={RATION_PRESENTATION}
                                        />
                                        {showErrors('presentation')}
                                    </li>

                                    <li className={classNames("d-flex align-items-center mt-2", { 'd-none': !data.storageConditions })}>
                                        <LiaTemperatureLowSolid className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        <Select
                                            id="storageConditions"
                                            onChange={(selectedOption: ChangeEvent<HTMLInputElement>) => {
                                                formik.handleChange(selectedOption);
                                                formik.setFieldValue('storageConditions', selectedOption.target.value);
                                            }}
                                            value={formik.values.storageConditions}
                                            placeholder='Elegir condiciones de almacenamiento...'
                                            ariaLabel="storageConditions"
                                            onBlur={formik.handleBlur}
                                            list={STORAGE_CONDITIONS}
                                        />
                                        {showErrors('storageConditions')}
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-4">
                                <CardLabel title="Nombre" className="bg-secondary-light rounded py-3 px-5" style={{ width: 'fit-content' }}>
                                    <CardTitle>Información nutricional</CardTitle>
                                </CardLabel>

                                {(nutrientsGroups.length > 0) && nutrientsGroups.map((nutrientGroup: any) => {
                                    let data;
                                    if (nutrientGroup.group === 'Macronutrients') {
                                        data = nutrientGroup.nutrients.filter((nutrient: any) => macronutrients.includes(nutrient.name));
                                    }

                                    data = data?.map((nutrient: any) => {
                                        return {
                                            name: nutrient.nombre,
                                            value: nutrient.value,
                                        }
                                    });

                                    return (
                                        <div key={nutrientGroup.group}>
                                            {nutrientGroup.group === 'Macronutrients' && data?.length > 0 && data.reduce((acc: number, nutrient: any) => acc + nutrient.value, 0) > 0 && (
                                                <ResponsiveContainer width='100%' height={180}>
                                                    <div className="d-flex flex-row">
                                                        <PieChart width={180} height={180}>
                                                            <Pie
                                                                data={data.filter((nutrient: any) => nutrient.value > 0)}
                                                                cx="50%"
                                                                cy="50%"
                                                                labelLine={false}
                                                                label={renderCustomizedLabel}
                                                                outerRadius={80}
                                                                fill="#8884d8"
                                                                dataKey="value"
                                                            >
                                                                {data.map((entry: any, index: number) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % data.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>

                                                        <div className="d-flex flex-column justify-content-center ml-4">
                                                            {data.map((entry: any, index: number) => (
                                                                <div key={`legend-${index}`} className="d-flex flex-row align-items-center">
                                                                    <div style={{ width: '10px', height: '10px', backgroundColor: COLORS[index % data.length] }}></div>
                                                                    <div className="ms-2">{entry.name === 'HC complejos (g)' ? 'HC (g)' : entry.name}:</div>
                                                                    <div className="ms-2">{FixNumber(entry.value)}</div>
                                                                </div>
                                                            ))}
                                                            <div className="text-center">Energía: {Math.round(nutrientGroup.nutrients.find((nutrient: any) => nutrient.name === 'energy')?.value)} Kcal</div>
                                                        </div>
                                                    </div>
                                                </ResponsiveContainer>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>

                    <div className="mt-5">
                        <div className="d-flex">
                            <CardLabel title="Nombre" className="bg-secondary-light rounded py-3 px-5 me-3" style={{ width: 'fit-content' }}>
                                <CardTitle>Ingredientes</CardTitle>
                            </CardLabel>
                        </div>

                        <RecipeIngredients formik={formik} ingredient={ingredient} setIngredient={setIngredient} />
                    </div>

                    <div className="mt-5">
                        <CardLabel title="Nombre" className="bg-secondary-light rounded py-3 px-5" style={{ width: 'fit-content' }}>
                            <CardTitle>Elaboración</CardTitle>
                        </CardLabel>
                        <ol className="border rounded border-secondary list-unstyled fs-5 p-3 mt-3">
                            {data.process?.map((step: any, index: number) => (
                                <li key={index} className="mb-3">
                                    <span className="fw-bold">{index + 1}.</span> {step}
                                </li>
                            ))}
                        </ol>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default EditRecipeModal;