import moment from "moment";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import useFetch from "../../hooks/useFetch";
import { menusMenu } from "../../menu";
import AssignTemplateModal from "../../pages/menus/menu-list/components/AssignTemplateModal";
import CloneModal from "../../pages/menus/menu-list/components/CloneModal";
import { MenuService } from "../../services/menus/menuService";
import { MenusApiResponse } from "../../type/clientMenus-type";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import Spinner from "../bootstrap/Spinner";
import Tooltips from "../bootstrap/Tooltips";
import Icon from "../icon/Icon";
import { usePrivilege } from "../priviledge/PriviledgeProvider";
import { CustomTable } from "../table/CustomTable";

interface MenusTabProps {
    filters: any;
    updateFilters: any;
    updateFilterOrder: any;
    updatePage: any;
    updatePageSize: any;
    page: string;
}

const MenusTab: FC<MenusTabProps> = ({ filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, page }) => {

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();

    const [selectedMenuId, setSelectedMenuId] = useState<string>('');
    const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [data, loading, menusError, refetchMenus] = useFetch(useCallback(async () => {
        const menuService = new MenuService();
        const response = await menuService.listMenusFilters(filters);
        return response.getResponseData() as MenusApiResponse;
    }, [filters]));

    const _handleSendMenuToPatient = async (menuId: string) => {
        setIsSending(true);
        try {
            const response = await (await (new MenuService()).sendMenuToClient(menuId)).getResponseData();
            if (response.success) {
                toast.success('Menú enviado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al enviar el menú al paciente");
        } finally {
            setIsSending(false);
        }
    };

    const _handleSendNotificationToPatient = async (menuId: string) => {
        try {
            const response = await (await (new MenuService()).sendMenuByNotificationToPatient(menuId)).getResponseData();
            if (response.success) {
                toast.success('Menú enviado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al enviar el menú al paciente");
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new MenuService()).deleteMenu(id)).getResponseData();
            if (response.success) {
                refetchMenus();
                updateFilters({ ...filters });

                setTimeout(() => {
                    toast.success('Menú eliminado correctamente');
                }, 100);
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el menú');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el menú');
        }
    };

    if (!data) return null;

    return (
        <>
            <CustomTable
                title="Menús nutricionales"
                loading={loading}
                data={data?.menus ? data.menus : null}
                pagination={true}
                paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: data as MenusApiResponse ? data.lastPage : 1,
                    handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                    handlePerPage: updatePageSize,
                }}
                className={"table-striped table-hover"}
                columns={[
                    {
                        name: "Nombre",
                        keyValue: "name",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <div
                                    className="cursor-pointer text-secondary"
                                    onClick={() => {
                                        if (element.isTemplate) {
                                            navigate(menusMenu.menus.path + `/${element.id}/isTemplate/view`);
                                        } else {
                                            navigate(menusMenu.menus.path + `/${element.id}/view`);
                                        }
                                    }}
                                >
                                    {element.name ? element.name : 'Sin nombre'}
                                </div>
                            )
                        },
                    },
                    {
                        name: "Descripción",
                        keyValue: "description",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        style: {
                            maxWidth: "400px"
                        },
                        render: (element: any) => {
                            return (
                                <Tooltips title={element.description || ''} placement="top">
                                    <div
                                        style={{
                                            maxWidth: "600px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {element.description}
                                    </div>
                                </Tooltips>
                            );
                        }
                    },
                    {
                        name: "Fecha de creación",
                        keyValue: "createdAt",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return <>{moment(element.createdAt.date).format('DD/MM/YY HH:mm')}</>
                        },
                    },
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={[
                    {
                        title: "Asignar",
                        icon: "PersonAdd",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: "Asignar menú al paciente",
                        hidden: (page !== 'list') || (filters.filter_filters?.is_template === 0) || !userCan('edit', 'menus'),
                        click: () => { },
                        render: (item: any) => {
                            return (
                                item.isTemplate ? <Icon icon="PersonAdd" className='icon-large me-2' onClick={() => { setIsOpen(true); setSelectedMenuId(item.id) }} /> : <></>
                            );
                        }
                    },
                    {
                        title: "Enviar notificación",
                        icon: "PhoneAndroid",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: "Enviar menú al paciente por notificación",
                        hidden: (filters.filter_filters?.is_template === 1),
                        click: () => { },
                        render: (item: any) => {
                            return (
                                !item.isTemplate ? <Icon icon="PhoneAndroid" className='icon-large me-2' onClick={() => { _handleSendNotificationToPatient(item.id) }} /> : <></>
                            );
                        }
                    },
                    {
                        title: "Enviar por email",
                        icon: "ForwardToInbox",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: "Enviar menú al paciente por email",
                        hidden: (filters.filter_filters?.is_template === 1),
                        click: () => { },
                        callback: (item: any) => { _handleSendMenuToPatient(item.id) },
                        render: (item: any) => {
                            return (
                                !item.isTemplate
                                    ? isSending ? <Spinner color={'dark'} className="me-2" /> : <Icon icon="ForwardToInbox" className='icon-large me-2' />
                                    : <></>
                            );
                        }
                    },
                    {
                        title: "Clonar",
                        icon: "ContentCopy",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: "Clonar menú",
                        hidden: !userCan('create', 'menus'),
                        click: (item: any) => { setOpenCloneModal(true); setSelectedMenuId(item.id) },
                    },
                    {
                        title: "Editar",
                        icon: "Edit",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        description: "Editar menú",
                        hidden: !userCan('edit', 'menus'),
                        click: (item: any) => {
                            if (item.isTemplate) {
                                navigate(menusMenu.menus.path + `/${item.id}/isTemplate/view`);
                            } else {
                                navigate(menusMenu.menus.path + `/${item.id}/view`);
                            }
                        },
                    },
                    {
                        title: "Eliminar",
                        icon: "Delete",
                        buttonType: 'icon',
                        additionalClasses: 'text-danger',
                        description: "Eliminar menú",
                        hidden: !userCan('delete', 'menus'),
                        click: (item: any) => {
                            handleConfirmationAlert({
                                title: "Eliminar menú",
                                text: "¿Está seguro que desea eliminar el menú?",
                                icon: "warning",
                                onConfirm: () => { handleDelete(item.id) }
                            })
                        },
                    },
                ]}
            />

            {isOpen && <AssignTemplateModal isOpen={isOpen} setIsOpen={setIsOpen} menuId={selectedMenuId} refetch={refetchMenus} />}
            {openCloneModal && <CloneModal isOpen={openCloneModal} setIsOpen={setOpenCloneModal} menuId={selectedMenuId} refetch={refetchMenus} isPatientProfile={page === 'patient'} />}
        </>
    );
};

export default MenusTab;