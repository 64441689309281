import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from 'yup';
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardFooter } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Spinner from "../../../../components/bootstrap/Spinner";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../../components/select/SearchableSelect";
import useServiceTypes from "../../../../hooks/api-calls/useServiceTypes";
import { ConsultationTypesService } from "../../../../services/consultation-types/consultationTypesService";
import useHandleErrors from "../../../../utils/hooks/useHandleErrors";
import { APPOINTMENT_TYPES } from "../../../../utils/mapping-collection";

interface ConsultationTypeModalFormProps {
    isOpen: boolean;
    setIsOpen: any;
    refetch: any;
    rowId?: string;
}

interface ConsultationTypeForm {
    name: string;
    duration: number;
    serviceTypeId: string;
    appointmentType: string | number;
    price: number;
}

const initialValues: ConsultationTypeForm = {
    name: '',
    duration: 0,
    serviceTypeId: '',
    appointmentType: 1,
    price: 0,
};

const schema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    duration: yup.number().required('La duración es obligatoria'),
    serviceTypeId: yup.string().required('El tipo de servicio es obligatorio'),
    appointmentType: yup.string().required('El tipo de cita es obligatorio'),
    price: yup.number().required('El precio es obligatorio'),
});

const ConsultationTypeModalForm: FC<ConsultationTypeModalFormProps> = ({ isOpen, setIsOpen, refetch, rowId }) => {

    // STATES

    const [isLoading, setIsLoading] = useState(false);

    // HOOKS

    const { handleErrors } = useHandleErrors();
    const { getServiceTypesOptions } = useServiceTypes();

    // FORMIK

    const formik = useFormik<ConsultationTypeForm>({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: (values) => { rowId ? handleEdit(values) : handleCreate(values) }
    });

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja la creación del tipo de consulta.
     * @EN Function that handles consultation type creation.
     * 
     * @param values 
     */
    //--------------------------------------------------------------------------------------------------
    const handleCreate = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (new ConsultationTypesService()).createConsultationType(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetch();
                setIsOpen(false);
                toast.success('Tipo de consulta creado correctamente');
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al crear el tipo de consulta');
        } finally {
            setIsLoading(false);
        }
    };
    //--------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja la edición del tipo de consulta.
     * @EN Function that handles consultation type edition.
     * 
     * @param values 
     */
    //--------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (new ConsultationTypesService()).editConsultationType(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetch();
                setIsOpen(false);
                toast.success('Tipo de consulta editado correctamente');
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al editar el tipo de consulta');
        } finally {
            setIsLoading(false);
        }
    };
    //------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que verifica si un campo tiene errores.
     * @EN Function that checks if a field has errors.
     * 
     * @param inputFieldID 
     */
    //--------------------------------------------------------------------------------------------------
    const verifyClass = (inputFieldID: keyof ConsultationTypeForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }
    //--------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que muestra los errores de un campo.
     * @EN Function that shows field errors.
     * 
     * @param inputFieldID 
     */
    //--------------------------------------------------------------------------------------------------
    const showErrors = (inputFieldID: keyof ConsultationTypeForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>; }
    //--------------------------------------------------------------------------------------------------

    // USE EFFECT

    useEffect(() => {
        if (rowId) {
            (async () => {
                const response = await (new ConsultationTypesService()).getConsultationType(rowId);
                const responseData = response.getResponseData();
                if (responseData.success) {
                    const values = {
                        consultationTypeId: responseData.data.id,
                        name: responseData.data.name,
                        duration: responseData.data.duration,
                        serviceTypeId: responseData.data.serviceType?.id,
                        appointmentType: Number(responseData.data.appointmentType),
                        price: responseData.data.price,
                    };
                    formik.setValues(values);
                } else {
                    handleErrors(response);
                }
            })();
        } else {
            formik.setValues(initialValues);
        }
    }, [rowId]);

    // RENDER

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del tipo de consulta' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id='consultationType-data'>{rowId ? 'Editar' : 'Crear'} Tipo de Consulta</ModalTitle>
            </ModalHeader>
            <ModalBody className='pb-4'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody>
                        <FormGroup label="Nombre" requiredInputLabel className='pb-3'>
                            <Input
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('name')}
                            />
                            {showErrors('name')}
                        </FormGroup>

                        <FormGroup label="Duración (minutos)" requiredInputLabel className='pb-3'>
                            <Input
                                id="duration"
                                name="duration"
                                type="number"
                                value={formik.values.duration}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('duration')}
                            />
                            {showErrors('duration')}
                        </FormGroup>

                        <FormGroup label="Tipo de servicio" requiredInputLabel className='pb-3'>
                            <SearchableSelect
                                name="serviceTypeId"
                                isClearable
                                options={getServiceTypesOptions()}
                                value={{ value: formik.values.serviceTypeId, label: getServiceTypesOptions().find((option: any) => option.value === formik.values.serviceTypeId)?.label }}
                                onChange={(option: any) => formik.setFieldValue('serviceTypeId', option?.value)}
                                placeholder='tipo de servicio'
                                classname={verifyClass('serviceTypeId')}
                            />
                            {showErrors('serviceTypeId')}
                        </FormGroup>

                        <FormGroup label="Tipo de cita" requiredInputLabel className='pb-3'>
                            <SearchableSelect
                                name="appointmentType"
                                isClearable
                                options={APPOINTMENT_TYPES}
                                value={{ value: formik.values.appointmentType, label: APPOINTMENT_TYPES.find((option: any) => option.value === formik.values.appointmentType)?.label }}
                                onChange={(option: any) => formik.setFieldValue('appointmentType', option?.value)}
                                placeholder='tipo de cita'
                                classname={verifyClass('appointmentType')}
                            />
                            {showErrors('appointmentType')}
                        </FormGroup>

                        <FormGroup label="Precio (€)" requiredInputLabel className='pb-3'>
                            <Input
                                id="price"
                                name="price"
                                type="number"
                                min={0}
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('price')}
                            />
                            {showErrors('price')}
                        </FormGroup>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                        <Button type="submit" isDisable={isLoading} color="primary">
                            {isLoading ? <Spinner color={'dark'} /> : rowId ? 'Guardar Cambios' : 'Crear'}
                        </Button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default ConsultationTypeModalForm;