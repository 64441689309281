import React from 'react';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import classNames from 'classnames';
import FormLabel from '../../../components/FormLabel';

interface WorkplacesFormProps {
    formik: any;
    workplace: { name: string };
    setWorkplace: (workplace: { name: string }) => void;
}

const WorkplacesForm: React.FC<WorkplacesFormProps> = ({ formik, workplace, setWorkplace }) => {
    return (
        <>
            <div className={classNames({ 'd-none': formik.values.workplaces.length === 0 })}>
                <div className='row'>
                    <FormLabel label='Nombre' cols={11} />
                </div>
                {formik.values.workplaces.map((r: any, index: number) => {
                    if (r.deleted) return null;
                    return (<div key={index} className='row mb-4'>
                        <FormGroup className='col-md-11' error={formik.errors.workplaces && formik.errors.workplaces[index]?.name}>
                            <Input
                                name={`workplaces[${index}].name`}
                                value={r.name}
                                onChange={(e: any) => {
                                    formik.setFieldValue(`workplaces[${index}].name`, e.target.value);
                                    if (r.exists) {
                                        formik.setFieldValue(`workplaces[${index}].updated`, true);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                            />
                        </FormGroup>

                        <div className="col-md-1 d-flex justify-content-end align-items-end">
                            <Button
                                color="danger"
                                size='sm'
                                onClick={() => {
                                    if (r.exists) {
                                        formik.setFieldValue(`workplaces[${index}].deleted`, true);
                                        return;
                                    } else {
                                        formik.setFieldValue('workplaces', formik.values.workplaces.filter((w: any, i: number) => i !== index));
                                    }
                                }}
                            >
                                <FaRegTrashAlt />
                            </Button>
                        </div>
                    </div>
                )})}
            </div>

            <div className='row'>
                <FormGroup label='Nombre' className='col-md-11' requiredInputLabel>
                    <Input
                        name='name'
                        value={workplace.name}
                        onChange={(e: any) => setWorkplace({ ...workplace, name: e.target.value })}
                    />
                </FormGroup>

                <div className='col-md-1 d-flex justify-content-end align-items-end'>
                    <Button
                        color='secondary'
                        size='sm'
                        isDisable={workplace.name === ''}
                        onClick={() => {
                            formik.setFieldValue('workplaces', [...formik.values.workplaces, workplace]);
                            setWorkplace({ name: '' });
                        }}
                    >
                        <MdAdd className='h-5 w-5' />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default WorkplacesForm;