import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../../../components/bootstrap/Spinner";
import { CustomTable } from "../../../../components/table/CustomTable";
import { MultipliersService } from "../../../../services/multipliers/MultipliersService";

type MultipliersTableProps = {
    dataMultipliers?: any;
    refetchMultipliers: any;
    rations?: any;
}

interface FormValues {
    id: string[];
    value: number[];
};

let initialFormikValues = {
    id: [] as string[],
    value: [] as number[]
};

const MultipliersTable: React.FC<MultipliersTableProps> = ({ dataMultipliers, refetchMultipliers, rations }) => {

    // STATES

    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    // FUNCTIONS

    //------------------------------------------------------------------------------------------------------------
    /**
     * @ES MANIPULA LA EDICIÓN DE UN MULTIPLICADOR CUANDO SE REALIZA UN CAMBIO EN EL INPUT
     * @EN HANDLES THE EDITION OF A MULTIPLIER WHEN A CHANGE IS MADE IN THE INPUT
     * 
     * @param {string} id
     * @param {number} value
     */
    //------------------------------------------------------------------------------------------------------------
    const handleEditMultiplier = async (id: string, value: number, name: string) => {
        if (isSubmitting) return;

        const values = {
            id: id,
            value: value
        };
        try {
            setIsSubmitting(true);
            const response = await (await new MultipliersService().editMultiplier(values));
            const responseData = response.getResponseData();
            if (responseData.success) {
                toast.success("Multiplicador de " + name + " actualizado correctamente");
                refetchMultipliers();
            } else {
                toast.error("Error al actualizar el multiplicador de " + name);
            }
        } catch (error) {
            toast.error("Error al actualizar el multiplicador de " + name);
        } finally {
            setIsSubmitting(false);
        }
    }
    //------------------------------------------------------------------------------------------------------------


    //------------------------------------------------------------------------------------------------------------
    /**
     * @ES CALCULA LAS RACIONES TOTALES DE UN GRUPO DE ALIMENTOS
     * @EN CALCULATES THE TOTAL RATIONS OF A FOOD GROUP
     * 
     * @param {any} element
     * @returns {string}
     */
    //------------------------------------------------------------------------------------------------------------
    const calculateTotalRations = (element: any) => {
        const rationConfig = rations?.foodGroupsConfig.find((config: any) =>
            config.foodGroups.includes(element.name)
        );

        const quantityPerRation = rationConfig ? rationConfig.quantity : 100;

        const quantityInMenu = Number(element.quantityInMenu) || 0;
        const totalRations = quantityInMenu / (quantityPerRation * element.value);

        return isNaN(totalRations) ? '0.00' : totalRations.toFixed(2);
    };
    //------------------------------------------------------------------------------------------------------------


    // USE EFFECT

    // Create initialFormikValues and store it in formik
    useEffect(() => {
        setIsMounted(false);
        initialFormikValues.id = dataMultipliers?.reduce((acc: any, multiplier: any) => {
            acc[multiplier.id] = multiplier.id ? multiplier.id : '';
            return acc;
        }, {});
        initialFormikValues.value = dataMultipliers?.reduce((acc: any, multiplier: any) => {
            acc[multiplier.id] = multiplier.value ? multiplier.value : 1;
            return acc;
        }, {});
        formik.resetForm();
        setIsMounted(true);
    }, [dataMultipliers]);

    // FORMIK

    const formik = useFormik({
        initialValues: initialFormikValues,
        onSubmit: () => { }
    });

    // RENDER

    return (
        <Fragment>
            {dataMultipliers
                ? (
                    <>
                        <CustomTable
                            title="Multiplicadores"
                            data={dataMultipliers ? dataMultipliers : null}
                            className={"table-striped table-hover p-2"}
                            columns={[
                                {
                                    name: 'Grupo de alimentos',
                                    keyValue: 'name',

                                },
                                {
                                    name: 'Factor multiplicador',
                                    keyValue: 'value',
                                    render: (element: any) => {
                                        return (
                                            <input
                                                type="number"
                                                name={`value.${element.id}`}
                                                value={formik.values.value?.[element.id] || ''}
                                                onChange={formik.handleChange}
                                                onBlur={(e) => {
                                                    formik.handleBlur(e);
                                                    const newValue = parseFloat(e.target.value) || 0;
                                                    if (newValue !== element.value) {
                                                        handleEditMultiplier(element.id, newValue, element.name);
                                                    }
                                                }}
                                                className="form-control"
                                                placeholder="Factor multiplicador"
                                                style={{ width: '100px' }}
                                                disabled={isSubmitting}
                                            />
                                        );
                                    }
                                },
                                {
                                    name: "Raciones Totales",
                                    keyValue: 'quantityInMenu',
                                    render: (element: any) => <span>{calculateTotalRations(element)}</span>
                                }
                            ]}
                        />
                    </>
                )
                : <div className="text-center"><Spinner color={'primary'} /></div>
            }
        </Fragment>
    )

}

export default MultipliersTable;

