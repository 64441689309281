import { useFormik } from "formik";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import Textarea from "../../../../../components/bootstrap/forms/Textarea";
import * as yup from 'yup';
import Button from "../../../../../components/bootstrap/Button";
import Spinner from "../../../../../components/bootstrap/Spinner";
import { useEffect, useState } from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import moment from "moment";
import SearchableSelect from "../../../../../components/select/SearchableSelect";
import { CirclePicker, ColorResult } from "react-color";
import { APPOINTMENT_STATUSES, getAppointmentStatus } from "../../../../../type/appointment-type";
import { APPOINTMENT_TYPES } from "../../../../../utils/mapping-collection";
import usePaymentMenthods from "../../../../../hooks/api-calls/usePaymentMethods";
import useServiceTypes from "../../../../../hooks/api-calls/useServiceTypes";
import useConsultationTypes from "../../../../../hooks/api-calls/useConsultationTypes";
import useWorkplaces from "../../../../../hooks/api-calls/useWorkplaces";
import useHandleErrors from "../../../../../utils/hooks/useHandleErrors";
import { AppointmentService } from "../../../../../services/appointments/appointmentService";
import { toast } from "react-toastify";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import FormLabel from "../../../../../components/FormLabel";

const AppointmentFormSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio').min(1, 'Demasiado Corto').max(40, 'Demasiado Largo'),
    start: yup.date().required('La fecha de inicio es obligatoria'),
    end: yup.date().required('La fecha de fin es obligatoria'),
    user: yup.string().optional(),
    client: yup.string().nullable().optional(),
    comments: yup.string().nullable().optional(),
    concept: yup.string().nullable().optional(),
    workPlace: yup.string().required('El centro de trabajo es obligatorio'),
    consultationType: yup.string().optional().nullable(),
    appointmentType: yup.string().required('El tipo de cita es obligatorio'),
    serviceType: yup.string().optional(),
    status: yup.string().required("El estado de la cita es obligatorio"),
    appointment_color: yup.string().nullable().optional(),
    paymentMethod: yup.string().optional(),
    price: yup.number().min(0, 'El precio mínimo es 0€').max(1000000, 'El precio máximo es 1.000.000€').optional(),
});

interface AppointmentModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    editAppointment: any;
    user : any;
    client : any;
    setSelectedAppointment?: any;
    refetchData?: any;
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({ isOpen, setIsOpen, editAppointment , user, client, setSelectedAppointment, refetchData }) => {
    // STATE

    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const appointmentService = new AppointmentService();

    // HOOKS

    const { getWorkplacesList } = useWorkplaces({user: user});
    const { getConsultationTypesOptions, getConsultationTypeId } = useConsultationTypes();
    const { getServiceTypesOptions } = useServiceTypes();
    const { getPaymentMethodsOptions } = usePaymentMenthods();
    const { handleErrors } = useHandleErrors();

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
    * @EN CREATE APPOINTMENT
    * @ES CREAR CITA
    * 
    * @param values 
    */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const createAppointment = async (values: any) => {
        setIsLoading(true);
        values.workplace = values.workPlace;
        delete values.workPlace;
        try {
            const response = (await appointmentService.createAppointment(values)).getResponseData();
            if (response.success) {
                toast.success('Cita creada correctamente');
                setSelectedAppointment(null);
                setIsOpen(false);
                refetchData();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error('Error al crear la cita');
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------
   
    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
    * @EN UPDATE APPOINTMENT
    * @ES ACTUALIZAR CITA
    * 
    * @param values
    * @returns
    */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const updateAppointment = async (values: any) => {
        setIsLoading(true);
        values.workplace = values.workPlace;
        delete values.workPlace;
        try {
            const response = (await appointmentService.updateAppointment(values)).getResponseData();
            if (response.success) {
                toast.success('Cita actualizada correctamente');
                setSelectedAppointment(null);
                setIsOpen(false);
                refetchData();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error('Error al actualizar la cita');
        } finally {
            setIsLoading(false);
        }
    };
    //------------------------------------------------------------------------------------------------------------------------------------------- 

    // FORMIK

    const formik = useFormik({
        validationSchema: AppointmentFormSchema,
        initialValues: {
            name: '',
            start: '',
            end: '',
            user: user,
            client: client,
            comments: '',
            concept: '',
            workPlace: '',
            consultationType: '',
            appointmentType: 0,
            serviceType: '',
            status: '',
            appointment_color: '',
            paymentMethod: '',
            paymentMethodLabel: '',
            price: 0,
            notifyBeforeADay: true,
            notifyBeforeTwoDays: true,
        },
        onSubmit: async (values) => {
            const formattedValues : any = {
                ...values,
                start_date: moment(values.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(values.end).format('YYYY-MM-DD HH:mm:ss'),
                comments: values.comments !== '' && values.comments !== null ? values.comments : null,
                concept: values.concept !== '' && values.concept !== null ? values.concept : null,
            }

            if (editAppointment) {
                formattedValues.appointment = editAppointment.id;
                await updateAppointment(formattedValues);
            } else {
                await createAppointment(formattedValues);
            }
        }
    });

    // VALIDATION FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN VERIFY IF THE FIELD HAS ERRORS
     * @ES VERIFICAR SI EL CAMPO TIENE ERRORES
     * 
     * @param inputFieldID 
     * @returns 
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const verifyClass = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN SHOW ERRORS
     * @ES MOSTRAR ERRORES
     * 
     * @param inputFieldID 
     * @returns 
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const showErrors = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    // USE EFFECT

    useEffect(() => {
        if (editAppointment) {
            formik.setValues({
                name: editAppointment.name,
                start: editAppointment.startDate ? moment(editAppointment.startDate?.date).format('YYYY-MM-DDTHH:mm') : '',
                end: editAppointment.endDate ? moment(editAppointment.endDate?.date).format('YYYY-MM-DDTHH:mm') : '',
                comments: editAppointment.comments,
                concept: editAppointment.concept,
                workPlace: editAppointment.workPlace?.id,
                consultationType: editAppointment.consultationType?.id,
                appointmentType: APPOINTMENT_TYPES.find((type: any) => type.labelEn === editAppointment.type)?.value || 0,
                serviceType: editAppointment.serviceType?.id,
                status: editAppointment.status,
                appointment_color: editAppointment.appointmentColor,
                paymentMethod: editAppointment.paymentMethod?.id,
                paymentMethodLabel: editAppointment.paymentMethod?.name,
                price: editAppointment.price,
                user: user,
                client: client,
                notifyBeforeADay: editAppointment.notifyBeforeADay,
                notifyBeforeTwoDays: editAppointment.notifyBeforeTwoDays,
            });

            let status = getAppointmentStatus(editAppointment.status)
            setSelectedStatus({ value: status.id, label: status.name });
        } else {
            formik.resetForm();
            formik.setFieldValue('user', user);
            formik.setFieldValue('client', client);
        }
    }, [editAppointment, isOpen]);
    
    // RENDER

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Datos de la cita' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id='appointment-data'>{editAppointment ? 'Editar' : 'Crear'} Cita</ModalTitle>
            </ModalHeader>
            <ModalBody className='pb-4'>
                <form onSubmit={formik.handleSubmit} autoComplete="off" className='row g-4'>
                    {/* Name */}
                    <div className='col-6'>
                    <div className='col-12'>
                        <FormGroup requiredInputLabel label='Asunto'>
                            <Input id="name" type='text' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('name')} />
                            {showErrors('name')}
                        </FormGroup>
                    </div>

                    <div className="row mt-3">
                        {/* Comments */}
                        <div className='col-md-6'>
                            <FormGroup label='Concepto'>
                                <Textarea id="concept" value={formik.values.concept || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('concept')} />
                                {showErrors('concept')}
                            </FormGroup>
                        </div>

                        {/* Comments */}
                        <div className='col-md-6'>
                            <FormGroup label='Observaciones'>
                                <Textarea id="comments" value={formik.values.comments || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('comments')} />
                                {showErrors('comments')}
                            </FormGroup>
                        </div>
                    </div>

                    {/* Dates */}
                    <div className='col-12 mt-4'>
                        <Card className='mb-0' color="primary" shadow='sm'>
                            <CardHeader className='bg-l25-secondary'>
                                <CardLabel>
                                    <CardTitle className='text-secondary'>
                                        Fechas Seleccionadas
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <FormGroup requiredInputLabel label="Fecha de inicio">
                                            <Input
                                                id="start"
                                                type={'datetime-local'}
                                                step={300}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('start')}
                                                value={formik.values.start ? moment(formik.values.start).format('YYYY-MM-DDTHH:mm') : ''}
                                                onChange={(event: any) => {
                                                    formik.setFieldValue('start', event.target.value);
                                                    formik.setFieldValue('end', moment(event.target.value).add(30, 'minutes').format('YYYY-MM-DDTHH:mm'));
                                                }}
                                            />
                                            {showErrors('start')}
                                        </FormGroup>
                                    </div>
                                    <div className='col-12'>
                                        <FormGroup requiredInputLabel label="Fecha de fin">
                                            <Input
                                                id="end"
                                                type={'datetime-local'}
                                                step={300}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('end')}
                                                value={formik.values.end ? moment(formik.values.end).format('YYYY-MM-DDTHH:mm') : ''}
                                                onChange={(event: any) => {
                                                    formik.setFieldValue('end', event.target.value);
                                                }}
                                            />
                                            {showErrors('end')}
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    <div className='col-12 mt-4'>
                        <Card className='mb-0' color="primary" shadow='sm'>
                            <CardHeader className='bg-l25-info'>
                                <CardLabel>
                                    <CardTitle className='text-dark'>
                                        Configuración de notificaciones
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-3 d-flex justify-content-between'>
                                    <div className='col-6 d-flex align-items-start justify-content-start'>
                                        <Checks
                                            id='notifyBeforeADay'
                                            checked={formik.values.notifyBeforeADay}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} 
                                            className={verifyClass('notifyBeforeADay') + ' fs-5'}
                                        />
                                        <FormLabel label="Notificar un día antes" className="ms-2 fs-6 mt-1" htmlFor="notifyBeforeADay" />
                                        {showErrors('notifyBeforeADay')}
                                    </div>
                                    <div className='col-6 d-flex align-items-start justify-content-start'>
                                        <Checks
                                            id='notifyBeforeTwoDays'
                                            checked={formik.values.notifyBeforeTwoDays}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('notifyBeforeTwoDays') + ' fs-5'}
                                        />
                                        <FormLabel label="Notificar dos días antes" className="ms-2 fs-6 mt-1" htmlFor="notifyBeforeTwoDays" />
                                        {showErrors('notifyBeforeTwoDays')}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    </div>

                    {/* Appointment configuration */}
                    <div className='col-6'>
                        <Card className='mb-0' color="primary" shadow='sm'>
                            <CardHeader className='bg-l25-primary'>
                                <CardLabel icon='User Add' iconColor='primary'>
                                    <CardTitle>Configuración de la cita</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <FormGroup label="Centro de trabajo" className="mb-3" requiredInputLabel key={'workPlace' + formik.values.user + formik.values.workPlace}>
                                    <SearchableSelect
                                        name="workPlace"
                                        isSearchable
                                        options={getWorkplacesList()}
                                        value={{ value: formik.values.workPlace, label: getWorkplacesList().find((w: any) => w.value === formik.values.workPlace)?.label }}
                                        onChange={(newValue: any) => { formik.setFieldValue('workPlace', newValue.value) }}
                                        onBlur={formik.handleBlur}
                                        classname={verifyClass('workPlace')}
                                        placeholder='centro de trabajo'
                                    />
                                    {showErrors('workPlace')}
                                </FormGroup>

                                <FormGroup label="Tipo de consulta" className="mb-3">
                                    <SearchableSelect
                                        name="consultationType"
                                        isSearchable
                                        options={getConsultationTypesOptions()}
                                        value={{ value: formik.values.consultationType, label: getConsultationTypesOptions().find((option: any) => option.value === formik.values.consultationType)?.label }}
                                        onChange={(option: any) => {
                                            // When the consultation type changes, we set the service type, appointment type, price and increase the end date
                                            const consultationType = getConsultationTypeId(option?.value);
                                            formik.setValues({
                                                ...formik.values,
                                                consultationType: option?.value,
                                                serviceType: consultationType?.serviceType?.id,
                                                appointmentType: APPOINTMENT_TYPES.find((type: any) => type.value === Number(consultationType?.appointmentType))?.value || 0,
                                                price: consultationType?.price,
                                                end: moment(formik.values.start).add(consultationType?.duration, 'minutes').format('YYYY-MM-DDTHH:mm'),
                                            });
                                        }}
                                        onBlur={formik.handleBlur}
                                        classname={verifyClass('consultationType')}
                                        placeholder='Tipo de consulta'
                                    />
                                </FormGroup>

                                <FormGroup label="Tipo de cita" className="mb-3" requiredInputLabel key={'appointmentType' + formik.values.appointmentType}>
                                    <SearchableSelect
                                        name="appointmentType"
                                        isSearchable
                                        options={APPOINTMENT_TYPES}
                                        value={{ value: formik.values.appointmentType, label: APPOINTMENT_TYPES.find((option: any) => option.value === formik.values.appointmentType)?.label }}
                                        onChange={(option: any) => formik.setFieldValue('appointmentType', option?.value)}
                                        onBlur={formik.handleBlur}
                                        classname={verifyClass('appointmentType')}
                                        placeholder='Tipo de cita'
                                    />
                                    {showErrors('appointmentType')}
                                </FormGroup>

                                <FormGroup label="Tipo de servicio" className="mb-3" requiredInputLabel key={'serviceType' + formik.values.serviceType}>
                                    <SearchableSelect
                                        name="serviceType"
                                        isSearchable
                                        options={getServiceTypesOptions()}
                                        value={{ value: formik.values.serviceType, label: getServiceTypesOptions().find((option: any) => option.value === formik.values.serviceType)?.label }}
                                        onChange={(option: any) => formik.setFieldValue('serviceType', option?.value)}
                                        onBlur={formik.handleBlur}
                                        classname={verifyClass('serviceType')}
                                        placeholder='tipo de servicio'
                                    />
                                    {showErrors('serviceType')}
                                </FormGroup>

                                <FormGroup label="Estado" className="mb-3" requiredInputLabel>
                                    <SearchableSelect
                                        name="status"
                                        isSearchable
                                        classname={verifyClass('status')}
                                        onBlur={formik.handleBlur}
                                        placeholder='Estado de la cita'
                                        value={selectedStatus}
                                        onChange={(newValue: any) => {
                                            setSelectedStatus(newValue)
                                            formik.setFieldValue('status', newValue.value)
                                        }}
                                        options={APPOINTMENT_STATUSES.map((status: any) => ({
                                            value: status.id,
                                            label: status.name,
                                        }))}
                                    />
                                    {showErrors('status')}
                                </FormGroup>

                                <FormGroup className="mb-3" label="Color">
                                    <CirclePicker color={formik.values.appointment_color ? formik.values.appointment_color : ''} onChangeComplete={(color: ColorResult) => {
                                        formik.setFieldValue('appointment_color', color ? color.hex : null)
                                    }} />
                                    {showErrors('appointment_color')}
                                </FormGroup>

                                <div className="row">
                                    <div className="col-md-6">
                                        <FormGroup className="mb-3" label="Método de pago" requiredInputLabel>
                                            <SearchableSelect
                                                name="paymentMethod"
                                                isSearchable
                                                value={{ value: formik.values.paymentMethod, label: formik.values.paymentMethodLabel }}
                                                options={getPaymentMethodsOptions()}
                                                onChange={(option: any) => {
                                                    formik.setFieldValue('paymentMethod', option?.value)
                                                    formik.setFieldValue('paymentMethodLabel', option?.label)
                                                }}
                                                onBlur={formik.handleBlur}
                                                classname={verifyClass('paymentMethod')}
                                                placeholder="Método de pago"
                                            />
                                            {showErrors('paymentMethod')}
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className="mb-3" label="Importe (€)" requiredInputLabel>
                                            <Input
                                                id="price"
                                                type='number'
                                                value={formik.values.price || 0}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('price')}
                                            />
                                            {showErrors('price')}
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <div className='d-flex justify-content-end text-right relative'>
                    <Button color='primary' className="flex-grow-1" type='submit' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color='dark' /> : 'Guardar'}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
} 

export default AppointmentModal;