import classNames from 'classnames';
import React, { useCallback } from 'react';
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/bootstrap/Button";
import { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { ConsultationTypesService } from '../../../services/consultation-types/consultationTypesService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import { APPOINTMENT_TYPES } from '../../../utils/mapping-collection';
import ConsultationTypesFilters from './ConsultationTypesFilters';
import ConsultationTypeModalForm from './components/ConsultationTypeModalForm';

interface GCConsultationTypesProps { }

const GCConsultationTypes: React.FC<GCConsultationTypesProps> = ({ }) => {

    // STATES

    const [isOpen, setIsOpen] = React.useState(false);
    const [rowId, setRowId] = React.useState('');

    // HOOKS

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3 });

    // FETCH DATA

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new ConsultationTypesService()).listConsultationTypes(filters));
        return response.getResponseData();
    }, [filters]));

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función para eliminar un tipo de consulta.
     * @EN Function to delete a consultation type.
     * 
     * @param id 
     */
    //--------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new ConsultationTypesService()).deleteConsultationType(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Tipo de consulta eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al eliminar el tipo de consulta');
        }
    };
    //--------------------------------------------------------------------------------------------------

    // RENDER

    if (error) return <ErrorMessage />;

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Tipos de Consulta</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Tipo de Consulta"
                        color="storybook"
                        icon="Add"
                        isLight
                        className={classNames({ 'd-none': !userCan('create', 'consultation_types') })}
                        onClick={() => { setRowId(''); setIsOpen(true); }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ConsultationTypesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </CardHeader>
            <CardBody className="table-responsive">
                <CustomTable
                    title="Tipos de Consulta"
                    data={data ? data?.consultationTypes : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data ? data?.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Precio",
                            keyValue: "price",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render(item: any) { return <>{item.price} €</> }
                        },
                        {
                            name: "Duración",
                            keyValue: "duration",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render(item: any) { return <>{item.duration} minutos</> }
                        },
                        {
                            name: "Tipo de servicio",
                            keyValue: "serviceType",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render(item: any) { return (<> {item.serviceType?.name} </>) }
                        },
                        {
                            name: "Tipo de cita",
                            keyValue: "appointment_type",
                            render(item: any) { return (<>{APPOINTMENT_TYPES.find((option: any) => option.value === Number(item.appointmentType))?.label}</>) }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar tipo de consulta",
                            hidden: !userCan('edit', 'consultation_types'),
                            click: (item: any) => { setRowId(item.id); setIsOpen(true); },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar tipo de consulta",
                            hidden: !userCan('delete', 'consultation_types'),
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar tipo de consulta",
                                    text: "¿Está seguro que desea eliminar el tipo de consulta?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            </CardBody>

            {isOpen && <ConsultationTypeModalForm isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} rowId={rowId} />}
        </>
    );
};

export default GCConsultationTypes;