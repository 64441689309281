import moment from "moment"
import Avatar from "../../../components/Avatar"
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert"
import Tooltips from "../../../components/bootstrap/Tooltips"
import { CircleColor } from "../../../components/decorators/circle-color/CircleColor"
import { CustomTable } from "../../../components/table/CustomTable"
import { FilterOptions } from "../../../hooks/useFilters"
import { AppointmentService } from "../../../services/appointments/appointmentService"
import { DocumentService } from "../../../services/documents/documentService"
import { Appointment } from "../../../type/appointment-type"
import { getDuration } from "../../../utils/DateUtils"
import { APPOINTMENT_TYPES } from "../../../utils/mapping-collection"

type AppointmentsTableProps = {
    filters: FilterOptions,
    appointments: Appointment[],
    lastPage?: number,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    onEdit?: (appointment: Appointment) => void,
    onDelete?: (appointment: string) => void,
}

export const AppointmentsTable: React.FC<AppointmentsTableProps> = ({ filters, appointments, lastPage, updatePage, updatePageSize, updateFilterOrder, onEdit, onDelete }) => {

    const truncateString = (string: string) => {
        const words = string?.split(' ') || [];
        const truncatedWords = words.slice(0, 5);
        const truncatedText = truncatedWords.join(' ');

        return <span>{truncatedText} {words.length > 5 && ' ...'}</span>;
    };

    return (
        <>
            {appointments && (
                <CustomTable
                    title="Citas"
                    data={appointments || null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: lastPage ? lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "",
                            keyValue: "color",
                            sortable: false,
                            render: (element: any) => {
                                let color = null;

                                if (element.appointmentColor !== null) {
                                    color = element.appointmentColor;
                                }
                                if (!color && element.user.color) {
                                    color = element.user.color;
                                }

                                return (
                                    <Tooltips title={'Color de la cita'}>
                                        <div>
                                            <CircleColor color={color} size={15} />
                                        </div>
                                    </Tooltips>
                                );
                            },
                        },
                        {
                            name: "",
                            keyValue: "user",
                            sortable: false,
                            render: (element: any) => {
                                return (<Avatar src={(new DocumentService).renderDocumentURL(element.user.profileImg?.id)} size={40}></Avatar>);
                            },
                        },
                        {
                            name: "Usuario",
                            keyValue: "user",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <strong>
                                        {`${element.user.name} ${element.user.lastName ?? ""}` || "-"}
                                    </strong>
                                );
                            },
                        },
                        {
                            name: "Paciente",
                            keyValue: "client",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                if (element.client == null) return (<>-</>)

                                return (
                                    <>
                                        <strong>
                                            {`${element.client.name} ${element.client.lastName || ""}` || "-"}
                                        </strong>
                                    </>
                                );
                            },
                        },
                        {
                            name: "Estado",
                            keyValue: "status",
                            render: (element: any) => {
                                const status = (new AppointmentService).getStatusById(element.status);
                                return (
                                    <>
                                        <strong style={{ color: status.color }}>
                                            {status?.name || "-"}
                                        </strong>
                                    </>
                                );
                            },
                        },
                        {
                            name: "Observaciones",
                            keyValue: "comments",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => { return (<>{truncateString(element.comments) || "-"}</>) },
                        },
                        {
                            name: "Tipo de cita",
                            keyValue: "type",
                            render: (element: any) => { return (<> {APPOINTMENT_TYPES.find((type) => type.value === Number(element.type))?.label || "-"} </>) },
                        },
                        {
                            name: "Fecha",
                            keyValue: "startDate",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => { return (<>{moment(element.startDate?.date).format('hh:mm DD/MM/YYYY') || "-"}</>) },
                        },
                        {
                            name: "Duración",
                            keyValue: "duration",
                            render: (element: any) => { return (<>{getDuration(element.startDate?.date, element.endDate?.date) || "-"}</>) },
                        },
                        {
                            name: "Servicio",
                            keyValue: "service",
                            render: (element: any) => { return (<>{element.serviceType?.name}</>) },
                        },
                        {
                            name: "Método de pago",
                            keyValue: "paymentMethod",
                            render: (element: any) => { return (<>{element.paymentMethod?.name || "-"}</>) }
                        },
                        {
                            name: "Importe",
                            keyValue: "price",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (<>{element.price + " €"}</>)
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar cita",
                            click: (item: any) => { onEdit && onEdit(item as Appointment) },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar cita",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar cita",
                                    text: "¿Está seguro que desea eliminar la cita?",
                                    icon: "warning",
                                    onConfirm: () => { onDelete && onDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            )
            }
        </>
    )
}