import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/multipliers';

export class MultipliersService extends RestServiceConnection {


    getMultipliersByMenu = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { 
                id: id
             }
        }, true) as AxiosResponse;
        return this;
    }

    editMultiplier = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: values
        }, true) as AxiosResponse;
        return this;
    }


}