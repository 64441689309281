import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/basal-metabolism';

export class BasalMetabolismService extends RestServiceConnection {


    getBasalMetabolismByCompany = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
        }, true) as AxiosResponse;
        return this;
    }

    editBasalMetabolism = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: values
        }, true) as AxiosResponse;
        return this;
    }
}