import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CompanyService } from "../../../services/companies/organizationService";
import CompanyForm from "../CompanyForm";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";

const CreateCompany: FC = () => {

	const { handleErrors } = useHandleErrors();
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);
		try {
			const response = await (await (new CompanyService()).createCompany(values)).getResponseData();
			if (response.success) {
				navigate(-1);
				toast.success('Organización creada correctamente');
			} else {
				handleErrors(response);
			}
		} catch (error: any) {
			toast.error("Error al crear la organización");
		} finally {
			setLoading(false);
		}
	};

	return <CompanyForm submit={handleCreation} isLoading={loading} />;
}

export default CreateCompany;