import { FC, Fragment } from 'react';
import CustomSearchInput from '../../../components/bootstrap/forms/CustomSearchInput';
import Icon from '../../../components/icon/Icon';

interface IPhysiologicalStatesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const PhysiologicalStatesFilters: FC<IPhysiologicalStatesFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja el cambio de texto en el campo de búsqueda.
     * @EN Function that handles text change in search field.
     * 
     * @param search 
     */
    //------------------------------------------------------------------------------------------------
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };
    //------------------------------------------------------------------------------------------------

    // RENDER

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />
        </Fragment>
    )
}

export default PhysiologicalStatesFilters;