import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import FormLabel from '../../../components/FormLabel';
import Button from '../../../components/bootstrap/Button';
import { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import IconWithTooltip from '../../../components/bootstrap/IconWithTooltip';
import Spinner from '../../../components/bootstrap/Spinner';
import { Loader } from '../../../components/bootstrap/SpinnerLogo';
import Input from '../../../components/bootstrap/forms/Input';
import useFetch from '../../../hooks/useFetch';
import { CompanyService } from '../../../services/companies/organizationService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';

interface GCCalendarProps { }

interface GCForm {
    timeSlot: number;
}

const initialValues: GCForm = {
    timeSlot: 60,
}

const schema = yup.object({
    timeSlot: yup.number().required('Este campo es obligatorio').min(1, 'El valor mínimo es 1 minuto').max(1440, 'El valor máximo es 1440 minutos (24 horas)'),
});

const GCCalendar: React.FC<GCCalendarProps> = ({ }) => {

    const { handleErrors } = useHandleErrors();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new CompanyService()).getCalendarTimeSlot();
        return response.getResponseData();
    }, []));

    const handleSubmit = async (values: GCForm) => {
        setIsLoading(true);
        try {
            const response = await (await (new CompanyService()).setCalendarTimeSlot(values)).getResponseData();
            if (response.success) {
                toast.success("Tiempo del calendario editado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar el tiempo del calendario");
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik<GCForm>({
        initialValues: data || initialValues,
        validationSchema: schema,
        onSubmit: values => { handleSubmit(values) }
    });

    useEffect(() => {
        if (data) {
            formik.setFieldValue('timeSlot', data);
        }
    }, [data]);

    const verifyClass = (inputFieldID: keyof GCForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof GCForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>; }

    if (loading) return <Loader />;

    return (
        <>
            <CardHeader>
                <CardLabel>
                    <CardTitle>Calendario</CardTitle>
                </CardLabel>
            </CardHeader>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody>
                    <div className='w-25'>
                        <FormLabel
                            label='Tiempo del calendario (min)'
                            tooltip={<IconWithTooltip icon='Info' label='Tiempo en minutos que dura cada slot en el calendario.' />}
                        />
                        <Input
                            id="timeSlot"
                            type="number"
                            value={formik.values.timeSlot}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={verifyClass('timeSlot')}
                        />
                        {showErrors('timeSlot')}
                    </div>
                </CardBody>

                <CardFooter className='d-flex justify-content-end'>
                    <Button
                        type="submit"
                        isDisable={isLoading}
                        color='primary'
                    >
                        {isLoading ? <Spinner color={'dark'} /> : 'Guardar'}
                    </Button>
                </CardFooter>
            </form>
        </>
    );
};

export default GCCalendar;