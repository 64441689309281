import { Fragment } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Button from '../../components/bootstrap/Button';
import Card, { CardTitle } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import AsideMenu from './AsideMenu';

const GeneralConfigWrapper = () => {

    const navigate = useNavigate();

    return (
        <Fragment>
            <ToastContainer />
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-2)} />
                    <SubheaderSeparator />
                    <CardTitle>Configurador General</CardTitle>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid' className="px-0 py-2">
                <div className="row">
                    <div className="col-md-2">
                        <AsideMenu />
                    </div>
                    <div className="col-md-10">
                        {window.location.pathname.includes('accounting') ? 
                        <Outlet /> :
                        <Card>
                            <Outlet />
                        </Card>
                        }
                    </div>
                </div>
            </Page>
        </Fragment>
    );
}

export default GeneralConfigWrapper;