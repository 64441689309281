import { useCallback, useState } from "react";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import useFilters from "../../../hooks/useFilters";
import useFetch from "../../../hooks/useFetch";
import { NutrientConfigurationService } from "../../../services/nutrient-configuration/nutrientService";
import { toast } from "react-toastify";
import { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import classNames from "classnames";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import NutrientConfigurationFilters from "./NutrientConfigurationFilters";
import NutrientConfigurationModalForm from "./components/NutrientConfigurationModalForm";


interface GCNutrientsProps { }


const GCNutrients: React.FC<GCNutrientsProps> = ({ }) => {

    // STATE
    const [isOpen, setIsOpen] = useState(false);
    const [rowId, setRowId] = useState('');

    // FUNCTIONS

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3 });


    // FETCH DATA

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new NutrientConfigurationService()).listNutrients(filters));
        return response.getResponseData();
    }, [filters]));

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIÓN PARA ELIMINAR UN NUTRIENTE
     * @EN FUNCTION TO DELETE A NUTRIENT
     * @param id
     * 
     */
    const handleDelete = async (id: string) => {
        try{
            const response = await (await (new NutrientConfigurationService()).deleteNutrientConfiguration(id)).getResponseData();
            if(response){
                refetch();
                toast.success('Nutriente eliminado correctamente');
            } else {
                toast.error('Error al eliminar el nutriente');
            }
        }catch(error){
            toast.error('Error al eliminar el nutriente');
        }
    };
    //-------------------------------------------------------------------------------------------------------

    // RENDER 

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Configuración de los nutrientes</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear configuración de un nutriente"
                        color="storybook"
                        icon="Add"
                        isLight
                        className={classNames({ 'd-none': !userCan('create', 'nutrient_configuration') })}
                        onClick={() => { setRowId(''); setIsOpen(true); }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <NutrientConfigurationFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </CardHeader>
            <CardBody className="table-responsive">
                <CustomTable
                    title="Nutrientes"
                    data={data ? data.data : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data ? data?.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                         {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Maximo",
                            keyValue: "max",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Minimo",
                            keyValue: "min",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar patología",
                            hidden: !userCan('edit', 'nutrient_configuration'),
                            click: (item: any) => { setRowId(item.id); setIsOpen(true); },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar configuración del nutriente",
                            hidden: !userCan('delete', 'pathology'),
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar configuración del nutriente",
                                    text: "¿Está seguro que desea eliminar la configuración del nutriente?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            </CardBody>
            {isOpen && <NutrientConfigurationModalForm isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} rowId={rowId} />}

        </>
    );
}

export default GCNutrients;