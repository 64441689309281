import { createSlice } from "@reduxjs/toolkit";

interface Intake {
    [key: string]: any;
    id: string;
    menuId: string;
    name: string;
    menuItems: any[];
}

export interface menuState {
    intakes: Intake[];
}

export const initialState: menuState = {
    intakes: [],
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        copyIntakes: (state, action) => {
            state.intakes = action.payload;
        },
    },
});

export const { copyIntakes } = menuSlice.actions;

export default menuSlice.reducer;