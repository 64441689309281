import classNames from "classnames"
import { es } from 'date-fns/locale'
import moment from "moment"
import 'moment/locale/es'
import { FC, Fragment, useEffect, useState } from "react"
import { Calendar, Views, momentLocalizer } from "react-big-calendar"
import WithDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import { Calendar as CalendarPicker } from "react-date-range"
import { useSelector } from "react-redux"
import Button from "../../../components/bootstrap/Button"
import { CardTitle } from "../../../components/bootstrap/Card"
import Popovers from "../../../components/bootstrap/Popovers"
import { MyEvent, MyEventDay, MyWeekEvent, eventStyleGetter } from "../../../components/calendar/CalendarEvents"
import { CalendarTodayButton, CalendarViewModeButtons, getLabel, getUnitType } from "../../../components/extras/calendarHelper"
import Page from "../../../layout/Page/Page"
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader"
import { RootState } from "../../../redux/store"
import { AppointmentService } from "../../../services/appointments/appointmentService"
import { DocumentService } from "../../../services/documents/documentService"
import { APPOINTMENT_STATUSES_MAP, Appointment, AppointmentFormData, IEvent } from "../../../type/appointment-type"
import { APPOINTMENT_TYPES } from "../../../utils/mapping-collection"
import PatientModalForm from "../../patients/PatientModalForm"
import { AppointmentCanvas } from "../components/AppointmentCanvas"
import { AppointmentFiltersCanvas } from "../components/AppointmentFiltersCanvas"
import { useAppointmentCalendar } from "../providers/AppointmentCalendarProvider"
import './AppointmentCalendar.css'
moment.locale('es-ES');

const localizer = momentLocalizer(moment);

interface currentDate {
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
}

const DragAndDropCalendar = WithDragAndDrop(Calendar);

export const AppointmentCalendar: FC = () => {

    const token = useSelector((state: RootState) => state.auth);

    const newCurrentDate: currentDate = {
        year: moment().year(),
        month: moment().month(),
        day: moment().date(),
        hour: moment().hour(),
        minute: moment().minute(),
        second: moment().second(),
    }

    // Get Calendar Provider
    const { appointments, viewMode, changeViewMode, setCalendarDate, currentDate, appointmentSelected, selectAppointment, refetchAppointments, openPatientModal, setOpenPatientModal, refetchClients } = useAppointmentCalendar();

    const [calendarEvents, setCalendarEvents] = useState<IEvent[]>([]);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    // Calendar Date Label
    const calendarDateLabel = getLabel(currentDate, viewMode);

    // Calendar Unit Type
    const unitType = getUnitType(viewMode);

    // New Event
    const handleSelectNew = ({ start, end }: { start: any; end: any }) => {
        selectAppointment({
            start,
            end,
            status: APPOINTMENT_STATUSES_MAP.PENDING.id,
            type: '',
            appointment_color: '',
            paymentMethod: 'Tarjeta',
            serviceType: '',
            price: 0,
            notifyBeforeADay: true,
            notifyBeforeTwoDays: true,
        } as AppointmentFormData);
    };

    // Change view mode
    const handleViewMode = (e: any) => {
        setCalendarDate(moment(e).toDate());
        changeViewMode(Views.DAY);
    };

    // Event Drop
    const _handleEventDrop = async ({ event, start, end }: any) => {
        // remove event from calendar events
        let calendarEventsFiltered = calendarEvents.filter((item: IEvent) => item.id !== event.id);
        setCalendarEvents(calendarEventsFiltered);

        let eventReceived: any = {
            ...event,
            start_date: moment(start).format('YYYY-MM-DD HH:mm:ss'),
            end_date: moment(end).format('YYYY-MM-DD HH:mm:ss'),
            user: event.user?.id,
            client: event.client?.id,
            appointment: event.id,
            colorHex: event.colorHex,
            color: event.color,
            workplace: event.workPlace,
            paymentMethod: event.paymentMethod?.id,
        };

        delete eventReceived.workPlace;

        let updatedAppointment = await (new AppointmentService()).updateAppointment(eventReceived);
        let data = updatedAppointment.getResponseData();

        if (data.success) {
            refetchAppointments();
        }
    };

    useEffect(() => {
        if (!appointments) return;
        const events: IEvent[] = appointments.map((appointment: Appointment): IEvent => {
            return {
                id: appointment.id,
                name: appointment.name,
                start: moment(appointment.startDate?.date).toDate(),
                end: moment(appointment.endDate?.date).toDate(),
                status: appointment.status,
                user: {
                    id: appointment.user?.id ? appointment.user?.id : "",
                    name: appointment.user ? appointment.user?.name : "",
                    lastName: appointment.user?.lastName ? appointment.user?.lastName : "",
                    src: appointment.user?.profileImg?.id ? (new DocumentService()).renderDocumentURL(appointment.user?.profileImg?.id) : "",
                    srcSet: appointment.user?.profileImg?.id ? (new DocumentService()).renderDocumentURL(appointment.user?.profileImg?.id) : "",
                },
                consultationType: appointment.consultationType?.id,
                appointmentType: APPOINTMENT_TYPES.find((type) => type.labelEn == appointment.type)?.value,
                serviceType: appointment.serviceType?.id,
                appointment_color: appointment.appointmentColor,
                client: appointment.client,
                comments: appointment.comments,
                notified: appointment.notified,
                color: 'primary',
                colorHex: appointment.appointmentColor ? appointment.appointmentColor : (appointment.user?.color ? appointment.user?.color : "primary"),
                paymentMethod: appointment.paymentMethod,
                price: appointment.price,
                workPlace: appointment?.workPlace?.id,
                notifyBeforeADay: appointment.notifyBeforeADay,
                notifyBeforeTwoDays: appointment.notifyBeforeTwoDays,
            }
        });
        setCalendarEvents(events);
    }, [appointments]);

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Popovers
                        desc={
                            <CalendarPicker
                                onChange={(item) => { setCalendarDate(item) }}
                                date={currentDate}
                                color={process.env.REACT_APP_PRIMARY_COLOR}
                                locale={es}
                                weekStartsOn={1}
                            />
                        }
                        placement='bottom-end'
                        className='mw-100'
                        trigger='click'>
                        <Button color='secondary' isLight>{calendarDateLabel}</Button>
                    </Popovers>
                    <SubheaderSeparator />
                    <CardTitle>Calendario de Citas</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="secondary" isLight icon="filter"
                        onClick={() => { setShowFilters(true) }}
                    >
                        Filtros
                    </Button>
                    <CalendarViewModeButtons
                        setViewMode={changeViewMode}
                        viewMode={viewMode}
                    />
                    <CalendarTodayButton
                        unitType={unitType}
                        date={currentDate as object}
                        setDate={setCalendarDate}
                        viewMode={viewMode}
                    />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid" >
                <DragAndDropCalendar
                    selectable
                    toolbar={false}
                    localizer={localizer}
                    events={calendarEvents}
                    defaultView={Views.MONTH}
                    // @ts-ignore
                    views={Object.keys(Views).map((k: string) => Views[k])}
                    view={viewMode}
                    date={currentDate}
                    onNavigate={(_date) => setCalendarDate(_date)}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectSlot={handleSelectNew}
                    onView={handleViewMode}
                    onEventDrop={_handleEventDrop}
                    onEventResize={_handleEventDrop}
                    onDrillDown={handleViewMode}
                    timeslots={1}
                    step={token && token.user?.calendarTimeSlot || 15}
                    min={new Date(newCurrentDate.year, newCurrentDate.month, newCurrentDate.day, 7, 0, 0)}
                    slotPropGetter={(date) => {
                        return {
                            className: classNames({
                                'bg-light-secondary': moment(date).isSame(moment(), 'day'),
                            }),
                        };
                    }}
                    components={{
                        event: MyEvent, // used by each view (Month, Day, Week)
                        week: { event: MyWeekEvent, },
                        day: { event: MyEventDay, },
                        work_week: { event: MyWeekEvent, },
                    }}
                    eventPropGetter={eventStyleGetter}
                    messages={{
                        noEventsInRange: 'No hay citas en este rango de fechas',
                        showMore: (total) => `+${total} más`,
                    }}
                />

                <AppointmentCanvas appointment={appointmentSelected} setOpen={(status: boolean) => { selectAppointment(null) }} />

                <AppointmentFiltersCanvas setOpen={(status: boolean) => { setShowFilters(status) }} isOpen={showFilters} />

                {openPatientModal && (
                    <PatientModalForm
                        openModal={openPatientModal} setOpenModal={setOpenPatientModal}
                        onConfirm={() => {
                            setOpenPatientModal(false);
                            refetchClients();
                        }}
                    />
                )}
            </Page>
        </Fragment>
    )
}