import { AppointmentFormData, IEvent } from "../../type/appointment-type"
import Avatar from "../Avatar"
import Tooltips from "../bootstrap/Tooltips"
import { CardActions } from "../bootstrap/Card"
import Button from "../bootstrap/Button"
import { handleConfirmationAlert } from "../../components/ConfirmationAlert"
import moment from "moment"
import { DocumentService } from "../../services/documents/documentService"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppointmentCalendar } from "../../pages/appointments/providers/AppointmentCalendarProvider"

type CalendarEventWindowProps = {
    event: IEvent,
    onDelete?: (id?: string) => void
    onClose?: () => void
}

export const CalendarEventWindow: React.FC<CalendarEventWindowProps> = ({ event, onDelete, onClose }) => {

    const navigate = useNavigate();
    const { selectAppointment } = useAppointmentCalendar();
    const documentService = new DocumentService();

    const _deleteEvent = () => {
        handleConfirmationAlert({
            title: 'Eliminar cita',
            text: '¿Está seguro que desea eliminar esta cita?',
            icon: 'warning',
            onConfirm: () => {
                if (onDelete === undefined) return;
                onDelete(event.id);
            }
        })
    }

    const _closeEvent = () => {
        if (onClose === undefined) return;
        onClose();
    }

    const _handleNotified = () => {
        toast.info("Esta funcionalidad aún no está disponible");
    }

    const _handleEdit = () => {
        selectAppointment({
            ...event,
            user: event.user?.id,
            client: event.client?.id,
            workplace: event.workPlace?.id,
        } as AppointmentFormData);

        _closeEvent();
    }

    return (
        <div className="mw-100">
            <div className="row mb-2">
                {event?.user?.src && (
                    <div className='col-auto'>
                        <div className='row g-1 align-items-baseline'>
                            <div className='col-auto'>
                                <Tooltips title={`${event?.user?.name} ${event?.user?.lastName}`}>
                                    <Avatar src={event?.user?.src} srcSet={event?.user?.srcSet} size={30} />
                                </Tooltips>
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-auto flex-grow-1">
                    <CardActions className="text-end">
                        <Button title="Notificar a usuario" icon="Notifications" className="p-1 m-0" onClick={_handleNotified}></Button>
                        <Button title="Editar cita" icon="edit" className="p-1 m-0" onClick={_handleEdit}></Button>
                        <Button title="Eliminar cita" icon="delete" className="p-1 m-0" onClick={_deleteEvent}></Button>
                        <Button title="Cerrar ventana" icon="close" className="p-1 m-0" onClick={_closeEvent}></Button>
                    </CardActions>
                </div>
            </div>
            <div className='row g-2 align-items-center'>
                <div className='col-3 flex-grow-1'>
                    <h5><strong>Cita </strong></h5>
                </div>
                <div className='col-9 flex-grow-1'>
                    <p className="text-muted h6">{event?.name}</p>
                </div>
            </div>
            {event?.client && (
                <div className="row align-items-center">
                    <div className='col-3 flex-grow-1 text-truncate'>
                        <h5><strong>Paciente</strong></h5>
                    </div>
                    <div className='col-9 flex-grow-1'>
                        <Avatar src={documentService.renderDocumentURL(event?.client?.profileImg?.id)} srcSet={documentService.renderDocumentURL(event?.client?.profileImg?.id)} size={30} />
                        <a className="text-muted h6 ms-2" onClick={() => { navigate(`/clients/${event?.client?.id}/view`) }}>
                            {event.client?.name} {event.client.lastName}
                        </a>
                    </div>
                </div>
            )}
            <div className="row align-items-center">
                <div className='col-3 flex-grow-1'>
                    <h5><strong>Fecha </strong></h5>
                </div>
                <div className='col-9 flex-grow-1'>
                    <p className="text-muted h6">{moment(event?.start).format('DD-MM-YYYY HH:mm')} - {moment(event?.end).format('DD-MM-YYYY HH:mm')} </p>
                </div>
            </div>
            <div className="row align-items-center">
                <div className='col-3 flex-grow-1 text-truncate'>
                    <h5><strong>Comentarios</strong></h5>
                </div>
                <div className='col-9 flex-grow-1'>
                    <p className="text-muted h6">{event.comments ? event.comments : "-"}</p>
                </div>
            </div>
            <div className="row align-items-center">
                <div className='col-3 flex-grow-1 text-truncate'>
                    <h5><strong>Notificado</strong></h5>
                </div>
                <div className='col-9 flex-grow-1'>
                    <p className="text-muted h6">{event.notified ? "Si" : "No"}</p>
                </div>
            </div>
        </div>
    )
}