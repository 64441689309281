import { useEffect, useMemo, useState } from 'react';
import { PathologiesService } from '../../services/pathologies/pathologyService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage pathologies fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de patologías.
----------------------------------------------------------------------------------------*/

const usePathologies = () => {

    const service = useMemo(() => new PathologiesService(), [])
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await service.listPathologies();
            const fetchedData = response.getResponseData()?.data;

            if (fetchedData && fetchedData.pathologies) {
                const mappedData = fetchedData.pathologies?.map((data: any) => data);
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching pathologies: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getOptions = () => {
        return data.map((option: { id: string, name: string }) => ({
            value: option.id,
            label: option.name
        }));
    };

    const getList = (entityId?: string) => {
        if (entityId) return data.filter((state: any) => state.id === entityId);
        return data;
    };

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    };

    return {
        fetchPathologies: fetchData,
        getPathologyOptions: getOptions,
        getPathologiesList: getList,
        getPathologyId: getEntity,
        isLoadingPathologies: isLoading
    };
}

export default usePathologies;