import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import { usePrivilege } from '../../components/priviledge/PriviledgeProvider';
import { generalConfigMenu } from '../../menu';
import { setSelectedTab } from '../../redux/generalConfigSlice';
import { RootState } from '../../redux/store';
import path from 'path';

interface AsideMenuProps { }

const menuLinks = [
    { label: 'Consentimiento', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.informedConsent, permission: 'show_consent' },
    { label: 'Fichas técnicas', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.technicalSheet, permission: 'show_technical_sheets' },
    { label: 'Raciones', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.rations, permission: 'show_rations' },
    { label: 'Calendario', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.calendar, permission: 'show_calendar' },
    { label: 'Tipos de consulta', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.consultationTypes, permission: 'show_consultation_types' },
    { label: 'Tipos de servicio', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.serviceTypes, permission: 'show_service_types' },
    { label: 'Centros de trabajo', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.workplaces, permission: 'show_workplaces' },
    { label: 'Patologías', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.pathologies, permission: 'show_pathologies' },
    { label: 'Intolerancias', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.intolerances, permission: 'show_intolerances' },
    { label: 'Alergias', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.allergies, permission: 'show_allergies' },
    { label: 'Estados fisiológicos', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.physiologicalStates, permission: 'show_physiological_states' },
    { label: 'Contabilidad', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.accounting, permission: 'show_accounting' },
    { label: 'Alimentos', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.app, permission: 'show_data_source' },
    { label: 'Menús', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.menus, permission: 'show_menus' },
    { label: 'Metabolismo basal', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.basalMetabolism, permission: 'show_basal_metabolism' },
    { label: 'Nutrientes', path: generalConfigMenu.generalConfig.path + generalConfigMenu.generalConfig.nutrients, permission: 'show_nutrient_configuration'},
];

const AsideMenu: React.FC<AsideMenuProps> = ({ }) => {

    const dispatch = useDispatch();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const { selectedTab } = useSelector((state: RootState) => state.generalConfig);

    const pathname = useLocation().pathname;

    useEffect(() => {
        if (pathname !== selectedTab) navigate(selectedTab);
    }, [selectedTab]);

    return (
        <div id='aside-menu' className='rounded bg-transparent p-3 border border-secondary border-2 min-w-180'>
            <ul className='p-0'>
                {menuLinks.map((link) => {
                    let isActive = pathname === (link.path as string);
                    return (
                        <div
                            key={link.path}
                            className={classNames('d-flex justify-content-start align-items-center py-1 mb-3', {
                                'd-none': !userCan(link.permission, 'general_configurator')
                            })}
                        >
                            {isActive ? <Icon icon='Circle' className='text-secondary' size={'sm'} /> : null}
                            <li
                                key={link.label}
                                className={"list-group-item rounded cursor-pointer text-dark fs-6 px-2"}
                                onClick={() => dispatch(setSelectedTab(link.path))}
                            >
                                <span className={isActive ? 'border-bottom border-2 border-secondary fw-bold text-secondary' : ''}>
                                    {link.label}
                                </span>
                            </li>
                        </div>
                    )
                })}
            </ul>
        </div>
    );
};

export default AsideMenu;