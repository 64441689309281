import React from 'react';
import Button from '../../../../components/bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import Spinner from '../../../../components/bootstrap/Spinner';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import SearchableSelect from '../../../../components/select/SearchableSelect';

interface ListPatientTipsFiltersProps {
    filterMenu: boolean;
    filters: any
    setFilterMenu: (value: boolean) => void;
    resetFilters: () => void;
    updateFilters: (filters: any) => void;
    tipGroupOptions: any;
}

const ListPatientTipsFilters: React.FC<ListPatientTipsFiltersProps> = ({filterMenu, setFilterMenu, filters, resetFilters, updateFilters, tipGroupOptions}) => {

    return (
        <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='foodsFilters' isBodyScroll placement='end'>
            <OffCanvasHeader setOpen={setFilterMenu}>
                <OffCanvasTitle id='foodsFilters'> Filtros de consejos </OffCanvasTitle>
                <Button
                    color="storybook"
                    isLight
                    onClick={() => {
                        resetFilters()
                    }}
                >
                    Resetear
                </Button>
            </OffCanvasHeader>

            <OffCanvasBody>
                <div className='row g-4'>
                    <div className='col-12'>
                        <FormGroup id='filter1' label='Grupos de consejos'>
                            {
                                tipGroupOptions ? (
                                    <SearchableSelect
                                        isMulti
                                        onChange={(e: any) => updateFilters({tipGroups: e?.map((item: any) => item.value)})}
                                        value={tipGroupOptions.filter((item: any) => filters.filter_filters?.tipGroups?.includes(item.value))}
                                        name='groups_filter'
                                        placeholder='grupos'
                                        options={tipGroupOptions}
                                    />
                                ) : (
                                    <Spinner isSmall className='ms-3' />
                                )
                            }
                        </FormGroup>

                    </div>
                </div>
            </OffCanvasBody>
        </OffCanvas>
    );
};

export default ListPatientTipsFilters;