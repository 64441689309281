import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/service-types';

export class ServiceTypesService extends RestServiceConnection {

    listServiceTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getServiceType = async (serviceTypeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { serviceTypeId }
        }, true) as AxiosResponse;
        return this;
    }

    createServiceType = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    editServiceType = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    deleteServiceType = async (serviceTypeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { serviceTypeId }
        }, true) as AxiosResponse;
        return this;
    }

}