import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/calibrator';

export class CalibratorService extends RestServiceConnection {


    getCalibratorByCompany = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
        }, true) as AxiosResponse;
        return this;
    }

    editCalibrator = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: values
        }, true) as AxiosResponse;
        return this;
    }


}