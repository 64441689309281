import { FC, useCallback, useEffect } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../bootstrap/Modal";
import { CardBody, CardFooter } from "../bootstrap/Card";
import { useFormik } from "formik";
import useFetch from "../../hooks/useFetch";
import { MenuService } from "../../services/menus/menuService";
import Checks from "../bootstrap/forms/Checks";
import Spinner from "../bootstrap/Spinner";

interface ContextMenuChangeProps {
    menuItem : any;
    menuId : string;
    isOpen : boolean;
    setIsOpen : any;
    onClose : (values: any, dayId: string, intakeId: string) => void;
}

const ContextMenuChange: FC<ContextMenuChangeProps> = ({ menuItem , menuId, isOpen, setIsOpen,  onClose }) => {

    // STATES

    // HOOKS

    // DATA FETCHING

    const [similarDishes, loadingSimilarDishes, similarDishesError, refetchSimilarDishes] = useFetch(useCallback(async () => {
        if (!menuItem) return null;
        const response = (await (new MenuService()).getSimilarDishes(menuItem?.id || ''));
        return response.getResponseData();
    }, [menuItem]));

    // FUNCTIONS

    // --------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE ACTUALIZA EL PLATO
     * @EN FUNCTION THAT UPDATES THE DISH
     * 
     * @param values 
     * 
     * @returns void
     */
    // --------------------------------------------------------------------------------------------
    const handleUpdate = async (values: any) => {
        if (!menuItem || !values.menu_item || !values.recipe && !values.food) return;
        const response = (await (new MenuService()).editMenuItem(values)).getResponseData();
        if (response.success) {
            const values = {
                id : response.data.id,
                dayId : menuItem.dayId,
                intakeId : menuItem.intakeId,
                food : response.data.food ? {
                    id : response.data.food.id,
                    name : response.data.food.name,
                    food_group : response.data.food.foodGroup?.name,
                    food_group_id : response.data.food.foodGroup?.id,
                    food_group_color : response.data.food.foodGroup?.color,
                } : null,
                recipe : response.data.recipe ? {
                    id : response.data.recipe.id,
                    name : response.data.recipe.name,
                    food_group : response.data.recipe.foodGroup?.name,
                    food_group_id : response.data.recipe.foodGroup?.id,
                    food_group_color : response.data.recipe.foodGroup?.color,
                } : null,
                physicalActivity : null,
            }
            onClose(values , menuItem.dayId, menuItem.intakeId);
        } else {
            console.log(response);
        }
    }
    // --------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik({
        initialValues: {
            menu_item   : null,
            menu        : null,
            day         : null,
            intake      : null,
            quantity    : null,
            order_item  : null,
            food        : null,
            recipe      : null,
        },
        onSubmit: (values) => {
            handleUpdate(values);
        }
    });

    // USE EFFECTS

    useEffect(() => {
        if (menuItem) {
            formik.setFieldValue('menu_item', menuItem.id);
            formik.setFieldValue('day', menuItem.dayId);
            formik.setFieldValue('intake', menuItem.intakeId);
            formik.setFieldValue('quantity', 1);
            formik.setFieldValue('order_item', 1);
            formik.setFieldValue('food', null);
            formik.setFieldValue('recipe', null);
        }
    }, [menuItem]);

    useEffect(() => {
        if (menuId) {
            formik.setFieldValue('menu', menuId);
        }   
    }, [menuId]); 

    // RENDER

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del paciente'>
            <ModalHeader setIsOpen={setIsOpen} className='p-0 pt-4 px-4'>
                <ModalTitle id='patient-data'>Cambio de plato</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4 py-0'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody className="d-flex justify-content-center flex-column">
                        {similarDishes ?
                            similarDishes[0].length > 0 ? (
                                similarDishes[0].map((dish: any, index: number) => {
                                    return(
                                    <Checks 
                                        key={index} 
                                        name={dish.name} 
                                        value={dish.id}
                                        label={dish.name} 
                                        id={dish.name}
                                        checked={formik.values.recipe === dish.id || formik.values.food === dish.id}
                                        className="fs-5" 
                                        onChange={(e : any) => {
                                            if (formik.values.recipe === dish.id || formik.values.food === dish.id) {
                                                formik.setFieldValue('recipe', null);
                                                formik.setFieldValue('food', null);
                                            } else {
                                                if (dish?.process !== undefined) {
                                                    console.log('entro');
                                                    formik.setFieldValue('recipe', dish.id);
                                                    formik.setFieldValue('food', null);
                                                } else {
                                                    formik.setFieldValue('food', dish.id);
                                                    formik.setFieldValue('recipe', null);
                                                }
                                            }
                                        }}
                                    />
                                    );
                                })
                            ) : (
                                <p>No hay platos similares</p>
                            )
                        : (
                            <Spinner />
                        )
                    }
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary" disabled={(!formik.values.recipe && !formik.values.food)}>Seleccionar</button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    );
}

export default ContextMenuChange;   