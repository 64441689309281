import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from 'yup';
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardFooter } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Spinner from "../../../../components/bootstrap/Spinner";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../../components/select/SearchableSelect";
import useAllergens from "../../../../hooks/api-calls/useAllergens";
import useFoodGroups from "../../../../hooks/api-calls/useFoodGroups";
import useFoods from "../../../../hooks/api-calls/useFoods";
import { PathologiesService } from "../../../../services/pathologies/pathologyService";
import useHandleErrors from "../../../../utils/hooks/useHandleErrors";
import { NUTRIENTS } from "../../../../utils/mapping-collection";
import RecommendedDailyIntake from "./RecommendedDailyIntake";

interface PathologyModalFormProps {
    isOpen: boolean;
    setIsOpen: any;
    refetch: any;
    rowId?: string;
}

interface PathologyForm {
    name: string;
    foodsToAvoid: { value: string, label: string }[] | null;
    foodGroupsToAvoid: { value: string, label: string }[] | null;
    allergensToAvoid: string[] | null;
    nutrientsToAvoid: string[] | null;
    recommendIntake: { nutrient: string, measureUnit: string, min: number, max: number }[] | null;
}

const initialValues: PathologyForm = {
    name: '',
    foodsToAvoid: null,
    foodGroupsToAvoid: null,
    allergensToAvoid: null,
    nutrientsToAvoid: null,
    recommendIntake: [],
};

const schema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    foodsToAvoid: yup.array().of(yup.string()).nullable().notRequired(),
    foodGroupsToAvoid: yup.array().of(yup.string()).nullable().notRequired(),
    allergensToAvoid: yup.array().of(yup.string()).nullable().notRequired(),
    nutrientsToAvoid: yup.array().of(yup.string()).nullable().notRequired(),
    recommendIntake: yup.array().of(
        yup.object().shape({
            nutrient: yup.string().required('El nutriente es obligatorio'),
            measureUnit: yup.string().required('La unidad de medida es obligatoria'),
            min: yup.number().required('El valor mínimo es obligatorio'),
            max: yup.number().required('El valor máximo es obligatorio'),
        })
    ).nullable().notRequired(),
});

const PathologyModalForm: FC<PathologyModalFormProps> = ({ isOpen, setIsOpen, refetch, rowId }) => {

    // STATES

    const [isLoading, setIsLoading] = useState(false);
    const [dailyIntake, setDailyIntake] = useState<{ nutrient: string, measureUnit: string, min: number, max: number }>({ nutrient: '', measureUnit: '', min: 0, max: 0 });

    // HOOKS

    const { handleErrors } = useHandleErrors();
    const { getFoodsList } = useFoods();
    const { getFoodGroupsOptions } = useFoodGroups();
    const { getAllergenOptions } = useAllergens();

    // FORMIK

    const formik = useFormik<PathologyForm>({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            // Add daily intake to the formik values if it has a nutrient and measure unit selected
            let updatedIntakes = formik.values.recommendIntake || [];
            if (dailyIntake.nutrient !== '' && dailyIntake.measureUnit !== '') {
                updatedIntakes.push(dailyIntake);
                values.recommendIntake = updatedIntakes;
                await formik.setFieldValue('recommendIntake', updatedIntakes);
                setDailyIntake({ nutrient: '', measureUnit: '', min: 0, max: 0 });
            }

            rowId ? handleEdit(values) : handleCreate(values)
        }
    });

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja la creación de la patología.
     * @EN Function that handles pathology creation.
     * 
     * @param values 
     */
    //--------------------------------------------------------------------------------------------------
    const handleCreate = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (new PathologiesService()).createPathology(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetch();
                setIsOpen(false);
                toast.success('Patología creada correctamente');
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al crear la patología');
        } finally {
            setIsLoading(false);
        }
    };
    //--------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja la edición de la patología.
     * @EN Function that handles intolerance edition.
     * 
     * @param values 
     */
    //--------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (new PathologiesService()).editPathology(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetch();
                setIsOpen(false);
                toast.success('Patología editada correctamente');
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al editar la patología');
        } finally {
            setIsLoading(false);
        }
    };
    //------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que verifica si un campo tiene errores.
     * @EN Function that checks if a field has errors.
     * 
     * @param inputFieldID 
     */
    //--------------------------------------------------------------------------------------------------
    const verifyClass = (inputFieldID: keyof PathologyForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }
    //--------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que muestra los errores de un campo.
     * @EN Function that shows field errors.
     * 
     * @param inputFieldID 
     */
    //--------------------------------------------------------------------------------------------------
    const showErrors = (inputFieldID: keyof PathologyForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }
    //--------------------------------------------------------------------------------------------------

    // USE EFFECT

    useEffect(() => {
        if (rowId) {
            (async () => {
                const response = await (new PathologiesService()).getPathology(rowId);
                const responseData = response.getResponseData();
                if (responseData.success) {
                    const values = {
                        id: responseData.data.id,
                        name: responseData.data.name,
                        foodsToAvoid: responseData.data.foodsToAvoid.map((f: any) => f.id),
                        foodGroupsToAvoid: responseData.data.foodGroupsToAvoid.map((f: any) => f.id),
                        allergensToAvoid: responseData.data.allergensToAvoid,
                        nutrientsToAvoid: responseData.data.nutrientsToAvoid,
                        recommendIntake: responseData.data.recommendIntakes,
                    };
                    formik.setValues(values);
                } else {
                    handleErrors(response);
                }
            })();
        } else {
            formik.setValues(initialValues);
        }
    }, [rowId]);

    // RENDER

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Datos de la patología' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id='pathology-data'>{rowId ? 'Editar' : 'Crear'} Patología</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody className="row g-md-3">
                        <FormGroup label="Nombre" requiredInputLabel className="col-md-12">
                            <Input
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('name') + ' w-50'}
                            />
                            {showErrors('name')}
                        </FormGroup>

                        <FormGroup label="Alimentos a evitar" className="col-md-6">
                            <SearchableSelect
                                name="foodsToAvoid"
                                isMulti
                                isClearable
                                options={getFoodsList()}
                                value={formik.values.foodsToAvoid?.map((f: any) => ({ value: f, label: getFoodsList().find((food: any) => food.value === f)?.label }))}
                                onChange={(e: any) => e ? formik.setFieldValue('foodsToAvoid', e.map((f: any) => f.value)) : formik.setFieldValue('foodsToAvoid', null)}
                                placeholder='alimento'
                                classname={verifyClass('foodsToAvoid')}
                            />
                            {showErrors('foodsToAvoid')}
                        </FormGroup>

                        <FormGroup label="Grupos de alimentos a evitar" className="col-md-6">
                            <SearchableSelect
                                name="foodGroupsToAvoid"
                                isMulti
                                isClearable
                                options={getFoodGroupsOptions()}
                                value={formik.values.foodGroupsToAvoid?.map((f: any) => ({ value: f, label: getFoodGroupsOptions().find((fg: any) => fg.value === f)?.label }))}
                                onChange={(e: any) => e ? formik.setFieldValue('foodGroupsToAvoid', e.map((f: any) => f.value)) : formik.setFieldValue('foodGroupsToAvoid', null)}
                                placeholder='grupo de alimentos'
                                classname={verifyClass('foodGroupsToAvoid')}
                            />
                            {showErrors('foodGroupsToAvoid')}
                        </FormGroup>

                        <FormGroup label="Alérgenos a evitar" className="col-md-6">
                            <SearchableSelect
                                name="allergensToAvoid"
                                isMulti
                                isClearable
                                options={getAllergenOptions()}
                                value={formik.values.allergensToAvoid?.map((f: any) => ({ value: f, label: getAllergenOptions().find((a: any) => a.value === f)?.label }))}
                                onChange={(e: any) => e ? formik.setFieldValue('allergensToAvoid', e.map((f: any) => f.value)) : formik.setFieldValue('allergensToAvoid', null)}
                                placeholder='alérgeno'
                                classname={verifyClass('allergensToAvoid')}
                            />
                            {showErrors('allergensToAvoid')}
                        </FormGroup>

                        <FormGroup label="Nutrientes a evitar" className="col-md-6">
                            <SearchableSelect
                                name="nutrientsToAvoid"
                                isMulti
                                isClearable
                                options={NUTRIENTS}
                                value={formik.values.nutrientsToAvoid?.map((f: any) => ({ value: f, label: NUTRIENTS.find((n: any) => n.value === f)?.label }))}
                                onChange={(e: any) => e ? formik.setFieldValue('nutrientsToAvoid', e.map((f: any) => f.value)) : formik.setFieldValue('nutrientsToAvoid', null)}
                                placeholder='nutriente'
                                classname={verifyClass('nutrientsToAvoid')}
                            />
                            {showErrors('nutrientsToAvoid')}
                        </FormGroup>

                        <RecommendedDailyIntake formik={formik} dailyIntake={dailyIntake} setDailyIntake={setDailyIntake} />
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                        <Button type="submit" isDisable={isLoading} color="primary">
                            {isLoading ? <Spinner color={'dark'} /> : rowId ? 'Guardar Cambios' : 'Crear'}
                        </Button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default PathologyModalForm;