import { FC, useEffect } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import { RestrictionCategory, useMenuProvider } from "../../../../components/menu/providers/MenuProvider";
import { ALLERGENS_LIST, NUTRIENTS } from "../../../../utils/mapping-collection";

interface PatientRestrictionsProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    data: any;
}

const PatientRestrictions: FC<PatientRestrictionsProps> = ({ isOpen, setIsOpen, data }) => {

    const { restrictions, setRestrictions } = useMenuProvider();

    useEffect(() => {
        if (!data) return;

        let updatedRestrictions: RestrictionCategory[] = [
            { name: "Grupo de alimentos", values: [] },
            { name: "Alimentos", values: [] },
            { name: "Nutrientes", values: [] },
            { name: "Alérgenos", values: [] }
        ];

        /**
         * Función para agregar valores únicos a una categoría de restricciones.
         */
        const addUniqueValues = (categoryIndex: number, items: { name: string }[] | string[]) => {
            if (Array.isArray(items) && items.length > 0) {
                items.forEach((item) => {
                    const name = typeof item === "string" ? item : item.name;
                    if (!updatedRestrictions[categoryIndex].values.includes(name)) {
                        updatedRestrictions[categoryIndex].values.push(name);
                    }
                });
            }
        };

        /**
         * Procesa los datos de restricciones para patologías, intolerancias y alergias.
         */
        const processRestrictions = (entries: any[], key: string) => {
            entries.forEach((entry) => {
                if (entry[key]) {
                    addUniqueValues(0, entry[key].foodGroupsToAvoid);
                    addUniqueValues(1, entry[key].foodsToAvoid);
                    addUniqueValues(2, entry[key].nutrientsToAvoid);
                    addUniqueValues(3, entry[key].allergensToAvoid);
                }
            });
        };

        // Procesar clientHasPathologies, intolerances y allergies (mismo tratamiento)
        processRestrictions(data?.clientHasPathologies || [], "pathology");
        processRestrictions(data?.intolerances || [], "intolerance");
        processRestrictions(data?.allergies || [], "allergy");

        // Procesar aversiones (solo tiene foodGroup y food)
        (data?.clientAversions || []).forEach((aversion: any) => {
            if (aversion.foodGroup) addUniqueValues(0, [aversion.foodGroup]);
            if (aversion.food) addUniqueValues(1, [aversion.food]);
        });

        // Transformar los valores de nutrientes y alérgenos a los nombres correspondientes
        updatedRestrictions[2].values = updatedRestrictions[2].values.map((nutrient) => {
            const found = NUTRIENTS.find((n) => n.value === nutrient);
            return found ? found.label : nutrient;
        });

        updatedRestrictions[3].values = updatedRestrictions[3].values.map((allergen) => {
            const found = ALLERGENS_LIST.find((a) => a.name === allergen);
            return found ? found.nombre : allergen;
        });

        // Actualizar el estado con las restricciones procesadas
        setRestrictions(updatedRestrictions);
    }, [data]);

    return (
        data && (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xl" titleId="Restricciones del paciente">
                <ModalHeader setIsOpen={setIsOpen} className="p-4">
                    <ModalTitle id="restriction-data">Restricciones del paciente</ModalTitle>
                </ModalHeader>
                <ModalBody className="px-4 py-0">
                    <table className="table">
                        <thead>
                            <tr>
                                {restrictions.map((category) => (
                                    <th key={category.name}>{category.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderStyle: 'none' }}>
                                {restrictions.map((category) => (
                                    <td key={category.name} className="p-0">
                                        {category.values.length > 0 ? (
                                            <ul className="list-unstyled">
                                                {category.values.map((value, index) => (
                                                    <li key={index} className="px-1 py-2" style={{ borderTop: '1px solid #eaeaea' }}>{value}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span className="text-muted">Sin restricciones</span>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </ModalBody>
            </Modal>
        )
    );
};

export default PatientRestrictions;