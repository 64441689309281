import classNames from "classnames";
import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AsyncImg from "../../../components/AsyncImg";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import StatusDropdown from "../../../components/StatusDropdown";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle, } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import { PatientService } from "../../../services/patients/patientService";
import { PatientsApiResponse } from "../../../type/patients-type";
import PatientsFilters from "./patients-options/PatientsFilters";

export interface IPatientFilters {
  active?: number;
  minAge?: any;
  maxAge?: any;
  tags?: string[];
  user?: any;
}

const patientFilters: IPatientFilters = {
  active: 3,
  minAge: null,
  maxAge: null,
  tags: [],
  user: null,
};

const PatientsList = () => {

  const [isExporting, setIsExporting] = useState(false);

  const { userCan } = usePrivilege();
  const navigate = useNavigate();

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(patientFilters);

  const [patientsData, fetchingPatients, patientsError, refetchPatients] = useFetch(useCallback(async () => {
    const response = await (new PatientService()).getPatients(filters);
    return response.getResponseData();
  }, [filters]));

  const handleExport = async () => {
    try {
      if (userCan('export', 'clients')) {
        setIsExporting(true);
        const response = await (new PatientService()).exportPatients(filters);
        if (response) {
          const fileData = response.getResponseData();
          const blob = new Blob([fileData]);
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'patient_list.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setIsExporting(false);
        } else {
          toast.error('Error al exportar los pacientes');
        }
      } else {
        toast.error('No tienes permisos para exportar pacientes');
      }
    } catch (error) {
      toast.error('Error al exportar pacientes');
    }
  };

  const getContent = () => {
    if (patientsError) return <ErrorMessage message={patientsError.message} />;

    const togglePatientStatus = async (id: string, status: boolean, toggleStatus: Function) => {
      try {
        setChangingStatus([...changingStatus, id]);
        const response = (await (new PatientService()).togglePatientStatus(id)).getResponseData();
        if (response.success) {
          setChangingStatus(changingStatus.filter((item) => item !== id));
          toggleStatus(status);
          refetchPatients();
          toast.success(status ? 'Se ha activado el paciente' : 'Se ha desactivado el paciente');
        } else {
          setChangingStatus(changingStatus.filter((item) => item !== id));
          toast.error(response.message || 'Error al cambiar el estado del paciente');
        }
      } catch (error: any) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toast.error(error.message || 'Error al cambiar el estado del paciente');
      }
    };

    const handleDelete = async (id: string) => {
      try {
        const response = (await (new PatientService()).deletePatient(id)).getResponseData();
        if (response.success) {
          refetchPatients();
          setTimeout(() => {
            toast.success('Paciente eliminado correctamente');
          }, 100);
        } else {
          setTimeout(() => {
            toast.error(patientsError.message || 'Error al eliminar el paciente');
          }, 100);
        }
      } catch (error: any) {
        toast.error(patientsError.message || 'Error al eliminar el paciente');
      }
    };


    if (patientsData && patientsData.patients.length > 0) {
      let apiData = patientsData as PatientsApiResponse;

      return (
        <CustomTable
          title="Pacientes"
          loading={fetchingPatients}
          data={apiData.patients || null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: apiData as PatientsApiResponse ? apiData.lastPage : 1,
            handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "",
              keyValue: "PatientImage",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className='user-avatar'>
                      <AsyncImg id={element.profileImg ? element.profileImg.id : null} />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre y Apellidos",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/edit`) }}>
                    {element.name + (element.lastName ? ' ' + element.lastName : '')}
                  </div>
                )
              },
            },
            {
              name: "Correo electrónico",
              keyValue: "email",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.email}
                  </>
                )
              }
            },
            {
              name: "Teléfono",
              keyValue: "telephone",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.telephone || "-"}
                  </>
                )
              }
            },
            {
              name: "Etiquetas",
              keyValue: "clientLabels",
              render: (element: any) => {
                return (
                  <>
                    {
                      element.clientLabels.length > 0
                        ? (
                          element.clientLabels?.map((tag: any) => {
                            return (
                              <span
                                key={tag.clientLabel.id}
                                className="badge text-center w-auto m-1 p-2 ps-3 pe-3 position-relative"
                                style={{
                                  backgroundColor: tag.clientLabel.color || '#d7d7d7',
                                  color: tag.clientLabel.color == '#3c3c3b' || tag.clientLabel.color == '#b81e53' || tag.clientLabel.color == '#e5133d' ? 'white' : 'black',
                                }}
                              >
                                {tag.clientLabel.name}
                              </span>
                            )
                          })
                        ) : '-'
                    }
                  </>
                )
              },
              style: { maxWidth: '300px' }
            },
            {
              name: "Profesional",
              keyValue: "admin",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.user?.name + ' ' + (element.user?.lastName ? element.user?.lastName : '') || "-"}
                  </>
                )
              }
            },
            {
              name: "C. de trabajo",
              keyValue: "workplace",
              render: (element: any) => {
                return (
                  <div className="d-flex flex-column">
                    {element.workPlaces?.map((w: any) => (<span key={w.workPlace.id}>{w.workPlace.name}</span>)) || "-"}
                  </div>
                );
              }
            },
            {
              name: "Estado",
              keyValue: "active",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className={'text-center'} key={element.id}>
                    <StatusDropdown
                      disabled={changingStatus.includes(element.id)}
                      itemId={element.id}
                      status={element.active}
                      change={togglePatientStatus}
                    />
                  </div>
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={[
            {
              title: "Ver",
              icon: "Visibility",
              buttonType: 'icon',
              additionalClasses: 'text-primary',
              description: "Ver perfil de paciente",
              hidden: !userCan('show', 'clients'),
              click: (item: any) => { navigate(`${item.id}/view`) },
            },
            {
              title: "Editar",
              icon: "Edit",
              buttonType: 'icon',
              additionalClasses: 'text-primary',
              description: "Editar perfil de paciente",
              hidden: !userCan('edit', 'clients'),
              click: (item: any) => { navigate(`${item.id}/edit`) },
            },
            {
              title: "Eliminar",
              icon: "Delete",
              buttonType: 'icon',
              additionalClasses: 'text-danger',
              description: "Eliminar paciente",
              click: (item: any) => {
                handleConfirmationAlert({
                  title: "Eliminar paciente",
                  text: "¿Está seguro que desea eliminar el paciente?",
                  icon: "warning",
                  onConfirm: () => { handleDelete(item.id) }
                })
              },
            },
          ]}
        />
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Pacientes</CardTitle>
          <SubheaderSeparator />
          <Button
            title="Crear Paciente"
            color="storybook"
            icon="Add"
            isLight
            onClick={() => { navigate("create") }}
          />

          <Button
            color="light"
            isLight
            title="Exportar pacientes"
            onClick={handleExport}
            className={classNames({ 'd-none': !userCan('export', 'clients') })}
          >
            Exportar
          </Button>

          {isExporting && <Spinner color={"primary"} />}

        </SubHeaderLeft>
        <SubHeaderRight>
          <PatientsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <CardBody className="table-responsive" isScrollable={true}>
            <>{getContent()}</>
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default PatientsList;