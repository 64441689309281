import { useFormik } from "formik";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import Page from "../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import * as Yup from 'yup';
import { useState } from "react";
import AsyncImg from "../../components/AsyncImg";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import { ActivitiesService } from "../../services/activities/activitiesService";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import classNames from "classnames";

interface ActivityFormProps {
    submit: (values: any) => void;
    isLoading: boolean;
    activityData?: any;
}

interface ActivityForm {
    name: string;
    description: string;
    time: number;
    calories: number;
}

const activityInitialValues: ActivityForm = {
    name: '',
    description: '',
    time: 0,
    calories: 0,
}

const ActivitySchema = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    description: Yup.string(),
    time: Yup.number().required('El tiempo es obligatorio'),
    calories: Yup.number().required('Las calorías son obligatorias'),
});

const ActivityForm: React.FC<ActivityFormProps> = ({ submit, isLoading, activityData }) => {

    const { id = '' } = useParams();
    const navigate = useNavigate();
    const mode = activityData ? 'Editar' : 'Crear';
    const buttonTitle = mode === 'Editar' ? 'Guardar Cambios' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<any>(null);

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const response = await (new ActivitiesService).insertActivityImg(id, file);
            const responseData = response.getResponseData();

            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Imagen actualizada');
                }, 100);
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará la imagen de este ejercicio',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const response = await (new ActivitiesService).deleteActivityImg(id);
                    const responseData = response.getResponseData();

                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la imagen");
                }
            }
        })
    };

    const formik = useFormik<ActivityForm>({
        initialValues: activityData || activityInitialValues,
        validationSchema: ActivitySchema,
        onSubmit: values => submit(values)
    });

    const verifyClass = (inputFieldID: keyof ActivityForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof ActivityForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row mt-4 d-flex justify-content-between">
                    {mode === 'Editar' && (
                        <Card stretch={false} className={'col-md-2'}>
                            <CardBody className={'text-center'}>
                                <div className={'text-center pb-4'}>
                                    {selectedImage
                                        ? <img
                                            width={200} height={200}
                                            src={selectedImage} alt="selected"
                                            className='mx-auto d-block img-fluid mb-3 rounded'
                                        />
                                        : activityData.img
                                            ? <AsyncImg isBackground height="200px" width="200px" styles="mx-auto d-block img-fluid rounded" id={activityData.img.id} />
                                            : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                                    }
                                </div>

                                <Input
                                    onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                    type='file' autoComplete='photo' placeholder={"Cambiar imagen"} className="mb-3"
                                />

                                <Button color='dark' isLight icon='Delete' onClick={deleteImage} title="Eliminar">Eliminar</Button>
                            </CardBody>
                        </Card>
                    )
                    }

                    <Card stretch={false} className={classNames({ 'col-md-12': mode === 'Crear', 'col-md-9': mode === 'Editar' })}>
                        <CardTitle>Datos del ejercicio físico</CardTitle>
                        <div className="row mt-3 d-flex justify-content-between">
                            <div className="col-md-4">
                                <FormGroup label="Nombre" requiredInputLabel>
                                    <Input
                                        type="text"
                                        className={verifyClass('name')}
                                        id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    {showErrors('name')}
                                </FormGroup>
                            </div>

                            <div className="col-md-3">
                                <FormGroup label="Duración en minutos">
                                    <Input
                                        type="number"
                                        className={verifyClass('time') + ' w-50'}
                                        id="time"
                                        name="time"
                                        onChange={formik.handleChange}
                                        value={formik.values.time}
                                    />
                                    {showErrors('time')}
                                </FormGroup>
                            </div>

                            <div className="col-md-3">
                                <FormGroup label="Calorías quemadas (kcal)">
                                    <Input
                                        type="number"
                                        className={verifyClass('calories') + ' w-50'}
                                        id="calories"
                                        name="calories"
                                        onChange={formik.handleChange}
                                        value={formik.values.calories}
                                    />
                                    {showErrors('calories')}
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-between my-5">
                            <FormGroup label="Descripción" className="col-md-12">
                                <textarea
                                    className={`form-control ${verifyClass('description')}`}
                                    id="description"
                                    name="description"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                />
                                {showErrors('description')}
                            </FormGroup>
                        </div>
                    </Card>
                </div>
            </form>
        )
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Ejercicio Físico</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color={'dark'} /> : `${buttonTitle}`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='xxl'>
                {(mode === "Editar" && !activityData) && <Spinner />}
                {(mode === "Editar" && activityData) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
        </>
    )
}

export default ActivityForm;