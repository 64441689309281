export const ROLES = {
    SUPER_ADMIN: 'Superadministrador',
    ADMIN: 'Administrador',
    NUTRIC: 'Administrador Nutric',
    TOTAL: 'Acceso total',
};

export const COLORS = [
    "#30b0ac",
    "#e31740", 
    "#f79b39",
    "#313e61",
    "#e07a5f",
    "#AF19FF",
    "#FF8042",
    "#FFBB28",
    "#0088FE",
    "#91a046",
    "#81abbc",
    "#00C49F",
    "#74bdcb",
    "#ffa384",
    "#fe4a3b",
    "#f5b461",
];

export const COLORS_2 = [
    '#F7F7F7',
    '#ffb7f0',
    '#b81e53',
    '#e5133d',
    '#f59c39',
    '#fffeb7',
    '#beffb7',
    '#30b7af',
    '#2199ff',
    '#b7feff',
    '#9F63BF',
    '#3c3c3b',
];

export const COLOR_OPTIONS = [
    { value: '#ffb7f0', label: 'Rosa' },
    { value: '#b81e53', label: 'Burdeos' },
    { value: '#e5133d', label: 'Rojo' },
    { value: '#f59c39', label: 'Naranja' },
    { value: '#fffeb7', label: 'Amarillo' },
    { value: '#beffb7', label: 'Verde' },
    { value: '#30b7af', label: 'Verde agua' },
    { value: '#2199ff', label: 'Azul' },
    { value: '#b7feff', label: 'Azul celeste' },
    { value: '#3c3c3b', label: 'Negro' },
];

export const APPOINTMENT_TYPES = [
    { value: 1, label: 'Presencial', labelEn: 'Insite' },
    { value: 2, label: 'Online', labelEn: 'Online' },
    { value: 3, label: 'Telefónica', labelEn: 'Telephone' },
    { value: 4, label: 'Otros', labelEn: 'Others' },
];

export const COUNTRIES = [
    { value: "AF", label: "Afganistán" },
    { value: "AX", label: "Islas Åland" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Argelia" },
    { value: "AS", label: "Samoa Americana" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguila" },
    { value: "AQ", label: "Antártida" },
    { value: "AG", label: "Antigua y Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaiyán" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Baréin" },
    { value: "BD", label: "Bangladés" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Bielorrusia" },
    { value: "BE", label: "Bélgica" },
    { value: "BZ", label: "Belice" },
    { value: "BJ", label: "Benín" },
    { value: "BM", label: "Bermudas" },
    { value: "BT", label: "Bután" },
    { value: "BO", label: "Bolivia" },
    { value: "BQ", label: "Bonaire, San Eustaquio y Saba" },
    { value: "BA", label: "Bosnia y Herzegovina" },
    { value: "BW", label: "Botsuana" },
    { value: "BV", label: "Isla Bouvet" },
    { value: "BR", label: "Brasil" },
    { value: "IO", label: "Territorio Británico del Océano Índico" },
    { value: "BN", label: "Brunéi" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Camboya" },
    { value: "CM", label: "Camerún" },
    { value: "CA", label: "Canadá" },
    { value: "CV", label: "Cabo Verde" },
    { value: "KY", label: "Islas Caimán" },
    { value: "CF", label: "República Centroafricana" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Isla de Navidad" },
    { value: "CC", label: "Islas Cocos" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoras" },
    { value: "CG", label: "Congo" },
    { value: "CD", label: "República Democrática del Congo" },
    { value: "CK", label: "Islas Cook" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Costa de Marfil" },
    { value: "HR", label: "Croacia" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curazao" },
    { value: "CY", label: "Chipre" },
    { value: "CZ", label: "República Checa" },
    { value: "DK", label: "Dinamarca" },
    { value: "DJ", label: "Yibuti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "República Dominicana" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egipto" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Guinea Ecuatorial" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "ET", label: "Etiopía" },
    { value: "FK", label: "Islas Malvinas" },
    { value: "FO", label: "Islas Feroe" },
    { value: "FJ", label: "Fiyi" },
    { value: "FI", label: "Finlandia" },
    { value: "FR", label: "Francia" },
    { value: "GF", label: "Guayana Francesa" },
    { value: "PF", label: "Polinesia Francesa" },
    { value: "TF", label: "Territorios Australes Franceses" },
    { value: "GA", label: "Gabón" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Alemania" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Grecia" },
    { value: "GL", label: "Groenlandia" },
    { value: "GD", label: "Granada" },
    { value: "GP", label: "Guadalupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernesey" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bisáu" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haití" },
    { value: "HM", label: "Islas Heard y McDonald" },
    { value: "VA", label: "Ciudad del Vaticano" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungría" },
    { value: "IS", label: "Islandia" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IR", label: "Irán" },
    { value: "IQ", label: "Irak" },
    { value: "IE", label: "Irlanda" },
    { value: "IM", label: "Isla de Man" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italia" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japón" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordania" },
    { value: "KZ", label: "Kazajistán" },
    { value: "KE", label: "Kenia" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Corea del Norte" },
    { value: "KR", label: "Corea del Sur" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kirguistán" },
    { value: "LA", label: "Laos" },
    { value: "LV", label: "Letonia" },
    { value: "LB", label: "Líbano" },
    { value: "LS", label: "Lesoto" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libia" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lituania" },
    { value: "LU", label: "Luxemburgo" },
    { value: "MO", label: "Macao" },
    { value: "MK", label: "Macedonia del Norte" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malaui" },
    { value: "MY", label: "Malasia" },
    { value: "MV", label: "Maldivas" },
    { value: "ML", label: "Malí" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Islas Marshall" },
    { value: "MQ", label: "Martinica" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauricio" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "México" },
    { value: "FM", label: "Micronesia" },
    { value: "MD", label: "Moldavia" },
    { value: "MC", label: "Mónaco" },
    { value: "MN", label: "Mongolia" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Marruecos" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Birmania (Myanmar)" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Países Bajos" },
    { value: "NC", label: "Nueva Caledonia" },
    { value: "NZ", label: "Nueva Zelanda" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Níger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Isla Norfolk" },
    { value: "MP", label: "Islas Marianas del Norte" },
    { value: "NO", label: "Noruega" },
    { value: "OM", label: "Omán" },
    { value: "PK", label: "Pakistán" },
    { value: "PW", label: "Palaos" },
    { value: "PS", label: "Palestina" },
    { value: "PA", label: "Panamá" },
    { value: "PG", label: "Papúa Nueva Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Perú" },
    { value: "PH", label: "Filipinas" },
    { value: "PN", label: "Islas Pitcairn" },
    { value: "PL", label: "Polonia" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Catar" },
    { value: "RE", label: "Reunión" },
    { value: "RO", label: "Rumania" },
    { value: "RU", label: "Rusia" },
    { value: "RW", label: "Ruanda" },
    { value: "BL", label: "San Bartolomé" },
    { value: "SH", label: "Santa Elena, Ascensión y Tristán de Acuña" },
    { value: "KN", label: "San Cristóbal y Nieves" },
    { value: "LC", label: "Santa Lucía" },
    { value: "MF", label: "San Martín (Francia)" },
    { value: "PM", label: "San Pedro y Miquelón" },
    { value: "VC", label: "San Vicente y las Granadinas" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Santo Tomé y Príncipe" },
    { value: "SA", label: "Arabia Saudita" },
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Serbia" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leona" },
    { value: "SG", label: "Singapur" },
    { value: "SX", label: "San Martín (Países Bajos)" },
    { value: "SK", label: "Eslovaquia" },
    { value: "SI", label: "Eslovenia" },
    { value: "SB", label: "Islas Salomón" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "Sudáfrica" },
    { value: "GS", label: "Georgia del Sur e Islas Sandwich del Sur" },
    { value: "SS", label: "Sudán del Sur" },
    { value: "ES", label: "España" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudán" },
    { value: "SR", label: "Surinam" },
    { value: "SJ", label: "Svalbard y Jan Mayen" },
    { value: "SZ", label: "Esuatini" },
    { value: "SE", label: "Suecia" },
    { value: "CH", label: "Suiza" },
    { value: "SY", label: "Siria" },
    { value: "TW", label: "Taiwán" },
    { value: "TJ", label: "Tayikistán" },
    { value: "TZ", label: "Tanzania" },
    { value: "TH", label: "Tailandia" },
    { value: "TL", label: "Timor Oriental" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad y Tobago" },
    { value: "TN", label: "Túnez" },
    { value: "TR", label: "Turquía" },
    { value: "TM", label: "Turkmenistán" },
    { value: "TC", label: "Islas Turcas y Caicos" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ucrania" },
    { value: "AE", label: "Emiratos Árabes Unidos" },
    { value: "GB", label: "Reino Unido" },
    { value: "US", label: "Estados Unidos" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistán" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "VG", label: "Islas Vírgenes Británicas" },
    { value: "VI", label: "Islas Vírgenes de los Estados Unidos" },
    { value: "WF", label: "Wallis y Futuna" },
    { value: "EH", label: "Sahara Occidental" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabue" }
];

/**
 * FORMULARIOS
 */
export const FIELD_TYPE_OPTIONS = [
    { value: 'number', label: 'Número' },
    { value: 'text', label: 'Texto' },
    { value: 'date', label: 'Fecha' },
    { value: 'select', label: 'Selector' },
    { value: 'multi-select', label: 'Selector múltiple' },
];

export const FORMULAS = [
    { value: 'imc', label: 'IMC' },
    { value: "icc", label: "Índice cintura/cadera" },
    { value: 'sum4', label: 'Sumatorio de 4 pliegues' },
    { value: 'sum6', label: 'Sumatorio de 6 pliegues' },
    { value: 'sum8', label: 'Sumatorio de 8 pliegues' },
    { value: 'faulkner', label: '% grasa Faulkner' },
    { value: 'yuhasz', label: '% grasa Yuhasz' },
];

export const FORMULA_FIELDS: Record<string, { value: string; label: string }[]> = {
    imc: [
        { value: "altura", label: "Altura (cm)" },
        { value: "peso", label: "Peso (kg)" },
    ],
    icc: [
        { value: "cintura", label: "Perímetro cintura (cm)" },
        { value: "cadera", label: "Perímetro cadera (cm)" },
    ],
    sum4: [
        { value: "tricipital", label: "Pliegue tricipital (mm)" },
        { value: "subescapular", label: "Pliegue subescapular (mm)" },
        { value: "supraespinal", label: "Pliegue supraespinal (mm)" },
        { value: "abdominal", label: "Pliegue abdominal (mm)" },
    ],
    sum6: [
        { value: "tricipital", label: "Pliegue tricipital (mm)" },
        { value: "subescapular", label: "Pliegue subescapular (mm)" },
        { value: "supraespinal", label: "Pliegue supraespinal (mm)" },
        { value: "abdominal", label: "Pliegue abdominal (mm)" },
        { value: "muslo", label: "Pliegue muslo anterior (mm)" },
        { value: "pantorrilla", label: "Pliegue pantorrilla medial (mm)" },
    ],
    sum8: [
        { value: "tricipital", label: "Pliegue tricipital (mm)" },
        { value: "subescapular", label: "Pliegue subescapular (mm)" },
        { value: "supraespinal", label: "Pliegue supraespinal (mm)" },
        { value: "abdominal", label: "Pliegue abdominal (mm)" },
        { value: "muslo", label: "Pliegue muslo anterior (mm)" },
        { value: "pantorrilla", label: "Pliegue pantorrilla medial (mm)" },
        { value: "bicipital", label: "Pliegue bicipital (mm)" },
        { value: "cresta", label: "Pliegue cresta ilíaca (mm)" },
    ],
    faulkner: [
        { value: "tricipital", label: "Pliegue tricipital (mm)" },
        { value: "subescapular", label: "Pliegue subescapular (mm)" },
        { value: "supraespinal", label: "Pliegue supraespinal (mm)" },
        { value: "abdominal", label: "Pliegue abdominal (mm)" },
    ],
    yuhasz: [
        { value: "tricipital", label: "Pliegue tricipital (mm)" },
        { value: "subescapular", label: "Pliegue subescapular (mm)" },
        { value: "supraespinal", label: "Pliegue supraespinal (mm)" },
        { value: "abdominal", label: "Pliegue abdominal (mm)" },
        { value: "muslo", label: "Pliegue muslo anterior (mm)" },
        { value: "pantorrilla", label: "Pliegue pantorrilla medial (mm)" },
    ],
};

export const FIXED_FIELDS = [
    { value: "altura", label: "Altura (cm)" },
    { value: "peso", label: "Peso (kg)" },
    { value: "cintura", label: "Perímetro cintura (cm)" },
    { value: "cadera", label: "Perímetro cadera (cm)" },
    { value: "bicipital", label: "Pliegue bicipital (mm)" },
    { value: "tricipital", label: "Pliegue tricipital (mm)" },
    { value: "subescapular", label: "Pliegue subescapular (mm)" },
    { value: "supraespinal", label: "Pliegue supraespinal (mm)" },
    { value: "abdominal", label: "Pliegue abdominal (mm)" },
    { value: "muslo", label: "Pliegue muslo anterior (mm)" },
    { value: "pantorrilla", label: "Pliegue pantorrilla medial (mm)" },
    { value: "cresta", label: "Pliegue cresta ilíaca (mm)" },
];


/**
 * ALIMENTOS
 */
export const NUTRIENTS = [
    { value: 'proteins', label: 'Proteinas (g)' },
    { value: 'carbohydrates', label: 'Carbohidratos (g)' },
    { value: 'fat', label: 'Grasas (g)' },
    { value: 'alcohol', label: 'Alcohol (g)' },
    { value: 'water', label: 'Agua (g)' },
    { value: 'totalfiber', label: 'Fibra total (g)' },
    { value: 'starch', label: 'Almidón (g)' },
    { value: 'sugars', label: 'Azucares (g)' },
    { value: 'fatacidspolyunsaturated', label: 'Ácidos grasos poliinsaturados (g)' },
    { value: 'fatacidssaturated', label: 'Ácidos grasos saturados (g)' },
    { value: 'colesterol', label: 'Colesterol (g)' },
    { value: 'fatacidlauric12', label: 'Ácido graso laurico 12:0 (g)' },
    { value: 'fatacidmyristic14', label: 'Ácido graso miristico 14:0 (g)' },
    { value: 'fatacidpalmitic16', label: 'Ácido graso palmítico 16:0 (g)' },
    { value: 'fatacidstearic18', label: 'Ácido graso esteárico 18:0 (g)' },
    { value: 'fatacidoleic181', label: 'Ácido graso oleico 18:1 n-9 cis (g)' },
    { value: 'fatacid182', label: 'Ácido graso 18:2 (g)' },
    { value: 'fatacid183', label: 'Ácido graso 18:3 (g)' },
    { value: 'fatacidarachidonic204', label: 'Ácido graso araquidonico 20:4 n-6 (g)' },
    { value: 'fatacideicosapentaenoic205', label: 'Ácido graso eicosapentaenoico 20:5 n-3 (g)' },
    { value: 'fataciddocosahexaenoic226', label: 'Ácido graso docosahexaenoico 22:6 n-3 (g)' },
    { value: 'vitamind', label: 'Vitamina D / calciferol (mcg)' },
    { value: 'vitamine', label: 'Vitamina E / tocoferol (mcg)' },
    { value: 'vitaminb7', label: 'Vitamina B7 / biotina (mcg)' },
    { value: 'vitaminb9', label: 'Vitamina B9 / folato (mcg)' },
    { value: 'vitaminb3', label: 'Vitamina B3 / niacina (mg)' },
    { value: 'vitaminb5', label: 'Vitamina B5 / ácido pantoténico (mg)' },
    { value: 'vitaminb2', label: 'Vitamina B2 / riboflavina (mg)' },
    { value: 'vitaminb1', label: 'Vitamina B1 / tiamina (mg)' },
    { value: 'vitaminb12', label: 'Vitamina B12 / cobalamina (mcg)' },
    { value: 'vitaminb6', label: 'Vitamina B6 / piridoxina (mcg)' },
    { value: 'vitaminc', label: 'Vitamina C / ácido ascórbico (mg)' },
    { value: 'vitamina', label: 'Vitamina A / retinol (mcg)' },
    { value: 'calcium', label: 'Calcio (mg)' },
    { value: 'iron', label: 'Hierro (mg)' },
    { value: 'potassium', label: 'Potasio (mg)' },
    { value: 'magnesium', label: 'Magnesio (mg)' },
    { value: 'sodium', label: 'Sodio (mg)' },
    { value: 'phosphorus', label: 'Fosforo (mg)' },
    { value: 'copper', label: 'Cobre (mg)' },
    { value: 'iodine', label: 'Iodo (mcg)' },
    { value: 'selenium', label: 'Selenio (mcg)' },
    { value: 'zinc', label: 'Zinc (mg)' },
    { value: 'energy', label: 'Energía (kcal)' },
    { value: 'energykj', label: 'Energía (kJ)' },
    { value: 'cisteine', label: 'Cisteína (mg)' },
    { value: 'histidine', label: 'Histidina (mg)' },
    { value: 'isoleucine', label: 'Isoleucina (mg)' },
    { value: 'leucine', label: 'Leucina (mg)' },
    { value: 'lysine', label: 'Lisina (mg)' },
    { value: 'methionine', label: 'Metionina (mg)' },
    { value: 'phenylalanine', label: 'Fenilalanina (mg)' },
    { value: 'serine', label: 'Serina (mg)' },
    { value: 'threonine', label: 'Treonina (mg)' },
    { value: 'tryptophan', label: 'Triptófano (mg)' },
    { value: 'tyrosine', label: 'Tirosina (mg)' },
    { value: 'valine', label: 'Valina (mg)' },
    { value: 'arginine', label: 'Arginina (mg)' },
    { value: 'glutamine', label: 'Glutamina (mg)' },
    { value: 'alanine', label: 'Alanina (mg)' },
    { value: 'asparagine', label: 'Asparagina (mg)' },
    { value: 'glycine', label: 'Glicina (mg)' },
    { value: 'proline', label: 'Prolina (mg)' },
    { value: 'glucose', label: 'Glucosa (g)' },
    { value: 'fructose', label: 'Fructosa (g)' },
    { value: 'galactose', label: 'Galactosa (g)' },
    { value: 'sucrose', label: 'Sacarosa (g)' },
    { value: 'maltose', label: 'Maltosa (g)' },
    { value: 'lactose', label: 'Lactosa (g)' },
    { value: 'oligosaccharides', label: 'Oligosacaridos (g)' },
    { value: 'solublefiber', label: 'Fibra soluble (g)' },
    { value: 'insolublefiber', label: 'Fibra insoluble (g)' },
    { value: 'vitamink', label: 'Vitamina K / naftoquinona (mcg)' },
    { value: 'chlorine', label: 'Cloro (mg)' },
    { value: 'fluoride', label: 'Fluor (mg)' },
    { value: 'cobalt', label: 'Cobalto (mcg)' },
    { value: 'nickel', label: 'Niquel (mg)' },
    { value: 'manganese', label: 'Manganeso (mg)' },
    { value: 'oxalate', label: 'Oxalato (mg)' },
    { value: 'purines', label: 'Purinas (mg)' },
    { value: 'caffeine', label: 'Cafeína (mg)' },
];

export const ALLERGENS_LIST = [
    { nombre: 'Gluten', name: 'gluten' },
    { nombre: 'Crustáceos', name: 'crustaceans' },
    { nombre: 'Huevos', name: 'eggs' },
    { nombre: 'Pescado', name: 'fish' },
    { nombre: 'Cacahuetes', name: 'peanuts' },
    { nombre: 'Soja', name: 'soy' },
    { nombre: 'Leche', name: 'milk' },
    { nombre: 'Frutos de cascara', name: 'nuts' },
    { nombre: 'Apio', name: 'celery' },
    { nombre: 'Mostaza', name: 'mustard' },
    { nombre: 'Granos de  sésamo', name: 'sesameseeds' },
    { nombre: 'Dióxido de azufre', name: 'sulfurdioxide' },
    { nombre: 'Altramuces', name: 'lupin' },
    { nombre: 'Moluscos', name: 'molluscs' },
];

export const MONTHS = [
    { label: 'Enero', value: 'enero' },
    { label: 'Febrero', value: 'febrero' },
    { label: 'Marzo', value: 'marzo' },
    { label: 'Abril', value: 'abril' },
    { label: 'Mayo', value: 'mayo' },
    { label: 'Junio', value: 'junio' },
    { label: 'Julio', value: 'julio' },
    { label: 'Agosto', value: 'agosto' },
    { label: 'Septiembre', value: 'septiembre' },
    { label: 'Octubre', value: 'octubre' },
    { label: 'Noviembre', value: 'noviembre' },
    { label: 'Diciembre', value: 'diciembre' },
];

export const DIFFICULTY = [
    { value: 'Fácil', label: 'Fácil' },
    { value: 'Media', label: 'Media' },
    { value: 'Difícil', label: 'Difícil' }
];

export const TECHNIQUES = [
    { label: 'Horno', value: 'oven' },
    { label: 'Guisado', value: 'stew' },
    { label: 'Salteado', value: 'sauteed' },
    { label: 'Frito', value: 'fry' },
    { label: 'Crudo', value: 'crude' },
    { label: 'Hervido', value: 'boil' },
    { label: 'Freidora de aire', value: 'airFryer' },
    { label: 'Plancha', value: 'griddle' },
    { label: 'Vapor', value: 'steam' },
    { label: 'Microondas', value: 'microwave' },
];

export const STORAGE_CONDITIONS = [
    { label: 'Se conservará caliente hasta su consumo. Temperatura superior a 65ºC', value: 'hot' },
    { label: 'Se conservará en frío hasta su consumo. Temperatura aproximada 4ºC', value: 'cold' },
];

export const RATION_PRESENTATION = [
    { label: 'Entero/plato completo', value: 'whole' },
    { label: 'Troceado/porciones/unidades', value: 'slicing' },
    { label: 'Crema/puré', value: 'cream' },
];

export const UNIT_MEASUREMENT = [
    { label: 'Gramos', value: 'g' },
    { label: 'Miligramos', value: 'mg' },
    { label: 'Microgramos', value: 'mcg' },
];