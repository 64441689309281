import { Fragment, useCallback, useEffect, useState } from "react";
import { MenuService } from "../../../../services/menus/menuService";
import useFetch from "../../../../hooks/useFetch";
import { useFormik } from "formik";
import Card, { CardBody, CardFooter, CardTitle } from "../../../../components/bootstrap/Card";
import Spinner from "../../../../components/bootstrap/Spinner";
import { toast } from "react-toastify";
import moment from "moment";


type ObjectivesMenuTableProps = {
    clientData: any;
    weight: number;
    height: number;
}

const BasalMetabolismMenu: React.FC<ObjectivesMenuTableProps> = ({ clientData, weight, height }) => {

    // STATES


    // FETCHS


    // FUNCTIONS
    
    //-------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIÓN QUE REALIZA LA FORMULA DE METABOLISMO BASAL SEGÚN LA OPCIÓN ESCOGIDA POR LA ORGANIZACIÓN
     * @EN FUNCTION THAT PERFORMS THE BASAL METABOLISM FORMULA ACCORDING TO THE OPTION CHOSEN BY THE ORGANIZATION
     * @param data
     * @returns 
     */
    //-------------------------------------------------------------------------------------------------
    const calculateBasalMetabolism = (data: any) => {
        let basalMetabolism = 0;
 
        let age = data.birthdayDate ? moment().diff(data.birthdayDate?.date, 'years') : 0;
        if(data.basalMetabolism.option === 1)
        {
            if( data.sex === "H" )
            {
               
                basalMetabolism = 66.473 + (13.751 * weight ) + (5.0033 * height) - (6.7550 * age);
            }

            if( data.sex === "M")
            {
                basalMetabolism = 655.1 + (9.463 * weight) + (1.8 * height) - (4.6756 * age);
            }
        }
        
        if(data.basalMetabolism.option === 2)
        {
            if ( data.sex === "H" )
            {
                basalMetabolism = 10 * weight + 6.25 * height - 5 * age + 5;
            }

            if ( data.sex === "M" )
            {
                basalMetabolism = 10 * weight + 6.25 * height - 5 * age - 161;
            }
        }

        return basalMetabolism;
    };

    // RENDER

    return (
        <Fragment>
            <div className="row">
                <div className="col-6 p-3">
                    <span className="fw-bold"> Metabolismo basal (MB): </span>
                </div>
                <div className="col-4 p-3 bg-light rounded d-flex justify-content-center align-items-center">
                    <span className="fw-bold"> {calculateBasalMetabolism(clientData).toFixed(2)} kcal </span>
                </div>
            </div>
            <div className="row p-4"> 
                <div className="col-12 p-4 bg-secondary rounded">

                    <div className="row">
                        <div className="col-12 mb-3">
                            <span className="fw-bold">Fórmula de Mifflin - St. Jeor:</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-1">
                            <span className="fw-bold">Hombres</span>
                        </div>
                        <div className="col-12">
                            <span>10 x peso(kg) + 6,25 x altura(cm)  - 5 x edad(años) + 5</span>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 mb-1">
                            <span className="fw-bold">Mujeres</span>
                        </div>
                        <div className="col-12">
                            <span>10 x peso(kg) + 6,25 x altura(cm)  - 5 x edad(años) - 161</span>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-12 mb-3">
                            <span className="fw-bold">Fórmula de Harris-Benedic:</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-1">
                            <span className="fw-bold">Hombres</span>
                        </div>
                        <div className="col-12">
                            <span>66,473 + [13,751 x peso(kg)] + [5,0033 x altura(cm)] - [6,7550 x edad(años)]</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-1">
                            <span className="fw-bold">Mujeres</span>
                        </div>
                        <div className="col-12">
                            <span>655,1 + [9,463 x peso(kg)] + [1,8 x altura(cm)] - [4,6756 x edad(años)]</span>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default BasalMetabolismMenu;