import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from 'yup';
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardFooter } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Spinner from "../../../../components/bootstrap/Spinner";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { RationsService } from "../../../../services/rations/rationService";
import useHandleErrors from "../../../../utils/hooks/useHandleErrors";
import GroupRations from "./GroupRations";

interface RationModalFormProps {
    isOpen: boolean;
    setIsOpen: any;
    refetch: any;
    rowId?: string;
}

interface RationForm {
    name: string;
    ageFrom: number;
    ageTo: number;
    foodGroupsConfig: { id: string[], quantity: number }[] | null;
}

const initialValues: RationForm = {
    name: '',
    ageFrom: 0,
    ageTo: 0,
    foodGroupsConfig: [],
};

const schema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    ageFrom: yup.number().required('La edad mínima es obligatoria'),
    ageTo: yup.number().required('La edad máxima es obligatoria'),
    foodGroupsConfig: yup.array().of(
        yup.object().shape({
            id: yup.array().of(yup.string()).required('El grupo de alimentos es obligatorio').test('is-not-empty', 'El grupo de alimentos es obligatorio', (value) => value?.length! > 0),
            quantity: yup.number().required('La cantidad es obligatoria'),
        })
    ),
});

const RationModalForm: FC<RationModalFormProps> = ({ isOpen, setIsOpen, refetch, rowId }) => {

    // STATES

    const [isLoading, setIsLoading] = useState(false);
    const [foodGroupConfig, setFoodGroupConfig] = useState<{ id: string[], quantity: number }>({ id: [], quantity: 0 });

    // HOOKS

    const { handleErrors } = useHandleErrors();

    // FORMIK

    const formik = useFormik<RationForm>({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            // Add food group configuration to the formik values if it has an id and quantity selected
            let updatedFGC = formik.values.foodGroupsConfig || [];
            if (foodGroupConfig.id.length! > 0) {
                updatedFGC.push(foodGroupConfig);
                values.foodGroupsConfig = updatedFGC;
                await formik.setFieldValue('foodGroupsConfig', updatedFGC);
                setFoodGroupConfig({ id: [], quantity: 0 });
            }

            if (values.foodGroupsConfig?.length === 0) return toast.error('La configuración de grupos de alimentos es obligatoria');

            rowId ? handleEdit(values) : handleCreate(values)
        }
    });

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja la creación de la ración.
     * @EN Function that handles ration creation.
     * 
     * @param values 
     */
    //--------------------------------------------------------------------------------------------------
    const handleCreate = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (new RationsService()).createRation(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetch();
                setIsOpen(false);
                toast.success('Ración creada correctamente');
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al crear la ración');
        } finally {
            setIsLoading(false);
        }
    };
    //--------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja la edición de la ración.
     * @EN Function that handles ration edition.
     * 
     * @param values 
     */
    //--------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (new RationsService()).editRation(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetch();
                setIsOpen(false);
                toast.success('Ración editada correctamente');
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al editar la ración');
        } finally {
            setIsLoading(false);
        }
    };
    //------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que verifica si un campo tiene errores.
     * @EN Function that checks if a field has errors.
     * 
     * @param inputFieldID 
     */
    //--------------------------------------------------------------------------------------------------
    const verifyClass = (inputFieldID: keyof RationForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }
    //--------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que muestra los errores de un campo.
     * @EN Function that shows field errors.
     * 
     * @param inputFieldID 
     */
    //--------------------------------------------------------------------------------------------------
    const showErrors = (inputFieldID: keyof RationForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }
    //--------------------------------------------------------------------------------------------------

    // USE EFFECT

    useEffect(() => {
        if (rowId) {
            (async () => {
                const response = await (new RationsService()).getRation(rowId);
                const responseData = response.getResponseData();
                if (responseData.success) {
                    const values = {
                        portionConfigurationId: responseData.data.id,
                        name: responseData.data.name,
                        ageFrom: responseData.data.ageFrom,
                        ageTo: responseData.data.ageTo,
                        foodGroupsConfig: responseData.data.foodGroupsConfiguration.map((fg: any) => ({
                            id: fg.portionConfigurationHasFoodGroupConfigurationHasFoodGroups.map((fgc: any) => fgc.foodGroup?.id),
                            quantity: fg.quantity,
                        }))
                    };
                    formik.setValues(values);
                } else {
                    handleErrors(response);
                }
            })();
        } else {
            formik.setValues(initialValues);
        }
    }, [rowId]);

    // RENDER

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Datos de la ración' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id='ration-data'>{rowId ? 'Editar' : 'Crear'} Ración</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody className="row g-md-3">
                        <FormGroup label="Nombre" requiredInputLabel className="col-md-6">
                            <Input
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('name')}
                            />
                            {showErrors('name')}
                        </FormGroup>

                        <FormGroup label="Edad mínima" requiredInputLabel className="col-md-3">
                            <Input
                                id="ageFrom"
                                name="ageFrom"
                                type="number"
                                value={formik.values.ageFrom}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('ageFrom')}
                            />
                            {showErrors('ageFrom')}
                        </FormGroup>

                        <FormGroup label="Edad máxima" requiredInputLabel className="col-md-3">
                            <Input
                                id="ageTo"
                                name="ageTo"
                                type="number"
                                value={formik.values.ageTo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={verifyClass('ageTo')}
                            />
                            {showErrors('ageTo')}
                        </FormGroup>

                        <GroupRations formik={formik} foodGroupConfig={foodGroupConfig} setFoodGroupConfig={setFoodGroupConfig} />
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                        <Button type="submit" isDisable={isLoading} color="primary">
                            {isLoading ? <Spinner color={'dark'} /> : rowId ? 'Guardar Cambios' : 'Crear'}
                        </Button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default RationModalForm;