import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SettingsState {
    selectedTab: string;
}

const initialState: SettingsState = {
    selectedTab: 'informed_consent',
};

const settingsSlice = createSlice({
    name: 'generalConfig',
    initialState,
    reducers: {
        setSelectedTab(state, action: PayloadAction<string>) {
            state.selectedTab = action.payload;
        }
    },
});

export const { setSelectedTab } = settingsSlice.actions;
export default settingsSlice.reducer;