import moment from "moment";
import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle, } from "../../../components/bootstrap/Card";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";
import Icon from "../../../components/icon/Icon";
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import { menusMenu } from "../../../menu";
import { MenuService } from "../../../services/menus/menuService";
import { ProfileService } from "../../../services/profile/profileService";
import { MenusApiResponse } from "../../../type/clientMenus-type";

export interface IMenuFilters {
    name?: string;
}

const menuFilters: IMenuFilters = {
    name: '',
};

const ConfigMenuList = () => {

    const navigate = useNavigate();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(menuFilters);

    const [profile] = useFetch(useCallback(async () => {
        const profileService = new ProfileService();
        const response = await profileService.getMyProfile();
        return response.getResponseData();
    }, []));

    const [configData, fetchingConfigs, configError, refetchConfigs] = useFetch(useCallback(async () => {
        const menuService = new MenuService();
        const response = await menuService.listMenuConfigs(filters);
        return response.getResponseData() as MenusApiResponse;
    }, [profile, filters]));

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const getContent = () => {
        if (configError) return <ErrorMessage message={configError} />;

        const handleDelete = async (id: string) => {
            try {
                const response = await (await (new MenuService()).deleteConfig(id)).getResponseData();
                if (response.success) {
                    refetchConfigs();
                    updateFilters({ ...filters });

                    setTimeout(() => {
                        toast.success('Configuración eliminada correctamente');
                    }, 100);
                } else {
                    toast.error(response.message || 'Error al eliminar la configuración');
                }
            } catch (error: any) {
                toast.error(error.message || 'Error al eliminar la configuración');
            }
        };

        if (configData && configData.configs.length > 0) {
            let apiData = configData as MenusApiResponse;

            return (
                <CustomTable
                    title="Configuraciones de menú"
                    loading={fetchingConfigs}
                    data={configData.configs ? configData.configs : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as MenusApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return <>{element.name}</>
                            },
                        },
                        {
                            name: "Número de días",
                            keyValue: "days",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return <>{element.dayValue}</>
                            },
                        },
                        {
                            name: "Ingestas por día",
                            keyValue: "intakes",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return <>{element.intakeValue}</>
                            },
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return <>{moment(element.createdAt.date).format('DD/MM/YYYY') || '-'}</>
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar configuración",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar configuración",
                                    text: "¿Está seguro que desea eliminar la configuración?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Listado de Configuraciones</CardTitle>
                        <SubheaderSeparator />
                        <Button
                            color="storybook"
                            icon="Add"
                            isLight
                            onClick={() => { navigate(menusMenu.menus.path + "/config-create") }}
                        >
                        </Button>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <>{getContent()}</>
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    );
};

export default ConfigMenuList;