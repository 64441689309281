export const getContrastColor = (hexColor: string): string => {
    if (!hexColor) return '#000000';
    // REMOVE THE # FROM HEX
    hexColor = hexColor.replace(/^#/, '');
  
    // CONVERT HEX TO RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
  
    // CALCULATE LUMINANCE
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // RETURN BLACK FOR BRIGHT COLORS, WHITE FOR DARK COLORS
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
}