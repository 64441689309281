import React, { useCallback, useEffect, useState } from 'react';
import { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import { toast } from 'react-toastify';
import useFetch from '../../../hooks/useFetch';
import { CompanyService } from '../../../services/companies/organizationService';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import { Loader } from '../../../components/bootstrap/SpinnerLogo';
import WorkplacesForm from './WorkplaceForm';

interface GCWorkplacesProps { }

export interface WorkplaceForm {
    workplaces: { name: string }[];
}

const schema = yup.object({
    workplaces: yup.array().of(yup.object().shape({
        name: yup.string().required("El nombre es obligatorio"),
    }))
});

const GCWorkplaces: React.FC<GCWorkplacesProps> = ({ }) => {

    // STATES 

    const [workplace, setWorkplace] = useState<{ name: string }>({ name: '' });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // HOOKS
    
    const { handleErrors } = useHandleErrors();

    // DATA FETCHING

    //--------------------------------------------------------------------------------------------
    /**
     * @ES ESTA FUNCION SE ENCARGA DE OBTENER LOS CENTROS DE TRABAJO DE LA EMPRESA
     * @EN THIS FUNCTION IS RESPONSIBLE FOR GETTING THE WORKPLACES OF THE COMPANY 
     */
    //--------------------------------------------------------------------------------------------
    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new CompanyService()).getWorkplaces();
        return response.getResponseData();
    }, []));
    //--------------------------------------------------------------------------------------------

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------
    /**
     * @ES ESTA FUNCION SE ENCARGA DE ENVIAR LOS CENTROS DE TRABAJO DE LA EMPRESA
     * @EN THIS FUNCTION IS RESPONSIBLE FOR SENDING THE WORKPLACES OF THE COMPANY 
     */
    //--------------------------------------------------------------------------------------------
    const handleSubmit = async (values: WorkplaceForm) => {
        setIsLoading(true);
        if (workplace.name !== '') values.workplaces.push(workplace);
        try {
            const response = await (await (new CompanyService()).setWorkplaces(values)).getResponseData();
            if (response.success) {
                toast.success("Centros de trabajo editados correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar las fichas técnicas");
        } finally {
            setIsLoading(false);
            setWorkplace({ name: '' });
        }
    };
    //--------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik<WorkplaceForm>({
        initialValues: { workplaces: [] },
        validationSchema: schema,
        onSubmit: values => { handleSubmit(values) }
    });

    // USE EFFECT

    useEffect(() => {
        if (data) {
            const mappedData = data?.workPlaces.map((w: any) => ({ id:w.id, name: w.name, exists: true }));
            formik.setFieldValue('workplaces', mappedData);
        }
    }, [data]);

    if (loading) return <Loader />;

    // RENDER

    return (
        <>
            <CardHeader>
                <CardLabel>
                    <CardTitle>Centros de trabajo</CardTitle>
                </CardLabel>
            </CardHeader>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody>
                    <WorkplacesForm formik={formik} workplace={workplace} setWorkplace={setWorkplace} />
                </CardBody>

                <CardFooter className='d-flex justify-content-end'>
                    <Button
                        type="submit"
                        isDisable={isLoading || formik.values.workplaces.length === 0}
                        color='primary'
                    >
                        {isLoading ? <Spinner color={'dark'} /> : 'Guardar'}
                    </Button>
                </CardFooter>
            </form>
        </>
    );
};

export default GCWorkplaces;