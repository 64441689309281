import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardFooter } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import useHandleErrors from "../../../../utils/hooks/useHandleErrors";
import Spinner from "../../../../components/bootstrap/Spinner";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import SearchableSelect from "../../../../components/select/SearchableSelect";
import { DataSourceService } from "../../../../services/data-sources/dataSourceService";
import ColorPicker from "../../../../components/ColorPicker";
import { showErrors } from "../../../../utils/Forms/FormikVerifyClass";

interface AppModalFormProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    refetch: () => void;
    rowId?: string;
}

interface AppForm {
    source: string;
    color: string;
}

const initialValues: AppForm = {
    source: "",
    color: "#ffffff",
};

const schema = yup.object({
    source: yup.string().required("Campo requerido"),
});

const AppModalForm: FC<AppModalFormProps> = ({ isOpen, setIsOpen, refetch, rowId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const { handleErrors } = useHandleErrors();

    // Configuración de Formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            rowId ? await handleEdit(values) : await handleCreate(values);
        },
    });

    // Obtener opciones disponibles
    const getOptionsAvailable = async () => {
        const response = (await new DataSourceService().getAvailableSources()).getResponseData();
        if (response.success) {
            setOptions(response.data.map((option: string) => ({ value: option, label: option })));
        } else {
            handleErrors(response);
        }
    };

    // Crear nueva fuente de datos
    const handleCreate = async (values: any) => {
        try {
            setIsLoading(true);
            const response = (await new DataSourceService().createDataSource(values)).getResponseData();
            if (response.success) {
                refetch();
                toast.success("Fuente de datos creada correctamente");
                setIsOpen(false);
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error("Error al crear la fuente de datos");
        } finally {
            setIsLoading(false);
        }
    };

    // Editar fuente de datos
    const handleEdit = async (values: any) => {
        try {
            setIsLoading(true);
            const response = (await new DataSourceService().editDataSource(values)).getResponseData();
            if (response.success) {
                refetch();
                toast.success("Fuente de datos editada correctamente");
                setIsOpen(false);
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error("Error al editar la fuente de datos");
        } finally {
            setIsLoading(false);
        }
    };

    // Manejar cambios en el selector de color
    const handleColorChange = (color: any) => {
        formik.setFieldValue("color", color.hex);
    };

    // Cargar opciones disponibles al montar el componente
    useEffect(() => {
        getOptionsAvailable();
    }, []);

    // Cargar datos cuando `rowId` cambia (edición)
    useEffect(() => {
        if (rowId) {
            (async () => {
                const response = (await new DataSourceService().getDataSourceById(rowId)).getResponseData();
                if (response.success) {
                    const values = {
                        id: response.data.id,
                        source: response.data.source,
                        color: response.data.color,
                    };
                    formik.setValues(values);
                } else {
                    handleErrors(response);
                }
            })();
        } else {
            formik.resetForm(); // Restablecer el formulario al valor inicial si no hay `rowId`
        }
    }, [rowId]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="lg" titleId="Datos del Color de la Fuente de Datos de los Nutrientes" isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id="allergy-data">{rowId ? "Editar" : "Crear"} Color de la Fuente de Datos Nutrientes</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={formik.handleSubmit}>
                    <CardBody>
                        <FormGroup label="Fuente" requiredInputLabel>
                            <SearchableSelect
                                name="source"
                                options={options}
                                value={options.find((option) => option.value === formik.values.source) || null}
                                onChange={(selectedOption: { value: string; label: string } | null) => {
                                    formik.setFieldValue("source", selectedOption?.value || "");
                                }}
                                classname={formik.touched.source && formik.errors.source ? "is-invalid" : ""}
                                placeholder="Selecciona una fuente de datos"
                            />
                            {showErrors(formik, 'source')}

                        </FormGroup>
                        <FormGroup label="Color" className="col-md-6 m-2">
                            <ColorPicker
                                initialColor={formik.values.color}
                                onChange={handleColorChange}
                                useSketchPicker
                            />
                        </FormGroup>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                        <Button type="submit" isDisable={isLoading} color="primary">
                            {isLoading ? <Spinner color="dark" /> : rowId ? "Guardar Cambios" : "Crear"}
                        </Button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default AppModalForm;