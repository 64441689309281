import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import { CardTitle } from "../../../components/bootstrap/Card";
import StatusDropdown from "../../../components/StatusDropdown";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/organizationService";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { CompaniesApiResponse } from "../../../type/company-type";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import AsyncImg from "../../../components/AsyncImg";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import CompaniesFilters from "./companies-options/CompaniesFilters";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";

export interface ICompanyFilters {
  active: number;
  role?: any;
}

const companyFilters: ICompanyFilters = {
  active: 3,
  role: '',
};

const CompaniesList = () => {

  const { handleErrors } = useHandleErrors();
  const navigate = useNavigate();
  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(companyFilters);

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await (new CompanyService()).getCompanies(filters);
    return response.getResponseData() as CompaniesApiResponse;
  }, [filters]));

  const handleDelete = async (id: string) => {
    try {
      const response = await (await (new CompanyService()).deleteCompany(id)).getResponseData();
      if (response.success) {
        refetch();
        toast.success('Organización eliminada');
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      toast.error('Error al eliminar la organización');
    }
  };

  const toggleCompanyStatus = async (id: string, status: boolean, toggleStatus: Function) => {
    try {
      setChangingStatus([...changingStatus, id]);
      const response = await (await new CompanyService().toggleCompanyStatus(id, status)).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success(status ? 'Se ha activado la organización' : 'Se ha desactivado la organización');
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data) {
      let apiData = data as CompaniesApiResponse;

      return (
        <CustomTable
          title="Organizaciones"
          loading={loading}
          data={apiData.companies ? apiData.companies : null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: apiData as CompaniesApiResponse ? apiData.lastPage : 1,
            handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "",
              keyValue: "logo",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className='user-avatar'>
                      <AsyncImg id={element.logo ? element.logo.id : null} styles={'avatar-contain'} />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/profile`) }}>
                    {element.name}
                  </div>
                )
              },
            },
            {
              name: "Dirección",
              keyValue: "address",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (<>{element.address || "-"}</>);
              },
            },
            {
              name: "CIF",
              keyValue: "cif",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (<>{element.cif}</>);
              },
            },
            {
              name: "Nº de roles",
              keyValue: "role",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (<>{element.roles.length}</>);
              },
            },
            {
              name: "Estado",
              keyValue: "active",
              render: (element: any) => {
                return (
                  <StatusDropdown
                    disabled={changingStatus.includes(element.id)}
                    itemId={element.id}
                    status={element.active}
                    change={toggleCompanyStatus}
                  />
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={[
            {
              title: "Editar",
              icon: "Edit",
              buttonType: 'icon',
              additionalClasses: 'text-primary',
              description: "Editar organización",
              click: (item: any) => {
                navigate(`${item.id}/edit`);
              },
            },
            {
              title: "Eliminar",
              icon: "Delete",
              buttonType: 'icon',
              additionalClasses: 'text-danger',
              description: "Eliminar organización",
              click: (item: any) => {
                handleConfirmationAlert({
                  title: "Eliminar organización",
                  text: "¿Está seguro que desea eliminar la organización?",
                  icon: "warning",
                  onConfirm: () => { handleDelete(item.id) }
                })
              }
            }
          ]}
        />
      )
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Organizaciones</CardTitle>
          <SubheaderSeparator />
          <Button color="storybook" icon="Add" isLight onClick={() => { navigate("create") }} />
        </SubHeaderLeft>
        <SubHeaderRight>
          <CompaniesFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <>{getContent()}</>
      </Page>
    </Fragment>
  );
};

export default CompaniesList;