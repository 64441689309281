import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { copyIntakes } from '../../redux/menuSlice';
import { AppDispatch } from '../../redux/store';
import { MenuService } from '../../services/menus/menuService';
import { MenusApiResponse } from '../../type/clientMenus-type';
import useHandleErrors from '../../utils/hooks/useHandleErrors';
import { handleConfirmationAlert } from '../ConfirmationAlert';
import Button from '../bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Spinner from '../bootstrap/Spinner';
import Icon from '../icon/Icon';
import ItemSelectors from './ItemSelectors';
import { useMenuDataProvider } from './providers/MenuDataProvider';
import { useMenuProvider } from './providers/MenuProvider';
import './styles/styles.scss';

interface MenuActionsProps {
    menuId: string;
    data: any;
    client: any;
}

const MenuActions: React.FC<MenuActionsProps> = ({ menuId, data, client }) => {

    // PROVIDERS

    const {
        menu_state, addIntakesFromSession,
        showFoodGroup, setShowFoodGroup, showPhysicalActivities, setShowPhysicalActivities,
        showRecipeSelectors, showFoodSelectors, showSupplementSelectors, showPhysicalActivitySelectors,
        setShowRecipeSelectors, setShowFoodSelectors, setShowSupplementSelectors, setShowPhysicalActivitySelectors,
        copyingIntake, setCopyingIntake,
    } = useMenuDataProvider();
    const { setDisplayCreateTemplate } = useMenuProvider();

    // HOOKS

    const dispatch = useDispatch<AppDispatch>();
    const { handleErrors } = useHandleErrors();

    // STATES

    const [isSending, setIsSending] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    // VARIABLES
    const buttons = [
        { label: 'Recetas', stateSetter: setShowRecipeSelectors },
        { label: 'Alimentos', stateSetter: setShowFoodSelectors },
        { label: 'Suplementos', stateSetter: setShowSupplementSelectors },
        { label: 'Ejercicio físico', stateSetter: setShowPhysicalActivitySelectors },
    ];

    // FUNCTIONS

    /**
     * @ES Función que se dispara al hacer click en un botón de acción
     * @EN Function that is triggered when clicking on an action button
     * @param setter 
     */
    const handleClick = (setter: any) => {
        setShowRecipeSelectors(false);
        setShowFoodSelectors(false);
        setShowSupplementSelectors(false);
        setShowPhysicalActivitySelectors(false);
        setter(true);
    };

    /**
     * @ES Descargar menú
     * @EN Download menu
     */
    const _downloadMenu = async () => {
        try {
            setIsDownloading(true);
            const response = await (new MenuService()).downloadMenu(menuId);
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.name.replace(/\s/g, "") + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                handleErrors(response);
            }
            setIsDownloading(false);
        } catch (error: any) {
            toast.error(error.message);
            setIsDownloading(false);
        }
    };

    /**
     * @ES Enviar menú por email
     * @EN Send menu by email
     */
    const _sendMenuByEmail = async () => {
        try {
            setIsSending(true);
            const response = await (new MenuService()).sendMenuByEmail(menuId, client.email);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                toast.success("Plan enviado al paciente");
            } else {
                handleErrors(response);
            }
            setIsSending(false);
        } catch (error: any) {
            toast.error("Error al enviar el plan al paciente");
            setIsSending(false);
        }
    };

    /**
     * @ES Pegar ingesta en el menú
     * @EN Paste intake into the menu
     */
    const handlePasteIntake = async () => {
        addIntakesFromSession(menu_state.intakes);

        try {
            const response = await (await (new MenuService()).addIntake(menuId, menu_state.intakes)).getResponseData();
            if (response.success) {
                toast.success("Ingesta(s) pegada(s) correctamente");
                dispatch(copyIntakes([]));
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al añadir el item al menú");
        }
    };

    /**
     * @ES Eliminar ingesta copiada
     * @EN Delete copied intake
     */
    const handleRemoveCopiedIntake = async () => {
        handleConfirmationAlert({
            title: "Eliminar ingesta(s) copiada(s)",
            text: "¿Está seguro que desea eliminar la(s) ingesta(s) copiada(s)?",
            icon: "warning",
            onConfirm: () => { dispatch(copyIntakes([])); }
        })
    };

    if (!data) return null;

    // RENDER

    return (
        <>
            <div className='actions-menu rounded row d-flex justify-content-between'>
                <div className='col-md-7 d-flex align-items-center'>
                    {buttons.map(({ label, stateSetter }) => (
                        <Button
                            key={label}
                            color='light'
                            isLight
                            onClick={() => handleClick(stateSetter)}
                            className="p-2"
                        >
                            {label}
                        </Button>
                    ))}

                    <Button
                        color='light'
                        isLight
                        onClick={() => handleClick(() => { })}
                        className={classNames('p-2', {
                            'd-none': !showRecipeSelectors && !showFoodSelectors && !showSupplementSelectors && !showPhysicalActivitySelectors
                        })}
                    >
                        <Icon icon='KeyboardArrowUp' size={'2x'} />
                    </Button>
                </div>

                <div className='col-md-5 d-flex justify-content-end p-0'>
                    <Button
                        color='primary'
                        title='Pegar ingesta'
                        onClick={() => { handlePasteIntake() }}
                        className={classNames('p-2', { 'd-none': menu_state.intakes.length === 0 })}
                    >
                        <Icon icon="ContentPaste" color='dark' size={'2x'} />
                    </Button>

                    <Button
                        color='primary'
                        isLight={menu_state.intakes.length === 0}
                        title='Copiar ingesta'
                        onClick={() => {
                            if (copyingIntake) handleRemoveCopiedIntake();
                            if (!copyingIntake) setCopyingIntake(true);
                        }}
                        className="p-2"
                    >
                        <Icon icon="ContentCopy" color='dark' size={'2x'} />
                        {menu_state.intakes.length > 0 && `(${menu_state.intakes.length})`}
                    </Button>

                    <Button
                        color='primary'
                        isLight
                        title='Imprimir'
                        onClick={() => { _downloadMenu() }}
                        className="p-2"
                    >
                        {isDownloading ? <div className='text-center'><Spinner /></div> : <Icon icon="Print" color='dark' size={'2x'} />}
                    </Button>

                    {client && (
                        <Button
                            color='primary'
                            isLight
                            title='Enviar por email'
                            onClick={() => { _sendMenuByEmail() }}
                            className="p-2"
                        >
                            {isSending ? <div className='text-center'><Spinner /></div> : <Icon icon="Email" color='dark' size={'2x'} />}
                        </Button>
                    )}

                    <Button
                        color='primary'
                        isLight
                        title='Crear plantilla'
                        onClick={() => { setDisplayCreateTemplate(true) }}
                        className='p-2'
                    >
                        <Icon icon="Assignment" color='dark' size={'2x'} />
                    </Button>

                    <Dropdown>
                        <DropdownToggle hasIcon>
                            <Button
                                isLight
                                color='primary'
                                className='p-2'
                            >
                                <Icon icon="Layers" color='dark' size={'2x'} />
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <Button
                                    color='primary'
                                    onClick={() => { setShowPhysicalActivities(false); setShowFoodGroup(false) }}
                                    className='m-auto'
                                >
                                    <span className={classNames({ 'text-secondary fw-bold': !showFoodGroup && !showPhysicalActivities })}>Visualizar recetas y alimentos</span>
                                </Button>
                            </DropdownItem>
                            <DropdownItem>
                                <Button
                                    color='primary'
                                    onClick={() => { setShowPhysicalActivities(false); setShowFoodGroup(true) }}
                                    className='m-auto'
                                >
                                    <span className={classNames({ 'text-secondary fw-bold': showFoodGroup && !showPhysicalActivities })}>Visualizar grupos de alimentos</span>
                                </Button>
                            </DropdownItem>
                            <DropdownItem>
                                <Button
                                    color='primary'
                                    onClick={() => { setShowFoodGroup(false); setShowPhysicalActivities(true) }}
                                    className='m-auto'
                                >
                                    <span className={classNames({ 'text-secondary fw-bold': showPhysicalActivities })}>Visualizar ejercicio físico</span>
                                </Button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            <ItemSelectors />
        </>
    );
};

export default MenuActions;