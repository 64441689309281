import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from 'yup';
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardFooter } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Spinner from "../../../../components/bootstrap/Spinner";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../../components/select/SearchableSelect";
import useHandleErrors from "../../../../utils/hooks/useHandleErrors";
import { NUTRIENTS } from "../../../../utils/mapping-collection";
import { NutrientConfigurationService } from "../../../../services/nutrient-configuration/nutrientService";


interface NutrientConfigurationModalFormProps {
    isOpen: boolean;
    setIsOpen: any;
    refetch: any;
    rowId?: string;
}

interface NutrientForm {
    name: string;
    max: number;
    min: number;
}

const initialValues: NutrientForm = {
    name: '',
    max: 0,
    min: 0
}

const validationSchema = yup.object().shape({
    name: yup.string().required('El nombre es obligatorio'),
    max: yup.number().required('El valor máximo es obligatorio'),
    min: yup.number().required('El valor mínimo es obligatorio')
});


const NutrientConfigurationModalForm: FC<NutrientConfigurationModalFormProps> = ({ isOpen, setIsOpen, refetch, rowId }) => {

    // STATE

    const [loading, setLoading] = useState(false);

    // HOOKS

    const { handleErrors } = useHandleErrors();
    
    // FORMIK

    const formik = useFormik<NutrientForm>({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
           rowId ? await handleEdit(values) : await handleCreate(values);
        }
    });

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION PARA CREAR UN NUTRIENTE
     * @EN FUNCTION TO CREATE A NUTRIENT
     * @param values 
     */
    //-------------------------------------------------------------------------------------------------------
    const handleCreate = async (values: NutrientForm) => {
        try {
            setLoading(true);
            const response = await (await (new NutrientConfigurationService()).createNutrientConfiguration(values)).getResponseData();
            if(response){
                refetch();
                toast.success('Configuración del nutriente creado correctamente');
                setIsOpen(false);
            } else {
                toast.error('Error al crear el configuración de nutriente');
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------


    //-------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION PARA EDITAR UN NUTRIENTE
     * @EN FUNCTION TO EDIT A NUTRIENT
     * @param values 
     */
    //-------------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        setLoading(true);
        try {
            const response = await (new NutrientConfigurationService()).editNutrientConfiguration(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetch();
                setIsOpen(false);
                toast.success('Configuración del nutriente configurada editada correctamente');
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al editar la configuración del nutriente');
        } finally {
            setLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------
    /**
     * @ES Función que verifica si un campo tiene errores.
     * @EN Function that checks if a field has errors.
     * 
     * @param inputFieldID 
     */
    //-------------------------------------------------------------------------------------------------------
    const verifyClass = (inputFieldID: keyof NutrientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }
    //-------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------
    /**
     * @ES Función que muestra los errores de un campo.
     * @EN Function that shows field errors.
     * 
     * @param inputFieldID 
     */
    //-------------------------------------------------------------------------------------------------------
    const showErrors = (inputFieldID: keyof NutrientForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }
    //-------------------------------------------------------------------------------------------------------

    // EFFECTS

    useEffect(() => {
        if(rowId){
            (async () => {
                try {
                    const response = await (new NutrientConfigurationService()).getNutrient(rowId);
                    const responseData = response.getResponseData();
                    if(responseData.success){
                        formik.setFieldValue('id',  responseData.data.id);
                        formik.setFieldValue('name',  responseData.data.name);
                        formik.setFieldValue('max',  responseData.data.max);
                        formik.setFieldValue('min',  responseData.data.min);

                    } else {
                        handleErrors(response);
                    }
                } catch (error: any) {
                    handleErrors(error);
                }
            })();
        } else {
            formik.setValues(initialValues);
        }
    }, [rowId]);

    // RENDER

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del nutriente' isStaticBackdrop>
                <ModalHeader setIsOpen={setIsOpen}>
                    <ModalTitle id='Datos del nutriente'>Datos del nutriente</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={formik.handleSubmit}>
                        <CardBody>

                            <div className="row">
                                <FormGroup label='Nombre' className='mb-3' key={formik.values.name}>
                                    <SearchableSelect
                                        name='name'
                                        options={NUTRIENTS}
                                        onChange={(e: any) => formik.setFieldValue('name', e.label)}
                                        defaultValue={NUTRIENTS.filter((item: any) =>  item.label === formik.values.name).map((item: any) => ({ value: item.value, label: item.label }))}
                                        placeholder='nutrientes'
                                    />
                                    {showErrors('name')}
                                </FormGroup>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormGroup label='Valor máximo' className='mb-3'>
                                        <Input
                                            type='number'
                                            id='max'
                                            name='max'
                                            value={formik.values.max}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('max')}
                                        />
                                        {showErrors('max')}
                                    </FormGroup>
                                </div>

                                <div className="col-md-6">
                                    <FormGroup label='Valor mínimo' className='mb-3'>
                                        <Input
                                            type='number'
                                            id='min'
                                            name='min'
                                            value={formik.values.min}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('min')}
                                        />
                                        {showErrors('min')}
                                    </FormGroup>
                                </div>
                            </div>

                        </CardBody>
                        <CardFooter>
                            <Button type="submit" isDisable={loading} color="primary">
                                {loading ? <Spinner color={'dark'} /> : rowId ? 'Guardar Cambios' : 'Crear'}
                            </Button>
                        </CardFooter>
                    </form>
                </ModalBody>
        </Modal>

    );
}

export default NutrientConfigurationModalForm;