import { useFormik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { CirclePicker, ColorResult } from 'react-color';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import Button from "../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Select from "../../../components/bootstrap/forms/Select";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import SearchableSelect from "../../../components/select/SearchableSelect";
import useConsultationTypes from "../../../hooks/api-calls/useConsultationTypes";
import useServiceTypes from "../../../hooks/api-calls/useServiceTypes";
import useWorkplaces from "../../../hooks/api-calls/useWorkplaces";
import useFetch from "../../../hooks/useFetch";
import { RootState } from "../../../redux/store";
import { AppointmentService } from "../../../services/appointments/appointmentService";
import { APPOINTMENT_STATUSES, AppointmentFormData, PAYMENT_METHODS_MAP, getAppointmentStatus } from "../../../type/appointment-type";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { APPOINTMENT_TYPES } from "../../../utils/mapping-collection";
import { useAppointmentCalendar } from "../providers/AppointmentCalendarProvider";
import usePaymentMenthods from "../../../hooks/api-calls/usePaymentMethods";
import FormLabel from "../../../components/FormLabel";
import Checks from "../../../components/bootstrap/forms/Checks";

interface AppointmentFormProps {
    appointment: AppointmentFormData | null;
    onSubmitted?: (values: any) => void;
}

const AppointmentFormSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio').min(1, 'Demasiado Corto').max(40, 'Demasiado Largo'),
    start: yup.date().required('La fecha de inicio es obligatoria'),
    end: yup.date().required('La fecha de fin es obligatoria'),
    user: yup.string().optional(),
    client: yup.string().nullable().optional(),
    comments: yup.string().nullable().optional(),
    concept: yup.string().nullable().optional(),
    workPlace: yup.string().required('El centro de trabajo es obligatorio'),
    consultationType: yup.string().optional().nullable(),
    appointmentType: yup.string().required('El tipo de cita es obligatorio'),
    serviceType: yup.string().optional(),
    status: yup.string().required("El estado de la cita es obligatorio"),
    appointment_color: yup.string().nullable().optional(),
    paymentMethod: yup.string().optional(),
    price: yup.number().min(0, 'El precio mínimo es 0€').max(1000000, 'El precio máximo es 1.000.000€').optional(),
    notifyBeforeADay: yup.boolean().optional(),
    notifyBeforeTwoDays: yup.boolean().optional(),
});

export const AppointmentForm: React.FC<AppointmentFormProps> = ({ appointment, onSubmitted }) => {

    // STATES

    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any | null>(null);
    const [selectedPatient, setSelectedPatient] = useState<any | null>(null);
    const [userWorkplaces, setUserWorkplaces] = useState<any | null>([]);
    const receivedAppointmentStatus = appointment?.status && getAppointmentStatus(appointment?.status);
    const [selectedStatus, setSelectedStatus] = useState<any | null>(receivedAppointmentStatus && { value: receivedAppointmentStatus.id, label: receivedAppointmentStatus.name });
    const appointmentService = new AppointmentService();

    // HOOKS

    const token = useSelector((state: RootState) => state.auth.user);
    const { setOpenPatientModal, newPatientSelected } = useAppointmentCalendar();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { getWorkplacesList } = useWorkplaces();
    const { getConsultationTypesOptions, getConsultationTypeId } = useConsultationTypes();
    const { getServiceTypesOptions } = useServiceTypes();
    const { getPaymentMethodsOptions } = usePaymentMenthods();

    // FETCH DATA

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN GET USERS LIST
     * @ES OBTENER LA LISTA DE USUARIOS
     */
    //--------------------------------------------------------------------------------------------------------------------------------------------
    const [usersResponse] = useFetch(useCallback(async () => {
        if (!userCan('assign_other_users_to_appointment', 'appointments')) return;
        const response = (await appointmentService.listUsers({ limit: 999999, filter_filters: { active: 1 } })).getResponseData();
        return response;
    }, []));
    //-------------------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN GET CLIENTS LIST
     * @ES OBTENER LA LISTA DE PACIENTES
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const [clientsResponse] = useFetch(useCallback(async () => {
        const response = (await appointmentService.listClients({ limit: 999999, filter_filters: { active: 1 } })).getResponseData();
        return response;
    }, []));
    //-------------------------------------------------------------------------------------------------------------------------------------------

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN CREATE APPOINTMENT
     * @ES CREAR CITA
     * 
     * @param values 
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const createAppointment = async (values: any) => {
        setIsLoading(true);
        values.workplace = values.workPlace;
        delete values.workPlace;
        try {
            const response = (await appointmentService.createAppointment(values)).getResponseData();
            if (response.success) {
                toast.success('Cita creada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error('Error al crear la cita');
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN UPDATE APPOINTMENT
     * @ES ACTUALIZAR CITA
     * 
     * @param values
     * @returns
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const updateAppointment = async (values: any) => {
        setIsLoading(true);
        values.workplace = values.workPlace;
        delete values.workPlace;
        try {
            const response = (await appointmentService.updateAppointment(values)).getResponseData();
            if (response.success) {
                toast.success('Cita actualizada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error('Error al actualizar la cita');
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik({
        validationSchema: AppointmentFormSchema,
        initialValues: appointment || {},
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                start_date: moment(values.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(values.end).format('YYYY-MM-DD HH:mm:ss'),
                comments: values.comments !== '' && values.comments !== null ? values.comments : null,
                concept: values.concept !== '' && values.concept !== null ? values.concept : null,
            }

            if (appointment?.id) {
                // Update appointment
                // @ts-ignore
                formattedValues.appointment = appointment?.id;
                await updateAppointment(formattedValues);
            } else {
                // Create appointment
                await createAppointment(formattedValues);
            }

            onSubmitted && onSubmitted(formattedValues);
        }
    });

    // VALIDATION FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN VERIFY IF THE FIELD HAS ERRORS
     * @ES VERIFICAR SI EL CAMPO TIENE ERRORES
     * 
     * @param inputFieldID 
     * @returns 
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const verifyClass = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN SHOW ERRORS
     * @ES MOSTRAR ERRORES
     * 
     * @param inputFieldID 
     * @returns 
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const showErrors = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    // USE EFFECTS

    /**
     * @EN SET THE NEW USER SELECTED IN THE FORMIK VALUES
     * @ES ESTABLECER EL NUEVO USUARIO SELECCIONADO EN LOS VALORES DE FORMIK
     */ 
    useEffect(() => {
        if (usersResponse?.users && appointment?.user) {
            let foundUser = usersResponse?.users.find((user: any) => user.id === appointment?.user);
            if (foundUser) {
                setSelectedUser({ value: foundUser.id, label: `${foundUser.name} ${foundUser.lastName} (${foundUser.email})` })
            }
        }
    }, [usersResponse]);

    /**
     * @EN SET THE NEW USER SELECTED IN THE FORMIK VALUES
     * @ES ESTABLECER EL NUEVO USUARIO SELECCIONADO EN LOS VALORES DE FORMIK
     */
    useEffect(() => {
        if (clientsResponse?.patients && appointment?.client) {
            let foundPatient = clientsResponse?.patients.find((patient: any) => patient.id === appointment?.client);
            if (foundPatient) {
                setSelectedPatient({ value: foundPatient.id, label: `${foundPatient.name} ${foundPatient.lastName} (${foundPatient.email})` })
            }
        }
    }, [clientsResponse]);

    /**
     * @EN SET THE NEW PATIENT SELECTED IN THE FORMIK VALUES
     * @ES ESTABLECER EL NUEVO PACIENTE SELECCIONADO EN LOS VALORES DE FORMIK
     */
    useEffect(() => {
        if (newPatientSelected && newPatientSelected.value !== '') {
            formik.setFieldValue('client', newPatientSelected.value);
            setSelectedPatient(newPatientSelected);
        }
    }, [newPatientSelected]);

    /**
     * @EN GET WORKPLACES LIST IF USER CAN ASSIGN OTHER USERS TO APPOINTMENTS
     * @ES OBTENER LA LISTA DE CENTROS DE TRABAJO SI EL USUARIO PUEDE ASIGNAR OTROS USUARIOS A CITAS
     */
    useEffect(() => {
        if (selectedUser && usersResponse?.users && userCan('assign_other_users_to_appointment', 'appointments')) {
            let workplaces = [] as any;
            usersResponse.users.find((u: any) => u.id === selectedUser?.value)?.workPlaces?.map((w: any) => {
                workplaces = ([...workplaces, { value: w.workPlace.id, label: w.workPlace.name }]);
            });
            setUserWorkplaces(workplaces);
        }
    }, [selectedUser]);

    /**
     * @EN GET WORKPLACES LIST IF USER CAN'T ASSIGN OTHER USERS TO APPOINTMENTS. WE SET THE WORKPLACES OF THE USER
     * @ES OBTENER LA LISTA DE CENTROS DE TRABAJO SI EL USUARIO NO PUEDE ASIGNAR OTROS USUARIOS A CITAS. ESTABLECEMOS LOS CENTROS DE TRABAJO DEL USUARIO
     */ 
    useEffect(() => {
        if (token?.id && !userCan('assign_other_users_to_appointment', 'appointments')) {
            setUserWorkplaces(token.workPlaces.map((w: any) => ({ value: w.id, label: w.name })));
        }
    }, [token, usersResponse]);

    // RENDER

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off" className='row g-4'>
            {/* Name */}
            <div className='col-12'>
                <FormGroup requiredInputLabel label='Asunto'>
                    <Input id="name" type='text' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('name')} />
                    {showErrors('name')}
                </FormGroup>
            </div>

            {/* Comments */}
            <div className='col-12'>
                <FormGroup label='Concepto'>
                    <Textarea id="concept" value={formik.values.concept || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('concept')} />
                    {showErrors('concept')}
                </FormGroup>
            </div>

            {/* Comments */}
            <div className='col-12'>
                <FormGroup label='Observaciones'>
                    <Textarea id="comments" value={formik.values.comments || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('comments')} />
                    {showErrors('comments')}
                </FormGroup>
            </div>

            {/* Dates */}
            <div className='col-12'>
                <Card className='mb-0' color="primary" shadow='sm'>
                    <CardHeader className='bg-l25-secondary'>
                        <CardLabel>
                            <CardTitle className='text-secondary'>
                                Fechas Seleccionadas
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-3'>
                            <div className='col-12'>
                                <FormGroup requiredInputLabel label="Fecha de inicio">
                                    <Input
                                        id="start"
                                        type={'datetime-local'}
                                        step={300}
                                        onBlur={formik.handleBlur}
                                        className={verifyClass('start')}
                                        value={formik.values.start ? moment(formik.values.start).format('YYYY-MM-DDTHH:mm') : ''}
                                        onChange={(event: any) => {
                                            formik.setFieldValue('start', event.target.value);
                                            formik.setFieldValue('end', moment(event.target.value).add(30, 'minutes').format('YYYY-MM-DDTHH:mm'));
                                        }}
                                    />
                                    {showErrors('start')}
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup requiredInputLabel label="Fecha de fin">
                                    <Input
                                        id="end"
                                        type={'datetime-local'}
                                        step={300}
                                        onBlur={formik.handleBlur}
                                        className={verifyClass('end')}
                                        value={formik.values.end ? moment(formik.values.end).format('YYYY-MM-DDTHH:mm') : ''}
                                        onChange={(event: any) => {
                                            formik.setFieldValue('end', event.target.value);
                                        }}
                                    />
                                    {showErrors('end')}
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 mt-4'>
                <Card className='mb-0' color="primary" shadow='sm'>
                    <CardHeader className='bg-l25-info'>
                        <CardLabel>
                            <CardTitle className='text-dark'>
                                Configuración de notificaciones
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-3 d-flex justify-content-between'>
                            <div className='col-6 d-flex align-items-start justify-content-start'>
                                <Checks
                                    id='notifyBeforeADay'
                                    checked={formik.values.notifyBeforeADay}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                    className={verifyClass('notifyBeforeADay') + ' fs-5'}
                                />
                                <FormLabel label="Notificar un día antes" className="ms-2 fs-6 mt-1" htmlFor="notifyBeforeADay" />
                                {showErrors('notifyBeforeADay')}
                            </div>
                            <div className='col-6 d-flex align-items-start justify-content-start'>
                                <Checks
                                    id='notifyBeforeTwoDays'
                                    checked={formik.values.notifyBeforeTwoDays}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={verifyClass('notifyBeforeTwoDays') + ' fs-5'}
                                />
                                <FormLabel label="Notificar dos días antes" className="ms-2 fs-6 mt-1" htmlFor="notifyBeforeTwoDays" />
                                {showErrors('notifyBeforeTwoDays')}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>

            {/* Client */}
            {clientsResponse?.patients && (
                <div className='col-12'>
                    <Card className='mb-0' color="primary" shadow='sm'>
                        <CardHeader className='bg-l25-secondary'>
                            <CardLabel>
                                <CardTitle>Seleccionar paciente</CardTitle>
                            </CardLabel>
                            <CardActions>
                                <Button
                                    icon="Add"
                                    color='primary'
                                    onClick={() => { setOpenPatientModal(true) }}
                                />
                                <Button
                                    icon="Delete"
                                    color='danger'
                                    onClick={() => {
                                        setSelectedPatient(null);
                                        formik.setFieldValue('client', null);
                                    }}
                                />
                            </CardActions>
                        </CardHeader>
                        <CardBody>
                            <>
                                <SearchableSelect
                                    name="client"
                                    isSearchable
                                    onBlur={formik.handleBlur}
                                    completePlaceholder='Paciente existente ...'
                                    value={selectedPatient}
                                    onChange={(newValue: any) => {
                                        setSelectedPatient(newValue)
                                        formik.setFieldValue('client', newValue.value)
                                    }}
                                    options={clientsResponse?.patients.map((u: any) => ({
                                        value: u.id,
                                        label: `${u.name} ${u.lastName} (${u.email})`,
                                    }))}
                                />
                                {showErrors('client')}
                            </>
                        </CardBody>
                    </Card>
                </div>
            )}

            {/* User */}
            {usersResponse?.users && (
                <div className='col-12'>
                    <Card className='mb-0' color="primary" shadow='sm'>
                        <CardHeader className='bg-l25-secondary'>
                            <CardLabel icon='User Add' iconColor='secondary'>
                                <CardTitle>Asignar a otro usuario</CardTitle>
                            </CardLabel>
                            <CardActions>
                                <Button
                                    icon="Delete"
                                    color='danger'
                                    onClick={() => {
                                        setSelectedUser(null)
                                        formik.setFieldValue('user', null)
                                    }}
                                />
                            </CardActions>
                        </CardHeader>
                        <CardBody>
                            <SearchableSelect
                                name="user"
                                isSearchable
                                onBlur={formik.handleBlur}
                                placeholder='Usuario'
                                value={selectedUser}
                                onChange={(e: any) => {
                                    setUserWorkplaces([])
                                    setSelectedUser(e)
                                    formik.setValues({ ...formik.values, user: e.value, workPlace: undefined })
                                }}
                                options={usersResponse?.users.map((u: any) => ({
                                    value: u.id,
                                    label: `${u.name} ${u.lastName ? u.lastName : ""} (${u.email})`,
                                }))}
                            />
                            {showErrors('user')}
                            {!selectedUser ? (<small className="text-muted mt-1">Si no seleccionas un usuario se te asignará la cita a ti.</small>) : <></>}
                        </CardBody>
                    </Card>
                </div>
            )}

            {/* Appointment configuration */}
            <div className='col-12'>
                <Card className='mb-0' color="primary" shadow='sm'>
                    <CardHeader className='bg-l25-primary'>
                        <CardLabel icon='User Add' iconColor='primary'>
                            <CardTitle>Configuración de la cita</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <FormGroup label="Centro de trabajo" className="mb-3" requiredInputLabel key={'workPlace' + formik.values.user + formik.values.workPlace}>
                            <SearchableSelect
                                name="workPlace"
                                isSearchable
                                isDisabled={(userCan('assign_other_users_to_appointment', 'appointments') && !selectedUser)}
                                options={userWorkplaces}
                                value={{ value: formik.values.workPlace, label: getWorkplacesList().find((w: any) => w.value === formik.values.workPlace)?.label }}
                                onChange={(newValue: any) => { formik.setFieldValue('workPlace', newValue.value) }}
                                onBlur={formik.handleBlur}
                                classname={verifyClass('workPlace')}
                                placeholder='centro de trabajo'
                            />
                            {showErrors('workPlace')}
                        </FormGroup>

                        <FormGroup label="Tipo de consulta" className="mb-3">
                            <SearchableSelect
                                name="consultationType"
                                isSearchable
                                options={getConsultationTypesOptions()}
                                value={{ value: formik.values.consultationType, label: getConsultationTypesOptions().find((option: any) => option.value === formik.values.consultationType)?.label }}
                                onChange={(option: any) => {
                                    // When the consultation type changes, we set the service type, appointment type, price and increase the end date
                                    const consultationType = getConsultationTypeId(option?.value);
                                    formik.setValues({
                                        ...formik.values,
                                        consultationType: option?.value,
                                        serviceType: consultationType?.serviceType?.id,
                                        appointmentType: APPOINTMENT_TYPES.find((type: any) => type.value === Number(consultationType?.appointmentType))?.value,
                                        price: consultationType?.price,
                                        end: moment(formik.values.start).add(consultationType?.duration, 'minutes').format('YYYY-MM-DDTHH:mm'),
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                classname={verifyClass('consultationType')}
                                placeholder='Tipo de consulta'
                            />
                        </FormGroup>

                        <FormGroup label="Tipo de cita" className="mb-3" requiredInputLabel key={'appointmentType' + formik.values.appointmentType}>
                            <SearchableSelect
                                name="appointmentType"
                                isSearchable
                                options={APPOINTMENT_TYPES}
                                value={{ value: formik.values.appointmentType, label: APPOINTMENT_TYPES.find((option: any) => option.value === formik.values.appointmentType)?.label }}
                                onChange={(option: any) => formik.setFieldValue('appointmentType', option?.value)}
                                onBlur={formik.handleBlur}
                                classname={verifyClass('appointmentType')}
                                placeholder='Tipo de cita'
                            />
                            {showErrors('appointmentType')}
                        </FormGroup>

                        <FormGroup label="Tipo de servicio" className="mb-3" requiredInputLabel key={'serviceType' + formik.values.serviceType}>
                            <SearchableSelect
                                name="serviceType"
                                isSearchable
                                options={getServiceTypesOptions()}
                                value={{ value: formik.values.serviceType, label: getServiceTypesOptions().find((option: any) => option.value === formik.values.serviceType)?.label }}
                                onChange={(option: any) => formik.setFieldValue('serviceType', option?.value)}
                                onBlur={formik.handleBlur}
                                classname={verifyClass('serviceType')}
                                placeholder='tipo de servicio'
                            />
                            {showErrors('serviceType')}
                        </FormGroup>

                        <FormGroup label="Estado" className="mb-3" requiredInputLabel>
                            <SearchableSelect
                                name="status"
                                isSearchable
                                classname={verifyClass('status')}
                                onBlur={formik.handleBlur}
                                placeholder='Estado de la cita'
                                value={selectedStatus}
                                onChange={(newValue: any) => {
                                    setSelectedStatus(newValue)
                                    formik.setFieldValue('status', newValue.value)
                                }}
                                options={APPOINTMENT_STATUSES.map((status: any) => ({
                                    value: status.id,
                                    label: status.name,
                                }))}
                            />
                            {showErrors('status')}
                        </FormGroup>

                        <FormGroup className="mb-3" label="Color">
                            <CirclePicker color={formik.values.appointment_color ? formik.values.appointment_color : ''} onChangeComplete={(color: ColorResult) => {
                                formik.setFieldValue('appointment_color', color ? color.hex : null)
                            }} />
                            {showErrors('appointment_color')}
                        </FormGroup>

                        <div className="row">
                            <div className="col-md-6">
                                <FormGroup className="mb-3" label="Método de pago" requiredInputLabel>
                                    <SearchableSelect
                                        name="paymentMethod"
                                        isSearchable
                                        value={{ value: formik.values.paymentMethod, label: formik.values.paymentMethodLabel }}
                                        options={getPaymentMethodsOptions()}
                                        onChange={(option: any) => {
                                            formik.setFieldValue('paymentMethod', option?.value)
                                            formik.setFieldValue('paymentMethodLabel', option?.label)
                                        }}
                                        onBlur={formik.handleBlur}
                                        classname={verifyClass('paymentMethod')}
                                        placeholder="Método de pago"
                                    />
                                    {showErrors('paymentMethod')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="mb-3" label="Importe (€)" requiredInputLabel>
                                    <Input
                                        id="price"
                                        type='number'
                                        value={formik.values.price || 0}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={verifyClass('price')}
                                    />
                                    {showErrors('price')}
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <div className='d-flex justify-content-end text-right relative'>
                <Button color='primary' className="flex-grow-1" type='submit' onClick={formik.handleSubmit} isDisable={isLoading}>
                    {isLoading ? <Spinner color='dark' /> : 'Guardar'}
                </Button>
            </div>
        </form>
    );
}