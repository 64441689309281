import classNames from 'classnames';
import React, { useCallback } from 'react';
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/bootstrap/Button";
import { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { PhysiologicalStatesService } from '../../../services/physiological-states/physiologicalStatesService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import ServiceTypesFilters from './PhysiologicalStatesFilters';
import PhysiologicalStateModalForm from './components/PhysiologicalStateModalForm';

interface GCPhysiologicalStatesProps { }

const GCPhysiologicalStates: React.FC<GCPhysiologicalStatesProps> = ({ }) => {

    // STATES

    const [isOpen, setIsOpen] = React.useState(false);
    const [rowId, setRowId] = React.useState('');

    // HOOKS

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3 });

    // FETCH DATA

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new PhysiologicalStatesService()).listPhysiologicalStates(filters));
        return response.getResponseData();
    }, [filters]));

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función para eliminar un estado fisiológico.
     * @EN Function to delete a physiological state.
     * 
     * @param id 
     */
    //--------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new PhysiologicalStatesService()).deletePhysiologicalState(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Estado fisiológico eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al eliminar el estado fisiológico');
        }
    };
    //--------------------------------------------------------------------------------------------------

    // RENDER

    if (error) return <ErrorMessage />;

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Estados Fisiológicos</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear estado fisiológico"
                        color="storybook"
                        icon="Add"
                        isLight
                        className={classNames({ 'd-none': !userCan('create', 'physiological_states') })}
                        onClick={() => { setRowId(''); setIsOpen(true); }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ServiceTypesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </CardHeader>
            <CardBody className="table-responsive">
                <CustomTable
                    title="Estados Fisiológicos"
                    data={data ? data?.physiologicalStates : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data ? data?.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar estado fisiológico",
                            hidden: !userCan('edit', 'physiological_states'),
                            click: (item: any) => { setRowId(item.id); setIsOpen(true); },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar estado fisiológico",
                            hidden: !userCan('delete', 'physiological_states'),
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar estado fisiológico",
                                    text: "¿Está seguro que desea eliminar el estado fisiológico?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            </CardBody>

            {isOpen && <PhysiologicalStateModalForm isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} rowId={rowId} />}
        </>
    );
};

export default GCPhysiologicalStates;