import React, { useCallback } from 'react';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import useFetch from '../../../hooks/useFetch';
import { DataSourceService } from '../../../services/data-sources/dataSourceService';
import { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import { CustomTable } from '../../../components/table/CustomTable';
import useFilters from '../../../hooks/useFilters';
import AppModalForm from './components/AppModalForm';
import { toast } from 'react-toastify';
import { handleConfirmationAlert } from '../../../components/ConfirmationAlert';
import AppFilters from './AppFilters';

interface GCAppProps { }

const GCApp: React.FC<GCAppProps> = () => {

    //STATES
    
    const [isOpen, setIsOpen] = React.useState(false);
    const [rowId, setRowId] = React.useState('');

    //HOOKS
    
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3 });

    //FETCH DATA

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new DataSourceService()).listDataSources(filters));
        return response.getResponseData();
    },[filters]));

    //FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función para eliminar una fuente de datos.
     * @EN Function to delete a data source.
     * 
     * @param id
     */
    //--------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new DataSourceService()).deleteDataSource(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Fuente de datos eliminada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al eliminar el color de la fuente de datos');
        }
    }
    //--------------------------------------------------------------------------------------------------


    //RENDER

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle> Colores de las Fuentes de Datos de los Nutrientes</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title='Añadir Color'
                        color='storybook'
                        icon='Add'
                        isLight
                        onClick={() => { setIsOpen(true); setRowId(''); }} 
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <AppFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </CardHeader>
            <CardBody>
                <CustomTable
                    title='Colores de los Fuentes de Datos de los Nutrientes'
                    data={ data ? data?.data : null }
                    pagination
                    paginationData={
                        {
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: data ? data?.lastPage : 1,
                            handlePagination: (page: any) => { updatePage({ selected: page.selected + 1}) },
                            handlePerPage: updatePageSize
                        }
                    }
                    className={'table-striped table-hover'}
                    columns={
                        [
                            {
                                name: "Fuente",
                                keyValue: "source",
                                sortable: true,
                                sortColumn: updateFilterOrder
                            },
                            {
                                name: "Color",
                                keyValue: "color",
                                render: (element: any) => {
                                    return (
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <div className='rounded-circle border border-2' style={{ backgroundColor: element.color, width: '20px', height: '20px' }}></div>
                                        </div>
                                    )
                                }
                            },
                            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                        ]
                    }
                    actions = {
                        [
                            {
                                title: 'Editar',
                                icon: 'Edit',
                                buttonType: 'icon',
                                additionalClasses: 'text-primary',
                                description: 'Editar Color',
                                click:(item: any) => { setIsOpen(true); setRowId(item.id) }
                            },
                            {
                                title: 'Eliminar',
                                icon: 'Delete',
                                buttonType: 'icon',
                                additionalClasses: 'text-danger',
                                description: 'Eliminar Color',
                                click: (item: any) => { 
                                    handleConfirmationAlert({
                                        title: 'Eliminar Color',
                                        text: '¿Está seguro de que desea eliminar este color de la fuente de datos?',
                                        icon: 'warning',
                                        onConfirm: () => { handleDelete(item.id) }

                                    })
                                },
                            }
                        ]
                    }
                
                />
            </CardBody>

            {isOpen && <AppModalForm isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} rowId={rowId} />}
        </>
    );
};

export default GCApp;