import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/physiological-state';

export class PhysiologicalStatesService extends RestServiceConnection {

    listPhysiologicalStates = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getPhysiologicalState = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { id }
        }, true) as AxiosResponse;
        return this;
    }

    createPhysiologicalState = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    editPhysiologicalState = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    deletePhysiologicalState = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { id }
        }, true) as AxiosResponse;
        return this;
    }

}