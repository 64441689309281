import { useCallback, FC, useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import { DocumentTypeService } from "../../../services/documents/documentTypeService";
import SearchableSelect from "../../select/SearchableSelect";
import { useFormik } from "formik";
import useFetch from "../../../hooks/useFetch";
import { CardBody, CardFooter } from "../../bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import Spinner from "../../bootstrap/Spinner";
import { showErrors, verifyClass } from "../../../utils/Forms/FormikVerifyClass";
import Button from "../../bootstrap/Button";
import Input from "../../bootstrap/forms/Input";
import { toast } from "react-toastify";
import { PatientService } from "../../../services/patients/patientService";

interface AddPhotoModalProps {
    isOpen: boolean;
    setIsOpen: any;
    patientId: string;
    type?: string;
    refetch?: () => void;
}


const AddPhotoModal: FC<AddPhotoModalProps> = ({ isOpen, setIsOpen, patientId, type, refetch }) => {


    // FUNCTIONS

    const getDocumentTypes = useCallback(async () => {
        const documentTypeService = new DocumentTypeService();
        const response = await documentTypeService.getDocumentTypes(type || '');
        return response.getResponseData();
    },[]);

    const [documentType, loadingDocumentTypes] = useFetch(getDocumentTypes);
    
    const [loading, setLoading] = useState(false);

    
    // FORMIK


    const formik = useFormik({
        initialValues: {
            photo_type: null,
            name: '',
            photo: null,
            clientId: patientId
        },
        onSubmit: values => {
            const valuesFormated = 
            {
                clientId: values.clientId,
                photos: [
                    {
                        name: values.name,
                        photo: values.photo,
                        photo_type: values.photo_type
                    }
                ]
            }

            handleSubmit(valuesFormated);
        }
    });

    const _handleSelectedType = (selectedOption: any) => {
        formik.handleChange(selectedOption.value)
        formik.setFieldValue('photo_type', selectedOption.value)
    };

    const getOptions = () => {
        if (documentType) {
            return documentType.map((documentType: any) => {
                return {
                    value: documentType.name,
                    label: documentType.name,
                }
            })
        }
        return [];
    }

    //--------------------------------------------------------------------------------
    /**
     * @ES FUNCION PARA MANEJAR EL SUBMIT DEL FORMULARIO
     * @EN FUNCTION TO HANDLE THE FORM SUBMIT
     * 
     * @param values 
     */
    //--------------------------------------------------------------------------------
    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new PatientService()).uploadPhoto(values)).getResponseData();

            if (response.success) {
                setTimeout(() => {
                    toast.success('Foto evolutiva subida al paciente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al subir la foto');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al subir la foto');
        }finally {
            setLoading(false);
            setIsOpen(false);
            refetch && refetch();
        }
    }
    //--------------------------------------------------------------------------------


 
    // RETURN
    
    if(loadingDocumentTypes) return <div className="text-center"><Spinner size={'xl'} /></div>;

    return (
        documentType && (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Añadir foto'>
                <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                    <ModalTitle id='add-photo'>Añadir foto</ModalTitle>
                </ModalHeader>
                <ModalBody className='px-4'>
                    <div className="row">
                        <div className="col-12">
                            <CardBody>
                                <form onSubmit={formik.handleSubmit} autoComplete="off">
                                    <div className="row align-items-center">
                                        <div className="form-group">
                                            <label htmlFor="name">Nombre</label>
                                            <Input 
                                                type="text"
                                                name="name"
                                                id="name"
                                                onChange={formik.handleChange}
                                                value={formik.values.name}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass(formik, 'name')}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mt-3">
                                        <div className="form-group">
                                            <label htmlFor="documentType">Tipo de foto</label>
                                            <SearchableSelect 
                                                isSearchable
                                                onChange={_handleSelectedType}
                                                name="document-select"
                                                placeholder="Tipo de foto"
                                                options={getOptions()}
                                                classname={verifyClass(formik,'photo_type')}
                                            />
                                            {showErrors(formik, 'photo_type')}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row align-items-center mt-3">
                                            <div className="form-group">
                                                <label htmlFor="photo">Foto</label>
                                                <Input 
                                                    type="file"
                                                    name="photo"
                                                    id="photo"
                                                    accept="image/*"
                                                    onChange={(e: React.ChangeEvent<any>) => formik.setFieldValue("photo", e.currentTarget.files[0])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                            <CardFooter className="d-flex justify-content-end">
                                <Button color='primary' onClick={formik.handleSubmit}>
                                    {loading ? <Spinner /> : 'Subir'}
                                </Button>
                            </CardFooter>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
        
    );

}

export default AddPhotoModal;