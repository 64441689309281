import { Fragment, useCallback, useEffect, useState } from "react";
import { MenuService } from "../../../../services/menus/menuService";
import useFetch from "../../../../hooks/useFetch";
import { useFormik } from "formik";
import Card, { CardBody, CardFooter, CardTitle } from "../../../../components/bootstrap/Card";
import Spinner from "../../../../components/bootstrap/Spinner";
import { toast } from "react-toastify";

type ObjectivesMenuTableProps = {
    menuId: string;
    clientId: string;
    isUpdated: number;
    setIsUpdated: (isUpdated: number) => void;
    dataObjectives: any;
    refetchDataObjectives: () => void;
}

const ObjectivesMenuTable: React.FC<ObjectivesMenuTableProps> = ({ menuId, clientId, isUpdated, setIsUpdated, dataObjectives, refetchDataObjectives}) => {
    
    // STATES
    
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // FETCHS


    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------
    /**
     * @ES Actualizar los objetivos del menú
     * @EN Update the menu objectives
     * @param values
     */
    //-------------------------------------------------------------------------------------------------
    const handleSubmit = async (values: any) => {
        try{
            setIsLoading(true);
            const response = await new MenuService().editObjectivesMenu(values);
            const responseData = response.getResponseData();
            if (responseData.success) {
                toast.success('Objetivos actualizados correctamente');
            } else {
                toast.error('Error al actualizar los objetivos');
            }
        }catch(error: any){
            toast.error('Error al actualizar los objetivos');
        }finally{
            setIsUpdated(isUpdated + 1);
            refetchDataObjectives();
            setIsLoading(false);
        }
    }
    //-------------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik({
        initialValues: {
            menuId: menuId,
            clientId: clientId,
            kcal: dataObjectives ? dataObjectives?.kcal : '0',
            protein: dataObjectives ? dataObjectives?.protein : '0',
            carbohydrates: dataObjectives ? dataObjectives?.carbohydrates : '0',
            fat: dataObjectives ? dataObjectives?.fat : '0',
        },
        onSubmit: async (values) => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            kcal: dataObjectives ? dataObjectives?.kcal : '0',
            protein: dataObjectives ? dataObjectives?.protein : '0',
            carbohydrates: dataObjectives ? dataObjectives?.carbohydrates : '0',
            fat: dataObjectives ? dataObjectives?.fat : '0',
        });
    }, [dataObjectives]);
    
    // RENDER

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="kcal">Kcal</label>
                        <input
                            type="number"
                            className="form-control"
                            id="kcal"
                            name="kcal"
                            min="0"
                            value={formik.values?.kcal || 0}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="protein">Proteínas</label>
                        <input
                            type="number"
                            className="form-control"
                            id="protein"
                            name="protein"
                            min="0"
                            value={formik.values?.protein || 0}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="form-group col-12">
                        <label htmlFor="carbohydrates">Carbohidratos</label>
                        <input
                            type="number"
                            className="form-control"
                            id="carbohydrates"
                            name="carbohydrates"
                            min="0"
                            value={formik.values?.carbohydrates || 0}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="fat">Grasas</label>
                        <input
                            type="number"
                            className="form-control"
                            id="fat"
                            name="fat"
                            min="0"
                            value={formik.values?.fat || 0}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                        {isLoading ? <Spinner/> : <button type="submit" className="btn btn-primary">Actualizar objetivos</button>}
                    </div>
                </div>
            </form>

        </Fragment>
    );
}

export default ObjectivesMenuTable;