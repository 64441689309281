import { useFormik } from "formik";
import { ChangeEvent, FC, Fragment, useCallback, useEffect, useState } from "react";
import InputPhone from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import AsyncImg from "../../components/AsyncImg";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import CustomPasswordInput from "../../components/bootstrap/forms/CustomPasswordInput";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import CustomSearchSelect from "../../components/select/CustomSearchSelect";
import SearchableSelect from "../../components/select/SearchableSelect";
import useAllergies from "../../hooks/api-calls/useAllergies";
import useIntolerances from "../../hooks/api-calls/useIntolerances";
import usePathologies from "../../hooks/api-calls/usePathologies";
import usePhysiologicalStates from "../../hooks/api-calls/usePhysiologicalStates";
import useWorkplaces from "../../hooks/api-calls/useWorkplaces";
import useFetch from "../../hooks/useFetch";
import Page from "../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../services/companies/organizationService";
import { FoodService } from "../../services/foods/foodService";
import { PatientService } from "../../services/patients/patientService";
import { RecipeService } from "../../services/recipes/recipeService";
import { UserService } from "../../services/users/userService";
import { AlimentsApiResponse } from "../../type/aliments-type";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import { Users, UsersApiResponse } from "../../type/user-type";
import { COUNTRIES, ROLES } from "../../utils/mapping-collection";

export interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    patientData?: PatientForm;
    profileImg?: any;
}

export interface PatientForm {
    user: string;
    name: string;
    last_name: string;
    email: string;
    dni: string;
    sex: string;
    province: string;
    city: string;
    pc: string;
    address: string;
    telephone: string;
    country: string | null;
    company: string;
    password: string;
    passwordConfirm: string;
    birthday_date: string;
    physiologicalState: string;
    clientAversions?: [];
    food_aversions?: string[];
    group_aversions?: string[];
    workplaces?: string[];
    intolerances?: string[];
    allergies?: string[];
    pathologies?: string[];
    weight: string;
    height: string;
    [key: string]: any;
}

export const patientInitialValues: PatientForm = {
    user: '',
    name: '',
    last_name: '',
    email: '',
    dni: '',
    sex: '',
    province: '',
    city: '',
    pc: '',
    address: '',
    telephone: '',
    company: '',
    password: '',
    passwordConfirm: '',
    country: '',
    birthday_date: '',
    physiologicalState: '',
    clientAversions: [],
    food_aversions: [],
    group_aversions: [],
    workplaces: [],
    intolerances: [],
    allergies: [],
    pathologies: [],
    weight: "",
    height: "",
};

const PatientEditSchema = yup.object({
    user: yup.string(),
    name: yup.string().min(1, 'Demasiado Corto').max(100, 'Demasiado Largo').required('Campo Obligatorio'),
    last_name: yup.string().min(1, 'Demasiado Corto').max(200, 'Demasiado Largo'),
    email: yup.string().email('Correo Inválido').required('Campo Obligatorio'),
    dni: yup.string().matches(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i, 'Formato de DNI incorrecto').required('Campo Obligatorio'),
    sex: yup.string().matches(/^[HM]$/i, 'Formato de sexo incorrecto').required('Campo Obligatorio'),
    //@ts-ignore
    province: yup.string().min(1, 'Demasiado Corto').max(30, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos'),
    country: yup.string().nullable(),
    //@ts-ignore
    city: yup.string().min(1, 'Demasiado Corto').max(30, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos'),
    pc: yup.string().matches(/^\d{5}$/, 'Formato de CP incorrecto'),
    address: yup.string(),
    telephone: yup.string().matches(/^\+34\d{9}$/, 'Debe ser un número de teléfono registrado en España'),
    //company: yup.string().required('Campo Obligatorio'),
    password: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,30}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    birthday_date: yup.date().max(new Date(), 'Fecha no válida'),
    physiologicalState: yup.string().nullable(),
    workplaces: yup.array().of(yup.string()).notRequired().nullable(),
    intolerances: yup.array().of(yup.string()).notRequired().nullable(),
    allergies: yup.array().of(yup.string()).notRequired().nullable(),
    pathologies: yup.array().of(yup.string()).notRequired().nullable(),
    weight: yup.number().min(0, 'El peso no puede ser negativo').nullable(),
    height: yup.number().min(0, 'La altura no puede ser negativa').nullable(),
});

export const PatientCreateSchema = PatientEditSchema.concat(
    yup.object({
        password: yup.string().required('La contraseña es obligatoria').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,30}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
        passwordConfirm: yup.string().required('Confirmación de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    })
);

const PatientForm: FC<CreateFormProps> = ({ isLoading, submit, patientData, profileImg }) => {

    const navigate = useNavigate();
    const auth = usePrivilege();
    const { userCan } = auth;
    const { id = '' } = useParams<{ id: string }>();
    const { getWorkplacesList } = useWorkplaces();
    const { getPathologyOptions } = usePathologies();
    const { getPhysiologicalStateOptions } = usePhysiologicalStates();
    const { getIntolerancesList } = useIntolerances();
    const { getAllergyOptions } = useAllergies();
    const mode = patientData ? 'Editar' : 'Crear';
    const buttonTitle = mode === 'Editar' ? 'Guardar Cambios' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [selectedUserWorkplaces, setSelectedUserWorkplaces] = useState<string[]>([]);

    const [organizations] = useFetch(useCallback(async () => {
        if (!auth.userHasRole(ROLES.SUPER_ADMIN)) return [];
        const response = await (new CompanyService()).getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []));

    const [users] = useFetch(useCallback(async () => {
        if (!userCan('access_to_all_patients', 'clients')) return;
        const response = await (new UserService()).getUsers();
        return response.getResponseData() as UsersApiResponse;
    }, []));

    const [foodsData] = useFetch(useCallback(async () => {
        const response = await (new RecipeService()).getIngredientsByCompany({ limit: 999999 });
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    const [groupsData] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getFoodGroups({ limit: 999999 });
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => { setSelectedImage(reader.result) };
        reader.readAsDataURL(file);

        try {
            const response = await (new PatientService()).editPatientImg(id, file);
            const responseData = response.getResponseData();
            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Imagen actualizada');
                }, 100);
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará la imagen de perfil',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const response = await (new PatientService()).deletePatientImg(id);
                    const responseData = response.getResponseData();
                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la imagen");
                }
            }
        })
    };

    const getOrganizationsList = () => {
        if (organizations as Companies) {
            return organizations.companies.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const getUsersList = () => {
        if (users as Users) {
            return users.users.map((option: { id: string; name: string; lastName: string; workPlaces: any }) => {
                return {
                    value: option.id,
                    label: option.name + (option.lastName ? (' ' + option.lastName) : ''),
                    workplace: option.workPlaces?.map((w: any) => w.workPlace?.id),
                }
            })
        }
        return [];
    };

    const getFoodsList = () => {
        const aversions = patientData?.clientAversions; // Aversiones del paciente
        const foodAversions = aversions?.filter((aversion: any) => aversion.food !== null); // Aversiones de alimentos
        const aversionsId = foodAversions?.map((aversion: any) => aversion.food!.id); // Ids de los alimentos

        return foodsData?.data?.map((food: any) => {
            const isAversionSelected = aversionsId?.includes(food.id);
            return {
                value: food.id,
                label: food.name,
                isSelected: isAversionSelected
            }
        }) || [];
    };

    const getGroupsList = () => {
        const aversions = patientData?.clientAversions; // Aversiones del paciente
        const groupAversions = aversions?.filter((aversion: any) => aversion.foodGroup !== null); // Aversiones de grupos
        const aversionsId = groupAversions?.map((aversion: any) => aversion.foodGroup!.id); // Ids de los grupos

        return groupsData?.food_groups.map((group: any) => {
            const isAversionSelected = aversionsId?.includes(group.id);
            return {
                value: group.id,
                label: group.name,
                isSelected: isAversionSelected
            }
        }) || [];
    };

    const formik = useFormik({
        initialValues: patientData || patientInitialValues,
        validationSchema: (mode === 'Editar') ? PatientEditSchema : PatientCreateSchema,
        onSubmit: values => { submit(values) }
    });

    const verifyClass = (inputFieldID: keyof PatientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; };

    const showErrors = (inputFieldID: keyof PatientForm) => {
        //@ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    // Cargar las opciones de los centros de trabajo del usuario seleccionado
    useEffect(() => {
        const user = users?.users.find((user: any) => user.id === formik.values.user);
        setSelectedUserWorkplaces(user?.workPlaces?.map((w: any) => w.workPlace?.id) || []);
    }, [formik.values.user, users]);

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row">
                    <div className="col-md-2">
                        {mode === 'Editar' && (
                            <Card>
                                <CardBody className='text-center'>
                                    <div className={'text-center pb-4'}>
                                        {selectedImage
                                            ? <img
                                                width={200} height={200}
                                                src={selectedImage} alt="selected"
                                                className='mx-auto d-block img-fluid mb-3 rounded'
                                            />
                                            : profileImg
                                                ? <AsyncImg isBackground height="200px" width="200px" styles="mx-auto d-block img-fluid rounded" id={profileImg.id} />
                                                : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                                        }
                                    </div>

                                    <Input
                                        onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                        type='file' autoComplete='photo' placeholder={"Cambiar imagen"} className="mb-3"
                                    />

                                    <Button color='dark' isLight icon='Delete' onClick={deleteImage} title="Eliminar">Eliminar</Button>
                                </CardBody>
                            </Card>
                        )}
                    </div>

                    <div className="col-md-7">
                        <Card stretch>
                            <CardBody className="px-3">
                                <CardTitle>Información de la cuenta</CardTitle>
                                <div className="row p-0">
                                    <FormGroup requiredInputLabel label='Nombre' className="col-md-6">
                                        <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                        {showErrors('name')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Email' className="col-md-6">
                                        <Input id='email' type='email' autoComplete="new-email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} className={verifyClass('email')} />
                                        {showErrors('email')}
                                    </FormGroup>
                                </div>

                                <div className="row mt-2 p-0">
                                    <FormGroup requiredInputLabel label='DNI' className="col-md-4">
                                        <Input id='dni' onChange={formik.handleChange} value={formik.values.dni} onBlur={formik.handleBlur} className={verifyClass('dni')} />
                                        {showErrors('dni')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Altura (cm)' className="col-md-4">
                                        <Input id='height' type='number' onChange={formik.handleChange} value={formik.values.height} onBlur={formik.handleBlur} className={verifyClass('height')} />
                                        {showErrors('height')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Peso (kg)' className="col-md-4">
                                        <Input id='weight' type='number' onChange={formik.handleChange} value={formik.values.weight} onBlur={formik.handleBlur} className={verifyClass('weight')} />
                                        {showErrors('weight')}
                                    </FormGroup>
                                </div>

                                <div className="row mt-2 p-0">
                                    <FormGroup label='Apellidos' className="col-md-4">
                                        <Input id='last_name' onChange={formik.handleChange} value={formik.values.last_name} onBlur={formik.handleBlur} className={verifyClass('last_name')} />
                                        {showErrors('last_name')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Género' className="col-md-2">
                                        <Select
                                            name="sex"
                                            id='sex'
                                            value={formik.values.sex}
                                            onChange={formik.handleChange}
                                            ariaLabel='Selector de género'
                                            list={[
                                                { value: 'H', label: 'Hombre', },
                                                { value: 'M', label: 'Mujer' },
                                            ]}
                                            className={verifyClass('sex')}
                                            placeholder={'H / M'}
                                        />
                                        {showErrors('sex')}
                                    </FormGroup>
                                    <FormGroup label='Fecha de nacimiento' className="col-md-3">
                                        <Input id='birthday_date' type='date' onChange={formik.handleChange} value={formik.values.birthday_date} className={verifyClass('birthday_date')} />
                                        {showErrors('birthday_date')}
                                    </FormGroup>
                                    <FormGroup label='Teléfono' className="col-md-3">
                                        <InputPhone
                                            id='telephone'
                                            onBlur={formik.handleBlur}
                                            labels={es}
                                            international
                                            defaultCountry="ES"
                                            onChange={e => { formik.setFieldValue('telephone', e) }}
                                            value={formik.values.telephone || ''}
                                            className={verifyClass('telephone')}
                                        />
                                        {showErrors('telephone')}
                                    </FormGroup>
                                </div>

                                {(users as Users) && (
                                    <div className="row mt-4">
                                        <CardTitle>Asignar</CardTitle>
                                        {(auth.userHasRole(ROLES.SUPER_ADMIN)) && (
                                            <FormGroup requiredInputLabel label='Organización'>
                                                <Select
                                                    id='company'
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        formik.handleChange(e)
                                                        formik.setFieldValue('company', e.target.value)
                                                    }}
                                                    value={formik.values.company}
                                                    onBlur={formik.handleBlur}
                                                    className={verifyClass('company')}
                                                    ariaLabel='Listado de organizaciones'
                                                    placeholder='Elegir...'
                                                    list={getOrganizationsList()}
                                                />
                                                {showErrors('company')}
                                            </FormGroup>
                                        )}

                                        <FormGroup requiredInputLabel label='Profesional' className='col-md-5'>
                                            <Select
                                                id='user'
                                                onChange={formik.handleChange}
                                                value={formik.values.user}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('user')}
                                                ariaLabel='Listado de profesionales'
                                                placeholder='Elegir...'
                                                list={getUsersList()}
                                            />
                                            {showErrors('user')}
                                        </FormGroup>

                                        <FormGroup label="Centro de trabajo" className='col-md-7'>
                                            <SearchableSelect
                                                isMulti
                                                isClearable
                                                name='workplace'
                                                options={getWorkplacesList().filter((option: any) => selectedUserWorkplaces.includes(option.value))}
                                                onChange={(e: any) => formik.setFieldValue('workplaces', e ? e.map((g: any) => g.value) : [])}
                                                value={getWorkplacesList().filter((option: any) => formik.values.workplaces?.includes(option.value))}
                                                placeholder="centro de trabajo"
                                                isDisabled={!userCan('list_workplace', 'workplace') || !formik.values.user}
                                            />
                                            {showErrors('workplaces')}
                                        </FormGroup>
                                    </div>
                                )}

                                <div className="row mt-4">
                                    <CardTitle>Ubicación</CardTitle>
                                    <FormGroup label='País' className="col-md-4">
                                        <CustomSearchSelect isMulti={false} id='country' defaultValue={COUNTRIES.find((country) => country.value === formik.values.country)}
                                            placeholder="Selecciona un país..." search={true} options={COUNTRIES} onChangeSingle={(e: any) => { formik.setFieldValue('country', e.value) }} />
                                        {showErrors('country')}
                                    </FormGroup>
                                    <FormGroup label='Provincia' className="col-md-4">
                                        <Input id='province' onChange={formik.handleChange} value={formik.values.province} onBlur={formik.handleBlur} className={verifyClass('province')} />
                                        {showErrors('province')}
                                    </FormGroup>
                                    <FormGroup label='Localidad' className="col-md-4">
                                        <Input id='city' onChange={formik.handleChange} value={formik.values.city} onBlur={formik.handleBlur} className={verifyClass('city')} />
                                        {showErrors('city')}
                                    </FormGroup>
                                    <FormGroup label='Dirección' className="col-md-10">
                                        <Input id='address' onChange={formik.handleChange} value={formik.values.address} className={verifyClass('address')} />
                                        {showErrors('address')}
                                    </FormGroup>
                                    <FormGroup label='Código Postal' className="col-md-2">
                                        <Input type='number' id='pc' onChange={formik.handleChange} value={formik.values.pc} onBlur={formik.handleBlur} className={verifyClass('pc')} placeholder="00000" />
                                        {showErrors('pc')}
                                    </FormGroup>
                                </div>

                                <div className="row mt-4">
                                    {mode === 'Editar'
                                        ? <CardTitle>Modificar contraseña actual</CardTitle>
                                        : <CardTitle>Seguridad</CardTitle>
                                    }

                                    <CustomPasswordInput
                                        formik={formik}
                                        id='password'
                                        value={formik.values.password}
                                        label="Contraseña"
                                        cols={6}
                                    />

                                    <CustomPasswordInput
                                        formik={formik}
                                        id='passwordConfirm'
                                        value={formik.values.passwordConfirm}
                                        label="Confirmar Contraseña"
                                        cols={6}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    <div className="col-md-3">
                        <Card stretch={false}>
                            <CardBody>
                                <CardTitle className="mt-3 mb-3">Patologías</CardTitle>
                                <SearchableSelect
                                    isMulti
                                    isClearable
                                    name='pathologies'
                                    options={getPathologyOptions()}
                                    onChange={(e: any) => formik.setFieldValue('pathologies', e ? e.map((i: any) => i.value) : [])}
                                    value={getPathologyOptions().filter((option: any) => formik.values.pathologies?.includes(option.value))}
                                    placeholder="patologías"
                                    classname="mt-3"
                                />

                                <CardTitle className="mt-3 mb-3">Aversiones</CardTitle>
                                {foodsData && (
                                    <FormGroup label='Alimentos'>
                                        <div className='row g-4'>
                                            <SearchableSelect
                                                name="food_aversions"
                                                isSearchable
                                                isMulti
                                                isClearable
                                                options={getFoodsList()}
                                                onChange={(e: any) => { formik.setFieldValue('food_aversions', e?.map((food: any) => food.value)) }}
                                                placeholder='alimentos'
                                                onBlur={formik.handleBlur}
                                                defaultValue={getFoodsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                            />
                                        </div>
                                    </FormGroup>
                                )}

                                {groupsData && (
                                    <FormGroup label='Grupos' className='mt-3'>
                                        <div className='row g-4'>
                                            <SearchableSelect
                                                name="group_aversions"
                                                isSearchable
                                                isMulti
                                                isClearable
                                                options={getGroupsList()}
                                                onChange={(e: any) => { formik.setFieldValue('group_aversions', e?.map((group: any) => group.value)) }}
                                                placeholder='grupos'
                                                onBlur={formik.handleBlur}
                                                defaultValue={getGroupsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                            />
                                        </div>
                                    </FormGroup>
                                )}

                                <CardTitle className="mt-3 mb-3">Intolerancias</CardTitle>
                                <SearchableSelect
                                    isMulti
                                    isClearable
                                    name='clientAversions'
                                    options={getIntolerancesList()}
                                    onChange={(e: any) => formik.setFieldValue('intolerances', e ? e.map((i: any) => i.value) : [])}
                                    value={getIntolerancesList().filter((option: any) => formik.values.intolerances?.includes(option.value))}
                                    placeholder="intolerancias"
                                    classname="mt-3"
                                />

                                <CardTitle className="mt-3 mb-3">Alergias</CardTitle>
                                <SearchableSelect
                                    isMulti
                                    isClearable
                                    name='allergies'
                                    options={getAllergyOptions()}
                                    onChange={(e: any) => formik.setFieldValue('allergies', e ? e.map((i: any) => i.value) : [])}
                                    value={getAllergyOptions().filter((option: any) => formik.values.allergies?.includes(option.value))}
                                    placeholder="alergias"
                                    classname="mt-3"
                                />

                                <CardTitle className="mt-3 mb-3">Estado Fisiológico</CardTitle>
                                <SearchableSelect
                                    isClearable
                                    name='physiologicalState'
                                    options={getPhysiologicalStateOptions()}
                                    onChange={(e: any) => formik.setFieldValue('physiologicalState', e ? e.value : '')}
                                    value={getPhysiologicalStateOptions().find((option: any) => option.value === formik.values.physiologicalState)}
                                    onBlur={formik.handleBlur}
                                    styles={verifyClass('physiologicalState')}
                                    placeholder='estado fisiológico'
                                />
                                {showErrors('physiologicalState')}
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </form>
        )
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Paciente</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color={'dark'} /> : `${buttonTitle} Paciente`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid' className="p-0">
                {(mode === "Editar" && !patientData) && <Spinner />}
                {(mode === "Editar" && patientData) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
        </Fragment>
    )
}

export default PatientForm;