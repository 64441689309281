import React, { useContext, useState } from "react";

export type RestrictionCategory = { name: string; values: string[] };

type MenuProviderContextData = {
    displayCreateTemplate?: boolean,
    setDisplayCreateTemplate: (display: boolean) => void,
    restrictions: RestrictionCategory[];
    setRestrictions: (restrictions: RestrictionCategory[]) => void;
}

const MenuProviderContext: React.Context<MenuProviderContextData> = React.createContext<MenuProviderContextData>({
    displayCreateTemplate: false,
    setDisplayCreateTemplate: () => {},
    restrictions: [],
    setRestrictions: () => { },
});

type MenuProviderProps = {
    children: React.ReactNode;
}

const MenuProvider: React.FC<MenuProviderProps> = ({
    children,
}) => {
    const [displayCreateTemplate, setDisplayCreateTemplate] = useState<boolean>(false);
    // Estado para almacenar las restricciones del paciente
    const [restrictions, setRestrictions] = useState<RestrictionCategory[]>([
        { name: "Grupo de alimentos", values: [] },
        { name: "Alimentos", values: [] },
        { name: "Nutrientes", values: [] },
        { name: "Alérgenos", values: [] }
    ]);

    return (
        <MenuProviderContext.Provider value={{
            displayCreateTemplate,
            setDisplayCreateTemplate,
            restrictions,
            setRestrictions,
        }}>
            {children}
        </MenuProviderContext.Provider>
    );
}

export { MenuProvider, MenuProviderContext };

export function useMenuProvider() {
    return useContext(MenuProviderContext);
}