import { FC, Fragment, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import CustomSearchInput from '../../../components/bootstrap/forms/CustomSearchInput';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Icon from '../../../components/icon/Icon';
import SearchableSelect from '../../../components/select/SearchableSelect';
import useServiceTypes from '../../../hooks/api-calls/useServiceTypes';
import { APPOINTMENT_TYPES } from '../../../utils/mapping-collection';

interface IConsultationTypesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const ConsultationTypesFilters: FC<IConsultationTypesFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    // STATES

    const [filterMenu, setFilterMenu] = useState(false);
    const [resetkey, setResetKey] = useState(0);

    // HOOKS

    const { getServiceTypesOptions } = useServiceTypes();

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja el cambio de texto en el campo de búsqueda.
     * @EN Function that handles text change in search field.
     * 
     * @param search 
     */
    //------------------------------------------------------------------------------------------------
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };
    //------------------------------------------------------------------------------------------------

    // RENDER

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>Filtros</Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'>Filtros de Tipo de Consultas</OffCanvasTitle>
                    <Button color="storybook" isLight onClick={() => {
                        resetFilters()
                        setResetKey(resetkey + 1)
                    }}>
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4' key={resetkey}>
                        <FormGroup label='Tipo de Servicio' className='col-12'>
                            <SearchableSelect
                                isClearable
                                name='serviceType-select'
                                options={getServiceTypesOptions()}
                                onChange={(e: any) => updateFilters({ serviceType: e?.value })}
                                defaultValue={filters.filter_filters.serviceType ? getServiceTypesOptions().filter((item: any) => filters.filter_filters.serviceType === item?.value).map((i: any) => ({ value: i.value, label: i.label })) : []}
                                placeholder='tipo de servicio'
                            />
                        </FormGroup>

                        <FormGroup label='Tipo de Cita' className='col-12'>
                            <SearchableSelect
                                isClearable
                                name='appointmentType-select'
                                options={APPOINTMENT_TYPES}
                                onChange={(e: any) => updateFilters({ appointmentType: e?.value })}
                                defaultValue={filters.filter_filters.appointmentType ? APPOINTMENT_TYPES.filter((item: any) => filters.filter_filters.appointmentType === item?.value).map((i: any) => ({ value: i.value, label: i.label })) : []}
                                placeholder='tipo de cita'
                            />
                        </FormGroup>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default ConsultationTypesFilters;