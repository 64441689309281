import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { isItemRestricted } from "../../../utils/CheckRestrictions";

interface Food {
    id: string;
    name: string;
    food_group: string;
    food_group_id: string;
    food_group_color: string;
}

interface PhysicalActivity {
    id: string;
    name: string;
}

interface Recipe {
    id: string;
    name: string;
    food_group: string;
    food_group_id: string;
    food_group_color: string;
}

interface MenuItem {
    id: string;
    dayId: string;
    dayIndex?: number;
    intakeId: string;
    food: Food | null;
    recipe: Recipe | null;
    physicalActivity: PhysicalActivity | null;
    quantity?: number;
}

interface Intake {
    id: string;
    name: string;
    menuItems: MenuItem[];
}

interface Day {
    id: string;
    name?: string;
    intakes: Intake[];
}

interface MenuData {
    days: Day[];
}

type MenuDataProviderContextData = {
    menu_state: any;
    menuData: MenuData;
    setMenuData: (data: MenuData) => void;
    addItemToMenu: (item: MenuItem, dayId: string, intakeId: string) => void;
    deleteItemFromMenu: (dayId: string, intakeId: string, itemId: string) => void;
    editItemInMenu: (item: MenuItem, dayId: string, intakeId: string) => void;
    initializeMenuData: (data: any) => void;
    findMenuItemsFromIntake: (intakeId: string) => MenuItem[];
    deleteDay: (dayId: string) => void;
    deleteIntake: (intakeId: string) => void;
    orderDay: (dayId: string, order: string) => void;
    orderIntake: (intakeId: string, order: string) => void;
    addDay: (day: Day) => void;
    addIntake: (intake: Intake) => void;
    addIntakesFromSession: (intakes: any) => void;
    getNumbersOfItemsByDayAndIntake: (dayId: string | null, intakeId: string) => number;
    overlappingRestrictions: Record<string, any>;
    setOverlappingRestrictions: (data: Record<string, any>) => void;
    showFoodGroup: boolean;
    setShowFoodGroup: (show: boolean) => void;
    showPhysicalActivities: boolean;
    setShowPhysicalActivities: (show: boolean) => void;
    recipeId: string;
    setRecipeId: (id: string) => void;
    openRecipeModal: boolean;
    setOpenRecipeModal: (open: boolean) => void;
    openEditRecipeModal: boolean;
    setOpenEditRecipeModal: (open: boolean) => void;
    showRecipeSelectors: boolean;
    setShowRecipeSelectors: (show: boolean) => void;
    showFoodSelectors: boolean;
    setShowFoodSelectors: (show: boolean) => void;
    showSupplementSelectors: boolean;
    setShowSupplementSelectors: (show: boolean) => void;
    showPhysicalActivitySelectors: boolean;
    setShowPhysicalActivitySelectors: (show: boolean) => void;
    copyingIntake: boolean;
    setCopyingIntake: (intake: boolean) => void;
    selectedMenuItem: string | null;
    setSelectedMenuItem: (id: string | null) => void;
    openContextMenuChange: boolean;
    setOpenContextMenuChange: (open: boolean) => void;
}

const MenuDataProviderContext = React.createContext<MenuDataProviderContextData>({
    menu_state: {},
    menuData: { days: [] },
    setMenuData: () => { },
    addItemToMenu: () => { },
    deleteItemFromMenu: () => { },
    editItemInMenu: () => { },
    initializeMenuData: () => { },
    findMenuItemsFromIntake: () => [],
    deleteDay: () => { },
    deleteIntake: () => { },
    orderDay: () => { },
    orderIntake: () => { },
    addDay: () => { },
    addIntake: () => { },
    addIntakesFromSession: () => { },
    getNumbersOfItemsByDayAndIntake: () => 0,
    overlappingRestrictions: {},
    setOverlappingRestrictions: () => { },
    showFoodGroup: false,
    setShowFoodGroup: () => { },
    showPhysicalActivities: false,
    setShowPhysicalActivities: () => { },
    recipeId: '',
    setRecipeId: () => { },
    openRecipeModal: false,
    setOpenRecipeModal: () => { },
    openEditRecipeModal: false,
    setOpenEditRecipeModal: () => { },
    showRecipeSelectors: false,
    setShowRecipeSelectors: () => { },
    showFoodSelectors: false,
    setShowFoodSelectors: () => { },
    showSupplementSelectors: false,
    setShowSupplementSelectors: () => { },
    showPhysicalActivitySelectors: false,
    setShowPhysicalActivitySelectors: () => { },
    copyingIntake: false,
    setCopyingIntake: () => { },
    selectedMenuItem: null,
    setSelectedMenuItem: () => { },
    openContextMenuChange: false,
    setOpenContextMenuChange: () => { },
});

type MenuDataProviderProps = {
    children: React.ReactNode;
}

const MenuDataProvider: React.FC<MenuDataProviderProps> = ({ children }) => {

    // STATES
    
    const menu_state = useSelector((state: RootState) => state.menu);
    const [menuData, setMenuData] = useState<MenuData>({ days: [] });
    const [overlappingRestrictions, setOverlappingRestrictions] = useState<Record<string, any>>({});
    const [showFoodGroup, setShowFoodGroup] = useState<boolean>(false);
    const [showPhysicalActivities, setShowPhysicalActivities] = useState<boolean>(false);
    const [recipeId, setRecipeId] = useState<string>('');
    const [openRecipeModal, setOpenRecipeModal] = useState<boolean>(false);
    const [openEditRecipeModal, setOpenEditRecipeModal] = useState<boolean>(false);
    const [showRecipeSelectors, setShowRecipeSelectors] = useState<boolean>(false);
    const [showFoodSelectors, setShowFoodSelectors] = useState<boolean>(false);
    const [showSupplementSelectors, setShowSupplementSelectors] = useState<boolean>(false);
    const [showPhysicalActivitySelectors, setShowPhysicalActivitySelectors] = useState<boolean>(false);
    const [copyingIntake, setCopyingIntake] = useState<any | null>(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
    const [openContextMenuChange, setOpenContextMenuChange] = useState<boolean>(false);

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE AGREGA UN ITEM AL MENU
     * @EN FUNCTION THAT ADDS AN ITEM TO THE MENU
     * 
     * @param item
     * @param dayId
     * @param intakeId
     */
    //--------------------------------------------------------------------------------------------
    const addItemToMenu = (item: MenuItem, dayId: string, intakeId: string) => {
        isItemRestricted(item, overlappingRestrictions); // CHECK IF ITEM IS RESTRICTED
        setMenuData(prevData => { // CHANGE THE STATE
            const newData = { ...prevData }; // COPY THE STATE
            const dayIndex = newData.days.findIndex(day => day.id === dayId); // FIND THE DAY INDEX
            if (dayIndex > -1) { // IF THE DAY EXISTS
                const intakeIndex = newData.days[dayIndex].intakes.findIndex(intake => intake.id === intakeId); // FIND THE INTAKE INDEX
                if (intakeIndex > -1) { // IF THE INTAKE EXISTS
                    newData.days[dayIndex].intakes[intakeIndex].menuItems.push(item); // ADD THE ITEM TO THE INTAKE
                    return {...newData}; // RETURN THE NEW STATE
                }
            }
            return newData; // RETURN THE NEW STATE
        });
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE ELIMINA UN ITEM DEL MENU
     * @EN FUNCTION THAT DELETES AN ITEM FROM THE MENU
     * 
     * @param dayId
     * @param intakeId
     * @param itemId
     */ 
    //--------------------------------------------------------------------------------------------
    const deleteItemFromMenu = (dayId: string, intakeId: string, itemId: string) => {
        setMenuData(prevData => {
            const newData = { ...prevData };
            const dayIndex = newData.days.findIndex(day => day.id === dayId);
            if (dayIndex > -1) {
                const intakeIndex = newData.days[dayIndex].intakes.findIndex(intake => intake.id === intakeId);
                if (intakeIndex > -1) {
                    newData.days[dayIndex].intakes[intakeIndex].menuItems = // FILTER THE ITEMS TO REMOVE THE ITEM
                        newData.days[dayIndex].intakes[intakeIndex].menuItems.filter(item => item.id !== itemId);
                }
            }
            return newData;
        });
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE EDITA UN ITEM DEL MENU
     * @EN FUNCTION THAT EDITS AN ITEM FROM THE MENU
     * 
     * @param item
     * @param dayId
     * @param intakeId
     */
    //--------------------------------------------------------------------------------------------
    const editItemInMenu = (item: MenuItem, dayId: string, intakeId: string) => {
        setMenuData(prevData => {
            return {
                ...prevData,
                days: prevData.days.map(day => {
                    if (day.id !== dayId) return day;
                    return {
                        ...day,
                        intakes: day.intakes.map(intake => {
                            if (intake.id !== intakeId) return intake;
                            return {
                                ...intake,
                                menuItems: intake.menuItems.map(menuItem => 
                                    menuItem.id === item.id ? item : menuItem
                                )
                            };
                        })
                    };
                })
            };
        });
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE INICIALIZA LOS DATOS DEL MENU
     * @EN FUNCTION THAT INITIALIZES THE MENU DATA
     * 
     * @param data
     */
    //--------------------------------------------------------------------------------------------
    const initializeMenuData = (data: any) => {
        const newMenuData: MenuData = { days: [] };

        // FIRST, WE ADD THE DAYS TO THE MENU
        data.days.forEach((day: any) => {
            newMenuData.days.push({
                id: day.id,
                name: day.name,
                intakes: []
            });
        });

        // THEN, WE ADD THE INTAKES TO THE DAYS
        newMenuData.days.forEach((day: Day) => {
            data.intakes.forEach((intake: any) => {
                day.intakes.push({
                    id: intake.id,
                    name: intake.name,
                    menuItems: []
                });
            });
        });

        // FINALLY, WE ADD THE MENU ITEMS TO THE INTAKES
        data.menuItems.forEach((item: any) => {
            const dayIndex = newMenuData.days.findIndex(day => day.id === item.days.id);
            if (dayIndex !== -1) {
                const intakeIndex = newMenuData.days[dayIndex].intakes.findIndex(intake => intake.id === item.intakes.id);
                if (intakeIndex !== -1) {
                    const menuItem: MenuItem = {
                        id: item.id,
                        dayId: item.days.id,
                        intakeId: item.intakes.id,
                        food: item.food ? {
                            id: item.food.id,
                            name: item.food.name,
                            food_group: item.food.foodGroup.name,
                            food_group_id: item.food.foodGroup.id,
                            food_group_color: item.food.foodGroup.color
                        } : null,
                        recipe: item.recipe ? {
                            id: item.recipe.id,
                            name: item.recipe.name,
                            food_group: item.recipe.foodGroup ? item.recipe.foodGroup.name : null,
                            food_group_id: item.recipe.foodGroup ? item.recipe.foodGroup.id : null,
                            food_group_color: item.recipe.foodGroup ? item.recipe.foodGroup.color : null
                        } : null,
                        physicalActivity: item.physicalActivity ? {
                            id: item.physicalActivity.id,
                            name: item.physicalActivity.name
                        } : null,
                        quantity: item.quantity,
                    };
                    newMenuData.days[dayIndex].intakes[intakeIndex].menuItems.push(menuItem);
                }
            }
        });

        setMenuData(newMenuData);
    };
    //--------------------------------------------------------------------------------------------

    /**
     * EN: Find menu items from a specific intake to add them to the session cookie
     * ES: Encuentra los items del menú de una ingesta específica para agregarlos a la cookie de sesión
     * @param intakeId
     * @returns menuItems[]
     */
    const findMenuItemsFromIntake = (intakeId: string) => {
        const menuItems: MenuItem[] = [];
        menuData.days.map((day, index) => {
            day.intakes.forEach(intake => {
                if (intake.id === intakeId) {
                    intake.menuItems.forEach(item => {
                        let newItem = { ...item, dayIndex: index };
                        menuItems.push(newItem);
                    });
                }
            });
        });
        return menuItems;
    };

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE ELIMINA UN DIA DEL MENU
     * @EN FUNCTION THAT DELETES A DAY FROM THE MENU
     * 
     * @param dayId
     */
    //--------------------------------------------------------------------------------------------
    const deleteDay = (dayId: string) => {
        setMenuData(prevData => {
            const newData = { ...prevData };
            newData.days = newData.days.filter(day => day.id !== dayId); // FILTER THE DAYS TO REMOVE THE DAY
            return newData;
        });
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE ELIMINA UNA INGESTA DEL MENU
     * @EN FUNCTION THAT DELETES AN INTAKE FROM THE MENU
     * 
     * @param intakeId
     */
    //--------------------------------------------------------------------------------------------
    const deleteIntake = (intakeId: string) => {
        setMenuData(prevData => {
            const newData = { ...prevData };
            newData.days = newData.days.map(day => ({
                ...day,
                intakes: day.intakes.filter(intake => intake.id !== intakeId) // FILTER THE INTAKES TO REMOVE THE INTAKE
            }));
            return newData;
        });
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE ORDENA LOS DIAS
     * @EN FUNCTION THAT ORDERS THE DAYS
     * 
     * @param dayId
     * @param order
     */
    //--------------------------------------------------------------------------------------------
    const orderDay = (dayId: string, order: string) => {
        if (order == "up") { // IF THE ORDER IS UP MOVE THE DAY TO THE RIGHT
            setMenuData(prevData => {
                const newData = { ...prevData };
                const dayIndex = newData.days.findIndex(day => day.id === dayId);
                if (dayIndex < newData.days.length - 1) {
                    const temp = newData.days[dayIndex + 1];
                    newData.days[dayIndex + 1] = newData.days[dayIndex]; 
                    newData.days[dayIndex] = temp;
                }
                return newData;
            });
        } else { // IF THE ORDER IS DOWN MOVE THE DAY TO THE LEFT
            setMenuData(prevData => {
                const newData = { ...prevData };
                const dayIndex = newData.days.findIndex(day => day.id === dayId);
                if (dayIndex > 0) {
                    const temp = newData.days[dayIndex - 1];
                    newData.days[dayIndex - 1] = newData.days[dayIndex];
                    newData.days[dayIndex] = temp;
                }
                return newData;
            });
        }
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE ORDENA LAS INGESTAS
     * @EN FUNCTION THAT ORDERS THE INTAKES
     * 
     * @param intakeId
     * @param order
     */
    //--------------------------------------------------------------------------------------------
    const orderIntake = (intakeId: string, order: string) => {
        if (order == "up") { // IF THE ORDER IS UP MOVE THE INTAKE TO THE BOTTOM
            setMenuData(prevData => {
                const newData = { ...prevData };
                newData.days = newData.days.map(day => {
                    const intakeIndex = day.intakes.findIndex(intake => intake.id === intakeId);
                    if (intakeIndex < day.intakes.length - 1) {
                        const temp = day.intakes[intakeIndex + 1];
                        day.intakes[intakeIndex + 1] = day.intakes[intakeIndex];
                        day.intakes[intakeIndex] = temp;
                    }
                    return day;
                });
                return newData;
            });
        } else {
            setMenuData(prevData => { // IF THE ORDER IS DOWN MOVE THE INTAKE TO THE TOP
                const newData = { ...prevData };
                newData.days = newData.days.map(day => {
                    const intakeIndex = day.intakes.findIndex(intake => intake.id === intakeId);
                    if (intakeIndex > 0) {
                        const temp = day.intakes[intakeIndex - 1];
                        day.intakes[intakeIndex - 1] = day.intakes[intakeIndex];
                        day.intakes[intakeIndex] = temp;
                    }
                    return day;
                });
                return newData;
            });
        }
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION QUE AGREGA UN DIA AL MENU
     * @EN FUNCTION THAT ADDS A DAY TO THE MENU
     * 
     * @param day
     */
    //--------------------------------------------------------------------------------------------
    const addDay = (day: Day) => {
        setMenuData(prevData => {
            const newData = { ...prevData };

            let dayFormated : Day = { // NEW DAY
                id: day.id,
                name: day.name,
                intakes: menuData.days[0].intakes.map(intake => ({
                    id: intake.id,
                    name: intake.name,
                    menuItems: []
                }))
            }

            newData.days.push(dayFormated);
            return newData;
        });
    };
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    const addIntake = (intake: Intake) => {
        setMenuData(prevData => {
            const newData = { ...prevData };
            newData.days = newData.days.map(day => {
                let intakeFormated: Intake = {
                    id: intake.id,
                    name: intake.name,
                    menuItems: []
                }
                return {
                    ...day,
                    intakes: [...day.intakes, intakeFormated]
                }
            });
            return newData;
        });
    };

    /**
     * EN: Add to the menu the intakes saved in the session cookie
     * ES: Añadir al menú las ingestas guardadas en la cookie de sesión
     * @param intakes {id: string, name: string, menuItems: MenuItem[]}[]
     * @returns 
     */
    const addIntakesFromSession = (intakes: any) => {
        const newData = { ...menuData };
        newData.days = newData.days.map((day: any, index: number) => {
            let newIntakes = intakes.map((intake: any) => {
                return {
                    id: intake.id,
                    name: intake.name,
                    // Filter the menuItems that belong to the current day
                    menuItems: intake.menuItems.filter((item: any) => item.dayIndex === index)
                }
            });
            return {
                ...day,
                intakes: [...newIntakes, ...day.intakes]
            }
        });
        setMenuData(newData);
    };

    const getNumbersOfItemsByDayAndIntake = (dayId: string | null, intakeId: string) => {
        if (!dayId) return 0;

        const day = menuData.days.find(day => day.id === dayId);
        if (day) {
            const intake = day.intakes.find(intake => intake.id === intakeId);
            if (intake) {
                return intake.menuItems.length;
            }
        }
        return 0;
    };

    return (
        <MenuDataProviderContext.Provider value={{
            menu_state,
            menuData,
            setMenuData,
            addItemToMenu,
            deleteItemFromMenu,
            editItemInMenu,
            initializeMenuData,
            findMenuItemsFromIntake,
            deleteDay,
            deleteIntake,
            orderDay,
            orderIntake,
            addDay,
            addIntake,
            addIntakesFromSession,
            getNumbersOfItemsByDayAndIntake,
            overlappingRestrictions,
            setOverlappingRestrictions,
            showFoodGroup,
            setShowFoodGroup,
            showPhysicalActivities,
            setShowPhysicalActivities,
            recipeId,
            setRecipeId,
            openRecipeModal,
            setOpenRecipeModal,
            openEditRecipeModal,
            setOpenEditRecipeModal,
            showRecipeSelectors,
            setShowRecipeSelectors,
            showFoodSelectors,
            setShowFoodSelectors,
            showSupplementSelectors,
            setShowSupplementSelectors,
            showPhysicalActivitySelectors,
            setShowPhysicalActivitySelectors,
            copyingIntake,
            setCopyingIntake,
            selectedMenuItem,
            setSelectedMenuItem,
            openContextMenuChange,
            setOpenContextMenuChange
        }}>
            {children}
        </MenuDataProviderContext.Provider>
    );
}

export { MenuDataProvider, MenuDataProviderContext };

export function useMenuDataProvider() {
    return useContext(MenuDataProviderContext);
}