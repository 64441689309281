import classNames from "classnames";
import moment from "moment";
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";
import Icon from "../../../components/icon/Icon";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { formsMenu } from "../../../menu";
import { RootState } from "../../../redux/store";
import { FormService } from "../../../services/forms/formService";

interface IFormsFilters {
    user: string;
    formType?: string | null;
    client?: string | null;
}

const FormsList: FC = () => {

    const user = useSelector((state: RootState) => state.auth.user);
    const { userCan } = usePrivilege();
    const navigate = useNavigate();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ formType: null, client: null, user: user?.id } as IFormsFilters);

    const [forms, loadingForms, formError, refetchForms] = useFetch(useCallback(async () => {
        const response = (await (new FormService()).getForms(filters));
        return response.getResponseData();
    }, [filters]));

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new FormService()).deleteForm(id)).getResponseData();
            if (response.success) {
                refetchForms();

                toast.success('Formulario eliminado correctamente');
            } else {
                toast.error(response.message || 'Error al eliminar el formulario');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el formulario');
        }
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Formularios</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Formulario"
                        color="storybook"
                        icon="Add"
                        isLight
                        className={classNames({ 'd-none': !userCan('create', 'forms') })}
                        onClick={() => { navigate(formsMenu.forms.path + '/create') }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={(e) => { updateFilters({ search_array: e }); }} placeholder='Buscar...' />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <CustomTable
                            title="Formularios"
                            data={forms ? forms?.forms : null}
                            pagination
                            paginationData={{
                                pageSize: filters.limit,
                                currentPage: filters.page,
                                pageCount: forms ? forms.lastPage : 1,
                                handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                                handlePerPage: updatePageSize,
                            }}
                            className={"table-striped table-hover"}
                            columns={[
                                {
                                    name: "Nombre",
                                    keyValue: "name",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (
                                            userCan('edit', 'forms')
                                                ? (
                                                    <div className="cursor-pointer text-secondary" onClick={() => { navigate(`/forms/${element.id}/edit`) }}>
                                                        {element.name}
                                                    </div>
                                                )
                                                : element.name
                                        )
                                    },
                                },
                                {
                                    name: "Nº de campos",
                                    keyValue: "fields",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return <>{element.formFields !== undefined ? element.formFields.length : "0"}</>
                                    }
                                },
                                {
                                    name: "Fecha de creación",
                                    keyValue: "createdAt",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return <>{moment(element.createdAt?.date).format('DD/MM/YY HH:mm') || "-"}</>
                                    }
                                },
                                {
                                    name: "Última actualización",
                                    keyValue: "updatedAt",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return <>{moment(element.updateAt?.date).format('DD/MM/YY HH:mm') || "-"}</>
                                    }
                                },
                                { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                            ]}
                            actions={[
                                {
                                    title: "Editar",
                                    icon: "Edit",
                                    buttonType: 'icon',
                                    additionalClasses: 'text-primary',
                                    description: "Editar formulario",
                                    hidden: !userCan('edit', 'forms'),
                                    click: (item: any) => { navigate(`/forms/${item.id}/edit`) }
                                },
                                {
                                    title: "Eliminar",
                                    icon: "Delete",
                                    buttonType: 'icon',
                                    additionalClasses: 'text-danger',
                                    description: "Eliminar formulario",
                                    hidden: !userCan('delete', 'forms'),
                                    click: (item: any) => {
                                        handleConfirmationAlert({
                                            title: "Eliminar formulario",
                                            text: "¿Está seguro de que desea eliminar el formulario?",
                                            icon: "warning",
                                            onConfirm: () => { handleDelete(item.id) }
                                        })
                                    },
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
            </Page>
        </>
    );
}

export default FormsList;