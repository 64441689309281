import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import usePatients from '../../../../hooks/api-calls/usePatients'

interface IMenuFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const MenuFilters: FC<IMenuFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const token = useSelector((state: RootState) => state.auth);

    const [filterMenu, setFilterMenu] = useState(false);

    const { getPatientsOptions } = usePatients(token.user?.id);

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>Filtros</Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'>Filtros de Menús Nutricionales</OffCanvasTitle>
                    <Button color="storybook" isLight onClick={() => resetFilters()}>
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter0' label='Tipo de menú'>
                                <Select
                                    id='type-select'
                                    value={filters.filter_filters.is_template.toString()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ is_template: parseInt(e.target.value) })}
                                    list={[
                                        { value: 3, label: 'Todos', text: 'Todos' },
                                        { value: 1, label: 'Plantillas', text: 'Plantillas' },
                                        { value: 0, label: 'Asignados', text: 'Asignados' }
                                    ]}
                                    ariaLabel='Select de tipo de menú'
                                    placeholder='Tipo de menú...'
                                />
                            </FormGroup>

                            <FormGroup id='filter1' label='Estado' className='mt-3'>
                                <Select
                                    id='active-select'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters.active.toString()}
                                    list={[{ value: 3, label: 'Todos', text: 'Todos' },
                                    { value: 1, label: 'Activo', text: 'Activo' },
                                    { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                                    ariaLabel='Select de estado'
                                    placeholder='Estado...'
                                />
                            </FormGroup>

                            <FormGroup id='filter3' label='Paciente' className='mt-3'>
                                <Select
                                    id='patient-select'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ client: e.target.value })}
                                    value={filters.filter_filters.client}
                                    list={getPatientsOptions()}
                                    ariaLabel='Listado de profesionales'
                                    placeholder='Paciente...'
                                />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default MenuFilters;