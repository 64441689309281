import { useEffect, useState } from 'react';
import { PatientService } from '../../services/patients/patientService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage patients fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de pacientes.
----------------------------------------------------------------------------------------*/

const usePatientsByUser = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
 

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const service = new PatientService();
            const response = await service.getPatientsByUser(initialFilters);
            const fetchedData = response.getResponseData().data.patients;

            if (fetchedData) {
                const mappedData = fetchedData?.map((data: any) => data );
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching patient list:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [initialFilters]);

    const getList = (entityId?: string) => {
        if (entityId) return data.filter((state: any) => state.id === entityId);
        return data;
    };

    const getOptionsList = () => {
        return data.map((option: { id: string; name: string; lastName: string }) => {
            return {
                value: option.id,
                label: option.name + (option.lastName ? ' ' + option.lastName : '')
            }
        })
    }

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    }

    return {
        fetchPatients: fetchData,
        getPatientsList: getList,
        getPatient: getEntity,
        getPatientsOptions: getOptionsList,
        isLoadingPatients: isLoading
    };
}

export default usePatientsByUser;