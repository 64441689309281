import classNames from 'classnames';
import React, { useCallback } from 'react';
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/bootstrap/Button";
import { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { RationsService } from '../../../services/rations/rationService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import RationsFilters from './RationsFilters';
import RationModalForm from './components/RationModalForm';

interface GCRationsProps { }

const GCRations: React.FC<GCRationsProps> = ({ }) => {

    // STATES

    const [isOpen, setIsOpen] = React.useState(false);
    const [rowId, setRowId] = React.useState('');

    // HOOKS

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3 });

    // FETCH DATA

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new RationsService()).listRations(filters));
        return response.getResponseData();
    }, [filters]));

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función para eliminar una ración.
     * @EN Function to delete a ration.
     * 
     * @param id 
     */
    //--------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new RationsService()).deleteRation(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Ración eliminada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al eliminar la ración');
        }
    };
    //--------------------------------------------------------------------------------------------------

    // RENDER

    if (error) return <ErrorMessage />;

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Raciones</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear patología"
                        color="storybook"
                        icon="Add"
                        isLight
                        className={classNames({ 'd-none': !userCan('create', 'portion_configuration') })}
                        onClick={() => { setRowId(''); setIsOpen(true); }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <RationsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </CardHeader>
            <CardBody className="table-responsive">
                <CustomTable
                    title="Raciones"
                    data={data ? data?.portionConfigurations : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data ? data?.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Edad desde (años)",
                            keyValue: "ageFrom",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Edad hasta (años)",
                            keyValue: "ageTo",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Raciones por grupos de alimentos",
                            keyValue: "foodGroupsConfiguration",
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.foodGroupsConfiguration?.length > 0
                                            ? element.foodGroupsConfiguration.map((fg: any, index: number) => {
                                                let foodGroups = fg.portionConfigurationHasFoodGroupConfigurationHasFoodGroups.map((fgc: any, i: number) => {
                                                    return fgc.foodGroup.name;
                                                });
                                                let convertedQuantity = (fg.quantity > 1000) ? `${fg.quantity / 1000} kg` : `${fg.quantity} g`;
                                                return (
                                                    <div key={index}>
                                                        <strong>{foodGroups.join(', ')}</strong>: {convertedQuantity}
                                                    </div>
                                                )
                                            })
                                            : "-"
                                        }
                                    </>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar ración",
                            hidden: !userCan('edit', 'portion_configuration'),
                            click: (item: any) => { setRowId(item.id); setIsOpen(true); },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar ración",
                            hidden: !userCan('delete', 'portion_configuration'),
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar ración",
                                    text: "¿Está seguro que desea eliminar la ración?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            </CardBody>

            {isOpen && <RationModalForm isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} rowId={rowId} />}
        </>
    );
};

export default GCRations;