import classNames from "classnames";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { getContrastColor } from "../../utils/ColorUtils";
import Button from "../bootstrap/Button";
import { usePrivilege } from "../priviledge/PriviledgeProvider";
import { DragItem } from "./DraggableItem";
import { useMenuDataProvider } from "./providers/MenuDataProvider";

const DraggableMenuItem = ({ item, selectedFood, selectedRecipe, selectedActivity, setSelectedFood, setSelectedRecipe, setSelectedActivity, deleteMenuItem, setSelectedMenuItem, setOpenContextMenuChange }: any) => {

    // HOOKS

    const { overlappingRestrictions, showFoodGroup, setRecipeId, setOpenRecipeModal, setOpenEditRecipeModal } = useMenuDataProvider();
    const { userCan } = usePrivilege();

    // STATES & VARIABLES

    const itemBgColor = overlappingRestrictions?.ids?.includes(item.food?.id || item.recipe?.id) ? '#E21840' : '#CBFFCD';
    const bgColor =
        item.physicalActivity
            ? '#B3DADE'
            : showFoodGroup
                ? item.food
                    ? item.food.food_group_color
                    : item.recipe
                        ? item.recipe.food_group_color
                        : itemBgColor
                : itemBgColor;

    // FUNCTIONS
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Evento que se dispara cuando se arrastra un item
     * @EN Event that is triggered when an item is dragged
     * 
     * @returns void
     */
    const [{ isDragging }, dragRef] = useDrag<DragItem, void, { isDragging: boolean }>({
        type: 'menuItem',
        item: {
            type: 'menuItem',
            id: (selectedFood ? selectedFood?.id : (selectedRecipe ? selectedRecipe?.id : (selectedActivity ? selectedActivity?.id : null))),
            name: selectedFood ? item.food?.name : (selectedRecipe ? item.recipe?.name : (selectedActivity ? item.physicalActivity?.name : null)),
            dayId: item.days?.id,
            intakeId: item.intakes?.id,
            isFood: (selectedFood ? true : false),
            foodGroup: (selectedFood ? item.food?.food_group : selectedRecipe ? item.recipe?.food_group : null),
            foodGroupId: (selectedFood ? item.food?.food_group_id : selectedRecipe ? item.recipe?.food_group_id : null),
            foodGroupColor: (selectedFood ? item.food?.food_group_color : selectedRecipe ? item.recipe?.food_group_color : null),
            isRecipe: (selectedRecipe ? true : false),
            isActivity: (selectedActivity ? true : false),
            quantity: item.quantity || null,
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------

    // RENDER 

    return (
        <div
            key={item.id}
            ref={dragRef}
            onDragStart={() => {
                item.food && setSelectedFood(item.food);
                item.recipe && setSelectedRecipe(item.recipe);
                item.physicalActivity && setSelectedActivity(item.physicalActivity);
            }}
            className='menu-item'
            style={{ backgroundColor: bgColor, cursor: 'pointer' }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={overlappingRestrictions?.ids?.includes(item.food?.id || item.recipe?.id) ? "Contiene restricciones" : ''}
            onContextMenu={(e) => {
                e.preventDefault();
                setSelectedMenuItem(item);
                setOpenContextMenuChange(true);
            }}
        >
            <div>
                {showFoodGroup
                    ? (
                        <span
                            className="menu-item-name"
                            style={{ color: item.physicalActivity ? '#000' : getContrastColor(showFoodGroup ? item.food ? item.food.food_group_color : item.recipe ? item.recipe.food_group_color : itemBgColor : item.foodGroupColor ? item.foodGroupColor : itemBgColor) }}
                        >
                            {item.food && item.food.food_group}
                            {item.recipe && item.recipe.food_group}
                            {item.physicalActivity && item.physicalActivity.name}
                        </span>
                    )
                    : (
                        <span
                            onClick={() => {
                                if (item.recipe) {
                                    setRecipeId(item.recipe.id);
                                    setOpenRecipeModal(true);
                                }
                            }}
                            className="menu-item-name"
                            style={{ color: item.physicalActivity ? '#000' : getContrastColor(itemBgColor) }}
                        >
                            {item.food && item.food.name}
                            {item.recipe && item.recipe.name}
                            {item.physicalActivity && item.physicalActivity.name}
                        </span>
                    )
                }

                <div className="d-flex justify-content-center delete-item">
                    <Button
                        onClick={() => {
                            if (item.recipe) {
                                setRecipeId(item.recipe.id);
                                setOpenRecipeModal(true);
                            }
                        }}
                        title='Ver receta'
                        size='sm'
                        icon="Visibility"
                        className={classNames({ 'd-none': !item.recipe || !userCan('show', 'recipe') })}
                    />

                    <Button
                        onClick={() => {
                            if (item.recipe) {
                                setRecipeId(item.recipe.id);
                                setOpenEditRecipeModal(true);
                            }
                        }}
                        title='Editar receta'
                        size='sm'
                        icon="Edit"
                        className={classNames({ 'd-none': !item.recipe || !userCan('create', 'recipe') })}
                    />

                    <Button
                        onClick={() => deleteMenuItem(item.id)}
                        title='Eliminar item'
                        size='sm'
                        icon="Close"
                    />
                </div>
            </div>
        </div>
    );
}

export default DraggableMenuItem;