import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { CiApple } from "react-icons/ci";
import { GiForkKnifeSpoon } from "react-icons/gi";
import { LiaTemperatureLowSolid } from "react-icons/lia";
import { PiChefHatDuotone } from "react-icons/pi";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import Allergens from "../../../../components/Allergens";
import AsyncImg from "../../../../components/AsyncImg";
import ErrorMessage from "../../../../components/ErrorMessage";
import Accordion, { AccordionItem } from "../../../../components/bootstrap/Accordion";
import { CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader } from "../../../../components/bootstrap/Modal";
import { Loader } from "../../../../components/bootstrap/SpinnerLogo";
import PlaceholderImage from "../../../../components/extras/PlaceholderImage";
import Icon from "../../../../components/icon/Icon";
import { macronutrients } from "../../../../components/menu/NutritionalMenuValues";
import useAllergens from "../../../../hooks/api-calls/useAllergens";
import useFetch from "../../../../hooks/useFetch";
import { RecipeService } from "../../../../services/recipes/recipeService";
import { Recipe } from "../../../../type/recipes-type";
import { FixNumber } from "../../../../utils/FixNumber";
import { COLORS } from "../../../../utils/mapping-collection";
import { ValuesTable } from "../../../recipes/recipe-view/RecipeView";

interface RecipeViewModalProps {
    id: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const RecipeViewModal: React.FC<RecipeViewModalProps> = ({ id, isOpen, setIsOpen }) => {

    const { groupNutrients } = useAllergens();

    const [nutrientsGroups, setNutrientsGroups] = useState<any>([]);

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = (await (new RecipeService()).getRecipeById(id as string, null));
        return response.getResponseData() as Recipe;
    }, [id]));

    const [nutritionalValues] = useFetch(useCallback(async () => {
        const response = (await (new RecipeService()).getNutritionalValues(id));
        return response.getResponseData();
    }, [id]));

    // Renderiza las etiquetas de la gráfica de los macronutrientes
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        // Obtiene los nutrientes de los macronutrientes
        const data = nutrientsGroups.find((group: any) => group.group === 'Macronutrients')?.nutrients?.filter((nutrient: any) => macronutrients.includes(nutrient.name));

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
        const name = data[index]?.name === 'starch' ? 'HC' : data[index]?.nombre.replace(/\(\w+\)/, '');
        const value = data[index]?.value;
        const unitMeasure = data[index]?.nombre.match(/\(([^)]+)\)/)[1];

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {/* <tspan x={x < cx ? x + 22 : x - 22} dy="0em">{name}</tspan> */}
                <tspan x={x < cx ? x + 22 : x - 22} dy="0em">{`${value} ${unitMeasure}`}</tspan>
            </text>
        );
    };

    useEffect(() => {
        if (nutritionalValues) {
            setNutrientsGroups(groupNutrients(nutritionalValues));
        }
    }, [nutritionalValues]);

    if (loading) return <Loader height='100vh' />;
    if (error) return <ErrorMessage />;
    if (!data) return null;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Información de la receta'>
            <ModalHeader setIsOpen={setIsOpen} className="p-4">
                <CardLabel title="Nombre" className="bg-secondary text-white rounded py-3 px-5" style={{ width: 'fit-content' }}>
                    <CardTitle>{data.name}</CardTitle>
                </CardLabel>
            </ModalHeader>
            <ModalBody className="row d-flex justify-content-between">
                <div className="col-md-7 pe-4">
                    <>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                {data?.RecipeImage?.id
                                    ? <AsyncImg id={data.RecipeImage.id} isBackground styles="d-block img-fluid rounded object-cover" />
                                    : <PlaceholderImage width={300} height={200} className='d-block img-fluid rounded' />
                                }
                            </div>

                            <div className="col-md-6">
                                <ul className="fs-5">
                                    <li className="d-flex align-items-center">
                                        <Icon icon='AccessTime' size={'2x'} className="me-3" />
                                        {data.duration} minutos
                                    </li>

                                    <li className="d-flex align-items-center mt-2">
                                        <PiChefHatDuotone className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        {data.difficulty}
                                    </li>

                                    <li className={classNames("d-flex align-items-center mt-2", { 'd-none': !data.foodGroup })}>
                                        <CiApple className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        {data.foodGroup.name}
                                    </li>

                                    <li className={classNames("d-flex align-items-center mt-2", { 'd-none': !data.presentation })}>
                                        <GiForkKnifeSpoon className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        {data.presentation === 'whole' && 'Entero/Plato completo'}
                                        {data.presentation === 'slicing' && 'Troceado/Porciones/Unidades'}
                                        {data.presentation === 'cream' && 'Crema/Puré'}
                                    </li>

                                    <li className={classNames("d-flex align-items-center mt-2", { 'd-none': !data.storageConditions })}>
                                        <LiaTemperatureLowSolid className="svg-icon--material svg-icon svg-icon-2x me-3" />
                                        {data.storageConditions === 'cold' ? 'Tª aprox. 4ºC' : 'Superior a Tª 65ºC'}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>

                    <div className="mt-5">
                        <div className="d-flex">
                            <CardLabel title="Nombre" className="bg-secondary-light rounded py-3 px-5 me-3" style={{ width: 'fit-content' }}>
                                <CardTitle>Ingredientes</CardTitle>
                            </CardLabel>
                            <Allergens recipe={data} />
                        </div>
                        <ul className="row d-flex justify-content-between border rounded border-secondary list-unstyled p-3 mt-3">
                            {data.ingredients?.map((ingredient: any, index: number) => (
                                <li key={index} className="col-md-5">
                                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                        <span>{ingredient.food?.name}</span>
                                        <span>{ingredient.quantity > 999 ? `${(ingredient.quantity / 1000).toFixed(2)} kg` : `${ingredient.quantity} g`}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mt-5">
                        <CardLabel title="Nombre" className="bg-secondary-light rounded py-3 px-5" style={{ width: 'fit-content' }}>
                            <CardTitle>Elaboración</CardTitle>
                        </CardLabel>
                        <ol className="row border rounded border-secondary list-unstyled fs-5 p-3 mt-3">
                            {data.process?.map((step: any, index: number) => (
                                <li key={index} className="mb-3">
                                    <span className="fw-bold">{index + 1}.</span> {step}
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>

                <div className="col-md-5">
                    <CardLabel title="Nombre" className="bg-secondary-light rounded py-3 px-5" style={{ width: 'fit-content' }}>
                        <CardTitle>Información nutricional</CardTitle>
                    </CardLabel>

                    {(nutrientsGroups.length > 0) && (
                        <Accordion id={"nutrients-group"} className="mt-3" shadow={'none'}>
                            {nutrientsGroups.map((nutrientGroup: any) => {
                                let data;
                                if (nutrientGroup.group === 'Macronutrients') {
                                    data = nutrientGroup.nutrients.filter((nutrient: any) => macronutrients.includes(nutrient.name));
                                }

                                data = data?.map((nutrient: any) => {
                                    return {
                                        name: nutrient.nombre,
                                        value: nutrient.value,
                                    }
                                });

                                return (
                                    <AccordionItem
                                        key={`group-${nutrientGroup.group}`}
                                        id={nutrientGroup.group}
                                        title={nutrientGroup.group_name}
                                        className={"mb-4"}
                                    >
                                        {nutrientGroup.group === 'Macronutrients' && data?.length > 0 && data.reduce((acc: number, nutrient: any) => acc + nutrient.value, 0) > 0
                                            ? (
                                                <>
                                                    <ResponsiveContainer width='100%' height={180}>
                                                        <div className="d-flex flex-row">
                                                            <PieChart width={180} height={180}>
                                                                <Pie
                                                                    data={data.filter((nutrient: any) => nutrient.value > 0)}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={80}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry: any, index: number) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % data.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>

                                                            <div className="d-flex flex-column justify-content-center ml-4">
                                                                {data.map((entry: any, index: number) => (
                                                                    <div key={`legend-${index}`} className="d-flex flex-row align-items-center">
                                                                        <div style={{ width: '10px', height: '10px', backgroundColor: COLORS[index % data.length] }}></div>
                                                                        <div className="ms-2">{entry.name === 'HC complejos (g)' ? 'HC (g)' : entry.name}:</div>
                                                                        <div className="ms-2">{FixNumber(entry.value)}</div>
                                                                    </div>
                                                                ))}
                                                                <div className="text-center">Energía: {Math.round(nutrientGroup.nutrients.find((nutrient: any) => nutrient.name === 'energy')?.value)} Kcal</div>
                                                            </div>
                                                        </div>
                                                    </ResponsiveContainer>

                                                    <ValuesTable nutrientGroup={nutrientGroup} />
                                                </>
                                            )
                                            : <ValuesTable nutrientGroup={nutrientGroup} />
                                        }
                                    </AccordionItem>
                                )
                            })}
                        </Accordion>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default RecipeViewModal;