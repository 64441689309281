import React from 'react';
import PaymentMethodList from './paymentMethods/PaymentMethodList';
import EconomicAvtivityList from './economicAvtivities/EconomicAvtivityList';

interface GCAccountingProps { }

const GCAccounting: React.FC<GCAccountingProps> = ({ }) => {
    return (
        <div className="row gap-4">
            <PaymentMethodList />
            <EconomicAvtivityList />
        </div>
    );
};

export default GCAccounting;