import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import useFilters from '../../hooks/useFilters';
import { ActivitiesService } from '../../services/activities/activitiesService';
import { FoodService } from '../../services/foods/foodService';
import { RecipeService } from '../../services/recipes/recipeService';
import { activityFilters, foodFilters, recipeFilters, supplementFilters } from '../../type/menu-type';
import { removeAccents } from '../../utils/StringUtils';
import CustomSearchInput from '../bootstrap/forms/CustomSearchInput';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import DraggableItem from './DraggableItem';
import FoodFilters from './Filters/FoodFilters';
import RecipeFilters from './Filters/RecipeFilters';
import { useMenuDataProvider } from './providers/MenuDataProvider';
import './styles/styles.scss';

interface ItemSelectorsProps { }

const ItemSelectors: React.FC<ItemSelectorsProps> = ({ }) => {

    // STATES

    const [searchFoodValue, setSearchFoodValue] = useState("");
    const [searchRecipeValue, setSearchRecipeValue] = useState("");
    const [searchPhysicalActivityValue, setSearchPhysicalActivityValue] = useState("");

    const [searchRecipeResults, setSearchRecipeResults] = useState<any[]>([]);
    const [searchPhysicalActivityResults, setSearchPhysicalActivityResults] = useState<any[]>([]);
    const [searchFoodResults, setSearchFoodResults] = useState<any[]>([]);
    const [searchSupplementResults, setSearchSupplementResults] = useState<any[]>([]);

    const [foodQuantity, setFoodQuantity] = useState<number>(100);
    const [physicalActivityDuration, setPhysicalActivityDuration] = useState<number>(30);

    // HOOKS

    const { showRecipeSelectors, showFoodSelectors, showSupplementSelectors, showPhysicalActivitySelectors } = useMenuDataProvider();
    const { filters, updateFilters, resetFilters } = useFilters(foodFilters);
    const { filters: filtersSupplements } = useFilters(supplementFilters);
    const { filters: filtersRecipes, updateFilters: updateFiltersRecipe, resetFilters: resetFiltersRecipe } = useFilters(recipeFilters);
    const { filters: filtersPhysicalActivities, updateFilters: updateFiltersPhysicalActivities } = useFilters(activityFilters);

    // FETCH DATA

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Hook que obtiene los alimentos de la API
     * @EN Hook that gets the foods from the API
     * 
     * @returns void
     */
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [foodsData] = useFetch(useCallback(async () => {
        const response = await new FoodService().getAliments({ ...filters, limit: 50 });
        return response.getResponseData();
    }, [filters]));
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Hook que obtiene los suplementos de la API
     * @EN Hook that gets the supplements from the API
     * 
     * @returns void
     */
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [supplementsData] = useFetch(useCallback(async () => {
        const response = await new FoodService().getAliments({ ...filtersSupplements, limit: 50 });
        return response.getResponseData();
    }, [filtersSupplements]));
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Hook que obtiene las recetas de la API
     * @EN Hook that gets the recipes from the API
     * 
     * @returns void
     */
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [recipesData] = useFetch(useCallback(async () => {
        const response = await new RecipeService().getRecipes({ ...filtersRecipes, limit: 50 });
        return response.getResponseData();
    }, [filtersRecipes]));
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Hook que obtiene las actividades físicas de la API
     * @EN Hook that gets the physical activities from the API
     * 
     * @returns void
     */
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [physicalActivitiesData] = useFetch(useCallback(async () => {
        const response = await new ActivitiesService().listActivities({ ...filtersPhysicalActivities, limit: 50 });
        return response.getResponseData();
    }, [filtersPhysicalActivities]));
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Función que filtra los resultados
     * @EN Function that filters the results
     * 
     * @param data 
     * @param searchValue 
     * @param key 
     * 
     * @returns any[]
     */
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const filterResults = (data: any[], searchValue: string, key: string) => {
        return data.filter((item: any) =>
            searchValue
                .toLowerCase()
                .split(' ')
                .every((searchWord: string) =>
                    item[key]
                        .toLowerCase()
                        .split(' ')
                        .some((itemWord: string) => removeAccents(itemWord).includes(removeAccents(searchWord)))
                )
        );
    };
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Función que renderiza los items
     * @EN Function that renders the items
     * 
     * @param items 
     * @param isFood 
     * @param isRecipe 
     * @param isActivity 
     * @param quantity 
     * 
     * @returns JSX.Element
     */
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const renderItems = (items: any[], isFood = false, isRecipe = false, isActivity = false, quantity = 0) => {
        return (
            <div className="row itemsCard-container d-flex justify-content-around p-2">
                {items.map((element, index) => {
                    const { label, data } = element;
                    return (
                        <DraggableItem
                            key={index}
                            label={label}
                            data={data}
                            food={isFood}
                            recipe={isRecipe}
                            activity={isActivity}
                            quantity={(isFood || isActivity) ? quantity : undefined}
                            fromSelectors={isFood}
                            foodGroup={data?.foodGroup?.name}
                            foodGroupId={data?.foodGroup?.id}
                            foodGroupColor={data?.foodGroup?.color}
                        />
                    );
                })}
            </div>
        );
    };
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

    // USE EFFECTS

    useEffect(() => {
        const timer = setTimeout(() => {
            const filteredResults = filterResults(recipesData?.recipes || [], searchRecipeValue, 'name');
            setSearchRecipeResults(
                filteredResults.map((result: any) => ({
                    label: result.name,
                    data: result,
                }))
            );
        }, 1000);
        return () => clearTimeout(timer);
    }, [searchRecipeValue, recipesData]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const filteredResults = filterResults(physicalActivitiesData?.physical_activities || [], searchPhysicalActivityValue, 'name');
            setSearchPhysicalActivityResults(
                filteredResults.map((result: any) => ({
                    label: result.name,
                    data: result,
                }))
            );
        }, 1000);
        return () => clearTimeout(timer);
    }, [searchPhysicalActivityValue, physicalActivitiesData]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const filteredResults = filterResults(foodsData?.data || [], searchFoodValue, 'name');
            setSearchFoodResults(
                filteredResults.map((result: any) => ({
                    label: result.name,
                    data: result,
                }))
            );
        }, 1000);
        return () => clearTimeout(timer);
    }, [searchFoodValue, foodsData]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const filteredResults = filterResults(supplementsData?.data || [], searchFoodValue, 'name');
            setSearchSupplementResults(
                filteredResults.map((result: any) => ({
                    label: result.name,
                    data: result,
                }))
            );
        }, 1000);
        return () => clearTimeout(timer);
    }, [searchFoodValue, supplementsData]);

    // RENDER

    return (
        <div className='row mx-0 py-2'>
            <div className={classNames({ 'd-none': !showRecipeSelectors })}>
                <div className='row p-0'>
                    <FormGroup label='Nombre' className='col-md-2'>
                        <CustomSearchInput
                            onSearch={(search: string) => {
                                updateFiltersRecipe({ search_array: search });
                                setSearchRecipeValue(search);
                            }}
                            placeholder='Buscar receta ...'
                        />
                    </FormGroup>
                    <div className='col-md-10'>
                        <RecipeFilters updateFilters={updateFiltersRecipe} resetFilters={resetFiltersRecipe} />
                    </div>
                </div>
                {renderItems(searchRecipeResults, false, true)}
            </div>

            <div className={classNames({ 'd-none': !showFoodSelectors })}>
                <div className='row p-0'>
                    <FormGroup label='Nombre' className='col-md-2'>
                        <CustomSearchInput
                            onSearch={(search: string) => {
                                updateFilters({ search_array: search });
                                setSearchFoodValue(search);
                            }}
                            placeholder='Buscar alimento ...'
                        />
                    </FormGroup>
                    <FormGroup label='Cantidad (g)' className='col-md-2'>
                        <Input
                            type='number'
                            value={foodQuantity}
                            onChange={(e: any) => setFoodQuantity(e.target.value)}
                            className='w-50'
                        />
                    </FormGroup>
                    <div className='col-md-8'>
                        <FoodFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
                    </div>
                </div>
                {renderItems(searchFoodResults, true, false, false, foodQuantity)}
            </div>

            <div className={classNames({ 'd-none': !showSupplementSelectors })}>
                <div className='row p-0'>
                    <FormGroup label='Nombre' className='col-md-4'>
                        <CustomSearchInput
                            onSearch={(search: string) => {
                                updateFilters({ search_array: search });
                                setSearchFoodValue(search);
                            }}
                            placeholder='Buscar suplemento ...'
                        />
                    </FormGroup>
                    <FormGroup label='Cantidad (g)' className='col-md-2'>
                        <Input
                            type='number'
                            value={foodQuantity}
                            onChange={(e: any) => setFoodQuantity(e.target.value)}
                            className='w-50'
                        />
                    </FormGroup>
                </div>
                {renderItems(searchSupplementResults, true)}
            </div>

            <div className={classNames({ 'd-none': !showPhysicalActivitySelectors })}>
                <div className='row p-0'>
                    <FormGroup label='Nombre' className='col-md-3'>
                        <CustomSearchInput
                            onSearch={(search: string) => {
                                updateFiltersPhysicalActivities({ search_array: search });
                                setSearchPhysicalActivityValue(search);
                            }}
                            placeholder='Buscar ejercicio ...'
                        />
                    </FormGroup>
                    <FormGroup label='Cantidad (min)' className='col-md-2'>
                        <Input
                            type='number'
                            value={physicalActivityDuration}
                            onChange={(e: any) => setPhysicalActivityDuration(e.target.value)}
                            className='w-50'
                        />
                    </FormGroup>
                </div>
                {renderItems(searchPhysicalActivityResults, false, false, true, physicalActivityDuration)}
            </div>
        </div>
    );
};

export default ItemSelectors;