import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import useFetch from "../../../hooks/useFetch";
import { PatientService } from "../../../services/patients/patientService";
import { ProfileService } from "../../../services/profile/profileService";
import { UserService } from "../../../services/users/userService";
import { Patient } from "../../../type/patients-type";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import PatientForm from "../PatientForm";

const PatientEdit: FC = () => {

  const { id = '' } = useParams<{ id: string }>();
  const { handleErrors } = useHandleErrors();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [userProfile] = useFetch(useCallback(async () => {
    const response = await (new ProfileService()).getMyProfile();
    return response.getResponseData();
  }, []));

  const [data, loading, error] = useFetch(useCallback(async () => {
    const response = (await (new PatientService()).getPatientById(id as string));
    return response.getResponseData() as Patient;
  }, [id]));

  const changePassword = async (password: string, confirmPassword: string) => {
    try {
      const response = await (new UserService()).changePassword(id, password, confirmPassword);
      const responseData = response.getResponseData();

      if (!responseData.success) {
        handleErrors(responseData);
      }
    }
    catch (error: any) {
      toast.error("Error al cambiar la contraseña");
    }
  };

  const handleUpdate = async (values: any) => {
    setIsLoading(true);
    values.client = id; // asignar el id del paciente al objeto values
    !values.user && (values.user = userProfile?.id); // asignar el id del usuario al objeto values

    if (values.password !== "" && values.passwordConfirm !== "" && values.password === values.passwordConfirm) {
      await changePassword(values.password, values.passwordConfirm);
    }

    try {
      const response = await (new PatientService()).editPatient(values);
      const responseData = response.getResponseData();

      if (responseData.success) {
        navigate(-1);
        toast.success('Paciente editado correctamente');
      } else {
        handleErrors(responseData);
      }
    } catch (error: any) {
      toast.error("Error al editar el paciente");
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) return <Loader />;

  if (error) return <ErrorMessage />;

  if (!data) return null;

  const patientData = {
    user: data.user !== null ? data.user?.id : "",
    name: data.name !== null ? data.name : "",
    last_name: data.lastName !== null ? data.lastName : "",
    email: data.email !== null ? data.email : "",
    dni: data.dni !== null ? data.dni : "",
    sex: data.sex !== null ? data.sex : "",
    province: data.province !== null ? data.province : "",
    city: data.city !== null ? data.city : "",
    pc: data.pc !== null ? data.pc : "",
    address: data.address !== null ? data.address : "",
    telephone: data.telephone !== null ? data.telephone : "",
    company: data.clientRoles[0].company !== null ? data.clientRoles[0].company.id : "",
    password: "",
    country: data.country !== null ? data.country : "",
    passwordConfirm: "",
    birthday_date: data.birthdayDate !== null ? data.birthdayDate.date.slice(0, 10) : "",
    clientAversions: data.clientAversions !== null ? data.clientAversions : [],
    allergens: [] as string[],
    food_aversions: data.clientAversions !== null ? data.clientAversions.filter((item: any) => item.food).map((item: any) => item.food.id) : [],
    group_aversions: data.clientAversions !== null ? data.clientAversions.filter((item: any) => item.foodGroup).map((item: any) => item.foodGroup.id) : [],
    workplaces: data.workPlaces !== null ? data.workPlaces?.map((w: any) => w.workPlace?.id) : [],
    pathologies: data.clientHasPathologies !== null ? data.clientHasPathologies?.map((item: any) => item.pathology.id) : [],
    intolerances: data.intolerances !== null ? data.intolerances?.map((item: any) => item.intolerance.id) : [],
    allergies: data?.allergies !== null ? data.allergies?.map((item: any) => item.allergy.id) : [],
    physiologicalState: data.clientHasPhysiologicalStates !== null ? data.clientHasPhysiologicalStates[0]?.physiologicalState.id : "",
    weight: data.weight !== null ? data.weight : "",
    height: data.height !== null ? data.height : "",
  };

  return <PatientForm isLoading={isLoading} submit={handleUpdate} patientData={patientData} profileImg={data.profileImg} />;
};

export default PatientEdit;