import classNames from "classnames";
import moment from "moment";
import { FC } from "react";
import AsyncImg from "../../../../components/AsyncImg";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import PlaceholderImage from "../../../../components/extras/PlaceholderImage";

interface PatientHeaderProps {
    data: any;
}

const PatientHeader: FC<PatientHeaderProps> = ({ data }) => {
    return (
        <div className="sticky-top">
            <Card className='shadow-3d-primary'>
                <CardBody>
                    <div className='row sticky-top'>
                        <div className='col-md-2'>
                            {data?.profileImg
                                ? <AsyncImg id={data.profileImg?.id} isBackground styles="mx-auto d-block img-fluid rounded" style={{ width: '120px', height: '120px', objectFit: 'cover' }} />
                                : <PlaceholderImage width={120} height={120} className='mx-auto d-block img-fluid rounded' />
                            }
                        </div>

                        <div className='col-md-10'>
                            <div className="row">
                                <div className='w-auto ms-3'>
                                    <div className='text-muted'>
                                        Nombre y apellidos
                                    </div>
                                    <div className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                        {data?.name + ' ' + (data?.lastName ? data.lastName : '')}
                                    </div>
                                </div>

                                <div className='w-auto ms-3'>
                                    <div className='text-muted'>
                                        Correo electrónico
                                    </div>
                                    <div className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                        {data?.email ? data.email : 'No proporcionado'}
                                    </div>
                                </div>

                                <div className='w-auto ms-3'>
                                    <div className='text-muted'>
                                        Teléfono
                                    </div>
                                    <div className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                        {data?.telephone ? data.telephone.slice(0, 3) + ' ' + data.telephone.slice(3) : '-'}
                                    </div>
                                </div>

                                <div className='w-auto ms-3'>
                                    <div className='text-muted'>
                                        Edad
                                    </div>
                                    <div className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                        {moment().diff(data?.birthdayDate?.date, 'years')}
                                    </div>
                                </div>

                                <div className='w-auto ms-3'>
                                    <div className='text-muted'>
                                        Dirección, provincia, localidad y país
                                    </div>
                                    <div className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                        {`${data?.address ? data.address : '- '}${data?.province ? ', ' + data.province : '- '}${data?.city ? ', ' + data.city : '- '}${data?.country ? ', ' + data.country : '-'}`}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.clientAversions.length < 1 })}>
                                    <span className='text-muted'>Aversiones</span>
                                    {data?.clientAversions?.map((aversion: any) => (
                                        <li key={aversion.id} style={{ fontSize: '12px' }}>
                                            {aversion.foodGroup ? aversion.foodGroup?.name : aversion.food?.name}
                                        </li>
                                    ))}
                                </ul>

                                <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.intolerances.length < 1 })}>
                                    <span className='text-muted'>Intolerancias</span>
                                    {data?.intolerances?.map((allergy: any) => (
                                        <li key={allergy.id} style={{ fontSize: '12px' }}>
                                            {allergy.intolerance?.name}
                                        </li>
                                    ))}
                                </ul>

                                <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.allergies?.length < 1 })}>
                                    <span className='text-muted'>Alergias</span>
                                    {data?.allergies?.map((allergy: any) => (
                                        <li key={allergy.id} style={{ fontSize: '12px' }}>
                                            {allergy.allergy?.name}
                                        </li>
                                    ))}
                                </ul>

                                <ul className={classNames("list-inline w-auto ms-3", { "d-none": data?.clientHasPathologies.length < 1 })}>
                                    <span className='text-muted'>Patologías</span>
                                    {data?.clientHasPathologies?.map((p: any) => (
                                        <li key={p.pathology.id} style={{ fontSize: '12px' }}>
                                            {p.pathology?.name}
                                        </li>
                                    ))}
                                </ul>

                                <div className={classNames("d-flex flex-column w-auto ms-3", { "d-none": data?.clientHasPhysiologicalStates?.length < 1 })}>
                                    <span className='text-muted'>Estado fisiológico</span>
                                    <span style={{ fontSize: '12px' }}>{data?.clientHasPhysiologicalStates[0]?.physiologicalState?.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div >
    )
}

export default PatientHeader;