import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const USER_ENDPOINT = '/users';

export class UserService extends RestServiceConnection {

    createUser = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/create',
            data: user,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    getUsers = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/list',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    getUserById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/get',
            data: { user: id },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleUserStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/toggle',
            data: {
                user: id,
                active: status
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    addUserDocument = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/add-document',
            data: data,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }, true);
        return this;
    }

    editUser = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/edit',
            data: user,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }, true);
        return this;
    }

    changePassword = async (entityId: string, password: string, confirmPassword: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/change-password',
            data: {
                entityId: entityId,
                password: password,
                password_confirmation: confirmPassword
            },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editUserImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('user', id);
        formData.append('profile_img', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteUserImg = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/delete-image',
            data: {
                user: id
            }
        }, true);
        return this;
    }

    deleteUser = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/delete',
            data: {
                user: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    editUserPermissions = async (user: string, company: string | null, permissions: number[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/edit-permissions',
            data: {
                user: user,
                company: company,
                permissions: permissions
            }
        }, true);
        return this;
    }

    getAdminInfo = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/roles/company',
        }, true);
        return this;
    }

    listDocuments = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/list-documents',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    uploadDocument = async (formData: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/upload-document',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    downloadDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/download-document',
            data: {
                document: id
            }
        }, true);
        return this;
    }

    deleteDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/delete-document',
            data: {
                document: id
            }
        }, true);
        return this;
    }

}