import { useFormik } from "formik";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import * as yup from 'yup';
import Button from "../../../../../components/bootstrap/Button";
import Spinner from "../../../../../components/bootstrap/Spinner";
import { useEffect, useState } from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";
import SearchableSelect from "../../../../../components/select/SearchableSelect";
import usePaymentMenthods from "../../../../../hooks/api-calls/usePaymentMethods";
import useHandleErrors from "../../../../../utils/hooks/useHandleErrors";
import { AppointmentService } from "../../../../../services/appointments/appointmentService";
import { toast } from "react-toastify";

const AppointmentModalPaymentMethodFormSchema = yup.object({
    paymentMethod: yup.string().optional(),
    price: yup.number().min(0, 'El precio mínimo es 0€').max(1000000, 'El precio máximo es 1.000.000€').optional(),
});

interface AppointmentModalPaymentMethodProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    setSelectedAppointmentId?: any;
    appointmentId?: any;
    refetchData?: any;
}

const AppointmentModalPaymentMethod: React.FC<AppointmentModalPaymentMethodProps> = ({ isOpen, setIsOpen, setSelectedAppointmentId, appointmentId, refetchData }) => {

    // STATE

    const [isLoading, setIsLoading] = useState(false);
    const appointmentService = new AppointmentService();

    // HOOKS

    const { getPaymentMethodsOptions } = usePaymentMenthods();
    const { handleErrors } = useHandleErrors();

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
    * @EN CREATE APPOINTMENT
    * @ES CREAR CITA
    * 
    * @param values 
    */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const updatePaymentMethod = async (values: any) => {
        setIsLoading(true);
        values.appointment = appointmentId;
        try {
            const response = (await appointmentService.updatePaymentMethodPatient(values)).getResponseData();
            if (response.success) {
                toast.success('Cita pagada correctamente');
                setSelectedAppointmentId(null);
                setIsOpen(false);
                refetchData();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error('Error al crear la cita');
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik({
        validationSchema: AppointmentModalPaymentMethodFormSchema,
        initialValues: {
            paymentMethod: '',
            paymentMethodLabel: '',
            amount: 0,
        },
        onSubmit: async (values) => {
            updatePaymentMethod(values);
        }
    });

    // VALIDATION FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN VERIFY IF THE FIELD HAS ERRORS
     * @ES VERIFICAR SI EL CAMPO TIENE ERRORES
     * 
     * @param inputFieldID 
     * @returns 
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const verifyClass = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @EN SHOW ERRORS
     * @ES MOSTRAR ERRORES
     * 
     * @param inputFieldID 
     * @returns 
     */
    //-------------------------------------------------------------------------------------------------------------------------------------------
    const showErrors = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------

    // USE EFFECT

    useEffect(() => {
        formik.resetForm();
    }, [isOpen]);
    
    // RENDER

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='sm' titleId='Datos de la cita' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id='appointment-data'>Pago de la cita</ModalTitle>
            </ModalHeader>
            <ModalBody className='pb-4'>
                <form onSubmit={formik.handleSubmit} autoComplete="off" className='row g-4'>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <FormGroup className="mb-3" label="Método de pago" requiredInputLabel>
                                <SearchableSelect
                                    name="paymentMethod"
                                    isSearchable
                                    value={{ value: formik.values.paymentMethod, label: formik.values.paymentMethodLabel }}
                                    options={getPaymentMethodsOptions()}
                                    onChange={(option: any) => {
                                        formik.setFieldValue('paymentMethod', option?.value)
                                        formik.setFieldValue('paymentMethodLabel', option?.label)
                                    }}
                                    onBlur={formik.handleBlur}
                                    classname={verifyClass('paymentMethod')}
                                    placeholder="Método de pago"
                                />
                                {showErrors('paymentMethod')}
                            </FormGroup>
                        </div>
                        <div className="col-md-12">
                            <FormGroup className="mb-3" label="Importe (€)" requiredInputLabel>
                                <Input
                                    id="amount"
                                    type='number'
                                    value={formik.values.amount || 0}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={verifyClass('amount')}
                                />
                                {showErrors('amount')}
                            </FormGroup>
                        </div>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <div className='d-flex justify-content-end text-right relative'>
                    <Button color='primary' className="flex-grow-1" type='submit' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color='dark' /> : 'Guardar'}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
} 

export default AppointmentModalPaymentMethod;