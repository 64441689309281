import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../../components/ConfirmationAlert";
import StatusDropdown from "../../../../components/StatusDropdown";
import Button from "../../../../components/bootstrap/Button";
import { CardBody } from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Spinner from "../../../../components/bootstrap/Spinner";
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import SearchableSelect from "../../../../components/select/SearchableSelect";
import { CustomTable } from "../../../../components/table/CustomTable";
import useFetch from "../../../../hooks/useFetch";
import useFilters from "../../../../hooks/useFilters";
import { TipsGroupsService } from "../../../../services/tips/groupsService";
import { TipsService } from "../../../../services/tips/tipsService";
import { TipsWorkClientService } from "../../../../services/tipsWorkClient/tipsWorkClientService";
import ListPatientTipsFilters from "./ListPatientTipsFilters";

interface PatientTipsProps {
}

interface ITipsFilters {
    tipGroup?: string;
    client?: string;
}

const ListPatientTips: FC<PatientTipsProps> = ({ }) => {
    const { id = '' } = useParams<{ id: string }>();

    const tipsWorkService = new TipsWorkClientService();
    const tipsService = new TipsService();
    const tipsGroupsService = new TipsGroupsService

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedTips, setSelectedTips] = useState<string[]>([]);
    const [filterMenu, setFilterMenu] = useState(false);


    const tipsFilters: ITipsFilters = {
        client: id
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tipsFilters);

    const [tips, loadingTips, tipsError, refetchTips] = useFetch(useCallback(async () => {
        const response = (await tipsWorkService.listTipsWorkClient(filters));
        return response.getResponseData();
    }, [filters]));

    const [alltips] = useFetch(useCallback(async () => {
        const response = (await tipsService.listTips(filters));
        return response.getResponseData();
    }, [filters]));

    const [groupsData] = useFetch(useCallback(async () => {
        const response = (await tipsGroupsService.listGroups());
        return response.getResponseData();
    }, []));

    const toggleTipWorkClientStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await tipsWorkService.toggleTipWork(id)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                refetchTips();
                toast.info(status ? 'Consejo trabajado' : 'Consejo sin trabajar');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message || 'Error al cambiar el estado del consejo');
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message || 'Error al cambiar el estado del consejo');
        }
    };

    const _handleExport = async (documentId: string, documentName: string) => {
        try {
            const response = (await tipsService.downloadDocument(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _handleDelete = async (id: string) => {
        try {
            const response = (await tipsWorkService.deleteTipWorkClient(id)).getResponseData();
            if (response.success) {
                refetchTips();
                toast.info('Consejo eliminado del paciente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al quitar el consejo al paciente');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al quitar el consejo al paciente');
        }
    };

    const _handleAssignTipsToPatient = async (tips?: any, tipGroup?: any) => {
        setIsLoading(true);
        try {
            const response = await tipsService.assignTipsToPatient(id, tips, tipGroup);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetchTips();
            } else {
                toast.error(responseData.message || "Error al asignar el consejo/s al paciente");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsOpen(false);
            setSelectedTips([]);
            setSelectedGroups([]);
            setIsLoading(false);
        }
    }

    const _handleDeleteGroupFromPatient = async (tipGroupId: string, client: string) => {
        setIsLoading(true);
        try {
            const response = await tipsGroupsService.deleteGroupFromPatient(tipGroupId, client);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetchTips();
            } else {
                toast.error(responseData.message || "Error al eliminar el grupo de consejos del paciente");
            }
        } catch (error: any) {
            toast.error(error.message);
        }finally {
            setIsOpenDelete(false);
            setSelectedGroups([]);
            setIsLoading(false);
        }
    }

    const getTipGroupOptions = () => {
        if (groupsData) {
            return groupsData.tip_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const getTipOptions = () => {
        if (alltips) {
            return alltips.tips
                .filter((tip: any) => {
                    let tipIsInGroup = (!selectedGroups[0] || tip?.tipGroups.some((tipHasTipGroups:any) => tipHasTipGroups.tipGroup.id === selectedGroups[0]) );
                    let clientNoHasTip = !tips?.tips_work_clients?.find((tipWork: any) => tipWork.tip.id === tip.id);
                    return tipIsInGroup && clientNoHasTip
                })
                .map((option: { id: any; name: any; }) => {
                    return {
                        value: option.id,
                        label: option.name
                    }
                })
        }
        return [];
    }

    // Actualizar los filtros cada vez que se cambie el id del paciente
    useEffect(() => {
        updateFilters({ client: id });
    }, [id]);

    const getContent = () => {
        return (
            <>
                <CustomTable
                    data={tips ? tips.tips_work_clients : []}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: tips ? tips.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Grupo",
                            keyValue: "tipGroup",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div key={element.id}>
                                        {/* show all tipGroups with badge */}
                                        {element?.tip?.tipGroups?.map((tipGroupRelation: any) => (
                                            <span key={tipGroupRelation.tipGroup.id} style={{fontSize: '11px'}} className="badge bg-secondary bg-opacity-75 m-1 p-2">{tipGroupRelation.tipGroup.name}</span>
                                        ))}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element?.tip?.name || "-"}
                                    </>
                                )
                            }
                        },

                        {
                            name: "Descripción",
                            keyValue: "description",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element?.tip?.description || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Documento",
                            keyValue: "document",
                            render: (element: any) => {
                                return (
                                    <div
                                        className={element?.tip?.tipDocuments[0]?.document?.id ? "cursor-pointer text-secondary" : "text-muted"}
                                        onClick={() => {
                                            if (element?.tip?.tipDocuments[0]?.document.id) {
                                                _handleExport(element.tip?.tipDocuments[0]?.document.id, element?.tip?.tipDocuments[0]?.document.originalName);
                                            }
                                        }}
                                    >
                                        {element?.tip?.tipDocuments[0]?.document.originalName || "No tiene documento"}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Estado",
                            keyValue: "worked",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className={'text-center'} key={element.id}>
                                        <StatusDropdown
                                            disabled={changingStatus.includes(element.id)}
                                            itemId={element.id}
                                            status={element?.worked ?? false}
                                            change={toggleTipWorkClientStatus}
                                            btnClass="btnNoBg"
                                            labelActive="Trabajado"
                                            labelInactive="Sin trabajar"
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Fecha Trabajado",
                            keyValue: "updatedAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element?.updatedAt?.date ? moment(element.updatedAt?.date).format('DD/MM/YYYY') : "Sin trabajar"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Fecha",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt?.date).format('DD/MM/YYYY') || "-"}
                                    </>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Descargar documento",
                            icon: "Article",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Descargar documento",
                            click: (item: any) => {
                                if (item?.tip?.tipDocuments?.length > 0) {
                                    _handleExport(item?.tip?.tipDocuments[0]?.document?.id, item?.tip?.tipDocuments[0]?.document.originalName);
                                } else {
                                    toast.info('No hay documento para descargar');
                                }
                            }
                        },
                        {
                            title: "Quitar",
                            icon: "Close",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Quitar consejo",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Quitar consejo",
                                    text: "¿Está seguro que desea quitar el consejo al paciente?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        _handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            </>
        )
    }

    // MODAL TO ADD TIPS TO PATIENT CONTENT WITH FORM
    const getAddTipContent = () => {
        return (
            <div className="d-flex flex-column">
                <form>
                    <div className="mb-3">
                        <label className="form-label">Grupo</label>
                        <SearchableSelect
                            name="tipGroup"
                            isSearchable
                            isClearable
                            options={getTipGroupOptions()}
                            onChange={(e: any) => {
                                setSelectedGroups((prevValues: any) => ({
                                    ...prevValues,
                                    [0]: e?.value ? e?.value : null
                                  }));
                                setSelectedTips([]); 
                            }}
                            placeholder='grupo de consejos'
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Consejo</label>
                        <SearchableSelect
                            key={selectedGroups[0] ?? 'allGroups'}
                            name="addTipOptions"
                            isSearchable
                            isMulti
                            isClearable
                            options={getTipOptions()}
                            onChange={(e: any) => { setSelectedTips(e ? e.map((item: any) => item.value) : []); }}

                            placeholder='consejo para asignar'
                        />
                    </div>
                    <div className="mb-3 ">
                        <Button className="btn btn-primary"
                            isDisable={selectedTips.length <= 0 || isLoading}
                            onClick={() => _handleAssignTipsToPatient(selectedTips, null)}
                            >{isLoading ? <Spinner color={'dark'}/> : 'Guardar'}</Button>
                    </div>
                </form>
                <span className="w-100 text-center m-auto mb-5"> o </span>
                <form>
                    <h5 className="mb-4">Añadir los consejos de un grupo</h5>
                    <div className="mb-3">
                        <label className="form-label">Grupo</label>
                        <SearchableSelect
                            name="tipGroup"
                            isSearchable
                            isClearable
                            options={getTipGroupOptions()}
                            onChange={(e: any) => { 
                                setSelectedGroups((prevValues: any) => ({
                                    ...prevValues,
                                    [1]: e?.value ? e?.value : null                                 
                                }));
                            }}
                            placeholder='grupo de consejos'
                        />
                    </div>
                    <div className="mb-3 ">
                        <Button className="btn btn-primary"
                            isDisable={!selectedGroups[1] || isLoading}
                            onClick={() => _handleAssignTipsToPatient(null, selectedGroups[1])}
                        >{isLoading ? <Spinner color={'dark'}/> : 'Guardar'}</Button>
                    </div>  
                </form>
            </div>
        )
    }

    // MODAL TO DELETE TIPS FROM PATIENT BY TIP GROUPS, CONTENT WITH FORM
    const getTipGroupDeleteContent = () => {
        return (
            <div className="d-flex flex-column">
                <form>
                    <div className="mb-4">
                        <label className="form-label">Grupo</label>
                        <SearchableSelect
                            name="tipGroup"
                            isSearchable
                            isClearable
                            options={getTipGroupOptions()}
                            onChange={(e: any) => {
                                setSelectedGroups((prevValues: any) => ({
                                    ...prevValues,
                                    [0]: e?.value ? e?.value : null
                                  }));
                            }}
                            placeholder='grupo de consejos'
                        />
                    </div>
                    <p className="text-muted">Al seleccionar un grupo, se eliminarán todos los consejos que tiene el paciente asignados y que pertenecen a dicho grupo.</p>
                    <div className="mb-3 ">
                        <Button className="btn btn-danger"
                            isDisable={!selectedGroups[0] || isLoading}
                            onClick={() => _handleDeleteGroupFromPatient(selectedGroups[0], id)}
                        >{isLoading ? <Spinner color={'light'}/>: 'Eliminar'}</Button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <CardBody className="cardBodyForms">

            {/*   MODAL TO ADD TIPS TO PATIENT   */}
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Añadir un consejo' isStaticBackdrop>
                <ModalHeader setIsOpen={setIsOpen} className='p-4 pb-2'>
                    <ModalTitle id='patient-data'>Añadir consejos</ModalTitle>
                </ModalHeader>
                <ModalBody className='px-4'>
                    {getAddTipContent()}
                </ModalBody>
            </Modal>

            {/*   MODAL TO REMOVE TIPS FROM PATIENT BY TIP GROUPS   */}
            <Modal isOpen={isOpenDelete} setIsOpen={setIsOpenDelete} size='md' titleId='Añadir un consejo' isStaticBackdrop>
                <ModalHeader setIsOpen={setIsOpenDelete} className='p-4 pb-2'>
                    <ModalTitle id='patient-data'>Eliminar los consejos </ModalTitle>
                </ModalHeader>
                <ModalBody className='px-4'>
                    {getTipGroupDeleteContent()}
                </ModalBody>
            </Modal>

            <div className="d-flex flex-row flex-nowrap mb-3">
                <div className="w-50">
                    <CustomSearchInput onSearch={(e: any) => updateFilters({ search_array: e })} placeholder='Buscar...' className="w-50" />
                </div>
                <div className="w-50 d-flex flex-row-reverse gap-2">
                    <ListPatientTipsFilters filterMenu={filterMenu} setFilterMenu={setFilterMenu} filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} tipGroupOptions={getTipGroupOptions()} ></ListPatientTipsFilters>
                    <Button 
                        color='secondary' 
                        isLight
                        isOutline
                        icon='Filter' 
                        onClick={() => { setFilterMenu(true) }}
                    >
                        Filtros
                    </Button>
                    <Button
                        color="brand"
                        icon="Add"
                        isLight
                        onClick={() => setIsOpen(true) }
                    >
                        Agregar consejos
                    </Button>
                    {tips?.tips_work_clients?.length > 0 && (
                        <Button
                            color="danger"
                            isLight
                            icon="Delete"
                            onClick={() => setIsOpenDelete(true) }
                        >
                            Eliminar Grupo
                        </Button>
                    )}
                </div>
            </div>
            {getContent()}
        </CardBody>
    )
}

export default ListPatientTips;