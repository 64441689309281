import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../components/bootstrap/Button';
import { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import { Loader } from '../../../components/bootstrap/SpinnerLogo';
import useFetch from '../../../hooks/useFetch';
import { CompanyService } from '../../../services/companies/organizationService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import TechnicalSheetForm from './TechnicalSheetForm';

interface GCTechnicalSheetsProps { }

export interface RationForm {
    rations: { name: string, description: string, multiplier: number }[];
}

const schema = yup.object({
    rations: yup.array().of(yup.object().shape({
        name: yup.string().required('Campo obligatorio'),
        description: yup.string().notRequired().nullable(),
        multiplier: yup.number().typeError('Debe ser un número válido').positive('Debe ser un número positivo').test(
            'max-2-decimals',
            'El número no puede tener más de 2 decimales',
            (value) => {
                if (value === undefined || value === null) return true;
                return /^\d+(\.\d{1,2})?$/.test(value.toString());
            }
        ).required('Campo obligatorio'),
    })),
});

const GCTechnicalSheets: React.FC<GCTechnicalSheetsProps> = ({ }) => {

    const { handleErrors } = useHandleErrors();

    const [ration, setRation] = useState<{ name: string, description: string, multiplier: number }>({ name: '', description: '', multiplier: 1 });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new CompanyService()).getTechnicalSheet();
        return response.getResponseData();
    }, []));

    const handleSubmit = async (values: RationForm) => {
        setIsLoading(true);
        if (ration.name !== '' && ration.multiplier !== 0) values.rations.push(ration);
        try {
            const response = await (await (new CompanyService()).setTechnicalSheet(values)).getResponseData();
            if (response.success) {
                toast.success("Fichas técnicas editadas correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar las fichas técnicas");
        } finally {
            setIsLoading(false);
            setRation({ name: '', description: '', multiplier: 1 });
        }
    };

    const formik = useFormik<RationForm>({
        initialValues: {
            rations: []
        },
        validationSchema: schema,
        onSubmit: values => { handleSubmit(values) }
    });

    useEffect(() => {
        if (data) {
            formik.setFieldValue('rations', data.map((r: any) => (
                { 
                    id: r.id,
                    name: r.name, 
                    description: r.description, 
                    multiplier: r.value, 
                    exists: true
                }
            )));
        }
    } , [data]);

    if (loading) return <Loader />;

    return (
        <>
            <CardHeader>
                <CardLabel>
                    <CardTitle>Ficha Técnica</CardTitle>
                </CardLabel>
            </CardHeader>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody>
                    <TechnicalSheetForm formik={formik} ration={ration} setRation={setRation} />
                </CardBody>

                <CardFooter className='d-flex justify-content-end'>
                    <Button
                        type="submit"
                        isDisable={isLoading || formik.values.rations.length === 0}
                        color='primary'
                    >
                        {isLoading ? <Spinner color={'dark'} /> : 'Guardar'}
                    </Button>
                </CardFooter>
            </form>
        </>
    );
};

export default GCTechnicalSheets;