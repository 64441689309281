import React, { useEffect } from 'react';
import SelectReact from 'react-select';

type SelectProps = {
    name?: string;
    isSearchable?: boolean;
    isMulti?: boolean;
    options?: any;
    customFilter?: any;
    onChange?: any;
    handle?: any;
    value?: any;
    defaultValue?: any;
    placeholder?: string;
    completePlaceholder?: string;
    classname?: string;
    onBlur?: any;
    required?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    menuIsOpen?: boolean;
    onInputChange?: any;
    styles?: any;
};

const SearchableSelect: React.FC<SelectProps> = ({ name, completePlaceholder, isSearchable, isMulti, options, customFilter, onChange, handle, value, defaultValue, placeholder, classname, onBlur, required, isClearable, isDisabled, menuIsOpen, onInputChange, styles }) => {

    useEffect(() => {
    }, [defaultValue]);
    
    return (
        <SelectReact
            name={name}
            id={name}
            menuIsOpen={menuIsOpen}
            onInputChange={onInputChange}
            isSearchable={isSearchable}
            isMulti={isMulti}
            options={options}
            filterOption={customFilter}
            onChange={onChange ? ((selectedOption: any) => { onChange(selectedOption) }) : handle}
            value={value}
            defaultValue={defaultValue}
            isClearable={isClearable ? isClearable : false}
            placeholder={completePlaceholder ? completePlaceholder : 'Elegir ' + placeholder?.toLocaleLowerCase() + ' ...'}
            noOptionsMessage={() => 'No se ha encontrado ningún resultado'}
            className={classname}
            onBlur={onBlur}
            styles={{
                control: (base, state) => ({
                    ...base,
                    borderRadius: '10px',
                    boxShadow: state.isFocused ? '0 0 0 3px rgba(93, 236, 227, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                    border: '0px !important',
                    backgroundColor: isDisabled ? '#e9ecef' : '#f8f9fa',
                    ...styles
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#30b7af' : 'white',
                    color: state.isFocused ? 'white' : 'black',
                    '&:hover': {
                        backgroundColor: '#30b7af',
                        color: 'white',
                        borderColor: '#30b7af !important'
                    }
                }),
            }}
            isDisabled={isDisabled}
            required={required}
        />
    );
};

export default SearchableSelect;
