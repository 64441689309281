import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/intolerances';

export class IntolerancesService extends RestServiceConnection {

    listIntolerances = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-intolerances',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getIntolerance = async (intoleranceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { intoleranceId }
        }, true) as AxiosResponse;
        return this;
    }

    createIntolerance = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    editIntolerance = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    deleteIntolerance = async (intoleranceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { intoleranceId }
        }, true) as AxiosResponse;
        return this;
    }

}