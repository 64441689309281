import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/data-source';

export class DataSourceService extends RestServiceConnection {


    getAvailableSources = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-all-available-source',
        }, true) as AxiosResponse;
        return this;
    }

    listDataSources = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true) as AxiosResponse;
        return this;
    }

    getDataSourceById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: {
                id: id
            }
        }, true);
        return this;
    }

    createDataSource = async (dataSource: FormData) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: dataSource
        }, true);
        return this;
    }

    editDataSource = async (dataSource: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: dataSource
        }, true);
        return this;
    }

    deleteDataSource = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: {
                id: id
            }
        }, true);
        return this;
    }

}

