import { FC } from "react";
import CreatableSelect from "react-select/creatable";
import { CardBody, CardTitle } from "../../../../components/bootstrap/Card";

interface LabelProps {
    labels: any;
    getLabelsOptions: any;
    handleSaveTags: any;
}

const Labels: FC<LabelProps> = ({ labels, getLabelsOptions, handleSaveTags }) => {

    const defaultValues = getLabelsOptions()?.filter((option: { isSelected: boolean }) => option.isSelected);

    return (
        <>
            <CardTitle>Añadir etiquetas</CardTitle>
            <CardBody>
                {
                    labels ? (
                        <div className='row g-4 d-flex justify-content-around'>
                            <div className="col-7 d-flex flex-column">
                                <CreatableSelect
                                    id="tags_select"
                                    isMulti
                                    options={getLabelsOptions()}
                                    noOptionsMessage={() => "No hay etiquetas disponibles"}
                                    onChange={(e: any) => {
                                        const tags = e.map((label: any) => label.value);
                                        handleSaveTags(tags);
                                    }}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            borderRadius: '10px',
                                            boxShadow: state.isFocused ? '0 0 0 3px rgba(93, 236, 227, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                                            border: '0px !important',
                                            backgroundColor: '#f8f9fa',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#30b7af' : 'white',
                                            color: state.isFocused ? 'black' : 'grey',
                                            '&:hover': {
                                                backgroundColor: '#30b7af',
                                                color: 'black',
                                                borderColor: '#30b7af !important'
                                            }
                                        })
                                    }}
                                    placeholder="Selecciona una o varias etiquetas..."
                                    defaultValue={defaultValues}
                                />
                            </div>
                        </div>
                    ) : (
                        <p>No hay etiquetas</p>
                    )
                }
            </CardBody>
        </>
    )
}

export default Labels;