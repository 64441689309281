import { useCallback, useEffect, useState } from 'react';
import { FoodService } from '../../services/foods/foodService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage foods fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de alimentos.
----------------------------------------------------------------------------------------*/

const useFoods = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchData = useCallback(async () => {
        try {
            const response = await (new FoodService()).getAliments({ limit: 999999 });
            const fetchedData = response.getResponseData()?.data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { name: string; id: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching foods:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const getFoodsList = () => {
        return data;
    };

    return { fetchData, getFoodsList };
}

export default useFoods;