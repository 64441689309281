import { FC, Fragment, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import CustomSearchInput from '../../../components/bootstrap/forms/CustomSearchInput';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Icon from '../../../components/icon/Icon';
import SearchableSelect from '../../../components/select/SearchableSelect';
import useAllergens from '../../../hooks/api-calls/useAllergens';
import useFoodGroups from '../../../hooks/api-calls/useFoodGroups';
import useFoods from '../../../hooks/api-calls/useFoods';
import { NUTRIENTS } from '../../../utils/mapping-collection';

interface IPathologiesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const PathologiesFilters: FC<IPathologiesFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    // STATES

    const [filterMenu, setFilterMenu] = useState(false);
    const [resetkey, setResetKey] = useState(0);

    // HOOKS

    const { getFoodsList } = useFoods();
    const { getFoodGroupsOptions } = useFoodGroups();
    const { getAllergenOptions } = useAllergens();

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja el cambio de texto en el campo de búsqueda.
     * @EN Function that handles text change in search field.
     * 
     * @param search 
     */
    //------------------------------------------------------------------------------------------------
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };
    //------------------------------------------------------------------------------------------------

    // RENDER

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>Filtros</Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'>Filtros de Patologías</OffCanvasTitle>
                    <Button color="storybook" isLight onClick={() => {
                        resetFilters()
                        setResetKey(resetkey + 1)
                    }}>
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4' key={resetkey}>
                        <FormGroup label='Alimentos' className='col-12'>
                            <SearchableSelect
                                isMulti
                                name='foodsToAvoid-select'
                                options={getFoodsList()}
                                onChange={(e: any) => updateFilters({ foodsToAvoid: e.map((item: any) => item.value) })}
                                defaultValue={filters.filter_filters.foodsToAvoid ?
                                    getFoodsList().filter((item: any) => filters.filter_filters.foodsToAvoid.includes(item.value)).map((item: any) => ({ value: item.value, label: item.label }))
                                    : []}
                                placeholder='alimentos'
                            />
                        </FormGroup>

                        <FormGroup label='Grupos de alimentos' className='col-12'>
                            <SearchableSelect
                                isMulti
                                name='foodGroupsToAvoid-select'
                                options={getFoodGroupsOptions()}
                                onChange={(e: any) => updateFilters({ foodGroupsToAvoid: e.map((item: any) => item.value) })}
                                defaultValue={filters.filter_filters.foodGroupsToAvoid ?
                                    getFoodGroupsOptions().filter((item: any) => filters.filter_filters.foodGroupsToAvoid.includes(item.value)).map((item: any) => ({ value: item.value, label: item.label }))
                                    : []}
                                placeholder='grupo de alimentos'
                            />
                        </FormGroup>

                        <FormGroup label='Alérgenos' className='col-12'>
                            <SearchableSelect
                                isMulti
                                name='allergens-select'
                                options={getAllergenOptions()}
                                onChange={(e: any) => updateFilters({ allergensToAvoid: e.map((item: any) => item.value) })}
                                defaultValue={filters.filter_filters.allergensToAvoid ?
                                    getAllergenOptions().filter((item: any) => filters.filter_filters.allergensToAvoid.includes(item.value)).map((item: any) => ({ value: item.value, label: item.label }))
                                    : []}
                                placeholder='alérgenos'
                            />
                        </FormGroup>

                        <FormGroup label='Nutrientes' className='col-12'>
                            <SearchableSelect
                                isMulti
                                name='nutrients-select'
                                options={NUTRIENTS}
                                onChange={(e: any) => updateFilters({ nutrientsToAvoid: e.map((item: any) => item.value) })}
                                defaultValue={filters.filter_filters.nutrientsToAvoid ?
                                    NUTRIENTS.filter((item: any) => filters.filter_filters.nutrientsToAvoid.includes(item.value)).map((item: any) => ({ value: item.value, label: item.label }))
                                    : []}
                                placeholder='nutrientes'
                            />
                        </FormGroup>

                        <FormGroup label='Nutrientes de la ingesta' className='col-12'>
                            <SearchableSelect
                                isMulti
                                name='nutrients-select'
                                options={NUTRIENTS}
                                onChange={(e: any) => updateFilters({ recommendIntakes: e.map((item: any) => item.value) })}
                                defaultValue={filters.filter_filters.recommendIntakes ?
                                    NUTRIENTS.filter((item: any) => filters.filter_filters.recommendIntakes.includes(item.value)).map((item: any) => ({ value: item.value, label: item.label }))
                                    : []}
                                placeholder='nutrientes'
                            />
                        </FormGroup>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default PathologiesFilters;