import { useCallback, useEffect } from "react";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { CalibratorService } from "../../../services/calibrators/calibratorService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import classNames from "classnames";
import Icon from "../../../components/icon/Icon";



interface GCMenusProps { 

}

interface FormValues {
    id: string,
    kcalMax: number,
    kcalMin: number,
    proteinMax: number,
    proteinMin: number,
    carbohydratesMax: number,
    carbohydratesMin: number,
    fatMax: number,
    fatMin: number,
    mineralsMax: number,
    mineralsMin: number,
    vitaminsMax: number,
    vitaminsMin: number,
}

let initialFormikValues = {
    id: '',
    kcalMax: 10,
    kcalMin: 10,
    proteinMax: 10,
    proteinMin: 10,
    carbohydratesMax: 10,
    carbohydratesMin: 10,
    fatMax: 10,
    fatMin: 10,
    mineralsMax: 10,
    mineralsMin: 10,
    vitaminsMax: 10,
    vitaminsMin: 10,
}

const GCMenus: React.FC<GCMenusProps> = () => {

    // STATES

    // HOOKS

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();

    // FETCH DATA
    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new CalibratorService()).getCalibratorByCompany());
        return response.getResponseData();
    }, []));

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------
    /**
     * @ES Función para editar un calibrador.
     * @EN Function to edit a calibrator.
     * 
     * @param values 
     */
    //-------------------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        try {
            const response = await (await (new CalibratorService()).editCalibrator(values)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Calibrador editado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar el calibrador');
        }
    };
    //-------------------------------------------------------------------------------------------------------------


    // USE EFFECT

    useEffect(() => {
        if (data) {
            formik.setFieldValue('id', data?.id);
            formik.setFieldValue('kcalMax', data?.Kcal.Max);
            formik.setFieldValue('kcalMin', data?.Kcal.Min);
            formik.setFieldValue('proteinMax', data?.Proteinas.Max);
            formik.setFieldValue('proteinMin', data?.Proteinas.Min);
            formik.setFieldValue('carbohydratesMax', data?.Carbohidratos.Max);
            formik.setFieldValue('carbohydratesMin', data?.Carbohidratos.Min);
            formik.setFieldValue('fatMax', data?.Grasas.Max);
            formik.setFieldValue('fatMin', data?.Grasas.Min);
            formik.setFieldValue('mineralsMax', data?.Minerales.Max);
            formik.setFieldValue('mineralsMin', data?.Minerales.Min);
            formik.setFieldValue('vitaminsMax', data?.Vitaminas.Max);
            formik.setFieldValue('vitaminsMin', data?.Vitaminas.Min);
        }
    }, [data]);


    // FORMIK

    const validationSchema = Yup.object({
        kcalMax: Yup.number().required('Campo requerido'),
        kcalMin: Yup.number().required('Campo requerido'),
        proteinMax: Yup.number().required('Campo requerido'),
        proteinMin: Yup.number().required('Campo requerido'),
        carbohydratesMax: Yup.number().required('Campo requerido'),
        carbohydratesMin: Yup.number().required('Campo requerido'),
        fatMax: Yup.number().required('Campo requerido'),
        fatMin: Yup.number().required('Campo requerido'),
        mineralsMax: Yup.number().required('Campo requerido'),
        mineralsMin: Yup.number().required('Campo requerido'),
        vitaminsMax: Yup.number().required('Campo requerido'),
        vitaminsMin: Yup.number().required('Campo requerido'), 
    });


    const formik = useFormik<FormValues>({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleEdit(values);
        }
    });


    // RENDER

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Calibradores</CardTitle>
                    <SubheaderSeparator/>
                    <Button
                        onClick={formik.handleSubmit}
                        title="Guardar"
                        color="storybook"
                        icon="Save"
                        isLight
                        className={classNames({ 'd-none': !userCan('edit', 'calibrator') })}
                        >
                            Guardar
                    </Button>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <></>
                </SubHeaderRight>
            </CardHeader>
            <CardBody>
                <div className="row p-3  rounded" style={{backgroundColor:"#f7f1cf"}} >
                    <div className="col-1">
                        <Icon icon="Warning" size="3x" className="text-warning" />
                    </div>
                    <div className="col-11">
                        <span className="">
                            Los valores de los calibradores son valores porcentuales que se utilizan para estimar el intervalo aceptable para el objetivo del paciente.
                            Estos valores afectarán al intervalo del objetivo de cada grupo de nutrientes de cada nutriente definido de todos los menús
                        </span>
                    </div>
                </div>
                <div className="row mt-5 mb-4">
                    <div className="col-4">
                        <span className="bold">GRUPO DE NUTRIENTES</span>
                    </div>  
                    <div className="col-4">
                        <span className="bold">MÁXIMO</span>
                    </div>
                    <div className="col-4">
                       <span className="bold">MÍNIMO</span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <label htmlFor="kcalMax">KCAL</label>
                    </div>  
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="kcalMax"
                            name="kcalMax"
                            value={formik.values.kcalMax}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="kcalMin"
                            name="kcalMin"
                            value={formik.values.kcalMin}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <label htmlFor="proteinMax">Proteínas</label>
                    </div>  
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="proteinMax"
                            name="proteinMax"
                            value={formik.values.proteinMax}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="proteinMin"
                            name="proteinMin"
                            value={formik.values.proteinMin}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <label htmlFor="carbohydratesMax">Carbohidratos</label>
                    </div>  
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="carbohydratesMax"
                            name="carbohydratesMax"
                            value={formik.values.carbohydratesMax}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="carbohydratesMin"
                            name="carbohydratesMin"
                            value={formik.values.carbohydratesMin}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <label htmlFor="fatMax">Grasas</label>
                    </div>  
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="fatMax"
                            name="fatMax"
                            value={formik.values.fatMax}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="fatMin"
                            name="fatMin"
                            value={formik.values.fatMin}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <label htmlFor="mineralsMax">Minerales</label>
                    </div>  
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="mineralsMax"
                            name="mineralsMax"
                            value={formik.values.mineralsMax}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="mineralsMin"
                            name="mineralsMin"
                            value={formik.values.mineralsMin}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <label htmlFor="vitaminsMax">Vitaminas</label>
                    </div>  
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="vitaminsMax"
                            name="vitaminsMax"
                            value={formik.values.vitaminsMax}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            id="vitaminsMin"
                            name="vitaminsMin"
                            value={formik.values.vitaminsMin}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
            </CardBody>
        </>
    );

}

export default GCMenus;