import { useFormik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import SearchableSelect from '../../../../components/select/SearchableSelect';
import useClients from '../../../../hooks/api-calls/useClients';
import { MenuService } from '../../../../services/menus/menuService';
import useHandleErrors from '../../../../utils/hooks/useHandleErrors';

interface CloneModalProps {
    isOpen: boolean;
    setIsOpen: any;
    menuId: string;
    refetch: () => void;
    isPatientProfile: boolean;
}

interface CloneMenuForm {
    menu: string;
    optionSelected: string;
    client: string;
    name: string;
}

const schema = yup.object({
    optionSelected: yup.string().required('El campo es obligatorio'),
    name: yup.string().min(1, 'Demasiado corto').max(250, 'Demasiado largo').required('Campo obligatorio'),
    client: yup.string().min(1, 'Demasiado corto').max(250, 'Demasiado largo')
        .when('optionSelected', {
            is: 'assign',
            then: schema => schema.required('Campo obligatorio'),
            otherwise: schema => schema.notRequired().nullable(),
        }),
});

const CloneModal: React.FC<CloneModalProps> = ({ isOpen, setIsOpen, menuId, refetch, isPatientProfile }) => {

    const { id = '' } = useParams();
    const { handleErrors } = useHandleErrors();
    const { getClientsList } = useClients();

    const [isLoading, setIsLoading] = React.useState(false);

    const submitCreateTemplate = async (values: CloneMenuForm) => {
        setIsLoading(true);
        try {
            const response = await (await (new MenuService()).cloneMenu({ menu: menuId, name: values.name })).getResponseData();
            if (response.success) {
                refetch();
                setIsOpen(false);
                toast.success('Menú clonado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al clonar el menú');
        } finally {
            setIsLoading(false);
        }
    };

    const submitAssignToPatient = async (values: CloneMenuForm) => {
        setIsLoading(true);
        try {
            const response = await (await new MenuService().assignTemplate(values)).getResponseData();
            if (response.success) {
                refetch && refetch();
                setIsOpen && setIsOpen(false);
                toast.success('Plantilla asignada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al asignar la plantilla');
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik<CloneMenuForm>({
        initialValues: {
            menu: menuId,
            optionSelected: '',
            client: isPatientProfile ? id : '',
            name: '',
        },
        validationSchema: schema,
        onSubmit: values => {
            values.optionSelected === 'create' && submitCreateTemplate(values);
            values.optionSelected === 'assign' && submitAssignToPatient(values);
        }
    });

    const verifyClass = (inputFieldID: keyof CloneMenuForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; }

    const showErrors = (inputFieldID: keyof CloneMenuForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='assign-template' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='patient-data'>Clonar menú</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-5 pt-3'>
                <div className='d-flex justify-content-center'>
                    <Select
                        name='optionSelected'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.optionSelected}
                        className='form-select mb-4 w-50'
                        ariaLabel='Selecciona una opción'
                    >
                        <option value='' disabled>Selecciona una opción</option>
                        <option value='create'>Crear plantilla</option>
                        <option value='assign'>Asignar a paciente</option>
                    </Select>
                </div>

                {formik.values.optionSelected !== '' && (
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup label='Nombre' className='mb-3' requiredInputLabel>
                            <Input
                                id='name'
                                name='name'
                                type='text'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                className={`form-control ${verifyClass('name')}`}
                            />
                            {showErrors('name')}
                        </FormGroup>

                        {formik.values.optionSelected === 'assign' && (
                            <FormGroup label='Paciente' className='mb-3' requiredInputLabel>
                                <SearchableSelect
                                    isSearchable
                                    isClearable
                                    onChange={(e: any) => formik.setFieldValue('client', e?.value)}
                                    value={{ value: formik.values.client, label: getClientsList().find(client => client.value === formik.values.client)?.label }}
                                    onBlur={formik.handleBlur}
                                    name='client-select'
                                    placeholder='paciente'
                                    options={getClientsList()}
                                    classname={verifyClass('client')}
                                />
                                {formik.errors['client'] ? <div className="invalid-feedback">{formik.errors['client']}</div> : <></>}
                            </FormGroup>
                        )}
                    </form>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={formik.submitForm} isDisable={isLoading}>
                    {formik.values.optionSelected === 'create' ? 'Crear' : 'Asignar'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CloneModal;