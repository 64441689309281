import React, { CSSProperties, FC } from "react";

interface FormLabelProps {
    label: string;
    cols?: number;
    className?: string;
    style?: CSSProperties;
    htmlFor?: string;
    required?: boolean;
    tooltip?: React.ReactNode;
}

const FormLabel: FC<FormLabelProps> = ({ label, cols, className, required, style, htmlFor, tooltip }) => {
    return (
        <label className={`labelStyle col-md-${cols || 12} ${className} pe-0`} style={style} htmlFor={htmlFor}>
            {tooltip ? <span>{label}{tooltip}</span> : label}
            {required && <span className="labelRequired"> *</span>}
        </label>
    )
}

export default FormLabel;