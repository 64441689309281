import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTabItem } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import Icon from "../../../components/icon/Icon";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { formsMenu } from "../../../menu";
import { FormService } from "../../../services/forms/formService";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import AssignFormToPatient from "./AssignFormToPatient";
import ListPatientForms from "./ListPatientForms";

interface IFormFilters {
    formType?: string;
}

const FormsTab: FC = () => {

    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const { id = '' } = useParams<{ id: string }>();
    const formService = new FormService();

    const [formTypeId, setFormTypeId] = useState<string>('');
    const [formId, setFormId] = useState<string>('');
    const [formFillId, setFormFillId] = useState('');
    const [displayModal, setDisplayModal] = useState<boolean>(false);

    const fetchFormTypes = useCallback(async () => {
        const response = (await formService.getFormTypes());
        return response.getResponseData();
    }, []);

    const [formTypeData, loadingFormType, formTypeError, refetchTypes] = useFetch(fetchFormTypes);

    const formFilters: IFormFilters = {
        formType: formTypeData?.formTypes[0]?.id ? formTypeData.formTypes[0].id : formTypeId, // Filtrar por el primer tipo de formulario
    };

    const { filters, updateFilters } = useFilters(formFilters);

    const [formsData] = useFetch(useCallback(async () => {
        const response = (await formService.getForms(filters));
        return response.getResponseData();
    }, [filters]));

    // Asignar un formulario a un paciente
    const _handleAssignFill = async () => {
        try {
            const response = (await formService.assignFormToPatient(id, formId)).getResponseData();
            if (response.success) {
                setFormFillId(response.data.id);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al asignar el formulario');
        }
    };

    const _handleDelete = async (id: string) => {
        const response = await (await (new FormService()).deleteFill(id)).getResponseData();
        if (response.success) {
            refetchTypes();
        }
    };

    // Actualizar el id del tipo de formulario seleccionado (por defecto, el primero) cuando se obtienen los datos
    useEffect(() => {
        if (formTypeData !== null && formTypeData.formTypes.length > 0) {
            setFormTypeId(formTypeData.formTypes[0]?.id);
        }
    }, [formTypeData]);

    // Actualizar el id del formulario seleccionado (por defecto, el primero) cuando se obtienen los datos
    useEffect(() => {
        if (formsData !== null && formsData.forms.length > 0) {
            setFormId(formsData.forms[0]?.id);
        }
    }, [formsData]);

    // Actualiza los filtros si cambia de FormType (por tanto, vuelve a hacer el fetch de los forms)
    useEffect(() => {
        if (formTypeId !== null) {
            updateFilters({ formType: formTypeId });
        }
    }, [formTypeId]);

    // Crea el formFill cuando se le da al botón de asignar
    useEffect(() => {
        displayModal && _handleAssignFill();
    }, [displayModal]);

    const getContent = () => {
        if (loadingFormType) return <div className="text-center"><Spinner /></div>;

        if (formTypeError) return <ErrorMessage message={formTypeError} />;

        return (
            formTypeData?.formTypes && formsData?.forms && (
                <div className="d-flex justify-content-between">
                    <div className="w-100 cardTabForms">
                        {formTypeData.formTypes.length > 0
                            ? (
                                <Card hasTab >
                                    {formTypeData.formTypes.map((formType: any) => (
                                        <CardTabItem
                                            id={formType.id}
                                            title={formType.name}
                                            key={formType.id}
                                            onSelected={(id: string) => { 
                                                setFormTypeId(id) 
                                            }}
                                            isActive={formTypeId === formType.id}
                                        >
                                            {
                                                formsData.forms?.length > 0 ? (
                                                    <Card hasTab className="formTypes">
                                                        {
                                                            formsData.forms?.map((form: any) => (
                                                                <CardTabItem
                                                                    id={form.id}
                                                                    title={form.name}
                                                                    key={form.id}
                                                                    onSelected={(id: string) => { setFormId(id) }}
                                                                    isActive={formId === form.id}
                                                                >
                                                                    <ListPatientForms formId={form.id} />
                                                                </CardTabItem>
                                                            ))
                                                        }
                                                    </Card>
                                                ) : (
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <h5 className="text-muted d-flex">
                                                            No hay formularios de este tipo.
                                                            <div className="cursor-pointer text-secondary ms-3" onClick={() => { navigate(formsMenu.forms.path + `/create`) }}>
                                                                Crear
                                                            </div>
                                                        </h5>
                                                    </div>
                                                )
                                            }
                                        </CardTabItem>
                                    ))}
                                </Card>
                            )
                            : (
                                <div className="d-flex justify-content-center mt-3">
                                    <h5 className="text-muted">No hay tipos de formulario</h5>
                                </div>
                            )
                        }
                    </div>

                    <div>
                        {formTypeData.formTypes.length > 0 && formsData.forms.length > 0 && (
                            <Button
                                color='primary'
                                onClick={() => { setDisplayModal(true) }}
                                className="btnAddForms"
                            >
                                <Icon icon='Add' title="Asignar formulario" />
                            </Button>
                        )}
                    </div>
                </div>
            )
        )
    };

    return (
        <div className="formsPage">
            {getContent()}
            {displayModal && <AssignFormToPatient isOpen={displayModal} setIsOpen={setDisplayModal} formFillId={formFillId} refetch={refetchTypes} deleteField={_handleDelete} 
            setFormFillId={setFormFillId} />}
        </div>
    )
}

export default FormsTab;