import { MD5 } from "crypto-js";
import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { toast } from "react-toastify";
import { MenuService } from "../../services/menus/menuService";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import DraggableMenuItem from "./DraggableMenuItem";
import { useMenuDataProvider } from "./providers/MenuDataProvider";
import './styles/styles.scss';

interface DropContainerProps {
    menuId: string;
    day: any;
    intake: any;
    menuItems: any[];
    addItemsToMenu: (item: any, dayId: string, intakeId: string) => void;
    removeItemFromMenu: (dayId: string, intakeId: string, itemId: string) => void;
    editItemInMenu: (item: any, dayId: string, intakeId: string) => void;
    daySelected?: any;
    intakeSelected?: any;
    setNutValuesFrom?: any;
    setSelectedMenuItem?: any;
    setOpenContextMenuChange?: any;
}

const DropContainer: React.FC<DropContainerProps> = ({ menuId, day, intake, menuItems, editItemInMenu, addItemsToMenu, removeItemFromMenu, daySelected, setNutValuesFrom, intakeSelected, setSelectedMenuItem, setOpenContextMenuChange }) => {

    // PROVIDERS

    const { showPhysicalActivities } = useMenuDataProvider();

    // HOOKS

    const { handleErrors } = useHandleErrors();
    const [selectedFood, setSelectedFood] = useState<any | null>(null);
    const [selectedRecipe, setSelectedRecipe] = useState<any | null>(null);
    const [selectedActivity, setSelectedActivity] = useState<any | null>(null);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);

    // FUNCTIONS

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'menuItem',
        drop: async (item: any) => {
            if (item.id !== undefined) {
                addFoodToMenuItem(
                    day.id,
                    intake.id,
                    item.id,
                    item.name,
                    item.isFood,
                    item.isRecipe,
                    item.isActivity,
                    item.quantity,
                    item.fromSelectors,
                    item.foodGroup,
                    item.foodGroupId,
                    item.foodGroupColor,
                );
            }
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
    });

    const updateValues = (dayId: string, intakeId: string) => {
        if (daySelected) {
            setNutValuesFrom({ day: dayId });
        } else if (intakeSelected) {
            setNutValuesFrom({ intake: intakeId });
        } else {
            let hash: string = MD5(dayId + intakeId).toString();
            setNutValuesFrom({ hash: hash });
        }
    };

    const addFoodToMenuItem = async (
        dayId: string,
        intakeId: string,
        itemId: string,
        itemName: string,
        isFood: boolean,
        isRecipe: boolean,
        isActivity: boolean,
        quantity: number,
        fromSelectors: boolean,
        itemFoodGroup?: string,
        itemFoodGroupId?: string,
        itemFoodGroupColor?: string,
    ) => {
        const menuItem: any = {
            menu: menuId,
            day: dayId,
            intake: intakeId,
            food: isFood ? itemId : null,
            recipe: isRecipe ? itemId : null,
            activity: isActivity ? itemId : null,
            quantity: Number(quantity),
            calculate: fromSelectors,
        };

        let dataMenuItem = {
            id: null,
            dayId: dayId,
            intakeId: intakeId,
            food: isFood ? { id: itemId, name: itemName, food_group: itemFoodGroup, food_group_id: itemFoodGroupId, food_group_color: itemFoodGroupColor } : null,
            recipe: isRecipe ? { id: itemId, name: itemName, food_group: itemFoodGroup, food_group_id: itemFoodGroupId, food_group_color: itemFoodGroupColor } : null,
            physicalActivity: isActivity ? { id: itemId, name: itemName.slice(-3) !== 'min' ? `${itemName} ${quantity} min` : itemName } : null,
            quantity: Number(quantity),
        };

        addItemsToMenu(dataMenuItem, dayId, intakeId);

        try {
            const response = await (await (new MenuService()).addMenuItem(menuItem)).getResponseData();
            if (response.success) {
                dataMenuItem.id = response.data.id;
                editItemInMenu(dataMenuItem, dayId, intakeId);
                updateValues(dayId, intakeId);
            }
            if (!response.success) {
                handleErrors(response);
                toast.error("Error al añadir el item al menú");
                removeItemFromMenu(dayId, intakeId, itemId);
            }
        } catch (error: any) {
            toast.error("Error al añadir el item al menú");
        }
    };

    const deleteMenuItem = async (menuItem: string) => {
        removeItemFromMenu(day.id, intake.id, menuItem);
        await (new MenuService()).deleteMenuItem(menuItem);
        updateValues(day.id, intake.id);
    };

    // USE EFFECT

    useEffect(() => {
        if (showPhysicalActivities) {
            let itemsFiltered = menuItems.filter((item) => item.physicalActivity);
            itemsFiltered.forEach((item) => {
                // Add quantity to physical activity name if it doesn't have it
                if (item.physicalActivity.name.slice(-3) !== 'min') item.physicalActivity.name = `${item.physicalActivity.name} ${item.quantity} min`;
            });
            setFilteredItems(itemsFiltered);
        } else {
            setFilteredItems(menuItems.filter((item) => item.food || item.recipe));
        }
    }, [menuItems, showPhysicalActivities]);

    // RENDER

    return (
        <div
            ref={drop}
            className="menu-cell-content d-flex justify-content-center flex-column"
            style={{
                backgroundColor: isOver ? "#f5d0a9" : "",
                opacity: isOver ? 0.5 : 1,
                minHeight: '55px',
                minWidth: '135px',
            }}
        >
            {filteredItems.length > 0
                ? (
                    <div className="menu-items-container">
                        {filteredItems.map((item: any) => (
                            <DraggableMenuItem
                                key={item.id}
                                item={item}
                                selectedFood={selectedFood}
                                selectedRecipe={selectedRecipe}
                                selectedActivity={selectedActivity}
                                setSelectedFood={setSelectedFood}
                                setSelectedRecipe={setSelectedRecipe}
                                setSelectedActivity={setSelectedActivity}
                                deleteMenuItem={deleteMenuItem}
                                setSelectedMenuItem={setSelectedMenuItem}
                                setOpenContextMenuChange={setOpenContextMenuChange}
                            />
                        ))}
                    </div>
                )
                : <span className="text-muted">Aún no hay items</span>
            }
        </div>
    );
};

export default DropContainer;