import { Link } from "react-router-dom";
import Button from "../../../../components/bootstrap/Button";
import { CardTitle } from "../../../../components/bootstrap/Card";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import { menusMenu } from "../../../../menu";
import ListPatientMenus from "./ListPatientMenus";

const PatientHasMenus = (id: any) => {
    return (
        <>
            <div className='row d-flex justify-content-between mb-4'>
                <CardTitle className="w-auto m-0">Listado de Menús</CardTitle>

                <Dropdown className="w-auto">
                    <DropdownToggle hasIcon={true}>
                        <Button
                            isLink
                            className="text-nowrap bg-secondary"
                        >
                            Añadir
                        </Button>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem key='assign-template'>
                            <Link
                                to={`${menusMenu.menus.path}/${id.id}/assign-menu`}
                                className="text-decoration-none text-dark"
                            >
                                Asignar plantilla
                            </Link>
                        </DropdownItem>
                        <DropdownItem key='create-menu'>
                            <Link
                                to={`${menusMenu.menus.path}/${id.id}/create-menu`}
                                className="text-decoration-none text-dark"
                            >
                                Crear plan nutricional
                            </Link>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>

            <ListPatientMenus id={id.id} />
        </>
    )
}

export default PatientHasMenus;