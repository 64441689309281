import { useFormik } from "formik";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import InputPhone from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../components/bootstrap/Modal";
import Spinner from "../../components/bootstrap/Spinner";
import CustomPasswordInput from "../../components/bootstrap/forms/CustomPasswordInput";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import CustomSearchSelect from "../../components/select/CustomSearchSelect";
import SearchableSelect from "../../components/select/SearchableSelect";
import useAllergies from "../../hooks/api-calls/useAllergies";
import useIntolerances from "../../hooks/api-calls/useIntolerances";
import usePathologies from "../../hooks/api-calls/usePathologies";
import usePhysiologicalStates from "../../hooks/api-calls/usePhysiologicalStates";
import useWorkplaces from "../../hooks/api-calls/useWorkplaces";
import useFetch from "../../hooks/useFetch";
import { CompanyService } from "../../services/companies/organizationService";
import { FoodService } from "../../services/foods/foodService";
import { PatientService } from "../../services/patients/patientService";
import { RecipeService } from "../../services/recipes/recipeService";
import { UserService } from "../../services/users/userService";
import { AlimentsApiResponse } from "../../type/aliments-type";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import { Users, UsersApiResponse } from "../../type/user-type";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import { COUNTRIES, ROLES } from "../../utils/mapping-collection";
import { useAppointmentCalendar } from "../appointments/providers/AppointmentCalendarProvider";
import { PatientCreateSchema, PatientForm, patientInitialValues } from "./PatientForm";

interface PatientModalFormProps {
    openModal: boolean;
    setOpenModal: (open: boolean) => void;
    onConfirm: any;
}

const PatientModalForm: React.FC<PatientModalFormProps> = ({ openModal, setOpenModal, onConfirm }) => {

    const auth = usePrivilege();
    const { userCan } = auth;
    const { getWorkplacesList } = useWorkplaces();
    const { getPathologyOptions } = usePathologies();
    const { getPhysiologicalStateOptions } = usePhysiologicalStates();
    const { getIntolerancesList } = useIntolerances();
    const { getAllergyOptions } = useAllergies();
    const { setNewPatientSelected } = useAppointmentCalendar();
    const { handleErrors } = useHandleErrors();

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedUserWorkplaces, setSelectedUserWorkplaces] = useState<string[]>([]);

    // Obtiene las organizaciones
    const [organizations] = useFetch(useCallback(async () => {
        if (!auth.userHasRole(ROLES.SUPER_ADMIN)) return [];
        const response = await (new CompanyService()).getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []));

    // Obtiene los usuarios
    const [users] = useFetch(useCallback(async () => {
        if (!userCan('access_to_all_patients', 'clients')) return;
        const response = await (new UserService()).getUsers();
        return response.getResponseData() as UsersApiResponse;
    }, []));

    // Obtiene los alimentos
    const [foodsData] = useFetch(useCallback(async () => {
        const response = await (new RecipeService()).getIngredientsByCompany();
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    // Obtiene los grupos de alimentos
    const [groupsData] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAlimentGroups();
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    const handleCreatePatient = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new PatientService()).createPatient(values)).getResponseData();
            if (response.success) {
                onConfirm();
                setNewPatientSelected({ label: response.data.name, value: response.data.id });
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al crear el paciente');
        } finally {
            setLoading(false);
        }
    };

    const getOrganizationsList = () => {
        if (organizations as Companies) {
            return organizations.companies.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const getUsersList = () => {
        if (users as Users) {
            return users.users.map((option: { id: string; name: string; lastName: string; }) => {
                return {
                    value: option.id,
                    label: option.name + (option.lastName ? (' ' + option.lastName) : '')
                }
            })
        }
        return [];
    };

    const getFoodsList = () => {
        return foodsData?.data?.map((food: any) => {
            return {
                value: food.id,
                label: food.name,
                isSelected: false
            }
        }) || [];
    };

    const getGroupsList = () => {
        return groupsData?.food_groups.map((group: any) => {
            return {
                value: group.id,
                label: group.name,
                isSelected: false
            }
        }) || [];
    };

    const formik = useFormik({
        initialValues: patientInitialValues,
        validationSchema: PatientCreateSchema,
        onSubmit: values => { handleCreatePatient(values) }
    });

    const verifyClass = (inputFieldID: keyof PatientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; };

    const showErrors = (inputFieldID: keyof PatientForm) => {
        //@ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    // Cargar las opciones de los centros de trabajo del usuario seleccionado
    useEffect(() => {
        const user = users?.users.find((user: any) => user.id === formik.values.user);
        setSelectedUserWorkplaces(user?.workPlaces?.map((w: any) => w.workPlace?.id) || []);
    }, [formik.values.user, users]);

    return (
        <Modal isOpen={openModal} setIsOpen={setOpenModal} size='xl' titleId='Datos del paciente'>
            <ModalHeader setIsOpen={setOpenModal}>
                <ModalTitle id='patient-form-data'>Datos del paciente</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4 py-0'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="row">
                        <div className="col-md-9">
                            <CardTitle>Información de la cuenta</CardTitle>

                            <div className="row p-0">
                                <FormGroup requiredInputLabel label='Nombre' className="col-md-3">
                                    <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                    {showErrors('name')}
                                </FormGroup>
                                <FormGroup requiredInputLabel label='Email' className="col-md-5">
                                    <Input id='email' type='email' autoComplete="new-email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} className={verifyClass('email')} />
                                    {showErrors('email')}
                                </FormGroup>
                                <FormGroup requiredInputLabel label='DNI' className="col-md-2">
                                    <Input id='dni' onChange={formik.handleChange} value={formik.values.dni} onBlur={formik.handleBlur} className={verifyClass('dni')} />
                                    {showErrors('dni')}
                                </FormGroup>
                                <FormGroup label="Peso (kg)" className="col-md-2">
                                    <Input id='weight' type='number' onChange={formik.handleChange} value={formik.values.weight} onBlur={formik.handleBlur} className={verifyClass('weight')} />
                                    {showErrors('weight')}
                                </FormGroup>
                            </div>

                            <div className="row mt-2 p-0">
                                <FormGroup label='Apellidos' className="col-md-4">
                                    <Input id='last_name' onChange={formik.handleChange} value={formik.values.last_name} onBlur={formik.handleBlur} className={verifyClass('last_name')} />
                                    {showErrors('last_name')}
                                </FormGroup>
                                <FormGroup requiredInputLabel label='Género' className="col-md-2">
                                    <Select
                                        name="sex"
                                        id='sex'
                                        value={formik.values.sex}
                                        onChange={formik.handleChange}
                                        ariaLabel='Selector de género'
                                        list={[
                                            { value: 'H', label: 'Hombre', },
                                            { value: 'M', label: 'Mujer' },
                                        ]}
                                        className={verifyClass('sex')}
                                        placeholder={'H / M'}
                                    />
                                    {showErrors('sex')}
                                </FormGroup>
                                <FormGroup label='Fecha de nacimiento' className="col-md-3">
                                    <Input id='birthday_date' type='date' onChange={formik.handleChange} value={formik.values.birthday_date} className={verifyClass('birthday_date')} />
                                    {showErrors('birthday_date')}
                                </FormGroup>
                                <FormGroup label='Teléfono' className="col-md-3">
                                    <InputPhone
                                        id='telephone'
                                        onBlur={formik.handleBlur}
                                        labels={es}
                                        international
                                        defaultCountry="ES"
                                        onChange={e => { formik.setFieldValue('telephone', e) }}
                                        value={formik.values.telephone || ''}
                                        className={verifyClass('telephone')}
                                    />
                                    {showErrors('telephone')}
                                </FormGroup>
                            </div>

                            {(users as Users) && (
                                <div className="row mt-4">
                                    <CardTitle>Asignar</CardTitle>
                                    {(auth.userHasRole(ROLES.SUPER_ADMIN)) && (
                                        <FormGroup requiredInputLabel label='Organización'>
                                            <Select
                                                id='company'
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    formik.handleChange(e)
                                                    formik.setFieldValue('company', e.target.value)
                                                }}
                                                value={formik.values.company}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('company')}
                                                ariaLabel='Listado de organizaciones'
                                                placeholder='Elegir...'
                                                list={getOrganizationsList()}
                                            />
                                            {showErrors('company')}
                                        </FormGroup>
                                    )}

                                    <FormGroup requiredInputLabel label='Profesional' className='col-md-5'>
                                        <Select
                                            id='user'
                                            onChange={formik.handleChange}
                                            value={formik.values.user}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('user')}
                                            ariaLabel='Listado de profesionales'
                                            placeholder='Elegir...'
                                            list={getUsersList()}
                                        />
                                        {showErrors('user')}
                                    </FormGroup>

                                    <FormGroup label="Centro de trabajo" className='col-md-7'>
                                        <SearchableSelect
                                            isMulti
                                            isClearable
                                            name='workplace'
                                            options={getWorkplacesList().filter((option: any) => selectedUserWorkplaces.includes(option.value))}
                                            onChange={(e: any) => formik.setFieldValue('workplaces', e ? e.map((g: any) => g.value) : [])}
                                            value={getWorkplacesList().filter((option: any) => formik.values.workplaces?.includes(option.value))}
                                            placeholder="centro de trabajo"
                                            isDisabled={!userCan('list_workplace', 'workplace') || !formik.values.user}
                                        />
                                        {showErrors('workplaces')}
                                    </FormGroup>
                                </div>
                            )}

                            <div className="row mt-4">
                                <CardTitle>Ubicación</CardTitle>
                                <FormGroup label='País' className="col-md-4">
                                    <CustomSearchSelect isMulti={false} id='country' defaultValue={COUNTRIES.find((country) => country.value === formik.values.country)}
                                        placeholder="Selecciona un país..." search={true} options={COUNTRIES} onChangeSingle={(e: any) => { formik.setFieldValue('country', e.value) }} />
                                    {showErrors('country')}
                                </FormGroup>
                                <FormGroup label='Provincia' className="col-md-4">
                                    <Input id='province' onChange={formik.handleChange} value={formik.values.province} onBlur={formik.handleBlur} className={verifyClass('province')} />
                                    {showErrors('province')}
                                </FormGroup>
                                <FormGroup label='Localidad' className="col-md-4">
                                    <Input id='city' onChange={formik.handleChange} value={formik.values.city} onBlur={formik.handleBlur} className={verifyClass('city')} />
                                    {showErrors('city')}
                                </FormGroup>
                                <FormGroup label='Dirección' className="col-md-10">
                                    <Input id='address' onChange={formik.handleChange} value={formik.values.address} className={verifyClass('address')} />
                                    {showErrors('address')}
                                </FormGroup>
                                <FormGroup label='Código Postal' className="col-md-2">
                                    <Input type='number' id='pc' onChange={formik.handleChange} value={formik.values.pc} onBlur={formik.handleBlur} className={verifyClass('pc')} placeholder="00000" />
                                    {showErrors('pc')}
                                </FormGroup>
                            </div>

                            <div className="row mt-4">
                                <CardTitle>Seguridad</CardTitle>

                                <CustomPasswordInput
                                    formik={formik}
                                    id='password'
                                    value={formik.values.password}
                                    label="Contraseña"
                                    cols={6}
                                />

                                <CustomPasswordInput
                                    formik={formik}
                                    id='passwordConfirm'
                                    value={formik.values.passwordConfirm}
                                    label="Confirmar Contraseña"
                                    cols={6}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-3">Patologías</CardTitle>
                                    <SearchableSelect
                                        isMulti
                                        isClearable
                                        name='pathologies'
                                        options={getPathologyOptions()}
                                        onChange={(e: any) => formik.setFieldValue('pathologies', e ? e.map((i: any) => i.value) : [])}
                                        value={getPathologyOptions().filter((option: any) => formik.values.pathologies?.includes(option.value))}
                                        placeholder="patologías"
                                        classname="mt-3"
                                    />

                                    <CardTitle className="mt-3">Aversiones</CardTitle>
                                    {foodsData && (
                                        <FormGroup label='Alimentos'>
                                            <div className='row g-4'>
                                                <SearchableSelect
                                                    name="food_aversions"
                                                    isSearchable
                                                    isMulti
                                                    isClearable
                                                    options={getFoodsList()}
                                                    onChange={(e: any) => { formik.setFieldValue('food_aversions', e?.map((food: any) => food.value)) }}
                                                    placeholder='alimentos'
                                                    onBlur={formik.handleBlur}
                                                    defaultValue={getFoodsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                                />
                                            </div>
                                            {showErrors('food_aversions')}
                                        </FormGroup>
                                    )}

                                    {groupsData && (
                                        <FormGroup label='Grupos' className='mt-3'>
                                            <div className='row g-4'>
                                                <SearchableSelect
                                                    name="group_aversions"
                                                    isSearchable
                                                    isMulti
                                                    isClearable
                                                    options={getGroupsList()}
                                                    onChange={(e: any) => { formik.setFieldValue('group_aversions', e?.map((group: any) => group.value)) }}
                                                    placeholder='grupos'
                                                    onBlur={formik.handleBlur}
                                                    defaultValue={getGroupsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                                />
                                                {showErrors('group_aversions')}
                                            </div>
                                        </FormGroup>
                                    )}

                                    <CardTitle className="mt-3 mb-3">Intolerancias</CardTitle>
                                    <SearchableSelect
                                        isMulti
                                        isClearable
                                        name='clientAversions'
                                        options={getIntolerancesList()}
                                        onChange={(e: any) => formik.setFieldValue('intolerances', e ? e.map((i: any) => i.value) : [])}
                                        value={getIntolerancesList().filter((option: any) => formik.values.intolerances?.includes(option.value))}
                                        placeholder="intolerancias"
                                    />
                                    {showErrors('intolerances')}

                                    <CardTitle className="mt-3 mb-3">Alergias</CardTitle>
                                    <SearchableSelect
                                        isMulti
                                        isClearable
                                        name='allergies'
                                        options={getAllergyOptions()}
                                        onChange={(e: any) => formik.setFieldValue('allergies', e ? e.map((i: any) => i.value) : [])}
                                        value={getAllergyOptions().filter((option: any) => formik.values.allergies?.includes(option.value))}
                                        placeholder="alergias"
                                    />
                                    {showErrors('allergies')}

                                    <CardTitle className="mt-3 mb-3">Estado Fisiológico</CardTitle>
                                    <SearchableSelect
                                        isClearable
                                        name='physiologicalState'
                                        options={getPhysiologicalStateOptions()}
                                        onChange={(e: any) => formik.setFieldValue('physiologicalState', e ? e.value : '')}
                                        value={getPhysiologicalStateOptions().find((option: any) => option.value === formik.values.physiologicalState)}
                                        onBlur={formik.handleBlur}
                                        styles={verifyClass('physiologicalState')}
                                        placeholder='estado fisiológico'
                                    />
                                    {showErrors('physiologicalState')}
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </form>
            </ModalBody>

            <ModalFooter>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setOpenModal(false)} className='btn btn-outline-danger me-2'>Cancelar</Button>
                    <Button type='submit' onClick={formik.handleSubmit} className='btn btn-primary' isDisable={loading}>
                        {loading ? <Spinner color={'dark'} /> : 'Guardar'}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
};

export default PatientModalForm;