import { FC, Fragment, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import CustomSearchInput from '../../../components/bootstrap/forms/CustomSearchInput';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Icon from '../../../components/icon/Icon';
import SearchableSelect from '../../../components/select/SearchableSelect';
import { NUTRIENTS } from '../../../utils/mapping-collection';

interface INutrientsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const NutrientConfigurationFilters: FC<INutrientsFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    // STATES

    const [filterMenu, setFilterMenu] = useState(false);
    const [resetkey, setResetKey] = useState(0);

    // HOOKS


    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja el cambio de texto en el campo de búsqueda.
     * @EN Function that handles text change in search field.
     * 
     * @param search 
     */
    //------------------------------------------------------------------------------------------------
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };
    //------------------------------------------------------------------------------------------------

    // RENDER

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>Filtros</Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'>Filtros de la configuración de nutrientes</OffCanvasTitle>
                    <Button color="storybook" isLight onClick={() => {
                        resetFilters()
                        setResetKey(resetkey + 1)
                    }}>
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4' key={resetkey}>
                        <FormGroup label='Nutrientes' className='col-12'>
                            <SearchableSelect
                                isMulti
                                name='nutrients-select'
                                options={NUTRIENTS}
                                onChange={(e: any) => updateFilters({ name: e.map((item: any) => item.label) })}
                                defaultValue={filters.filter_filters.name ?
                                    NUTRIENTS.filter((item: any) => filters.filter_filters.name.includes(item.label)).map((item: any) => ({ value: item.value, label: item.label }))
                                    : []}
                                placeholder='nutrientes'
                            />
                        </FormGroup>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default NutrientConfigurationFilters;