import { useCallback, useEffect, useState } from 'react';
import { IntolerancesService } from '../../services/intolerances/intolerancesService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage intolerances fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de intolerancias.
----------------------------------------------------------------------------------------*/

const useIntolerances = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchData = useCallback(async () => {
        try {
            const response = await (new IntolerancesService()).listIntolerances({ limit: 999999 });
            const fetchedData = response.getResponseData()?.data;

            if (fetchedData && fetchedData?.intolerances) {
                const mappedData = fetchedData.intolerances?.map((data: { name: string; id: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching intolerances:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const getIntolerancesList = () => {
        return data;
    };

    return { fetchData, getIntolerancesList };
}

export default useIntolerances;