const calibratorMap: any = {
    'energía': 'Kcal',
    'proteinas': 'Proteinas',
    'carbohidratos': 'Carbohidratos',
    'grasas': 'Grasas',
    'minerales': 'Minerales',
    'vitaminas': 'Vitaminas'
};

 // Mapear con las claves de dataObjectives
 const objectiveMap: any = {
    'energía': 'kcal',
    'proteinas': 'protein',
    'carbohidratos': 'carbohydrates',
    'grasas': 'fat'
};

export const getPatologhysIntake = (recommendIntake : any, dataCalibrator:any, groupName: any, dataNutrients: any, nutrient:any)  => {

    const calibratorKey = calibratorMap[groupName] || null;
    const calibratorValue = calibratorKey ? dataCalibrator?.[calibratorKey] : null;

    
    let findedNutrient = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === nutrient?.nombre
    })

    let findedNutrientIntake = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === recommendIntake?.nutrient
    })


    // Si el nutriente ha sido encontrado en el configurador de nutrientes y no hay IDR para el nutriente recomendado y no tiene estado fisiológico
    
    if (findedNutrient !== undefined && recommendIntake?.physiologicalState === false && findedNutrientIntake === undefined) {

        return `${(findedNutrient?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9))?.toFixed(2)} 
        - 
        ${(findedNutrient?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1))?.toFixed(2)}`

    // Si el nutriente ha sido encontrado en el configurador de nutrientes y hay IDR para el nutriente recomendado y no tiene estado fisiológico
    } else if (findedNutrientIntake !== undefined && recommendIntake?.physiologicalState === false) {

        return `${(findedNutrientIntake?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9))?.toFixed(2)} 
        - 
        ${(findedNutrientIntake?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1))?.toFixed(2)}`


    // Si el nutriente no ha sido encontrado en el configurador de nutrientes y tiene estado fisiológico
    } else {
        
        return `${recommendIntake?.quantity
            ? `${(recommendIntake?.quantity * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9))?.toFixed(2)} 
            - 
            ${(recommendIntake?.quantity * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1))?.toFixed(2)}`
            : '0'}`

    }
}

export const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export const getObjectivesData = (recommendIntake : any, dataCalibrator:any, groupName: any, dataNutrients: any, nutrient: any, dataObjectives: any)  => {

    const normalizedName = nutrient.nombre.trim().toLowerCase();

    // Obtener el nombre base para la búsqueda
    let searchValue = normalizedName.includes('/')
        ? normalizedName.split('/')[0].trim().toLowerCase()
        : normalizedName.includes('(')
            ? normalizedName.split('(')[0].trim().toLowerCase()
            : normalizedName;


    const objectiveKey = objectiveMap[searchValue] || null;
    const objectiveValue = objectiveKey ? dataObjectives?.[objectiveKey] : null;

    const calibratorKey = calibratorMap[searchValue] || null;
    const calibratorValue = calibratorKey ? dataCalibrator?.[calibratorKey] : null;

    let findedNutrient = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === nutrient?.nombre
    })

    let findedNutrientIntake = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === recommendIntake?.nutrient
    })

    // Si el nutriente es un nutriente con valor objetivo
    if(objectiveValue !== null) {

        // Calcular si está fuera del rango permitido (±10%)
        const minRange = objectiveValue ? objectiveValue * (100 - calibratorValue?.Min)/100 : null;
        const maxRange = objectiveValue ? objectiveValue * (100 + calibratorValue?.Max)/100 : null;
      
        return `${objectiveValue !== null 
            ? `${(minRange!?.toFixed(2))} - ${(maxRange!?.toFixed(2))}`
            : '0'}`

    // Si el nutriente ha sido encontrado en el configurador de nutrientes y no hay IDR para el nutriente recomendado y no tiene estado fisiológico
    } else if (findedNutrient !== undefined && recommendIntake?.physiologicalState === false && findedNutrientIntake === undefined) {

        return `${(findedNutrient?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9))?.toFixed(2)} 
        - 
        ${(findedNutrient?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1))?.toFixed(2)}`

        // Si el nutriente ha sido encontrado en el configurador de nutrientes y hay IDR para el nutriente recomendado y no tiene estado fisiológico
    } else if (findedNutrientIntake !== undefined && recommendIntake?.physiologicalState === false) {

        return `${(findedNutrientIntake?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9))?.toFixed(2)} 
        - 
        ${(findedNutrientIntake?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1))?.toFixed(2)}`

        // Si el nutriente no ha sido encontrado en el configurador de nutrientes y tiene estado fisiológico
    } else {
        return `${recommendIntake?.quantity
            ? `${(recommendIntake?.quantity * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9))?.toFixed(2)} 
            - 
            ${(recommendIntake?.quantity * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1))?.toFixed(2)}`
            : '0'}`

    }
}


export const isOutOfRangePatologhysIntake = (recommendIntake : any, dataCalibrator:any, groupName: any, dataNutrients: any, nutrient:any)  => {

    const calibratorKey = calibratorMap[groupName] || null;
    const calibratorValue = calibratorKey ? dataCalibrator?.[calibratorKey] : null;

    
    let findedNutrient = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === nutrient?.nombre
    })

    let findedNutrientIntake = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === recommendIntake?.nutrient
    })

    // Si el nutriente ha sido encontrado en el configurador de nutrientes y no hay IDR para el nutriente recomendado y no tiene estado fisiológico
    if (findedNutrient !== undefined && recommendIntake?.physiologicalState === false && findedNutrientIntake === undefined) {
        return `col-3 ${findedNutrient?.min && 
            (nutrient.value < findedNutrient?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9) || 
            nutrient.value > findedNutrient?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1)    ) 
            ? 'text-danger font-bold' 
            : ''}`

    // Si el nutriente ha sido encontrado en el configurador de nutrientes y hay IDR para el nutriente recomendado y no tiene estado fisiológico
    } else if (findedNutrientIntake !== undefined && recommendIntake?.physiologicalState === false && findedNutrientIntake === undefined) {

        return `col-3 ${findedNutrientIntake?.min && 
            (nutrient.value < findedNutrientIntake?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9) || 
            nutrient.value > findedNutrientIntake?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1)    ) 
            ? 'text-danger font-bold' 
            : ''}`

    // Si el nutriente no ha sido encontrado en el configurador de nutrientes y tiene estado fisiológico
    } else {

        return `col-3 ${recommendIntake?.quantity && 
            (nutrient.value < recommendIntake?.quantity * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9) || 
            nutrient.value > recommendIntake?.quantity * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1)    ) 
            ? 'text-danger font-bold' 
            : ''}`

    }
}


export const isOutOfRangeObjectives = (recommendIntake : any, dataCalibrator:any, groupName: any, dataNutrients: any, nutrient: any, dataObjectives: any)  => {

    const normalizedName = nutrient.nombre.trim().toLowerCase();

    // Obtener el nombre base para la búsqueda
    let searchValue = normalizedName.includes('/')
        ? normalizedName.split('/')[0].trim().toLowerCase()
        : normalizedName.includes('(')
            ? normalizedName.split('(')[0].trim().toLowerCase()
            : normalizedName;


    const objectiveKey = objectiveMap[searchValue] || null;
    const objectiveValue = objectiveKey ? dataObjectives?.[objectiveKey] : null;

    const calibratorKey = calibratorMap[searchValue] || null;
    const calibratorValue = calibratorKey ? dataCalibrator?.[calibratorKey] : null;

    let findedNutrient = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === nutrient?.nombre
    })

    let findedNutrientIntake = dataNutrients?.find((nutrientMaxMin: any) =>{
        return  removeAccents(nutrientMaxMin.name) === recommendIntake?.nutrient
    })

    // Si el nutriente es un nutriente con valor objetivo
    if(objectiveValue !== null) {

        // Calcular si está fuera del rango permitido (±10%)
        const minRange = objectiveValue ? objectiveValue * (100 - calibratorValue?.Min)/100 : null;
        const maxRange = objectiveValue ? objectiveValue * (100 + calibratorValue?.Max)/100 : null;
      
        const isOutOfRange = objectiveValue !== null &&
        (nutrient.value < minRange! || nutrient.value > maxRange!);
        if(isOutOfRange) return 'col-3 text-danger font-bold';
    // Si el nutriente ha sido encontrado en el configurador de nutrientes y no hay IDR para el nutriente recomendado y no tiene estado fisiológico
    } else if (findedNutrient !== undefined && recommendIntake?.physiologicalState === false && findedNutrientIntake === undefined) {

        return `col-3 ${findedNutrient?.min && 
            (nutrient.value < findedNutrient?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9) || 
            nutrient.value > findedNutrient?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1)    ) 
            ? 'text-danger font-bold' 
            : ''}`
    // Si el nutriente ha sido encontrado en el configurador de nutrientes y hay IDR para el nutriente recomendado y no tiene estado
    } else if (findedNutrientIntake !== undefined && recommendIntake?.physiologicalState === false) {

        return `col-3 ${findedNutrientIntake?.min && 
            (nutrient.value < findedNutrientIntake?.min * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9) || 
            nutrient.value > findedNutrientIntake?.max * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1)    ) 
            ? 'text-danger font-bold' 
            : ''}`
// Si el nutriente no ha sido encontrado en el configurador de nutrientes y tiene estado fisiológico
    } else {

            return `col-3 ${recommendIntake?.quantity && 
            (nutrient.value < recommendIntake?.quantity * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9) || 
            nutrient.value > recommendIntake?.quantity * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1)    ) 
            ? 'text-danger font-bold' 
            : ''}`

    }
}





