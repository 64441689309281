import moment, { Moment } from "moment";
import { CreatedAt } from "./apiResponse-type";
import { AppointmentClient } from "./client-type";
import { TColor } from "./color-type";
import { Company } from "./company-type";
import { User } from "./user-type";

export interface IEvent {
    start?: Date;
    end?: Date;
    name?: string;
    id?: string;
    status?: number;
    user?: IEventUser;
    color?: TColor;
    colorHex?: string;
    icon?: string;
    users?: IEventUser[];
    client?: AppointmentClient | null,
    comments?: string | null;
    notified?: boolean;
    consultationType?: string | null;
    appointmentType?: string | number | null;
    serviceType?: string;
    appointment_color?: string | null;
    paymentMethod?: string | null;
    price?: number | null;
    workPlace?: any;
    notifyBeforeADay?: boolean;
    notifyBeforeTwoDays?: boolean;
}

export interface IEventUser {
    id?: string;
    name?: string;
    lastName?: string;
    src?: string;
    srcSet?: string;
    color?: string | null;
}

export interface AppointmentFormData {
    start?: Moment;
    end?: Moment | string;
    name?: string;
    id?: string;
    client?: string;
    user?: string;
    color?: string;
    createdAt?: string;
    updatedAt?: string;
    comments?: string;
    concept?: string;
    status?: number;
    consultationType?: string;
    serviceType?: string;
    workPlace?: string;
    appointmentType?: string | number;
    appointment_color?: string;
    paymentMethod?: string | any;
    paymentMethodLabel?: string;    
    price?: number;
    notifyBeforeADay?: boolean;
    notifyBeforeTwoDays?: boolean;
}

type TAppointmentStatuses = {
    [key: string]: any
}

export const APPOINTMENT_STATUSES_MAP: TAppointmentStatuses = {
    PENDING: {
        id: 1,
        name: 'Pendiente',
        color: '#FD8D14'
    },
    CONFIRMED: {
        id: 2,
        name: 'Confirmada',
        color: '#91C8E4'

    },
    PAID: {
        id: 3,
        name: 'Pagada',
        color: '#CECE5A'
    },
    CANCELLED: {
        id: 4,
        name: 'Cancelada',
        color: '#C51605'
    },
}

export const PAYMENT_METHODS_MAP: any = [
    { value: 'Efectivo', label: 'Efectivo' },
    { value: 'Tarjeta', label: 'Tarjeta' },
    { value: 'Bizum', label: 'Bizum' },
    { value: 'Transferencia', label: 'Transferencia' },
];


export type Appointment = {
    id?: string;
    name?: string;
    type?: string;
    appointmentColor?: null;
    startDate?: CreatedAt;
    endDate?: CreatedAt;
    createdAt?: CreatedAt;
    updateAt?: null;
    comments?: null;
    status?: number;
    consultationType?: any;
    appointmentType?: string | number;
    serviceType?: any;
    paymentMethod?: string;
    price?: number;
    notified?: boolean;
    client?: AppointmentClient | null;
    company?: Company;
    user?: User;
    workPlace?: any;
    notifyBeforeADay?: boolean;
    notifyBeforeTwoDays?: boolean;
}

export const APPOINTMENT_STATUSES: any[] = Object.values(APPOINTMENT_STATUSES_MAP);

export const getAppointmentStatus = (id: number) => {
    for (const status of Object.values(APPOINTMENT_STATUSES_MAP)) {
        if (status.id === id) {
            return status;
        }
    }
}

export const getAppointmentFormFromEvent = (event: IEvent): AppointmentFormData => {
    return {
        id: event.id,
        name: event.name,
        start: moment(event.start),
        end: moment(event.end),
        color: event.color,
        comments: event.comments ? event.comments : undefined,
        status: event.status,
        workPlace: event.workPlace ? event.workPlace : '',
        serviceType: event.serviceType ? event.serviceType : '',
        appointmentType: event.appointmentType ? event.appointmentType : '',
        appointment_color: event.appointment_color ? event.appointment_color : undefined,
        client: event.client?.id,
        user: event.user?.id,
    }
}