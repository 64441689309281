import { useFormik } from 'formik'
import { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Select from '../../../../components/bootstrap/forms/Select'
import Icon from '../../../../components/icon/Icon'
import SearchableSelect from '../../../../components/select/SearchableSelect'
import useAllergens from '../../../../hooks/api-calls/useAllergens'
import useFoodGroups from '../../../../hooks/api-calls/useFoodGroups'
import { MONTHS, NUTRIENTS } from '../../../../utils/mapping-collection'

interface IFoodsFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: () => void;
    updateFilterOrder: (keyValue: string, order: "asc" | "desc") => void
    filters: any;
}

const FoodsFilters: FC<IFoodsFiltersProps> = ({ updateFilters, updateFilterOrder, updatePageSize, filters, resetFilters }) => {

    const [nutritientsSelected, setNutritientsSelected] = useState<any>(null);
    const [orderSelected, setOrderSelected] = useState<any>(null);
    const [filterMenu, setFilterMenu] = useState(false);
    const [groupsSelected, setGroupsSelected] = useState<any>([]);
    const [allergensSelected, setAllergensSelected] = useState<any>([]);

    const { getFoodGroupsOptions, isLoadingFoodGroups } = useFoodGroups();
    const { getAllergenOptions, isLoadingAlergens } = useAllergens();

    // controlar el cambio en el selector de nutrientes
    const handleNutritientsChange = (e: any) => {
        setNutritientsSelected(e);
        if (orderSelected?.value) {
            updateFilterOrder(e.value, orderSelected.value);
        }
    }

    // controlar el cambio en el selector de orden
    const handleOrderChange = (e: any) => {
        setOrderSelected(e);
        if (nutritientsSelected?.value) {
            updateFilterOrder(nutritientsSelected.value, e.value);
        }
    }

    // controlar el cambio en el selector de grupos
    const handleGroupsChange = (e: any) => {
        setGroupsSelected(e);
        updateFilters({ group_food: e.map((group: any) => group.value) });
    }

    // controlar el cambio en el selector de alérgenos
    const handleAllergensChange = (e: any) => {
        setAllergensSelected(e);
        updateFilters({ allergens: e.map((allergen: any) => allergen.value) });
    }

    const initialValues = {
        group_food: undefined,
        allergens: undefined,
        season: undefined,
        seasonend: undefined,
    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values: any) => {
            updateFilters({
                ...values,
                search_array: values.search
            })
        }
    })

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='ImportExport' size='2x' color='secondary' />
            </label>

            <SearchableSelect
                isMulti={false}
                onChange={(e: any) => handleNutritientsChange(e)}
                value={nutritientsSelected}
                name='nutrient_filter'
                placeholder='nutrientes'
                options={NUTRIENTS}
                styles={{ minWidth: '300px !important' }}
            />

            <SearchableSelect
                isMulti={false}
                onChange={(e: any) => handleOrderChange(e)}
                value={orderSelected}
                name='order_filter'
                completePlaceholder='Ordenar por...'
                options={[{ value: 'asc', label: 'Ascendente' }, { value: 'desc', label: 'Descendente' }]}
            />

            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>
            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                    formik.setFieldValue('search', search);
                }}
                placeholder='Buscar...'
            />

            <Button color='secondary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='foodsFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='foodsFilters'> Filtros de Alimentos </OffCanvasTitle>
                    <Button
                        color="storybook"
                        isLight
                        onClick={() => {
                            resetFilters()
                            setGroupsSelected([])
                            setAllergensSelected([])
                        }}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Grupos de alimentos'>
                                {
                                    <SearchableSelect
                                        isMulti
                                        onChange={(e: any) => handleGroupsChange(e)}
                                        value={groupsSelected}
                                        name='groups_filter'
                                        placeholder='grupos'
                                        options={getFoodGroupsOptions()}
                                    />
                                }
                            </FormGroup>

                            <FormGroup id='filter2' label='Alérgenos (alimentos que no los contengan)' className='mt-4'>
                                {
                                    <SearchableSelect
                                        isMulti
                                        onChange={(e: any) => handleAllergensChange(e)}
                                        value={allergensSelected}
                                        name='allergen_filter'
                                        placeholder='alérgenos'
                                        options={getAllergenOptions()}
                                    />
                                }
                            </FormGroup>

                            <FormGroup id='filter3' label='Temporalidad' className='mt-4'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-3 d-flex flex-column align-content-center'><p
                                                className='m-0 d-flex align-content-center'
                                                style={{ height: '100%' }}></p></div>
                                            <div className='col-12'>
                                                <Select
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ season: e.target.value })}
                                                    value={filters.filter_filters.season}
                                                    id='season-select'
                                                    list={MONTHS}
                                                    ariaLabel={'Inicio de temporada'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default FoodsFilters;