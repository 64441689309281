import { FC, Fragment } from 'react';
import CustomSearchInput from '../../../components/bootstrap/forms/CustomSearchInput';
import Icon from '../../../components/icon/Icon';


interface IAppFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const AppFilters: FC<IAppFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    // STATES

    // const [filterMenu, setFilterMenu] = useState(false);
    // const [resetkey, setResetKey] = useState(0);

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función que maneja el cambio de texto en el campo de búsqueda.
     * @EN Function that handles text change in search field.
     * 
     * @param search 
     */
    //--------------------------------------------------------------------------------------------------
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };
    //--------------------------------------------------------------------------------------------------

    return (

        <Fragment>
           <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

        </Fragment>

    );

}

export default AppFilters;