import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";
import { showErrors } from "../../../../utils/Forms/FormikVerifyClass";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import { MenuService } from "../../../../services/menus/menuService";
import { toast } from "react-toastify";

interface EditWeightHeightMenuProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    menuData: any;
    refecthData: () => void;
}

const EditWeightHeightMenu: FC<EditWeightHeightMenuProps> = ({ isOpen, setIsOpen, menuData, refecthData }) => {


    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------
    /**
     * @ES Actualizar el peso y la altura del menú del cliente
     * @EN Update the weight and height of the client's menu
     * @param values
     */
    //-------------------------------------------------------------------------------------------------
    const handleSubmit = async (values: any) => {
        try {
            const response = await new MenuService().editWeightHeightMenu(values);
            if (response.getResponseData().success) {
                toast.success('Peso y altura actualizados correctamente');
            } else {
                toast.error('Error al actualizar el peso y la altura');
            }
        } catch (error: any) {
            toast.error('Error al actualizar el peso y la altura');
        } finally {
            refecthData();
            setIsOpen(false);
        }
    }
    //-------------------------------------------------------------------------------------------------

    // FORMIK

    const validationSchema = Yup.object({
        weight: Yup.number().required('El peso es obligatorio'),
        height: Yup.number().required('La altura es obligatoria')
    });

    const formik = useFormik({
        initialValues: {
            menu: menuData.id,
            weight: menuData.weight,
            height: menuData.height,
            toClient: false
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(formik.values);
        }
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Editar peso y altura">
            <ModalHeader setIsOpen={setIsOpen} className="p-4">
                <ModalTitle id="edit-weight-height-data">Editar peso y altura</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody>
                    <div>
                            <div className="form-group">
                                <label htmlFor="height">Altura (cm)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    value={formik.values.height}
                                    onChange={formik.handleChange}
                                />
                                {showErrors(formik, 'height')}
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor="weight">Peso (kg)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="weight"
                                    name="weight"
                                    value={formik.values.weight}
                                    onChange={formik.handleChange}
                                />
                                {showErrors(formik, 'weight')}
                            </div>


                            <div className="form-group form-switch mt-5 fs-5">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        role="switch"
                                        id="toClient"
                                        name="toClient"
                                        checked={formik.values.toClient}
                                        onChange={(e) => formik.setFieldValue("toClient", e.target.checked)}
                                    />
                                    <label className="form-check-label ms-2" htmlFor="toClient">
                                        Pasar cambios a la ficha del cliente
                                    </label>
                            </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-primary">Editar</button>
                </ModalFooter>
            </form>
        </Modal>
    );


}

export default EditWeightHeightMenu;