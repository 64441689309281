import { useCallback, useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import useFetch from "../../hooks/useFetch";
import { CalibratorService } from "../../services/calibrators/calibratorService";
import { MenuService } from "../../services/menus/menuService";
import { NutrientConfigurationService } from "../../services/nutrient-configuration/nutrientService";
import { Menu } from "../../type/clientMenus-type";
import { FixNumber } from "../../utils/FixNumber";
import { COLORS } from "../../utils/mapping-collection";
import Accordion, { AccordionItem } from "../bootstrap/Accordion";
import Spinner from "../bootstrap/Spinner";
import Icon from "../icon/Icon";
import { usePrivilege } from "../priviledge/PriviledgeProvider";
import { getObjectivesData, getPatologhysIntake, isOutOfRangeObjectives, isOutOfRangePatologhysIntake } from "./Utils/IDR";

export const macronutrients = ["starch", "proteins", "fat"];

export interface NutritionalValuesProps {
    nutValues?: any;
    clientId?: string;
    menuId?: string;
    menuDays?: number;
    isUpdated: number,
    dataObjectives: any;
    refetchDataObjectives: () => void;
    weight: number;
    height: number
}

const NutritionalMenuValues: React.FC<NutritionalValuesProps> = ({ nutValues, clientId, menuId, menuDays, isUpdated, dataObjectives, refetchDataObjectives, weight, height }) => {

    const [nutrientsGroups, setNutrientsGroups] = useState<any>([]);
    const [renderNutrients, setRenderNutrients] = useState(false);
    const [renderRecommendations, setRenderRecommendations] = useState(false);
    const { userCan } = usePrivilege();

    const nutrientOrder = [
        { name: "Energía (kcal)", tab: false },
        { name: "Proteinas (g)", tab: false },
        { name: "Carbohidratos (g)", tab: false },
        { name: "HC complejos (g)", tab: true },
        { name: "HC sencillos (g)", tab: true },
        { name: "Azúcares intrínsecos (g)", tab: true },
        { name: "Azúcares extrínsecos (g)", tab: true },
        { name: "Fibra total (g)", tab: true },
        { name: "Grasas (g)", tab: false },
        { name: "Acidos grasos monoinsaturados (g)", tab: true },
        { name: "Acidos grasos poliinsaturados (g)", tab: true },
        { name: "Acidos grasos saturados (g)", tab: true },
        { name: "Colesterol (mg)", tab: true },
        { name: "Sal (g)", tab: false },
        { name: "Alcohol (g)", tab: false },
        { name: "Agua (g)", tab: false }
    ];

    const [NutritionalValues, loadingValues] = useFetch(useCallback(async () => {
        if (menuId === undefined || !menuId) return;
        const response = (await (new MenuService()).getNutritionalValues({ menu: menuId, ...nutValues }));
        return response.getResponseData() as Menu;
    }, [nutValues]));

    const [RecommendedDailyIntakes, loadingDailyIntakesValues] = useFetch(useCallback(async () => {
        if (clientId === undefined || !clientId) return;
        const response = (await (new MenuService()).getRecommendedDailyIntakes(clientId, weight, height));
        return response.getResponseData();
    }, [clientId]));

    const [dataCalibrator] = useFetch(useCallback(async () => {
        const response = (await new CalibratorService().getCalibratorByCompany());
        return response.getResponseData();
    }, []));

    const [dataNutrients] = useFetch(useCallback(async () => {
        if (userCan('get', 'nutrient-configurator')) {
            const response = (await new NutrientConfigurationService().getAllByCompany());
            return response.getResponseData();
        }
        return [];
    }, []));

    // Agrupa los nutrientes
    const groupNutrients = (nutrients: []) => {
        // Clasifica los nutrientes por grupo
        const groups = nutrients.reduce((acc: any, nutrient: any) => {
            const nutrientGroupExists = acc.find((group: any) => group.group === nutrient.group);
            if (!nutrientGroupExists) {
                acc.push({
                    group: nutrient.group,
                    group_name: nutrient.grupo,
                    nutrients: []
                })
            }
            return acc;
        }, []);

        // Añade los nutrientes a su respectivo grupo
        nutrients.forEach((nutrient: any) => {
            const groupIndex = groups.findIndex((group: any) => group.group === nutrient.group);
            groups[groupIndex].nutrients.push(nutrient);
        });

        setNutrientsGroups(groups);
    };

    // Renderiza las etiquetas de la gráfica de los macronutrientes
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        // Obtiene los nutrientes de los macronutrientes
        const data = nutrientsGroups.find((group: any) => group.group === 'Macronutrients')?.nutrients?.filter((nutrient: any) => macronutrients.includes(nutrient.name));

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
        const name = data[index]?.name === 'starch' ? 'HC' : data[index]?.nombre.replace(/\(\w+\)/, '');
        const value = data[index]?.value || 0; // Valor sin formatear para calcular el valor por día

        const formattedValue = value.toFixed(2) || 0; // Valor formateado para mostrar
        const unitMeasure = data[index]?.nombre.match(/\(([^)]+)\)/)[1];

        // Calcula el porcentaje redondeado
        const percentage = Math.round(percent * 100);
        // Calcula el valor por día
        const dailyValue = menuDays ? ((value / weight) / menuDays)?.toFixed(2) : null;
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                <tspan x={x < cx ? x + 22 : x - 22} dy="-0.5em" fontSize={"13px"} fontWeight={"bold"}>{name}</tspan>
                <tspan x={x < cx ? x + 22 : x - 22} dy="1em" fontSize={"12px"}>{`${percentage}% - ${formattedValue} ${unitMeasure}`}</tspan>
                {dailyValue && (
                    <tspan x={x < cx ? x + 22 : x - 22} dy="1.1em" fontSize={"12px"}>
                        {`${dailyValue} ${unitMeasure}/kg/día`}
                    </tspan>
                )}
            </text>
        );
    };

    const orderNutrients = (nutrientGroup: any) => {
        let nutrientsOrdered: any = [];
        if (nutrientGroup.group !== 'Macronutrients') {
            return nutrientsOrdered
        }
        nutrientOrder.forEach((nutrient: any) => {
            const nutrientFound = nutrientGroup.nutrients.find((nutrientItem: any) => nutrientItem.nombre === nutrient.name);
            if (nutrientFound) {
                if (nutrient.tab) {
                    nutrientFound.tab = true;
                }
                nutrientsOrdered.push(nutrientFound);
            }
        });
        return nutrientsOrdered;
    };

    // Cuando se obtienen los valores nutricionales, se clasifican por grupos
    useEffect(() => {
        if (NutritionalValues !== null) {
            groupNutrients(NutritionalValues);
            setRenderNutrients(true);
        }
    }, [NutritionalValues]);

    useEffect(() => {
        if (RecommendedDailyIntakes !== null) {
            setRenderRecommendations(true);
        }
    }, [RecommendedDailyIntakes]);

    if (loadingValues && loadingDailyIntakesValues) return (
        <div className="row d-flex justify-content-center align-content-center" style={{ height: '80vh' }}>
            <Spinner className='h-max' />
        </div>
    );

    return (
        <>
            {((renderNutrients && renderRecommendations && clientId != null) || (renderNutrients && clientId == null)
                ? (
                    <Accordion id={"nutrients-group"} className="row mx-2" shadow={'none'}>
                        {nutrientsGroups.map((nutrientGroup: any) => {
                            let data: any;
                            if (nutrientGroup.group === 'Macronutrients') {
                                data = nutrientGroup.nutrients.filter((nutrient: any) => macronutrients.includes(nutrient.name));

                                data = data?.map((nutrient: any) => {
                                    return {
                                        name: nutrient.nombre,
                                        value: nutrient.value,
                                    }
                                });
                            };

                            return (
                                <AccordionItem
                                    key={`group-${nutrientGroup.group}`}
                                    id={nutrientGroup.group}
                                    title={nutrientGroup.group_name}
                                    className={"col-md-12 mb-4"}
                                >
                                    {(nutrientGroup.group === 'Macronutrients') && data.length > 0 && data.reduce((acc: number, nutrient: any) => acc + nutrient.value, 0) > 0
                                        ? (
                                            <ResponsiveContainer width={'100%'} height={"100%"} className={"position-relative"}>
                                                <div className="d-flex flex-column">
                                                    {/* Calorias */}
                                                    <div className="position-absolute top-0 start-0 d-flex flex-column align-items-center">
                                                        <Icon icon="Local Fire Department" size="2x" />
                                                        <div className="text-center">{Math.round(nutrientGroup.nutrients.find((nutrient: any) => nutrient.name === 'energy')?.value)} Kcal</div>
                                                        <div>
                                                            {menuDays ?
                                                                <div className="text-center">
                                                                    {((nutrientGroup.nutrients.find((nutrient: any) => nutrient.name === 'energy')?.value / weight) / menuDays).toFixed(2)} <br />Kcal/kg/día
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>

                                                    {/* Gráfica */}
                                                    <PieChart width={400} height={250}>
                                                        <Pie
                                                            data={data.filter((nutrient: any) => nutrient.value > 0)}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={renderCustomizedLabel}
                                                            outerRadius={120}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {data.map((entry: any, index: number) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % data.length]} />
                                                            ))}
                                                        </Pie>
                                                    </PieChart>

                                                    {/* Tabla */}
                                                    <div className="mt-4">
                                                        <div className="row mb-2">
                                                            <div className="col-6">NUTRIENTE</div>
                                                            <div className="col-3">APORTE</div>
                                                            <div className="col-3">OBJETIVO</div>
                                                        </div>

                                                        {orderNutrients(nutrientGroup).map((nutrient: any) => {
                                                            const normalizedName = nutrient.nombre.trim().toLowerCase();
                                                            const recommendedDailyIntake = RecommendedDailyIntakes?.find((item: any) =>
                                                                item.nutrient.trim().toLowerCase() === normalizedName.split('/')[0].trim().toLowerCase()
                                                            );

                                                            const groupName = nutrientGroup.group_name.trim().toLowerCase();

                                                            return (
                                                                <div
                                                                    key={`nutrient-${nutrient.nombre}`}
                                                                    className="row"
                                                                    style={{ borderBottom: '1px solid #f5d0a9' }}
                                                                >
                                                                    <div className="col-6">
                                                                        <span className={`${nutrient.tab ? 'ms-3' : ''}`}>{nutrient.nombre}</span>
                                                                    </div>
                                                                    <div
                                                                        className={isOutOfRangeObjectives(recommendedDailyIntake, dataCalibrator, groupName, dataNutrients, nutrient, dataObjectives)}
                                                                    >
                                                                        {nutrient.value?.toFixed(2)}
                                                                    </div>
                                                                    <div className="col-3">
                                                                        {getObjectivesData(recommendedDailyIntake, dataCalibrator, groupName, dataNutrients, nutrient, dataObjectives)}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                {/* Leyenda */}
                                                {/* <div className="d-flex flex-column justify-content-center ml-4">
                                                {data.map((entry: any, index: number) => (
                                                    <div key={`legend-${index}`} className="d-flex flex-row align-items-center">
                                                        <div style={{ width: '10px', height: '10px', backgroundColor: COLORS[index % data.length] }}></div>
                                                        <div className="ms-2">{entry.name === 'HC complejos (g)' ? 'HC (g)' : entry.name}:</div>
                                                                        <div className="ms-2">{FixNumber(entry.value)}</div>
                                                    </div>
                                                ))}
                                                <div className="text-center">Energía: {Math.round(nutrientGroup.nutrients.find((nutrient: any) => nutrient.name === 'energy')?.value)} Kcal</div>
                                            </div> */}
                                            </ResponsiveContainer>

                                        )
                                        : (
                                            <>
                                                <div className="row mb-2">
                                                    <div className="col-6"></div>
                                                    <div className="col-3">APORTE</div>
                                                    <div className="col-3">OBJETIVO</div>
                                                </div>
                                                {nutrientGroup.nutrients.map((nutrient: any) => {
                                                    // Normalizamos ambos nombres eliminando espacios extra y convirtiendo a minúsculas
                                                    const normalizedName = nutrient.nombre.trim().toLowerCase();

                                                    const groupName = nutrientGroup.group_name.trim().toLowerCase();

                                                    const recommendedDailyIntake = RecommendedDailyIntakes?.find((item: any) =>
                                                        item.nutrient.trim().toLowerCase() === normalizedName.split('/')[0].trim().toLowerCase()
                                                    );

                                                    return (
                                                        <div
                                                            key={`nutrient-${nutrient.nombre}`}
                                                            className="row"
                                                            style={{ borderBottom: '1px solid #f5d0a9' }}
                                                        >
                                                            <div className="col-md-6">{nutrient.nombre}</div>
                                                            <div
                                                                className={
                                                                    isOutOfRangePatologhysIntake(recommendedDailyIntake, dataCalibrator, groupName, dataNutrients, nutrient)
                                                                }>
                                                                {nutrient?.value !== null ? FixNumber(nutrient?.value, 4) : 0}
                                                            </div>
                                                            <div className="col-md-3">
                                                                {/* {recommendedDailyIntake?.quantity
                                                                    ? `${(recommendedDailyIntake?.quantity * (calibratorValue ? (100 - calibratorValue.Min)/100 : 0.9))?.toFixed(2)} - ${(recommendedDailyIntake?.quantity * (calibratorValue ? (100 + calibratorValue.Max)/100 : 1.1))?.toFixed(2)}`
                                                                    : '-'} */}
                                                                {getPatologhysIntake(recommendedDailyIntake, dataCalibrator, groupName, dataNutrients, nutrient)}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        )}
                                </AccordionItem>
                            )
                        })}
                    </Accordion>
                )
                : <></>
            )}
        </>
    );
}

export default NutritionalMenuValues;