import { useFormik } from "formik";
import { FC, Fragment, useState } from "react";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import AsyncImg from "../../components/AsyncImg";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyService } from "../../services/companies/organizationService";
import { toast } from "react-toastify";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Page from "../../layout/Page/Page";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    companyData?: CompanyForm;
    logo?: any;
}

interface CompanyForm {
    cif: string;
    name: string;
    address: string;
    logo?: Document;
}

const companyInitialValues: CompanyForm = {
    cif: '',
    name: '',
    address: '',
};

const OrganizationEditSchema = yup.object({
    cif: yup.string().matches(/^([ABCDEFGHJKLMNPQSUVW]{1}[0-9]{7}[0-9A-J]{1}|\d{8}[A-HJ-NP-TV-Z])$/, 'Formato de cif/dni no válido').nullable().required('Campo obligatorio'),
    name: yup.string().min(1, 'Demasiado corto').max(30, 'Demasiado largo').required('Campo Obligatorio'),
    address: yup.string(),
});

const CompanyForm: FC<CreateFormProps> = ({ isLoading, submit, companyData, logo }) => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const mode = companyData ? 'Editar' : 'Crear';
    const buttonTitle = mode === 'Editar' ? 'Guardar Cambios' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<any>(null);

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const response = await (new CompanyService()).editOrganizationImg(id, file);
            const responseData = response.getResponseData();
            if (responseData.success) {
                toast.success('Logo actualizado');
            } else {
                toast.error(responseData.message);
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará el logo de la organización',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const response = await (new CompanyService()).deleteOrganizationImg(id);
                    const responseData = response.getResponseData();
                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la imagen");
                }
            }
        })
    }

    const verifyClass = (inputFieldID: keyof CompanyForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof CompanyForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const formik = useFormik({
        initialValues: companyData ? companyData : companyInitialValues,
        validationSchema: OrganizationEditSchema,
        onSubmit: values => submit(values)
    });

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row d-flex justify-content-center">
                    {mode === 'Editar'
                        ? (
                            <div className={"col-md-3"}>
                                <Card stretch={false} className={'mx-2'}>
                                    <CardBody className={'text-center'}>
                                        <div className={'text-center pb-4'}>
                                            {selectedImage
                                                ? <img width={200} height={200} src={selectedImage} alt="selected" className='mx-auto d-block img-fluid mb-3 rounded' />
                                                : logo
                                                    ? <AsyncImg isBackground height="200px" width="200px" styles="avatar-content rounded" id={logo.id} />
                                                    : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                                            }
                                        </div>

                                        <Input type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                            autoComplete='photo' placeholder={"Cambiar imagen"} />
                                    </CardBody>
                                    <CardFooter className={'justify-content-center'}>
                                        <Button color='dark' isLight icon='Delete' onClick={deleteImage}>Eliminar imagen</Button>
                                    </CardFooter>
                                </Card>
                            </div>
                        )
                        : <></>
                    }

                    <div className="col-md-8">
                        <Card stretch={false} className={'mx-2'}>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardTitle>Datos de la organización</CardTitle>
                            </CardHeader>
                            <CardBody className="d-flex justify-content-center flex-column">
                                <div className="row d-flex justify-content-between">
                                    <FormGroup requiredInputLabel label='Nombre' className='col-md-5'>
                                        <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                        {showErrors('name')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='CIF' className='col-md-2'>
                                        <Input id='cif' onChange={formik.handleChange} value={formik.values.cif} onBlur={formik.handleBlur} className={verifyClass('cif')} />
                                        {showErrors('cif')}
                                    </FormGroup>
                                </div>

                                <div className="row d-flex justify-content-between mt-3">
                                    <FormGroup label='Dirección' className='col-md-8'>
                                        <Input id='address' onChange={formik.handleChange} value={formik.values.address} onBlur={formik.handleBlur} className={verifyClass('address')} />
                                        {showErrors('address')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </form>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color="secondary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} organización</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : `${buttonTitle} `}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="xxl">
                {(mode === "Editar" && !companyData) && <Spinner />}
                {(mode === "Editar" && companyData) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
        </Fragment>
    )
}

export default CompanyForm;