import { FC, Fragment } from "react";
import AsyncImg from "../../../components/AsyncImg";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
interface PersonalInfoCardProps {
    company: string;
    email: string;
    dni: string;
    phone?: string;
    address?: string;
    profileImg?: any;
    role?: string;
}

const PersonalInfoCard: FC<PersonalInfoCardProps> = ({ company, dni, email, phone, address, profileImg }) => {

    return (
        <Fragment>
            <Card className='shadow-3d-primary'>
                <CardBody>
                    <div className='row g-5 py-3'>
                        <div className='col-12 d-flex justify-content-center'>
                            <AsyncImg isBackground height="200px" width="200px" styles="mx-auto d-block img-fluid rounded" id={profileImg} />
                        </div>
                        <div className='col-12'>
                            <div className='row g-3'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='CorporateFare' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {company}
                                            </div>
                                            <div className='text-muted'>
                                                Organización
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Mail' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {email}
                                            </div>
                                            <div className='text-muted'>
                                                Correo electrónico
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Phone' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 fs-5 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {phone ? phone : 'No proporcionado'}
                                            </div>
                                            <div className='text-muted'>
                                                Teléfono
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='CreditCard' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 fs-5 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {dni}
                                            </div>
                                            <div className='text-muted'>
                                                DNI
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Home' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 fs-5 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {address ? address : 'No proporcionada'}
                                            </div>
                                            <div className='text-muted'>
                                                Dirección
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default PersonalInfoCard;