import classNames from 'classnames';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import FormLabel from '../../../../components/FormLabel';
import Button from '../../../../components/bootstrap/Button';
import { CardTitle } from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import SearchableSelect from '../../../../components/select/SearchableSelect';
import useNutrients from '../../../../hooks/api-calls/useNutrients';
import { UNIT_MEASUREMENT } from '../../../../utils/mapping-collection';

interface RecommendedDailyIntakeProps {
    formik: any;
    dailyIntake: any;
    setDailyIntake: any;
}

const RecommendedDailyIntake: React.FC<RecommendedDailyIntakeProps> = ({ formik, dailyIntake, setDailyIntake }) => {

    const { getNutrientsList } = useNutrients();

    return (
        <>
            <CardTitle className='mt-5 mb-3'>Ingesta diaria recomendada</CardTitle>
            <div className={classNames({ 'd-none': formik.values.recommendIntake?.length === 0 })}>
                <div className='row'>
                    <FormLabel label='Nutriente' cols={4} />
                    <FormLabel label='U. de medida' cols={3} />
                    <FormLabel label='Mínimo' cols={2} />
                    <FormLabel label='Máximo' cols={2} />
                </div>
                {formik.values.recommendIntake?.map((r: any, index: number) => (
                    <div key={index} className={classNames('row mb-4', { 'border-bottom border-grey-50 pb-3': index !== formik.values.recommendIntake?.length - 1 })}>
                        <FormGroup className='col-md-4' error={formik.errors.recommendIntake && formik.errors.recommendIntake[index]?.nutrient}>
                            <SearchableSelect
                                name={`recommendIntake[${index}].nutrient`}
                                options={getNutrientsList()}
                                value={{ value: r.nutrient, label: getNutrientsList().find((n) => n.value === r.nutrient)?.label }}
                                onChange={(e: any) => {
                                    const updatedIntakes = formik.values.recommendIntake.map((intake: any, i: number) => {
                                        if (i === index) {
                                            return { ...intake, id: e.value };
                                        }
                                        return intake;
                                    });
                                    formik.setFieldValue('recommendIntake', updatedIntakes);
                                }}
                                onBlur={formik.handleBlur}
                                placeholder='nutriente'
                            />
                        </FormGroup>

                        <FormGroup className='col-md-3' error={formik.errors.recommendIntake && formik.errors.recommendIntake[index]?.measureUnit}>
                            <SearchableSelect
                                name={`recommendIntake[${index}].nutrient`}
                                options={UNIT_MEASUREMENT}
                                value={{ value: r.measureUnit, label: UNIT_MEASUREMENT.find((u) => u.value === r.measureUnit)?.label }}
                                onChange={(e: any) => {
                                    const updatedIntakes = formik.values.recommendIntake.map((intake: any, i: number) => {
                                        if (i === index) {
                                            return { ...intake, measureUnit: e.value };
                                        }
                                        return intake;
                                    });
                                    formik.setFieldValue('recommendIntake', updatedIntakes);
                                }}
                                onBlur={formik.handleBlur}
                                placeholder='unidad de medida'
                            />
                        </FormGroup>

                        <FormGroup className='col-md-2' error={formik.errors.recommendIntake && formik.errors.recommendIntake[index]?.min}>
                            <Input
                                name={`recommendIntake[${index}].min`}
                                type='number'
                                step={0.01}
                                value={r.min}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormGroup>

                        <FormGroup className='col-md-2' error={formik.errors.recommendIntake && formik.errors.recommendIntake[index]?.max}>
                            <Input
                                name={`recommendIntake[${index}].max`}
                                type='number'
                                step={0.01}
                                value={r.max}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormGroup>

                        <div className="col-md-1 d-flex justify-content-end align-items-end">
                            <Button
                                color="danger"
                                size='sm'
                                onClick={() => { formik.setFieldValue('recommendIntake', formik.values.recommendIntake.filter((r: any, i: number) => i !== index)) }}
                            >
                                <FaRegTrashAlt />
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <div className='row'>
                    <FormGroup label='Nutriente' className='col-md-4'>
                        <SearchableSelect
                            isClearable
                            name='nutrient'
                            options={getNutrientsList()}
                            value={{ value: dailyIntake.nutrient, label: getNutrientsList().find((n) => n.value === dailyIntake.nutrient)?.label }}
                            onChange={(e: any) => setDailyIntake({ ...dailyIntake, nutrient: e?.value })}
                            placeholder='nutriente'
                        />
                    </FormGroup>

                    <FormGroup label='U. de medida' className='col-md-3'>
                        <SearchableSelect
                            isClearable
                            name='measureUnit'
                            options={UNIT_MEASUREMENT}
                            value={{ value: dailyIntake.measureUnit, label: UNIT_MEASUREMENT.find((u) => u.value === dailyIntake.measureUnit)?.label }}
                            onChange={(e: any) => setDailyIntake({ ...dailyIntake, measureUnit: e?.value })}
                            placeholder='unidad de medida'
                        />
                    </FormGroup>

                    <FormGroup label={`Mínimo (${dailyIntake.measureUnit})`} className='col-md-2'>
                        <Input
                            name='min'
                            type='number'
                            step={0.01}
                            value={dailyIntake.min}
                            onChange={(e: any) => setDailyIntake({ ...dailyIntake, min: e.target.value })}
                        />
                    </FormGroup>

                    <FormGroup label={`Máximo (${dailyIntake.measureUnit})`} className='col-md-2'>
                        <Input
                            name='max'
                            type='number'
                            step={0.01}
                            value={dailyIntake.max}
                            onChange={(e: any) => setDailyIntake({ ...dailyIntake, max: e.target.value })}
                        />
                    </FormGroup>

                    <div className='col-md-1 d-flex justify-content-end align-items-end'>
                        <Button
                            color='secondary'
                            size='sm'
                            isDisable={dailyIntake.nutrient === '' || dailyIntake.measureUnit === ''}
                            onClick={() => {
                                if (dailyIntake.min > dailyIntake.max) return toast.error('El valor mínimo no puede ser mayor que el valor máximo');
                                formik.setFieldValue('recommendIntake', [...formik.values.recommendIntake, dailyIntake]);
                                setDailyIntake({ nutrient: '', measureUnit: '', min: 0, max: 0 });
                            }}
                        >
                            <MdAdd className='h-5 w-5' />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecommendedDailyIntake;