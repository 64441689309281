import classNames from 'classnames';
import React, { useCallback } from 'react';
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/bootstrap/Button";
import { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { ServiceTypesService } from '../../../services/service-types/serviceTypesService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';
import ServiceTypesFilters from './ServiceTypesFilters';
import ServiceTypeModalForm from './components/ServiceTypeModalForm';

interface GCServiceTypesProps { }

const GCServiceTypes: React.FC<GCServiceTypesProps> = ({ }) => {

    // STATES

    const [isOpen, setIsOpen] = React.useState(false);
    const [rowId, setRowId] = React.useState('');

    // HOOKS

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3 });

    // FETCH DATA

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new ServiceTypesService()).listServiceTypes(filters));
        return response.getResponseData();
    }, [filters]));

    // FUNCTIONS

    //--------------------------------------------------------------------------------------------------
    /**
     * @ES Función para eliminar un tipo de servicio.
     * @EN Function to delete a service type.
     * 
     * @param id 
     */
    //--------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new ServiceTypesService()).deleteServiceType(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Tipo de servicio eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al eliminar el tipo de servicio');
        }
    };
    //--------------------------------------------------------------------------------------------------

    // RENDER

    if (error) return <ErrorMessage />;

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Tipos de Servicio</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Tipo de servicio"
                        color="storybook"
                        icon="Add"
                        isLight
                        className={classNames({ 'd-none': !userCan('create', 'service_types') })}
                        onClick={() => { setRowId(''); setIsOpen(true); }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ServiceTypesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </CardHeader>
            <CardBody className="table-responsive">
                <CustomTable
                    title="Tipos de Servicio"
                    data={data ? data?.serviceTypes : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data ? data?.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar tipo de servicio",
                            hidden: !userCan('edit', 'service_types'),
                            click: (item: any) => { setRowId(item.id); setIsOpen(true); },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar tipo de servicio",
                            hidden: !userCan('delete', 'service_types'),
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar tipo de servicio",
                                    text: "¿Está seguro que desea eliminar el tipo de servicio?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            </CardBody>

            {isOpen && <ServiceTypeModalForm isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} rowId={rowId} />}
        </>
    );
};

export default GCServiceTypes;