import { useFormik } from "formik";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import SearchableSelect from "../../components/select/SearchableSelect";
import useFetch from "../../hooks/useFetch";
import Page from "../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../services/companies/organizationService";
import { MenuService } from "../../services/menus/menuService";
import { MenusApiResponse } from "../../type/clientMenus-type";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import { Patient, PatientsApiResponse } from "../../type/patients-type";
import Textarea from "../bootstrap/forms/Textarea";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    userProfile: any;
    menuData?: MenuForm;
    menuImage?: any;
}

export interface MenuForm {
    name: string;
    client: string;
    menuConfig: string;
    company: string | { id: string };
    user: string;
    multiplier: number;
    description?: string;
}

const menuInitialValues: MenuForm = {
    name: '',
    client: '',
    menuConfig: '',
    company: '',
    user: '',
    multiplier: 1,
    description: '',
}

const MenuSchema = yup.object({
    name: yup.string().min(1, 'Demasiado Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    menuConfig: yup.string().required('Campo Obligatorio'),
    multiplier: yup.number().min(1, 'El mínimo es 1').max(10, 'El máximo es 10').required('Campo Obligatorio'),
});

const CreateTemplateForm: FC<CreateFormProps> = ({ isLoading, submit, menuData, userProfile }) => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const company = typeof menuData?.company === 'string' ? menuData?.company : menuData?.company.id;
    const mode = menuData ? 'Editar' : 'Crear';
    const buttonTitle = mode === 'Editar' ? 'Guardar Cambios' : 'Crear';

    const [selectedOrganization, setSelectedOrganization] = useState<any>('');

    const fetchOrganizations = useCallback(async () => {
        const companyService = new CompanyService();
        const response = await companyService.getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const fetchMenuConfig = useCallback(async () => {
        const menuService = new MenuService();
        if (!selectedOrganization) return;
        const response = await menuService.getMenuConfigs(selectedOrganization);
        return response.getResponseData() as MenusApiResponse;
    }, [selectedOrganization]);

    const [organizationsData] = useFetch(fetchOrganizations);
    const [menuConfigData, fetchingMenuConfig, menuConfigError, refetchMenuConfig] = useFetch(fetchMenuConfig);

    const [menuConfigDataA] = useFetch(useCallback(async () => {
        const menuService = new MenuService();
        const response = await menuService.getMenuConfigs(company);
        return response.getResponseData() as PatientsApiResponse;
    }, []));

    const getMenuConfigListAdmin = () => {
        if (menuConfigDataA as Patient) {
            return menuConfigDataA.map((config: any) => {
                return {
                    value: config.id,
                    label: config.name + ' - Días: ' + config.dayValue + ', nº de ingestas: ' + config.intakeValue,
                }
            })
        }
        return [];
    };

    const getOrganizationsList = () => {
        if (organizationsData as Companies) {
            return organizationsData.companies.map((company: any) => {
                return {
                    value: company.id,
                    label: company.name,
                }
            })
        }
        return [];
    };

    const getMenuConfigList = () => {
        if (menuConfigData as Patient) {
            return menuConfigData.map((config: any) => {
                return {
                    value: config.id,
                    label: config.name + ' - Días: ' + config.dayValue + ', nº de ingestas: ' + config.intakeValue,
                }
            })
        }
        return [];
    };

    const handleSelectOrganizationChange = (selectedOption: any) => {
        formik.setFieldValue('company', selectedOption.value)

        setSelectedOrganization(selectedOption.value);
    };

    const handleSelectMenuConfigChange = (selectedOption: any) => {
        formik.handleChange(selectedOption.value)

        formik.setFieldValue('menuConfig', selectedOption.value)
    };

    // cada vez que se cambie de organización se ejecuta esta función
    useEffect(() => {
        refetchMenuConfig(selectedOrganization);
    }, [selectedOrganization]);

    const verifyClass = (inputFieldID: keyof MenuForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    };

    const showErrors = (inputFieldID: keyof MenuForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    const formik = useFormik({
        initialValues: menuData ? menuData : menuInitialValues,
        validationSchema: MenuSchema,
        onSubmit: values => {
            values.client = id;
            submit(values);
        }
    });

    const getContent = () => {
        return (
            <Card className='rounded-2'>
                <CardBody>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <div className="row d-flex justify-content-between">
                            <div className='col-12'>
                                <div className="row">
                                    <CardTitle>Crear un nuevo plan</CardTitle>
                                    <div className="row mt-5">
                                        {userProfile?.userRoles[0].role?.id === 1
                                            ? (
                                                <Fragment>
                                                    <div className="row d-flex">
                                                        <div className='col-md-4'>
                                                            <FormGroup requiredInputLabel label='Nombre'>
                                                                <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                                                {showErrors('name')}
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-2"></div>

                                                        <div className='col-md-4'>
                                                            <FormGroup requiredInputLabel label='Selecciona la organización'>
                                                                <SearchableSelect
                                                                    isSearchable
                                                                    onChange={handleSelectOrganizationChange}
                                                                    name='company-select'
                                                                    placeholder='organización'
                                                                    options={getOrganizationsList()}
                                                                    classname={verifyClass('company')}
                                                                />
                                                                {showErrors('company')}
                                                            </FormGroup>
                                                        </div>
                                                    </div>

                                                    <div className="row d-flex mt-5">
                                                        <div className='col-md-12'>
                                                            <FormGroup requiredInputLabel label='Selecciona la configuración que se le va a aplicar al menú'>
                                                                <SearchableSelect
                                                                    isSearchable
                                                                    onChange={handleSelectMenuConfigChange}
                                                                    name='menuConfig-select'
                                                                    placeholder='configuración de menú'
                                                                    options={getMenuConfigList()}
                                                                    classname={verifyClass('menuConfig')}
                                                                />
                                                                {showErrors('menuConfig')}
                                                            </FormGroup>
                                                        </div>
                                                    </div>

                                                    <div className="row d-flex mt-5">
                                                        <div className='col-md-12'>
                                                            <FormGroup label='Descripción'>
                                                                <Textarea id='description' onChange={formik.handleChange} value={formik.values.description} onBlur={formik.handleBlur} className={verifyClass('description')} />
                                                                {showErrors('description')}
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                            : (
                                                <Fragment>
                                                    <div className="row d-flex">
                                                        <div className='col-md-4'>
                                                            <FormGroup requiredInputLabel label='Nombre'>
                                                                <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                                                {showErrors('name')}
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-md-8'>
                                                            <FormGroup requiredInputLabel label='Selecciona la configuración que se le va a aplicar al plan'>
                                                                <SearchableSelect
                                                                    isSearchable
                                                                    onChange={handleSelectMenuConfigChange}
                                                                    name='menuConfig-select'
                                                                    placeholder='configuración de menú'
                                                                    options={getMenuConfigListAdmin()}
                                                                    classname={verifyClass('menuConfig')}
                                                                />
                                                                {showErrors('client')}
                                                            </FormGroup>
                                                        </div>
                                                    </div>

                                                    <div className="row d-flex mt-5">
                                                        <div className='col-md-12'>
                                                            <FormGroup label='Descripción'>
                                                                <Textarea id='description' onChange={formik.handleChange} value={formik.values.description} onBlur={formik.handleBlur} className={verifyClass('description')} />
                                                                {showErrors('description')}
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color={'dark'} /> : `${buttonTitle}`}
                    </Button>
                </CardFooter>
            </Card>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    />
                    <SubheaderSeparator />
                    <CardTitle>{mode} plan nutricional</CardTitle>
                </SubHeaderLeft>
            </SubHeader>
            <Page container="xxl" className="w-50">
                {getContent()}
            </Page>
        </Fragment>
    )
}

export default CreateTemplateForm;