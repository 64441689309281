import { useFormik } from "formik";
import moment from "moment";
import { FC, Fragment, useCallback, useState } from "react";
import EasyEdit from 'react-easy-edit';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../../components/ConfirmationAlert";
import Button from "../../../../components/bootstrap/Button";
import { CardTitle } from "../../../../components/bootstrap/Card";
import Spinner from "../../../../components/bootstrap/Spinner";
import Icon from "../../../../components/icon/Icon";
import { usePrivilege } from "../../../../components/priviledge/PriviledgeProvider";
import { CustomTable } from "../../../../components/table/CustomTable";
import useFetch from "../../../../hooks/useFetch";
import useFilters from "../../../../hooks/useFilters";
import { AppointmentService } from "../../../../services/appointments/appointmentService";
import { APPOINTMENT_TYPES } from "../../../../utils/mapping-collection";
import AppointmentModal from "./components/AppointmentModal";
import AppointmentModalPaymentMethod from "./components/AppointmentModalPaymentMethod";

interface IAppointmentListProps {
    user: string;
}

const AppointmentList: FC<IAppointmentListProps> = ({user}) => {

    // STATES

    const { id = '' } = useParams<{ id: string }>();
    const [isOpen, setIsOpen] = useState(false);
    const appointmentService = new AppointmentService();
    const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
    const [isOpenEditPaymentMethod, setIsOpenEditPaymentMethod] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);

    // HOOKS

    const { userCan } = usePrivilege();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({active: 3, client: id});

    // DATA FETCHING

    const [data, fetchingData, dataError, refetchData] = useFetch(useCallback(async () => {
        const response = await appointmentService.getAppointments(filters);
        return response.getResponseData();
      }, [filters]));

    // FUNCTIONS

    // ------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION PARA CONFIRMAR UNA CITA
     * @EN FUNCTION TO CONFIRM AN APPOINTMENT
     * @param appointment 
     */
    // ------------------------------------------------------------------------------------------------------------------------------------
    const handleConfirmAppointment = async (appointment: any) => {
        try {
            const response = await (await appointmentService.confirmAppointmentPatient(appointment)).getResponseData();
            if (response.success) {
                toast.success('Cita confirmada correctamente');
                refetchData();
            }
        } catch (error) {
            toast.error('Error al confirmar la cita');
            console.error(error);
        }
    }
    // ------------------------------------------------------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION PARA MARCAR UNA CITA COMO REALIZADA
     * @EN FUNCTION TO MARK AN APPOINTMENT AS DONE
     * @param appointment 
     */
    // ------------------------------------------------------------------------------------------------------------------------------------
    const handleDoneAppointment = async (appointment: any) => {
        try {
            const response = await (await appointmentService.doneAppointmentPatient(appointment)).getResponseData();
            if (response.success) {
                toast.success('Cita marcada como realizada correctamente');
                refetchData();
            }
        } catch (error) {
            toast.error('Error al marcar la cita como realizada');
            console.error(error);
        }
    }
    // ------------------------------------------------------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION PARA EDITAR EL CONCEPTO DE UNA CITA
     * @EN FUNCTION TO EDIT AN APPOINTMENT CONCEPT
     * @param values 
     */
    // ------------------------------------------------------------------------------------------------------------------------------------
    const handleEditConcept = async (values: any) => {
        try {
            const response = await (await appointmentService.updateAppointmentConceptPatient(values)).getResponseData();
            if (response.success) {
                refetchData();
                toast.success('Concepto editado correctamente');
            }
        } catch (error) {
            console.error(error);
            toast.error('Error al editar el concepto');
        }
    }
    // ------------------------------------------------------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCION PARA BORRAR UNA CITA
     * @EN FUNCTION TO DELETE AN APPOINTMENT
     * @param appointment
     */
    // ------------------------------------------------------------------------------------------------------------------------------------
    const handleDeleteAppointment = async (appointment: any) => {
        try {
            const response = await (await appointmentService.deleteAppointment(appointment)).getResponseData();
            if (response.success) {
                toast.success('Cita eliminada correctamente');
                refetchData();
            }
        } catch (error) {
            toast.error('Error al eliminar la cita');
            console.error(error);
        }
    }
    // ------------------------------------------------------------------------------------------------------------------------------------

    const cleanValue = (value: string) => {
        if (!value || value.trim() === '' || value === '\n') {
            return '-';
        }
        return value;
    };
    
    // FORMIK

    const formikEdit = useFormik({
        initialValues: {
            concept: '',
            appointment: '',
        },
        onSubmit: async (values) => {
            handleEditConcept(values);
        },
    });

    // RENDER

    return (
        <Fragment>
        <div className="row d-flex justify-content-between">
            <CardTitle className="w-auto">Citas</CardTitle>
            <Button
                className="btn btn-secondary p-2 w-auto"
                onClick={() => { 
                    setIsOpen(true) 
                    setSelectedAppointment(null)    
                }}
                title='Añadir cita'
            >
                <Icon icon="Add" color='dark' size={'lg'} />
            </Button>
        </div>
        {data ?
            <CustomTable
                title="Citas"
                data={data ? data.appointments : null}
                pagination={true}
                paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: data ? data.lastPage : 1,
                    handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                    handlePerPage: updatePageSize,
                }}
                className={"table-striped table-hover fs-10px"}
                columns={[
                    {
                        name: "Fecha",
                        keyValue: "date",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <>{moment(element.startDate.date).format('DD/MM/YYYY')}</>
                            )
                        },
                    },
                    {
                        name: "Concepto",
                        keyValue: "concept",
                        className: "min-w-150",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return (
                                <div key={element.id} className="max-w-150">
                                    <EasyEdit
                                        type="textarea"
                                        onSave={(e: any) => {
                                            formikEdit.setFieldValue('concept', e === '' ? null : e)
                                            formikEdit.setFieldValue('appointment', element.id)
                                            formikEdit.handleSubmit()
                                        }}
                                        value={cleanValue(element.concept)}
                                        attributes={{ className: 'easy-input' }}
                                        inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                        saveOnBlur
                                    />
                                </div>
                            )
                        },
                    },
                    {
                        name: "Confirmada/\nNo confirmada",
                        keyValue: "confirmed",
                        cellClassName: "text-center",
                        className: "min-w-100px",
                        render: (element: any) => {
                            return (
                                <div key={element.id} className="d-flex align-items-center">
                                {
                                    element.confirmed ? (<>
                                        <Icon icon="Check" color="success" className="me-2"/>
                                        <span className="text-success fw-semibold">Confirmada</span>
                                    </>)
                                    : (<>
                                        <Icon icon="Close" color="danger" className="me-2"/>
                                        <span className="text-danger fw-semibold">No confirmada</span>
                                    </>)
                                }
                                </div>
                            )
                        },
                    },
                    {
                        name: "Realizada/\nPendiente",
                        keyValue: "done",
                        render: (element: any) => {
                            return (
                                <div key={element.id} className="d-flex align-items-center">
                                {
                                    element.done ? (<>
                                        <Icon icon="Check" color="success" className="me-2"/>
                                        <span className="text-success fw-semibold">Realizada</span>
                                    </>)
                                    : (<>
                                        <Icon icon="Schedule" color="warning" className="me-2"/>
                                        <span className="text-warning fw-semibold">Pendiente</span>
                                    </>)
                                }
                                </div>
                            )
                        },
                    },
                    {
                        name: "Pagada/\nNo pagada",
                        keyValue: "paid",
                        render: (element: any) => {
                            return (
                                <div key={element.id} className="d-flex align-items-center">
                                {
                                    element.paid ? (<>
                                        <Icon icon="Check" color="success" className="me-2"/>
                                        <span className="text-success fw-semibold">Pagada</span>
                                    </>)
                                    : (<>
                                         <Icon icon="Close" color="danger" className="me-2"/>
                                        <span className="text-danger fw-semibold">No pagada</span>
                                    </>)
                                }
                                </div>
                            )
                        },
                    },
                    {
                        name: "Importe",
                        keyValue: "price",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        render: (element: any) => {
                            return <>{element.price ? element.price.toFixed(2) : 0} €</>
                        },
                    },
                    {
                        name: "Método de pago",
                        keyValue: "paymentMethod",
                        render: (element: any) => {
                            return <>{element.paymentMethod ? element.paymentMethod.name : ''}</>
                        },
                    },
                    {
                        name: "Tipo",
                        keyValue: "type",
                        render: (element: any) => {
                            return <>{element.type ?  APPOINTMENT_TYPES.find((type) => type.labelEn == element.type)?.label : ''}</>
                        },
                    },
                    {
                        name: "Duración",
                        keyValue: "duration",
                        render: (element: any) => {
                            return <>{element.duration || 0} min</>
                        },
                    },
                    {
                        name: "Servicio",
                        keyValue: "service",
                        render: (element: any) => {
                            return <>{element.serviceType ? element.serviceType.name : ''}</>
                        },
                    },
                    {
                        name: "Observaciones",
                        keyValue: "observations",
                        render: (element: any) => {
                            return <div className="max-w-150">{element.comments}</div>
                        },
                    },
                    { name: "Acciones", className: "min-w-100px text-start", isActionCell: true},
                ]}
                actions={[
                    {
                        title: "Confirmar",
                        icon: "EventAvailable",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        iconColor: 'success',
                        size: 'md',
                        description: "Confirmar cita",
                        click: (item: any) => {
                            handleConfirmationAlert({
                                title: "Confirmar cita",
                                text: "¿Está seguro que desea confirmar la cita?",
                                icon: "warning",
                                onConfirm: () => {
                                    handleConfirmAppointment(item?.id)
                                }
                            })
                        }
                    },
                    {
                        title: "Realizada",
                        icon: "DoneAll",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        iconColor: 'success',
                        size: 'md',
                        description: "Marcar como realizada",
                        click: (item: any) => {
                            handleConfirmationAlert({
                                title: "Marcar como realizada",
                                text: "¿Está seguro que desea marcar la cita como realizada?",
                                icon: "warning",
                                onConfirm: () => {
                                    handleDoneAppointment(item?.id)
                                }
                            })
                        }
                    },
                    {
                        title: "Pagada",
                        icon: "AttachMoney",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        iconColor: 'success',
                        size: 'md',
                        description: "Clonar menú",
                        click: (item: any) => {
                            setSelectedAppointmentId(item.id)
                            setIsOpenEditPaymentMethod(true)
                        }
                    },
                    {
                        title: "Editar",
                        icon: "Edit",
                        buttonType: 'icon',
                        additionalClasses: 'text-primary',
                        size: 'md',
                        iconColor: 'secondary',
                        description: "Editar menú",
                        click: (item: any) => {
                            setSelectedAppointment(item)
                            setIsOpen(true)
                        },
                    },
                    {
                        title: "Eliminar",
                        icon: "Delete",
                        buttonType: 'icon',
                        additionalClasses: 'text-danger',
                        iconColor: 'danger',
                        size: 'md',
                        description: "Eliminar menú",
                        click: (item: any) => {
                            handleConfirmationAlert({
                                title: "Eliminar menú",
                                text: "¿Está seguro que desea eliminar el menú?",
                                icon: "warning",
                                onConfirm: () => {
                                    handleDeleteAppointment(item?.id)
                                }
                            })
                        },
                    },
                ]}
            />
        : 
            <Spinner />
        }
        <AppointmentModal isOpen={isOpen} setIsOpen={setIsOpen} setSelectedAppointment={setSelectedAppointment} refetchData={refetchData}
        editAppointment={selectedAppointment} client={id} user={user}/>
        <AppointmentModalPaymentMethod isOpen={isOpenEditPaymentMethod} setIsOpen={setIsOpenEditPaymentMethod}
        setSelectedAppointmentId={setSelectedAppointmentId} appointmentId={selectedAppointmentId} refetchData={refetchData}/>
    </Fragment>
    );
}
 
export default AppointmentList;