import { useCallback, useState } from "react";
import Card, { CardBody, CardHeader, CardTitle } from "../../../../components/bootstrap/Card";
import Spinner from "../../../../components/bootstrap/Spinner";
import useFetch from "../../../../hooks/useFetch";
import useFilters from "../../../../hooks/useFilters";
import { SubHeaderLeft } from "../../../../layout/SubHeader/SubHeader";
import Button from "../../../../components/bootstrap/Button";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import Input from "../../../../components/bootstrap/forms/Input";
import Icon from "../../../../components/icon/Icon";
import { EconomicActivityService } from "../../../../services/economicAvtivities/economicActivityService";

// const ValidationSchema = Yup.object().shape({
//     code: Yup.string().required('El código es requerido'),
//     name: Yup.string().required('El nombre es requerido'),
//     IVA: Yup.number()
//     .required('El IVA es requerido')
//     .min(0, 'El IVA no puede ser negativo'),
//     equivalenceSurcharge: Yup.number()
//         .required('El Recargo de Equivalencia es requerido')
//         .min(0, 'El Recargo de Equivalencia no puede ser negativo')
// });

const EconomicAvtivityList = () => {

    // STATES

    // const [currentPage, setCurrentPage] = useState(0); // Página actual
    // const itemsPerPage = 3; // Número de elementos por página

    // HOOKS

    const { filters } = useFilters({});

    // FETCH DATA

    const [data, loading, error , refetch] = useFetch(
        useCallback(async () => {
            const response = await new EconomicActivityService().listEconomicAvtivities(filters);
            return response.getResponseData();
        }, [filters])
    );

    // FUNCTIONS

    // const submit = async (values: any) => {
    //     const response = await (await new EconomicActivityService().creatEconomicAvtivity(values)).getResponseData();
    //     if (response.success) {
    //         toast.success("Actividad económica creada correctamente");
    //         formik.resetForm();
    //         refetch();
    //     } else {
    //         toast.error("Error al crear la actividad económica");
    //     }
    // };

    // FORMIK

    // const formik = useFormik({
    //     initialValues: {
    //         code: "",
    //         name: "",
    //         IVA: 0,
    //         equivalenceSurcharge: 0
    //     },
    //     validationSchema: ValidationSchema,
    //     onSubmit: async (values) => {
    //         submit(values);
    //     }
    // });

    // CALCULATE PAGINATION

    // const totalPages = data ? Math.ceil(data.data.length / itemsPerPage) : 0; // TOTAL PAGES
    // const startIndex = currentPage * itemsPerPage; // INITIAL INDEX OF THE PAGE
    // const currentItems = data ? data.data.slice(startIndex, startIndex + itemsPerPage) : []; // CURRENT ITEMS

    // RENDER

    return (
        <Card className="mb-3 col-md-8">
            <SubHeaderLeft className="ms-4 mt-4">
                <CardTitle>Actividades Económicas</CardTitle>
            </SubHeaderLeft>
            <CardBody>
                {data ? (
                    <div className="w-100">
                        {/* TABLE */}
                        <div className="row">
                            <div className="col-2 fw-bold text-muted">Código</div>
                            <div className="col-6 fw-bold text-muted">Nombre</div>
                            <div className="col-2 fw-bold text-muted">IVA</div>
                            <div className="col-2 fw-bold text-muted">Rec. equiv</div>
                            {data.data.length > 0 ? (
                                data.data.map((item: any) => (
                                    <>
                                        <div className="col-2 mt-2"><span className="ms-3">{item.code}</span></div>
                                        <div className="col-6 mt-2">{item.name}</div>
                                        <div className="col-2 mt-2">{parseFloat(item.IVA).toFixed(2)}%</div>
                                        <div className="col-2 mt-2">{parseFloat(item.equivalenceSurcharge).toFixed(2)}%</div>
                                    </>
                                ))
                            ) : (
                                <div className="col-12 mt-3 text-center">No hay actividades económicas</div>
                            )}
                        </div>

                        {/* PAGINATION */}
                        {/* <div className="d-flex justify-content-between mt-4">
                            <Button
                                isDisable={currentPage === 0}
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                            >
                                Anterior
                            </Button>
                            {currentPage + 1} / {totalPages}
                            <Button
                                isDisable={currentPage + 1 >= totalPages}
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
                            >
                                Siguiente
                            </Button>
                        </div> */}

                        {/* FORM */}
                        {/* <hr />
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-3">
                                    <div className="col-12 fw-bold text-muted">Código</div>
                                    <div className="col-12 mt-2">
                                        <Input
                                            id="code"
                                            name="code"
                                            value={formik.values.code}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.code && formik.errors.code ? "is-invalid" : ""}
                                        />
                                        {formik.touched.code && formik.errors.code && (
                                            <div className="invalid-feedback">{formik.errors.code}</div>
                                        )}
                                    </div>
                                </div>

                                {/* Nombre */}
                                {/* <div className="col-3">
                                    <div className="col-12 fw-bold text-muted">Nombre</div>
                                    <div className="col-12 mt-2">
                                        <Input
                                            id="name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.name && formik.errors.name ? "is-invalid" : ""}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className="invalid-feedback">{formik.errors.name}</div>
                                        )}
                                    </div>
                                </div> */}

                                {/* IVA */}
                                {/* <div className="col-3">
                                    <div className="col-12 fw-bold text-muted">IVA</div>
                                    <div className="col-12 mt-2">
                                        <Input
                                            id="IVA"
                                            name="IVA"
                                            type="number"
                                            value={formik.values.IVA}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.IVA && formik.errors.IVA ? "is-invalid" : ""}
                                        />
                                        {formik.touched.IVA && formik.errors.IVA && (
                                            <div className="invalid-feedback">{formik.errors.IVA}</div>
                                        )}
                                    </div>
                                </div> */}

                                {/* Recargo de Equivalencia */}
                                {/* <div className="col-3">
                                    <div className="col-12 fw-bold text-muted">Rec. equiv</div>
                                    <div className="col-12 mt-2">
                                        <Input
                                            id="equivalenceSurcharge"
                                            name="equivalenceSurcharge"
                                            type="number"
                                            value={formik.values.equivalenceSurcharge}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={formik.touched.equivalenceSurcharge && formik.errors.equivalenceSurcharge ? "is-invalid" : ""}
                                        />
                                        {formik.touched.equivalenceSurcharge && formik.errors.equivalenceSurcharge && (
                                            <div className="invalid-feedback">{formik.errors.equivalenceSurcharge}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-4">
                                    <Button color="light" type="submit">
                                        Agregar Actividad Económica
                                    </Button>
                                </div> */}
                            {/*</div>
                        </form> */}
                    </div>
                ) : (
                    <Spinner />
                )}
            </CardBody>
        </Card>
    );
};

export default EconomicAvtivityList;