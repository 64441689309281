import { useFormik } from "formik";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import Icon from "../../../components/icon/Icon";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import SearchableSelect from "../../../components/select/SearchableSelect";
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { TipsGroupsService } from "../../../services/tips/groupsService";
import { TipsService } from "../../../services/tips/tipsService";
import CreateTipModal from "../create-tip/CreateTipModal";
import { TipsFilters } from "./tips-options/TipsFilters";

interface ITipsFilters {
    client?: any;
}

const tipsFilters: ITipsFilters = {
    client: null,
};

interface FormValues {
    names: string[];
    descriptions: string[];
    tipGroups: string[];
}

let initialFormikValues = {
    names: [] as string[],
    descriptions: [] as string[],
    tipGroups: [] as string[],
};

const TipsList = () => {
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const tipsService = new TipsService();
    const tipGroupsService = new TipsGroupsService();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [tipId, setTipId] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState(false);


    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tipsFilters);

    const [tips, loadingTips, tipsError, refetchTips] = useFetch(useCallback(async () => {
        const response = (await tipsService.listTips(filters));
        return response.getResponseData();
    }, [filters]));

    const [tipGroups, loadingTipGroups, tipGroupsError, refetchTipGroups] = useFetch(useCallback(async () => {
        const response = (await tipGroupsService.listGroups(filters));
        return response.getResponseData();
    }, [filters]));

    // Create initialFormikValues and store it in formik
    useEffect(() => {
        setIsMounted(false);
        initialFormikValues.names = tips?.tips.reduce((acc: any, tip: any) => {
            acc[tip.id] = tip.name ? tip.name.trim() : '';
            return acc;
        }, {});
        initialFormikValues.descriptions = tips?.tips.reduce((acc: any, tip: any) => {
            acc[tip.id] = tip.description ? tip.description.trim() : '';
            return acc;
        }, {});
        initialFormikValues.tipGroups = tips?.tips.reduce((acc: any, tip: any) => {
            acc[tip.id] = tip.tipGroups.map((tipHasTipGroup: any) => tipHasTipGroup.tipGroup.id);
            return acc;
        }, {});
        formik.resetForm();
        setIsMounted(true);
    }, [tips]);

    // Create formik validation schema
    const validationSchema = Yup.object({
        names: Yup.object().shape(
            tips?.tips?.reduce((acc: any, element: any) => {
                acc[element.id] = Yup.string()
                    .required('El nombre es obligatorio')
                    .min(3, 'Debe tener al menos 3 caracteres');
                return acc;
            }, {})
        ),
        tipGroups: Yup.object().shape(
            tips?.tips?.reduce((acc: any, element: any) => {
                acc[element.id] = Yup.array()
                    .required('El grupo es obligatorio').min(1, 'El grupo es obligatorio');
                return acc;
            }, {})
        ),

    });

    const formik = useFormik<FormValues>({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: (values) => { },
        validateOnChange: true,
        validateOnBlur: true,
    });


    const getTipGroupsOptions = () => {
        if (tipGroups && tipGroups.tip_groups) {
            return tipGroups.tip_groups.map((group: any) => (
                {
                    value: group.id,
                    label: group.name
                }
            ));
        }
        return [];
    }

    // Update search_array filter to found tips by text
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    // Function to import or override a document into a tip for later download
    const _handleImport = async (e: React.ChangeEvent<any>) => {
        try {
            const selectedFile = e.target.files && e.target.files[0];

            const response = (await tipsService.addDocument(selectedFile, tipId)).getResponseData();
            if (response.success) {
                toast.success('Documento añadido');
                refetchTips();
            } else {
                toast.error('Error al añadir el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Function to export a document by ID
    const _handleDownload = async (documentId: string, documentName: string) => {
        try {
            const response = (await tipsService.downloadDocument(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Function to delete a tip by ID
    const handleDelete = async (id: string) => {
        try {
            const response = (await tipsService.deleteTip(id)).getResponseData();
            if (response.success) {
                refetchTips();

                toast.success('Consejo eliminado correctamente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el consejo');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el consejo');
        }
    };

    const _handleUpdate = async () => {
        let success = 0;
        let error = 0;

        if (tips?.tips) {
            for (const tip of tips.tips) {
                if (formik.values.names?.[tip.id] !== formik.initialValues.names?.[tip.id] ||
                    formik.values.descriptions?.[tip.id] !== formik.initialValues.descriptions?.[tip.id] ||
                    formik.values.tipGroups?.[tip.id] !== formik.initialValues.tipGroups?.[tip.id]) {

                    let values = {
                        tip: tip.id,
                        name: formik.values?.names?.[tip.id],
                        description: formik.values?.descriptions?.[tip.id],
                        tipGroups: formik.values.tipGroups?.[tip.id],
                    }

                    try {
                        const response = await tipsService.editTip(values);
                        const responseData = response.getResponseData();

                        if (responseData.success) {
                            success++;
                        } else {
                            error++;
                        }
                    } catch (error: any) {
                        console.error(error.message);
                        error++;
                    }
                }
            }
        }

        await refetchTips();

        if (success > 0) toast.success('Consejos editados correctamente: ' + success);
        if (error > 0) toast.error('Error al editar consejos: ' + error);

        setIsLoading(false);
        formik.resetForm();
    };


    const getContent = () => {

        if (!isMounted) {
            return (
                <div className="text-center">
                    <Spinner color={'primary'} />
                </div>
            )
        } else {
            return (
                <>
                    <CustomTable
                        title="Consejos"
                        data={tips ? tips?.tips : null}
                        pagination
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: tips ? tips.lastPage : 1,
                            handlePagination: (page: any) => {
                                updatePage({ selected: page.selected + 1 });
                            },
                            handlePerPage: updatePageSize,
                        }}
                        className={"table-striped table-hover"}

                        columns={[
                            {
                                name: "Nombre",
                                keyValue: "name",
                                sortable: true,
                                sortColumn: updateFilterOrder,
                                render: (element: any) => {
                                    const hasErrors = formik.errors.names?.[element.id];
                                    return (
                                        <div key={element.id}>
                                            <Textarea
                                                name={`names.${element.id}`}
                                                value={formik.values.names?.[element.id] || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Nombre del grupo"
                                                className={hasErrors ? "form-control is-invalid" : "form-control"}
                                                style={{ minWidth: '300px' }}
                                            />
                                            {formik.errors.names?.[element.id] && (
                                                <div className="text-danger p-1">
                                                    {formik.errors.names[element.id]}
                                                </div>
                                            )}
                                        </div>
                                    )
                                },
                            },
                            {
                                name: "Descripción",
                                keyValue: "description",
                                sortable: true,
                                sortColumn: updateFilterOrder,
                                render: (element: any) => {
                                    const hasErrors = formik.errors.descriptions?.[element.id];
                                    return (
                                        <div key={element.id}>
                                            <Textarea
                                                name={`descriptions.${element.id}`}
                                                value={formik.values.descriptions?.[element.id] || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Descripción del grupo"
                                                className={hasErrors ? "form-control is-invalid" : "form-control"}
                                                style={{ minWidth: '500px' }}
                                            />
                                            {formik.errors.descriptions?.[element.id] && (
                                                <div className="text-danger p-1">
                                                    {formik.errors.descriptions[element.id]}
                                                </div>
                                            )}
                                        </div>
                                    )
                                },
                            },
                            {
                                name: "Grupo",
                                keyValue: "group",
                                render: (element: any) => {
                                    const hasErrors = formik.errors.tipGroups?.[element.id];
                                    return (
                                        <div key={element.id} className="bg-white" style={{ minWidth: "200px" }}>
                                            <SearchableSelect
                                                key={element.id}
                                                isMulti
                                                name={`tipGroups.${element.id}`}
                                                value={getTipGroupsOptions().filter((group: any) => formik.values.tipGroups?.[element.id]?.includes(group.value))}
                                                onChange={(selectedOptions: any) => {
                                                    formik.setFieldValue(
                                                        `tipGroups.${element.id}`,
                                                        selectedOptions.map((option: any) => option.value)
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Grupo"
                                                options={getTipGroupsOptions()}
                                                classname={hasErrors ? "is-invalid" : ""}
                                            />
                                            {formik.errors.tipGroups?.[element.id] && (
                                                <div className="text-danger p-1">
                                                    {formik.errors.tipGroups[element.id]}
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            },
                            {
                                name: "Fecha de creación",
                                keyValue: "createdAt",
                                sortable: true,
                                sortColumn: updateFilterOrder,
                                render: (element: any) => {
                                    return (
                                        <>
                                            {moment(element.createdAt?.date).format('DD/MM/YYYY') || "-"}
                                        </>
                                    )
                                }
                            },
                            {
                                name: "Documento",
                                keyValue: "document",
                                sortable: true,
                                sortColumn: updateFilterOrder,
                                render: (element: any) => {
                                    return (
                                        <div
                                            className={element.tipDocuments[0]?.document.id ? "cursor-pointer text-primary" : "text-muted"}
                                            onClick={() => {
                                                if (element.tipDocuments[0]?.document.id) {
                                                    _handleDownload(element.tipDocuments[0]?.document.id, element.tipDocuments[0]?.document.originalName);
                                                }
                                            }}
                                        >
                                            {element.tipDocuments[0]?.document.originalName || "No tiene documento"}
                                        </div>
                                    )
                                }
                            },
                            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                        ]}
                        actions={[

                            {
                                title: "Descargar documento",
                                icon: "SimCardDownload",
                                buttonType: 'icon',
                                additionalClasses: 'text-primary',
                                description: "Descargar documento",
                                click: (item: any) => {
                                    if (item.tipDocuments.length > 0) {
                                        toast.info('Descargando documento...');
                                        _handleDownload(item.tipDocuments[0].document.id, item.tipDocuments[0].document.originalName);
                                    } else {
                                        toast.info('No hay documento para descargar');
                                    }
                                },
                                render: (item: any) => {
                                    return item.tipDocuments.length > 0 ? (
                                        <Icon icon="SimCardDownload" className='icon-large me-2'
                                            onClick={() => {
                                                if (item.tipDocuments.length > 0) {
                                                    _handleDownload(item.tipDocuments[0].document.id, item.tipDocuments[0].document.originalName);
                                                } else {
                                                    toast.info('No hay documento para descargar');
                                                }
                                            }}
                                        />
                                    ) : <></>;
                                }
                            },
                            {
                                title: "Subir documento",
                                icon: "Upload",
                                buttonType: 'icon',
                                additionalClasses: 'text-primary',
                                description: "Subir documento",
                                click: (item: any) => {
                                    if (fileInputRef.current) {
                                        setTipId(item.id);
                                        fileInputRef.current.click();
                                    }
                                },
                            },
                            {
                                title: "Eliminar",
                                icon: "Delete",
                                buttonType: 'icon',
                                additionalClasses: 'text-danger',
                                description: "Eliminar consejo",
                                click: (item: any) => {
                                    handleConfirmationAlert({
                                        title: "Eliminar consejo",
                                        text: "¿Está seguro que desea eliminar el consejo?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            handleDelete(item.id);
                                        }
                                    })
                                },
                            },
                        ]}
                    />
                    <div className="w-100 d-flex flex-row justify-content-center">
                        <CreateTipModal isOpen={isOpen} setIsOpen={setIsOpen} refetchTips={() => refetchTips()} getTipGroupsList={getTipGroupsOptions} />
                        <Button
                            title="Crear Grupo"
                            color="storybook"
                            isLight
                            onClick={() => setIsOpen(true)}
                            className="d-flex flex-row flex-nowrap align-content-center"
                        >
                            Añadir Consejo
                            <Icon icon="North" className="ms-2 m-auto" ></Icon>
                        </Button>
                    </div>
                </>
            )
        }

    };

    const handleExport = async () => {
        try {
            if (userCan('export', 'tips')) {
                setIsExporting(true);
                const response = await (await (new TipsService()).exportTips(filters));
                if (response) {
                    const fileData = response.getResponseData();
                    const blob = new Blob([fileData]);
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'tips_list.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    setIsExporting(false);
                } else {
                    toast.error('Error al exportar las recetas');
                }
            }
            else {
                toast.error('No tienes permisos para exportar consejos');
            }
        } catch (error) {
            toast.error('Error al exportar las recetas');
        }
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Consejos</CardTitle>
                    <SubheaderSeparator />
                    {!isLoading && formik.values !== initialFormikValues && (
                        <Button
                            title="Descartar cambios"
                            color="danger"
                            isLight
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: '¿Estás seguro?',
                                    text: 'Se descartarán todos los cambios realizados',
                                    icon: 'warning',
                                    onConfirm: async () => {
                                        formik.resetForm();
                                    }
                                })
                            }}
                        >
                            Descartar cambios</Button>
                    )}
                    {formik.values !== initialFormikValues && formik.dirty && formik.isValid && (
                        <Button
                            title="Guardar cambios"
                            color="secondary"
                            isLight
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: '¿Estás seguro?',
                                    text: 'Se guardarán todos los cambios realizados',
                                    icon: 'warning',
                                    onConfirm: async () => {
                                        setIsLoading(true);
                                        _handleUpdate();
                                    }
                                })
                            }}
                        >
                            {isLoading ? <Spinner color={'dark'} /> : 'Guardar cambios'}</Button>
                    )}

                    <Button color="light" isLight title="Exportar Consejos" onClick={handleExport}>Exportar</Button>

                    {isExporting && <Spinner color={"primary"} />}
                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

                    <TipsFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        {getContent()}

                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={_handleImport}
                        />
                    </CardBody>
                </Card>
            </Page>
        </>
    );
}

export default TipsList;