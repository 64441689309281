import { useEffect, useMemo, useState } from 'react';
import { PaymentMethodService } from '../../services/paymentMethods/paymentMethodService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage service types fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de tipos de servicio.
----------------------------------------------------------------------------------------*/

const usePaymentMenthods = () => {

    const paymentMethod = useMemo(() => new PaymentMethodService(), []);
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await paymentMethod.listPaymentMethods();
            const fetchedData = response.getResponseData()?.data;

            if (fetchedData && fetchedData.data) {
                const mappedData = fetchedData.data?.map((data: any) => data);
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching payment methods', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getOptions = () => {
        return data.map((option: { id: string, name: string }) => ({
            value: option.id,
            label: option.name
        }));
    };

    const getList = (entityId?: string) => {
        if (entityId) return data.filter((state: any) => state.id === entityId);
        return data;
    };

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    };

    return {
        fetchPaymentMethods: fetchData,
        getPaymentMethodsOptions: getOptions,
        getPaymentMethodsList: getList,
        getPaymentMethodId: getEntity,
        isLoadingPaymentMethods: isLoading
    };
}

export default usePaymentMenthods;