import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/nutrient-configuration';

export class NutrientConfigurationService extends RestServiceConnection {

    listNutrients = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getNutrient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { id }
        }, true) as AxiosResponse;
        return this;
    }

    createNutrientConfiguration = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    editNutrientConfiguration = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: values
        }, true) as AxiosResponse;
        return this;
    }

    deleteNutrientConfiguration = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { id }
        }, true) as AxiosResponse;
        return this;
    }

    getAllByCompany = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-all',
        }, true) as AxiosResponse;
        return this
    }

}