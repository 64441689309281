import classNames from 'classnames';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import FormLabel from '../../../../components/FormLabel';
import Button from '../../../../components/bootstrap/Button';
import { CardTitle } from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import SearchableSelect from '../../../../components/select/SearchableSelect';
import useFoodGroups from '../../../../hooks/api-calls/useFoodGroups';

interface GroupRationsProps {
    formik: any;
    foodGroupConfig: any;
    setFoodGroupConfig: any;
}

const GroupRations: React.FC<GroupRationsProps> = ({ formik, foodGroupConfig, setFoodGroupConfig }) => {

    const { getFoodGroupsOptions } = useFoodGroups();

    return (
        <>
            <CardTitle className='mt-5'>Configuración de grupos de alimentos</CardTitle>
            <div className={classNames({ 'd-none': formik.values.foodGroupsConfig?.length === 0 })}>
                <div className='row'>
                    <FormLabel label='Grupo de alimentos' cols={8} />
                    <FormLabel label='Cantidad (g)' cols={3} />
                </div>
                {formik.values.foodGroupsConfig?.map((fg: any, index: number) => (
                    <div key={index} className={classNames('row mb-3', { 'border-bottom border-grey-50 pb-3': index === formik.values.foodGroupsConfig?.length - 1 })}>
                        <FormGroup className='col-md-8' error={formik.errors.foodGroupsConfig && formik.errors.foodGroupsConfig[index]?.id}>
                            <SearchableSelect
                                isMulti
                                name={`foodGroupsConfig[${index}].id`}
                                options={getFoodGroupsOptions()}
                                value={getFoodGroupsOptions().filter((f) => fg.id.includes(f.value))}
                                onChange={(e: any) => {
                                    const updatedFG = formik.values.foodGroupsConfig.map((fgc: any, i: number) => {
                                        if (i === index) {
                                            return { ...fgc, id: e?.map((i: any) => i.value) };
                                        }
                                        return fgc;
                                    });
                                    formik.setFieldValue('foodGroupsConfig', updatedFG);
                                }}
                                onBlur={formik.handleBlur}
                                placeholder='grupo de alimentos'
                            />
                        </FormGroup>

                        <FormGroup className='col-md-3' error={formik.errors.foodGroupsConfig && formik.errors.foodGroupsConfig[index]?.quantity}>
                            <Input
                                name={`foodGroupsConfig[${index}].quantity`}
                                type='number'
                                value={fg.quantity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormGroup>

                        <div className="col-md-1 d-flex justify-content-end align-items-end">
                            <Button
                                color="danger"
                                size='sm'
                                onClick={() => { formik.setFieldValue('foodGroupsConfig', formik.values.foodGroupsConfig.filter((fg: any, i: number) => i !== index)) }}
                            >
                                <FaRegTrashAlt />
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <div className='row'>
                    <FormGroup label='Grupo de alimentos' className='col-md-8'>
                        <SearchableSelect
                            isMulti
                            isClearable
                            name='id'
                            options={getFoodGroupsOptions()}
                            value={getFoodGroupsOptions().filter((f) => foodGroupConfig.id.includes(f.value))}
                            onChange={(e: any) => setFoodGroupConfig({ ...foodGroupConfig, id: e?.map((i: any) => i.value) })}
                            placeholder='grupo de alimentos'
                        />
                    </FormGroup>

                    <FormGroup label={`Cantidad (g)`} className='col-md-3'>
                        <Input
                            name='quantity'
                            type='number'
                            value={foodGroupConfig.quantity}
                            onChange={(e: any) => setFoodGroupConfig({ ...foodGroupConfig, quantity: e.target.value })}
                            onBlur={formik.handleBlur}
                        />
                    </FormGroup>

                    <div className='col-md-1 d-flex justify-content-end align-items-end'>
                        <Button
                            color='secondary'
                            size='sm'
                            isDisable={foodGroupConfig.id.length === 0}
                            onClick={() => {
                                formik.setFieldValue('foodGroupsConfig', [...formik.values.foodGroupsConfig, foodGroupConfig]);
                                setFoodGroupConfig({ id: [], quantity: 0 });
                            }}
                        >
                            <MdAdd className='h-5 w-5' />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupRations;