import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/companies';

export class CompanyService extends RestServiceConnection {

    getCompanies = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true) as AxiosResponse;
        return this;
    }

    getOrganizations = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters ? {
                limit: filters.pageSize,
                page: filters.page,
                filter_filters: {
                    name: filters.searchText,
                }
            } : {
            }
        }, true);
        return this;
    }

    getCompanyById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: {
                company: id
            }
        }, true);
        return this;
    }

    toggleCompanyStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: {
                company: id,
                active: status
            }
        }, true);
        return this;
    }

    createCompany = async (company: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: company,
        }, true);
        return this;
    }

    editCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => (companyObj[key] = value));
        companyObj['logo'] = null;

        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

    editOrganizationImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('company', id);
        formData.append('logo', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteOrganizationImg = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-image',
            data: {
                company: id
            }
        }, true);
        return this;
    }

    deleteCompany = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: {
                company: id
            }
        }, true);
        return this;
    }

    editConfig = async (config: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-config',
            data: config,
        }, true);
        return this;
    }

    getFoodRationConfigurations = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-food-ration-configurations',
        }, true);
        return this;
    }

    getInformedConsent = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-text-field',
        }, true);
        return this;
    }

    setInformedConsent = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-text-field',
            data: data,
        }, true);
        return this;
    }

    getTechnicalSheet = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-food-ration-configurations',
        }, true);
        return this;
    }

    setTechnicalSheet = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/set-food-ration-configurations',
            data: data,
        }, true);
        return this;
    }

    getCalendarTimeSlot = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: ENDPOINT + '/get-calendar-time-slot',
        }, true);
        return this;
    }

    setCalendarTimeSlot = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-calendar-time-slot',
            data: data,
        }, true);
        return this;
    }

    getWorkplaces = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/workplaces' + '/list-workplaces',
            data: filters,
        }, true);
        return this;
    }

    setWorkplaces = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/workplaces' + '/set-workplaces',
            data: data,
        }, true);
        return this;
    }

}