import { useFormik } from "formik";
import { FC, Fragment, useCallback, useState } from "react";
import Button from "../bootstrap/Button";
import { CardTitle } from "../bootstrap/Card";
import Input from "../bootstrap/forms/Input";
import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
import { PatientService } from "../../services/patients/patientService";
import Spinner from "../bootstrap/Spinner";
import ErrorMessage from "../ErrorMessage";
import { CustomTable } from "../table/CustomTable";
import useFilters from "../../hooks/useFilters";
import { handleConfirmationAlert } from "../ConfirmationAlert";
import moment from "moment";
import { UserService } from "../../services/users/userService";
import AddPhotoModal from "./component/AddPhotoModal";

interface EvolutivePhotosProps {
    id: string;
    isPatient: boolean;
    type?: string;
}

interface IPatientFilters {
    client: string;
    documentType?: string;
}

interface IUserFilters {
    user: string;
    documentType?: string;
}

const EvolutivePhotos: FC<EvolutivePhotosProps> = ({ id, isPatient, type }) => {
    
    const patientService = new PatientService();
    const service =  patientService;

    moment.locale('es-ES');

    const patientFilters: IPatientFilters = {
        client: id,
        documentType: type,
    };

    const userFilters: IUserFilters = {
        user: id,
        documentType: type,
    };

    const documentsFilters = isPatient ? patientFilters : userFilters;
    
    // STATE 
    const [isOpen, setIsOpen] = useState(false);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(documentsFilters);

    const [documents, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listDocuments(filters);
        return response.getResponseData();
    }, [filters]));

    const _handleDelete = async (documentId: string) => {
        handleConfirmationAlert({
            title: "Eliminar Foto",
            text: "¿Está seguro que desea eliminar la foto?",
            icon: "warning",
            onConfirm: async () => {
                try {
                    const response = (await service.deleteDocument(documentId)).getResponseData();
                    if (response.success) {
                        refetch();
                        toast.success('Foto eliminada correctamente');
                    } else {
                        toast.error(response.message || "Error al eliminar la foto");
                    }
                } catch (error: any) {
                    toast.error(error.message);
                }
            }
        });
    };

    const downloadDocument = async (documentId: string, documentName: string) => {
        try {
            const response = (await service.downloadDocument(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar la foto');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    return (
        <Fragment>
            <CardTitle className="mb-4">Fotos evolutivas</CardTitle>
            <div className="d-flex justify-content-end">
                <Button
                    title="Añadir foto"
                    color="primary"
                    icon="Add"
                    onClick={() => setIsOpen(true)}
                > Subir fotos </Button>
            </div>

            {isOpen && id && (
                <AddPhotoModal isOpen= {isOpen} setIsOpen={setIsOpen} patientId={id} type={type} refetch={refetch} />
            )}

            { documents ? 
            <CustomTable
                    data={documents ? documents.data : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: documents.lastPage ? documents.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className="table-striped table-hover mt-5"
                    columns={[
                        {
                            name: "Nombre de la foto",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div
                                        onClick={() => { downloadDocument(element.document.id, element.document.originalName) }}
                                        className="cursor-pointer text-secondary"
                                    >
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Fecha de subida",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div>
                                        {moment(element.document.createdAt.date).format('DD/MM/YYYY')}
                                    </div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Descargar",
                                icon: "Download",
                                buttonType: 'icon',
                                additionalClasses: 'text-primary',
                                description: "Descargar documento",
                                click: (item: any) => { downloadDocument(item.document.id, item.document.originalName) },
                            },
                            {
                                title: "Eliminar",
                                icon: "Delete",
                                buttonType: 'icon',
                                additionalClasses: 'text-danger',
                                description: "Eliminar documento",
                                click: (item: any) => { _handleDelete(item.document.id); },
                            },
                        ]}
                />
            :   <div className="d-flex justify-content-center mt-4">
                    <Spinner className="m-auto"/>
                </div>}
        </Fragment>
    );
};

export default EvolutivePhotos;