import { RestServiceConnection } from '../restServiceConnection';

const DOCUMENTS_TYPE_ENDPOINT = '/documents-type';

export class DocumentTypeService extends RestServiceConnection {

    getDocumentTypes = async (entity: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DOCUMENTS_TYPE_ENDPOINT + '/get',
            data: {
                entity: entity
            }
        }, true);
        return this;
    }
}