import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { CardBody } from "../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Spinner from "../../../components/bootstrap/Spinner";
import Icon from "../../../components/icon/Icon";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { FormService } from "../../../services/forms/formService";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import EditFillValues from "../edit-fill-values/EditFillValues";
import PatientFieldGraph from "./PatientFieldGraph";

interface FormModalProps {
    formId: string;
}

export interface IFormsFilters {
    form?: string;
    client?: string;
}

const ListPatientForms: FC<FormModalProps> = ({ formId }) => {

    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const { id = '' } = useParams<{ id: string }>();
    const formService = new FormService();

    const formsFilters: IFormsFilters = {
        form: formId,
        client: id,
    };

    const { filters, updateFilters, updatePage, updatePageSize, updateFilterOrder } = useFilters(formsFilters);

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedColumn, setSelectedColumn] = useState<{} | undefined>(undefined);
    const [openGraph, setOpenGraph] = useState<boolean>(false);
    const [loadingShowInApp, setLoadingShowInApp] = useState<boolean>(false);

    const [fills, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await formService.listFormFills(filters));
        return response.getResponseData();
    }, [filters]));

    const toggleAppStatus = async (id: string, status: boolean,) => {
        setLoadingShowInApp(true);
        try {
            setChangingStatus([...changingStatus, id]);
            const response = await (await (new FormService()).showInApp(id)).getResponseData();

            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                setLoadingShowInApp(false);
                refetch();
                toast.success(status ? 'Se mostrará en la APP' : 'No se mostrará en la APP');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                handleErrors(response);
                setLoadingShowInApp(false);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error('Error al cambiar el estado');
            setLoadingShowInApp(false);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new FormService()).deleteFill(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Formulario eliminado correctamente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el formulario');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el formulario');
        }
    };

    // Actualizar los filtros cuando se cambia el tipo de formulario
    useEffect(() => {
        if (formId !== null) {
            updateFilters({ ...filters, formType: formId, client: id });
        }
    }, [formId]);

    const getFormList = () => {
        if (fills?.formFills?.length > 0) {
            // Obtener los campos del formulario y crear las columnas de la tabla de forma dinámica
            const fieldsColumns = [] as any;
            fills.formFills.forEach((formFill: any) => {
                formFill.formFillValues.forEach((field: any, index: number) => {
                    if (
                        field?.formField?.main &&
                        fieldsColumns.length < 9 &&
                        !fieldsColumns.some((column: any) => column.id === field.formField.id) // Verificar duplicados
                    ) {
                        fieldsColumns.push({
                            id: field.formField.id, // ID del campo
                            name: field.formField.name,
                            keyValue: `field-${field.formField.name}`, // Índice único basado en la longitud actual
                            // Si el campo es de tipo número, se muestra un botón para ver el gráfico
                            actionButton: field.formField.type === 'number'
                                ? {
                                    title: "Ver gráfico",
                                    icon: "BarChart",
                                    click: () => {
                                        setSelectedColumn({ id: field.formField.id, name: field.formField.name });
                                        setOpenGraph(true);
                                    }
                                }
                                : undefined,
                            render: (element: any) => {
                                const value = element.formFillValues[index]?.value;

                                // Verificar si el campo es de tipo fecha
                                if (field.formField.type === 'date' && value) {
                                    const date = new Date(value);
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0
                                    const year = String(date.getFullYear()); // Obtener los últimos dos dígitos del año
                                    return <div>{`${day}/${month}/${year}`}</div>;
                                }

                                // Si no es una fecha, mostrar el valor tal cual
                                return <div>{value}</div>;
                            }
                        });
                    }
                });
            });

            return (
                <CustomTable
                    data={fills ? fills.formFills : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: fills ? fills.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Fecha",
                            keyValue: "publicAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div>{moment(element.publicAt?.date).format('DD/MM/YY')}</div>
                                )
                            }
                        },
                        ...fieldsColumns,
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Ver en APP",
                            icon: "Eye",
                            buttonType: 'normal',
                            description: "Ver formulario en la APP",
                            hidden: !userCan('show_in_app', 'forms'),
                            click: (item: any) => {},
                            callback: (item: any) => { toggleAppStatus(item.id, !item.showInApp) },
                            render: (item: any) => {
                                return (<>
                                    { 
                                        loadingShowInApp && changingStatus.includes(item.id) ? 
                                            (<Spinner isSmall/>) 
                                        :
                                            (<Icon
                                                icon={item.showInApp ? 'Visibility' : 'VisibilityOff'}
                                                color={item.showInApp ? 'success' : 'danger'}
                                                size="sm"
                                                className="m-0 p-0"
                                            />)
                                    }
                                    </>
                                );
                            }
                        },
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar formulario",
                            hidden: !userCan('edit', 'forms'),
                            click: (item: any) => {
                                setSelectedRow(item.id);
                                setOpenModal(true);
                            }
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar formulario",
                            hidden: !userCan('delete', 'forms'),
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar formulario",
                                    text: "¿Está seguro que desea eliminar el formulario?",
                                    icon: "warning",
                                    onConfirm: () => { handleDelete(item.id) }
                                })
                            },
                        },
                    ]}
                />
            )
        } else {
            return <div className="text-center mt-3"><h5 className="text-muted">No hay formularios</h5></div>;
        }
    };

    return (
        <>
            <CardBody className="cardBodyForms">
                {formId ? getFormList() : <div className="text-center"><h5 className="text-muted">No hay formularios</h5></div>}
            </CardBody>

            <Modal isOpen={openModal} setIsOpen={setOpenModal} size='xl' titleId='Datos del formulario'>
                <ModalHeader setIsOpen={setOpenModal} className='p-4'>
                    <ModalTitle id='form-data'>Datos del formulario</ModalTitle>
                </ModalHeader>
                <ModalBody className='px-4'>
                    <EditFillValues formFillId={selectedRow} setIsOpen={setOpenModal} refetch={refetch} />
                </ModalBody>
            </Modal>

            {openGraph && (<PatientFieldGraph isOpen={openGraph} setIsOpen={setOpenGraph} data={{ column: selectedColumn, client: id }} />)}
        </>
    );
}

export default ListPatientForms;