import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import SearchableSelect from '../../../components/select/SearchableSelect';
import useFetch from '../../../hooks/useFetch';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { FormService } from '../../../services/forms/formService';
import { FIXED_FIELDS, FORMULAS, FORMULA_FIELDS } from '../../../utils/mapping-collection';
import FormFields from './FormFields';

interface FormFormProps {
    submit: (values: any) => void;
    isLoading: boolean;
    data?: any;
}

export interface Form {
    form: string;
    name: string;
    formType: string;
    formula?: string;
    fields: FormField[];
}

export interface FormField {
    formFieldId: string;
    order?: number;
    name: string;
    required: boolean;
    type: string;
    main?: boolean;
    columnSize: number;
    options?: string[];
    formula?: boolean;
    fixed?: boolean;
    isFixed?: boolean;
}

const formFieldInitialValues: FormField = {
    formFieldId: '',
    order: 0,
    name: '',
    required: false,
    type: 'number',
    main: false,
    columnSize: 12,
    options: [],
    formula: false,
    fixed: false,
    isFixed: false,
}

const formSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    formType: yup.string().required('El tipo de formulario es obligatorio'),
    formula: yup.string().notRequired(),
    fields: yup.array().of(
        yup.object().shape({
            name: yup.string().required('El nombre del campo es obligatorio'),
            required: yup.boolean().notRequired(),
            type: yup.string().required('El tipo de campo es obligatorio'),
            main: yup.boolean().notRequired(),
            columnSize: yup.number().required('El tamaño de la columna es obligatorio'),
            //options: yup.array().of(yup.string()).notRequired().nullable(),
        })
    ).notRequired(),
});

const FormForm: React.FC<FormFormProps> = ({ submit, isLoading, data }) => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const mode = data ? 'Editar' : 'Crear';

    const [field, setField] = React.useState<FormField>(formFieldInitialValues);
    const [fixedFields, setFixedFields] = React.useState<{ value: string, label: string }[]>(FIXED_FIELDS);

    const [formTypeData] = useFetch(useCallback(async () => {
        const response = (await (new FormService).getFormTypes());
        return response.getResponseData();
    }, []));

    const getFormTypesList = () => {
        if (formTypeData) {
            return formTypeData.formTypes?.map((formType: any) => {
                return {
                    value: formType.id,
                    label: formType.name
                }
            })
        }
        return [];
    };

    const handleFormulaChange = (e: any) => {
        if (!e?.value) {
            // Clear formula fields when formula is removed
            formik.setValues({
                ...formik.values,
                formula: '',
                fields: formik.values.fields.filter((field: FormField) => !field.formula),
            });

            // Reset fixed fields
            setFixedFields(FIXED_FIELDS);
            return;
        }

        // Set formula and add formula fields
        formik.setFieldValue('formula', e.value);
        let formulaFields = FORMULA_FIELDS[e.value];
        if (formulaFields.length > 0) {
            formik.values.fields = formik.values.fields.filter((field: FormField) => !field.formula);
            formulaFields.forEach((field: { value: string; label: string }) => {
                formik.values.fields.push({
                    ...formFieldInitialValues,
                    name: field.label,
                    required: true,
                    formula: true,
                    fixed: true,
                    isFixed: false,
                });
            });
        };
    };

    const handleFixedChange = (e: any) => {
        let oldFields = [...formik.values.fields];

        // Remove fixed fields that are not in the list
        oldFields = formik.values.fields.filter((f: FormField) => {
            return !f.isFixed || e.find((ff: { value: string, label: string }) => ff.label === f.name);
        });

        e.forEach((field: { value: string, label: string }) => {
            // Add field to fields if it isn't already there
            if (oldFields.find((f: FormField) => f.name === field.label)) return;
            oldFields = [
                ...oldFields,
                {
                    ...formFieldInitialValues,
                    name: field.label,
                    required: true,
                    fixed: true,
                    isFixed: true,
                }
            ];
        });

        formik.setFieldValue('fields', oldFields);
    };

    const formik = useFormik<Form>({
        initialValues: {
            form: data?.id || id,
            name: data?.name || '',
            formType: data?.formType?.id || '',
            formula: data?.formula || '',
            fields: data?.formFields ? data.formFields.map((f: any) => ({
                formFieldId: f.id,
                name: f.name,
                required: f.required,
                type: f.type,
                main: f.main,
                order: f.order,
                columnSize: f.columnSize,
                options: f.options,
                formula: f.formula,
                fixed: f.fixed,
            })) : [],
        },
        validationSchema: formSchema,
        onSubmit: values => {
            // Add field to fields if it has a name
            if (field.name) {
                values.fields.push(field);
            }
            // Add order to fields depending on the index
            values.fields.forEach((field, index) => {
                field.order = index;
            });
            submit(values);
        }
    });

    const verifyClass = (inputFieldID: keyof Form) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; }

    const showErrors = (inputFieldID: keyof Form) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Formulario</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color={'dark'} /> : `${mode} Formulario`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Card>
                        <CardBody>
                            <div className="row">
                                <FormGroup requiredInputLabel label='Nombre' className="col-md-3">
                                    <Input
                                        id='name'
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        onBlur={formik.handleBlur}
                                        className={verifyClass('name')}
                                    />
                                    {showErrors('name')}
                                </FormGroup>

                                <FormGroup requiredInputLabel label='Tipo de formulario' className="col-md-3">
                                    <SearchableSelect
                                        name="formType"
                                        options={getFormTypesList()}
                                        onChange={(e: any) => formik.setFieldValue('formType', e.value)}
                                        value={{ value: formik.values.formType, label: formTypeData?.formTypes?.find((formType: any) => formType.id === formik.values.formType)?.name }}
                                        placeholder='tipo'
                                        classname={verifyClass('formType')}
                                    />
                                    {showErrors('formType')}
                                </FormGroup>

                                <FormGroup label='Fórmula' className='col-md-3'>
                                    <SearchableSelect
                                        isClearable
                                        name='formula'
                                        options={FORMULAS}
                                        value={FORMULAS.find((formula: { value: string, label: string }) => formula.value === formik.values.formula)}
                                        onChange={(e: any) => handleFormulaChange(e)}
                                        placeholder='fórmula'
                                    />
                                </FormGroup>

                                <FormGroup label='Campos fijos' className='col-md-3'>
                                    <SearchableSelect
                                        name='fixedFields'
                                        isMulti
                                        options={fixedFields}
                                        value={fixedFields.filter((field: { value: string, label: string }) => formik.values.fields.find((f: FormField) => f.name === field.label))}
                                        onChange={(e: any) => handleFixedChange(e)}
                                        placeholder='campos fijos'
                                    />
                                </FormGroup>
                            </div>

                            <FormFields formik={formik} field={field} setField={setField} verifyClass={verifyClass} showErrors={showErrors} />
                        </CardBody>
                    </Card>
                </form>
            </Page>
        </>
    );
};

export default FormForm;