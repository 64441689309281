import { ALLERGENS_LIST, NUTRIENTS } from "./mapping-collection";

/**
 * Función para filtrar restricciones presentes en los menuItems
 * @param menuItems 
 * @param restrictions 
 * @returns 
 */
export const findMenuRestrictions = (menuItems: any, restrictions: any) => {
    const overlappingRestrictions: any = {};
    const translatedRestrictions = [...restrictions];

    translatedRestrictions[2].values = translatedRestrictions[2].values.map((value: string) => {
        const nutrient = NUTRIENTS.find((nutrient) => nutrient.label === value);
        return nutrient ? nutrient.value : value;
    });
    translatedRestrictions[3].values = translatedRestrictions[3].values.map((value: string) => {
        const allergen = ALLERGENS_LIST.find((allergen) => allergen.nombre === value);
        return allergen ? allergen.name : value;
    });

    menuItems.forEach((item: any) => {
        if (item.physicalActivity) return false;

        if (item.food) {
            const result: Record<string, any> = {};

            // Iterar sobre las restricciones de grupo de alimentos, alimentos, nutrientes y alérgenos para ver si coinciden con el alimento
            translatedRestrictions[0].values.forEach((value: string) => {
                if (item.food.foodGroup.name === value) {
                    result[value] = true;
                }
            });
            translatedRestrictions[1].values.forEach((value: string) => {
                if (item.food.name === value) {
                    result[value] = true;
                }
            });
            translatedRestrictions[2].values.forEach((value: string) => {
                if (item.food.hasOwnProperty(value) && item.food[value] > 0) {
                    result[value] = item.food[value];
                }
            });
            translatedRestrictions[3].values.forEach((value: string) => {
                if (item.food.hasOwnProperty(value) && item.food[value] === true) {
                    result[value] = item.food[value];
                }
            });

            // Transformar los valores de nutrientes y alérgenos a los nombres correspondientes en español
            Object.keys(result).forEach((key) => {
                if (NUTRIENTS.find((nutrient) => nutrient.value === key)) {
                    result[NUTRIENTS.find((nutrient) => nutrient.value === key)!.label] = result[key];
                    delete result[key];
                }
                if (ALLERGENS_LIST.find((allergen) => allergen.name === key)) {
                    result[ALLERGENS_LIST.find((allergen) => allergen.name === key)!.nombre] = result[key];
                    delete result[key];
                }
            });

            // Añadir al objeto overlappingRestrictions las restricciones que coinciden con el alimento si no están ya presentes
            Object.keys(result).forEach((key) => {
                if (!overlappingRestrictions[key]) {
                    overlappingRestrictions[key] = result[key];
                }
            });

            // Añadir el id del alimento a la lista de ids si incumple alguna restricción
            if (Object.keys(result).length > 0) {
                overlappingRestrictions.ids = overlappingRestrictions.ids || [];
                overlappingRestrictions.ids.push(item.food.id);
            }
        }

        if (item.recipe) {
            const result: Record<string, any> = {};

            // Iterar sobre los ingredientes de la receta para ver los alimentos y grupos de alimentos que coinciden con las restricciones
            item.recipe.ingredients.forEach((ingredient: any) => {
                if (ingredient.food) {
                    translatedRestrictions[0].values.forEach((value: string) => {
                        if (ingredient.food.foodGroup.name === value) {
                            result[value] = true;
                        }
                    });

                    translatedRestrictions[1].values.forEach((value: string) => {
                        if (ingredient.food.name === value) {
                            result[value] = true;
                        }
                    });
                }
            });

            // Iterar sobre las restricciones de nutrientes para ver si coinciden con las propiedades de la receta y su valor es distinto de 0
            translatedRestrictions[2].values.forEach((value: string) => {
                if (item.recipe.hasOwnProperty(value) && item.recipe[value] > 0) {
                    result[value] = item.recipe[value];
                }
            });

            // Iterar sobre las restricciones de alérgenos para ver si coinciden con las propiedades de la receta y su valor es true
            translatedRestrictions[3].values.forEach((value: string) => {
                if (item.recipe.hasOwnProperty(value) && item.recipe[value] === true) {
                    result[value] = item.recipe[value];
                }
            });

            // Transformar los valores de nutrientes y alérgenos a los nombres correspondientes en español
            Object.keys(result).forEach((key) => {
                if (NUTRIENTS.find((nutrient) => nutrient.value === key)) {
                    result[NUTRIENTS.find((nutrient) => nutrient.value === key)!.label] = result[key];
                    delete result[key];
                }
                if (ALLERGENS_LIST.find((allergen) => allergen.name === key)) {
                    result[ALLERGENS_LIST.find((allergen) => allergen.name === key)!.nombre] = result[key];
                    delete result[key];
                }
            });

            // Añadir al objeto overlappingRestrictions las restricciones que coinciden con la receta si no están ya presentes
            Object.keys(result).forEach((key) => {
                if (!overlappingRestrictions[key]) {
                    overlappingRestrictions[key] = result[key];
                }
            });

            // Añadir el id de la receta a la lista de ids si incumple alguna restricción
            if (Object.keys(result).length > 0) {
                overlappingRestrictions.ids = overlappingRestrictions.ids || [];
                overlappingRestrictions.ids.push(item.recipe.id);
            }
        }
    });

    return overlappingRestrictions;
};



/**
 * Función para comprobar si un alimento o receta está restringido al ser añadido desde el selector
 * @param item 
 * @param restrictions 
 * @returns 
 */
export const isItemRestricted = (item: any, restrictions: any) => {
    const overlappingRestrictions = restrictions;

    if (item.food) {
        for (const key in overlappingRestrictions) {
            if (Object.prototype.hasOwnProperty.call(overlappingRestrictions, key)) {
                if (item.food.food_group === key) {
                    overlappingRestrictions.ids = overlappingRestrictions.ids || [];
                    overlappingRestrictions.ids.push(item.food.id);
                }

                if (item.food.name === key) {
                    overlappingRestrictions.ids = overlappingRestrictions.ids || [];
                    overlappingRestrictions.ids.push(item.food.id);
                }
            }
        }
    }

    if (item.recipe) {
        for (const key in overlappingRestrictions) {
            if (Object.prototype.hasOwnProperty.call(overlappingRestrictions, key)) {
                if (item.recipe.food_group === key) {
                    overlappingRestrictions.ids = overlappingRestrictions.ids || [];
                    overlappingRestrictions.ids.push(item.recipe.id);
                }
            }
        }
    }

    return overlappingRestrictions;
};