import { DragSourceMonitor, useDrag } from 'react-dnd';

interface DraggableItemProps {
  label: string;
  data: any;
  food?: boolean;
  recipe?: boolean;
  activity?: boolean;
  quantity?: number | null;
  fromSelectors?: boolean;
  foodGroup?: string | null;
  foodGroupId?: string | null;
  foodGroupColor?: string | null;
}

export interface DragItem {
  type: string;
  id: string;
  dayId: string;
  intakeId: string;
  name: string;
  isFood?: boolean;
  isRecipe?: boolean;
  isActivity?: boolean;
  quantity?: number | null;
  fromSelectors?: boolean;
  foodGroup?: string | null;
  foodGroupId?: string | null;
  foodGroupColor?: string | null;
}

// Componente que dibuja cards de alimentos y las hace draggables para poder añadirlos al menú
const DraggableItem: React.FC<DraggableItemProps> = ({ label, data, food, recipe, activity, quantity, fromSelectors, foodGroup, foodGroupId, foodGroupColor }) => {
  const [{ isDragging }, drag] = useDrag<DragItem, void, { isDragging: boolean }>({
    type: 'menuItem', // Define el tipo de elemento que se está arrastrando
    item: { // Establece los datos que se transmitirán al soltar el elemento
      type: 'menuItem',
      id: data?.id,
      name: data?.name,
      dayId: data?.days?.id,
      intakeId: data?.intakes?.id,
      foodGroup: (food ? foodGroup : recipe ? foodGroup : null),
      foodGroupId: (food ? foodGroupId : recipe ? foodGroupId : null),
      foodGroupColor: (food ? foodGroupColor : recipe ? foodGroupColor : null),
      isFood: food,
      isRecipe: recipe,
      isActivity: activity,
      quantity: quantity,
      fromSelectors: fromSelectors,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      key={data.id}
      ref={drag} draggable
      className={"col-md-2 " + (activity ? 'physical-activity' : 'items-card')}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab' }}
    >
      <span className='d-flex justify-content-center' style={{ fontSize: '13px' }}>{label}</span>
    </div>
  );
};

export default DraggableItem;