import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import EasyEdit from 'react-easy-edit';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { handleConfirmationAlert } from '../../components/ConfirmationAlert';
import useFetch from '../../hooks/useFetch';
import EditRecipeModal from '../../pages/menus/menu-view/components/EditRecipeModal';
import RecipeViewModal from '../../pages/menus/menu-view/components/RecipeViewModal';
import { copyIntakes } from '../../redux/menuSlice';
import { AppDispatch } from '../../redux/store';
import { MenuService } from '../../services/menus/menuService';
import { Menu, MenusApiResponse } from '../../type/clientMenus-type';
import { findMenuRestrictions } from '../../utils/CheckRestrictions';
import useHandleErrors from '../../utils/hooks/useHandleErrors';
import Button from '../bootstrap/Button';
import Checks from '../bootstrap/forms/Checks';
import Icon from '../icon/Icon';
import ContextMenuChange from './ContextMenuChange';
import CreateTemplateModal from './CreateTemplateModal';
import DropContainer from './DropContainer';
import MenuActions from './MenuActions';
import NutritionalMenuValues from './NutritionalMenuValues';
import { useMenuDataProvider } from './providers/MenuDataProvider';
import { useMenuProvider } from './providers/MenuProvider';
import './styles/styles.scss';

interface MenuNutricionalProps {
    menuId: string;
    client: any;
    isUpdated: number;
    dataObjectives: any;
    refetchDataObjectives: () => void;
}

const NutritionalMenu: React.FC<MenuNutricionalProps> = ({ menuId, client, isUpdated, dataObjectives, refetchDataObjectives }) => {

    // STATES

    const [nutValuesFrom, setNutValuesFrom] = useState<any>();
    const [changedNutValues, setChangedNutValues] = useState<boolean>(false);
    const [displayNutValues, setDisplayNutValues] = useState(false);
    const [displayColorDay, setDisplayColorDay] = useState<string | null>(null);
    const [displayColorIntake, setDisplayColorIntake] = useState<string | null>(null);
    const [initialData, setInitialData] = useState<any>(false);
    const [menuDays, setMenuDays] = useState<number>(0);

    // PROVIDERS

    const { displayCreateTemplate, setDisplayCreateTemplate, restrictions } = useMenuProvider();
    const {
        menu_state,
        menuData, initializeMenuData, findMenuItemsFromIntake, addItemToMenu, deleteItemFromMenu, editItemInMenu, deleteDay, deleteIntake, orderDay, orderIntake, addDay, addIntake,
        getNumbersOfItemsByDayAndIntake, setOverlappingRestrictions, recipeId, openRecipeModal, setOpenRecipeModal, openEditRecipeModal, setOpenEditRecipeModal, copyingIntake,
        setOpenContextMenuChange, openContextMenuChange, selectedMenuItem, setSelectedMenuItem
    } = useMenuDataProvider();

    // SERVICES

    const menuService = new MenuService();

    // HOOKS

    const dispatch = useDispatch<AppDispatch>();
    const { handleErrors } = useHandleErrors();

    // FETCH DATA

    //---------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Obtiene los datos del menú por su ID
     * @EN Get the menu data by its ID
     * 
     * @returns {Menu} Datos del menú
     */
    //---------------------------------------------------------------------------------------------------------------------------------------
    const [data, loadingMenu, menuError, refetchMenu] = useFetch(useCallback(async () => {
        const response = (await menuService.getMenuDataById(menuId as string, null));
        return response.getResponseData() as Menu;
    }, []));
    //---------------------------------------------------------------------------------------------------------------------------------------

    //---------------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES Obtiene los valores nutricionales por día e ingesta
     * @EN Get the nutritional values per day and intake
     * 
     * @returns {Menu} Datos del menú
     */
    //---------------------------------------------------------------------------------------------------------------------------------------
    const [intakesValuesPerDay, loadingIntakesValuesPerDay, intakesValuesPerDayError, refetchIntakesValuesPerDay] = useFetch(useCallback(async () => {
        const response = (await menuService.getIntakesValuesPerDay({ menu: menuId, ...nutValuesFrom }));
        return response.getResponseData() as Menu;
    }, [nutValuesFrom]));
    //---------------------------------------------------------------------------------------------------------------------------------------

    // Filtrar los objetos cuyo "name" sea igual a "energy", "carbohydrates", "proteins" o "fat"
    const filteredData = intakesValuesPerDay?.map((day: any, index: number) => {
        const filteredValues = day.values?.filter((item: any) =>
            ['energy', 'carbohydrates', 'proteins', 'fat'].includes(item.name)
        ).map((item: any) => {
            if (item.name === 'energy') {
                return {
                    ...item,
                    nombre: 'Kcal',
                };
            } else if (item.name === 'carbohydrates') {
                return {
                    ...item,
                    nombre: 'HC (g)',
                };
            } else if (item.name === 'proteins') {
                return {
                    ...item,
                    nombre: 'Prot (g)',
                };
            } else if (item.name === 'fat') {
                return {
                    ...item,
                    nombre: 'Lip (g)',
                };
            }
            return item;
        })

        return {
            id: day.id,
            type: displayColorDay !== null ? 'day' : 'intake',
            values: filteredValues,
        };
    });

    // Editar nombre del día/ingesta
    const _handleUpdateName = async (id: string, name: string, day: boolean) => {
        const serviceFunction = day ? menuService.editDay : menuService.editIntake;
        const errorMessage = day ? "Error al editar el día" : "Error al editar la ingesta";

        try {
            const response = await serviceFunction(id, name);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                refetchMenu();
            } else {
                toast.error(errorMessage);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Añadir día/ingesta al menú
    const _addRowCol = async (rowCol: string) => {
        try {
            const response = await (rowCol === "day"
                ? await (menuService).addRowCol(menuId, true, false)
                : await (menuService).addRowCol(menuId, false, true)).getResponseData();
            if (response.success) {
                rowCol === "day" ? addDay(response.data) : addIntake(response.data);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error(error.message || "Error al añadir " + rowCol === "day" ? "día" : "ingesta");
        }
    };

    // Cambiar orden de día/ingesta
    const _handleChangeOrderItem = async (day: boolean, id: string, order: string) => {
        try {
            const response = await (day
                ? await (menuService).changeDayOrder(id, order)
                : await (menuService).changeIntakeOrder(id, order)).getResponseData();
            if (response.success) {
                day ? orderDay(id, order) : orderIntake(id, order);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al cambiar el orden del día");
        }
    };

    // Eliminar día
    const _deleteDay = async (dayId: string) => {
        try {
            const response = (await (new MenuService()).deleteDay(dayId)).getResponseData();
            if (response.success) {
                deleteDay(dayId || '');
                setChangedNutValues(true);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al eliminar el día");
        }
    };

    // Eliminar ingesta
    const _deleteIntake = async (intakeId: string) => {
        try {
            const response = (await (new MenuService()).deleteIntake(intakeId)).getResponseData();
            if (response.success) {
                deleteIntake(intakeId || '');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error(error.message || "Error al eliminar la ingesta");
        }
    };

    // Cambiar el color de fondo del día seleccionado
    const _handleDayClick = (dayId: string) => {
        if (displayColorDay === dayId) {
            setDisplayColorDay(null);
            setNutValuesFrom(undefined);
        } else {
            setDisplayColorDay(dayId);
            setNutValuesFrom({ day: dayId });
        }
        refetchIntakesValuesPerDay();
        setDisplayColorIntake(null);
        setChangedNutValues(false);
        setDisplayNutValues(true);
        setMenuDays(1);
    };

    const _handleIntakeClick = (intakeId: string) => {
        if (displayColorIntake === intakeId) {
            setDisplayColorIntake(null);
            setNutValuesFrom(undefined);
        } else {
            setDisplayColorIntake(intakeId);
            setNutValuesFrom({ intake: intakeId });
        }
        refetchIntakesValuesPerDay();
        setDisplayColorDay(null);
        setChangedNutValues(false);
        setDisplayNutValues(true);
        setMenuDays(menuData.days.length || 7);
    };

    const renderIntakeDetails = (filteredData: any, intake: any, displayColorDay: any, getNumbersOfItemsByDayAndIntake: any) => {
        return filteredData?.map((intakeFiltered: any) => {
            if (intakeFiltered.id === intake.id) {
                return (
                    <div key={intakeFiltered.id}>
                        {intakeFiltered.values?.map((item: any) => (
                            <p key={item.name} className='m-0 text-muted'>
                                {item.nombre + ' - ' + (
                                    ((intakeFiltered.type === 'day' && getNumbersOfItemsByDayAndIntake(displayColorDay, intakeFiltered.id) !== 0 && Math.round(item.value) !== 0) ||
                                        (intakeFiltered.type !== 'day' && Math.round(item.value) !== 0)) ?
                                        intakeFiltered.type === 'day' ?
                                            (Math.round(item.value) / getNumbersOfItemsByDayAndIntake(displayColorDay, intakeFiltered.id)).toFixed(2) :
                                            Math.round(item.value)
                                        : 0
                                )}
                            </p>
                        ))}
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    const onCloseContextMenuChange = (values: any, day: any, intake: any) => {
        setOpenContextMenuChange(false);
        setSelectedMenuItem(null);
        editItemInMenu(values, day, intake);
    }

    // Setea a true el estado de changedNutValues cuando se cambia el valor de nutValues
    useEffect(() => {
        if (nutValuesFrom !== null) {
            setChangedNutValues(true);
            setDisplayNutValues(true);
        }
    }, [nutValuesFrom]);

    useEffect(() => {
        if (data && !initialData) {
            initializeMenuData(data);
            setInitialData(true);
        }
    }, [data]);

    useEffect(() => {
        setMenuDays(menuData.days.length || 7);
    }, [menuData]);

    // Define las items del menú que coinciden con las restricciones del paciente
    useEffect(() => {
        if (data?.menuItems?.length) {
            setOverlappingRestrictions(findMenuRestrictions(data.menuItems, restrictions));
        }
    }, [data?.menuItems]);

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            day: true
        },
        onSubmit: async (values) => { _handleUpdateName(values.id, values.name, values.day) }
    });

    if (!data) return <></>;

    return (
        <div className='nutritional-menu'>
            {displayCreateTemplate && data && (
                <CreateTemplateModal isOpen={displayCreateTemplate} setIsOpen={setDisplayCreateTemplate} menuId={menuId} menuName={data.name} />
            )}

            <div className="row">
                <div className="col-md-9 p-0">
                    <MenuActions menuId={menuId} data={data} client={client} />

                    <div className="table-responsive" id="menuTab">
                        <table className="menu-table table table-striped" style={{ width: '99%' }}>
                            <thead>
                                <tr>
                                    <th className='sticky-header'>
                                        <Button
                                            className="btn btn-light"
                                            onClick={() => _addRowCol("day")}
                                            title='Añadir día'
                                            isDisable={menuData.days.length >= 31}
                                        >
                                            <Icon icon="Add" color='secondary' size={'lg'} />
                                        </Button>
                                    </th>
                                    {menuData.days.map((day: any, index: number) => (
                                        <th key={day.id} className={'sticky-header' + (displayColorDay === day.id ? ' selected-day' : '')}>
                                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                                <EasyEdit
                                                    type="text"
                                                    onSave={(e: any) => {
                                                        formik.setValues({ name: e, id: day.id, day: true });
                                                        formik.handleSubmit();
                                                    }}
                                                    onBlur={(e: any) => {
                                                        formik.setValues({ name: e, id: day.id, day: true });
                                                        formik.handleSubmit();
                                                    }}
                                                    value={day.name && day.name.length > 0 ? day.name : '---'}
                                                    attributes={{
                                                        className: 'easy-input',
                                                        onKeyDown: (e: React.KeyboardEvent) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                const target = e.target as HTMLInputElement;
                                                                if (target.value.trim() !== "") {
                                                                    formik.setValues({ name: target.value, id: day.id, day: true });
                                                                    formik.handleSubmit();
                                                                } else {
                                                                    toast.error("El nombre no puede estar vacío");
                                                                }
                                                            }
                                                        },
                                                    }}
                                                    inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                                    placeholder=''
                                                />
                                            </form>

                                            {index > 0 && (
                                                <Button
                                                    className="btn p-0"
                                                    onClick={() => _handleChangeOrderItem(true, day.id, 'down')}
                                                    title='Izquierda'
                                                >
                                                    <Icon icon="KeyboardArrowLeft" size={'2x'} style={{ color: 'grey' }} />
                                                </Button>
                                            )}

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => _handleDayClick(day.id)}
                                                title='Valores nut. del día'
                                            >
                                                <Icon icon="Search" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    handleConfirmationAlert({
                                                        title: "Eliminar día",
                                                        text: "¿Está seguro que desea eliminar el día completo?",
                                                        icon: "warning",
                                                        onConfirm: () => { _deleteDay(day?.id) }
                                                    })
                                                }}
                                                title='Eliminar día'
                                            >
                                                <Icon icon="HighlightOff" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            {index < data.days.length - 1 && (
                                                <Button
                                                    className="btn p-0"
                                                    onClick={() => _handleChangeOrderItem(true, day.id, 'up')}
                                                    title='Derecha'
                                                >
                                                    <Icon icon="KeyboardArrowRight" size={'2x'} style={{ color: 'grey' }} />
                                                </Button>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {menuData.days[0]?.intakes.map((intake: any, index: any) => (
                                    <tr key={intake.id}>
                                        <td
                                            className={'sticky-column' + (displayColorIntake === intake.id ? ' selected-column' : '')}
                                            style={{ minWidth: '100px', width: '110px', minHeight: '150px', padding: '5px' }}
                                        >
                                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                                <EasyEdit
                                                    type="text"
                                                    onSave={(e: any) => {
                                                        formik.setValues({ name: e, id: intake.id, day: false });
                                                        formik.handleSubmit();
                                                    }}
                                                    onBlur={(e: any) => {
                                                        formik.setValues({ name: e, id: intake.id, day: false });
                                                        formik.handleSubmit();
                                                    }}
                                                    value={intake.name && intake.name.length > 0 ? intake.name : '---'}
                                                    attributes={{
                                                        className: 'easy-input',
                                                        onKeyDown: (e: React.KeyboardEvent) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                const target = e.target as HTMLInputElement;
                                                                if (target.value.trim() !== "") {
                                                                    formik.setValues({ name: target.value, id: intake.id, day: false });
                                                                    formik.handleSubmit();
                                                                } else {
                                                                    toast.error("El nombre no puede estar vacío");
                                                                }
                                                            }
                                                        },
                                                    }}
                                                    inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                                    placeholder=''
                                                />
                                            </form>

                                            <div className='d-flex justify-content-center align-items-center w-100'>
                                                {(copyingIntake || menu_state.intakes.length > 0) && (
                                                    <Checks
                                                        checked={menu_state.intakes.find((i: any) => i.id === intake.id) ? true : false}
                                                        onChange={() => {
                                                            // Check if the intake is already in the menu
                                                            if (menu_state.intakes.find((i: any) => i.id === intake.id)) {
                                                                dispatch(copyIntakes(menu_state.intakes.filter((i: any) => i.id !== intake.id)));
                                                            } else {
                                                                // Add items to the new intake and copy it to the menu state
                                                                const newIntake = { ...intake };
                                                                const menuItems = findMenuItemsFromIntake(intake.id);
                                                                newIntake.menuItems = menuItems;
                                                                dispatch(copyIntakes([...menu_state?.intakes, newIntake]));
                                                            }
                                                        }}
                                                    />
                                                )}

                                                <Button
                                                    className="btn btn-sm"
                                                    onClick={() => _handleIntakeClick(intake.id)}
                                                    title='Valores nut. por ingesta'
                                                >
                                                    <Icon icon="Search" size={'2x'} style={{ color: 'lightgrey' }} />
                                                </Button>

                                                <Button
                                                    className="btn btn-sm"
                                                    onClick={() => {
                                                        handleConfirmationAlert({
                                                            title: "Eliminar ingesta",
                                                            text: "¿Está seguro que desea eliminar la ingesta completa?",
                                                            icon: "warning",
                                                            onConfirm: () => { _deleteIntake(intake.id) }
                                                        })
                                                    }}
                                                    title='Eliminar ingesta'
                                                >
                                                    <Icon icon="HighlightOff" size={'2x'} style={{ color: 'lightgrey' }} />
                                                </Button>
                                            </div>

                                            <div>
                                                {index > 0 && (
                                                    <Button
                                                        className="btn p-0"
                                                        onClick={() => _handleChangeOrderItem(false, intake.id, 'down')}
                                                        title='Arriba'
                                                    >
                                                        <Icon icon="KeyboardArrowUp" size={'2x'} style={{ color: 'grey' }} />
                                                    </Button>
                                                )}

                                                {index < data.intakes.length - 1 && (
                                                    <Button
                                                        className="btn p-0"
                                                        onClick={() => _handleChangeOrderItem(false, intake.id, 'up')}
                                                        title='Abajo'
                                                    >
                                                        <Icon icon="KeyboardArrowDown" size={'2x'} style={{ color: 'grey' }} />
                                                    </Button>
                                                )}
                                            </div>

                                            <div className='row'>
                                                <div className='col-12'>
                                                    {filteredData && displayNutValues && (
                                                        <>
                                                            {renderIntakeDetails(filteredData, intake, displayColorDay, getNumbersOfItemsByDayAndIntake)}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        {menuData.days.map((day: any) => (
                                            <td
                                                key={`${intake.id}-${day.id}-${day.intakes?.find((i: any) => i.id === intake.id)?.menuItems?.length}`}
                                                draggable
                                                className={`menu-cell 
                                                    ${displayColorDay === day.id ? 'selected-day-cell' : ''} 
                                                    ${displayColorIntake === intake.id ? 'selected-column-cell' : ''}
                                                `}
                                                style={{ width: '50px' }}
                                            >
                                                <DropContainer
                                                    menuId={menuId}
                                                    day={day}
                                                    intake={intake}
                                                    menuItems={day.intakes?.find((i: any) => i.id === intake.id)?.menuItems || []}
                                                    addItemsToMenu={addItemToMenu}
                                                    removeItemFromMenu={deleteItemFromMenu}
                                                    daySelected={displayColorDay === day.id}
                                                    intakeSelected={displayColorIntake === intake.id}
                                                    setNutValuesFrom={setNutValuesFrom}
                                                    setSelectedMenuItem={setSelectedMenuItem}
                                                    setOpenContextMenuChange={setOpenContextMenuChange}
                                                    editItemInMenu={editItemInMenu}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td className='sticky-column'>
                                        <Button
                                            className="btn btn-light sticky-column"
                                            onClick={() => _addRowCol("intake")}
                                            title='Añadir ingesta'
                                            isDisable={menuData.days[0]?.intakes.length >= 10}
                                        >
                                            <Icon icon="Add" color='secondary' size={'lg'} />
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={classNames("col-md-3 ps-3 p-0", { 'd-none': !changedNutValues || !data?.menuItems?.length })}>
                    <NutritionalMenuValues nutValues={nutValuesFrom} clientId={client ? client?.id : null} menuId={menuId} menuDays={menuDays} isUpdated={isUpdated} dataObjectives={dataObjectives} refetchDataObjectives={refetchDataObjectives} weight={(data.weight && data.weight !== 0 ? data?.weight : 1)} height={(data.height && data.height !== 0 ? data?.height : 1)} />
                </div>
            </div>

            <ContextMenuChange menuItem={selectedMenuItem} isOpen={openContextMenuChange} setIsOpen={setOpenContextMenuChange} menuId={menuId} onClose={onCloseContextMenuChange} />

            <RecipeViewModal id={recipeId} isOpen={openRecipeModal} setIsOpen={setOpenRecipeModal} />

            <EditRecipeModal id={recipeId} isOpen={openEditRecipeModal} setIsOpen={setOpenEditRecipeModal} />
        </div>
    )
};

export default NutritionalMenu;