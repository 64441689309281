import classNames from 'classnames';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import FormLabel from '../../../../components/FormLabel';
import Button from '../../../../components/bootstrap/Button';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import SearchableSelect from '../../../../components/select/SearchableSelect';
import useFoods from '../../../../hooks/api-calls/useFoods';

interface RecipeIngredientsProps {
    formik: any;
    ingredient: any;
    setIngredient: any;
}

const RecipeIngredients: React.FC<RecipeIngredientsProps> = ({ formik, ingredient, setIngredient }) => {

    // HOOKS

    const { getFoodsList } = useFoods();

    // RENDER

    return (
        <div className='border rounded border-secondary p-3 mt-3'>
            <div className={classNames({ 'd-none': formik.values.ingredients?.length === 0 })}>
                <div className='row'>
                    <FormLabel label='Nombre del ingrediente' cols={3} />
                    <FormLabel label='Peso sin desperdicio (g)' cols={2} />
                </div>

                <div className='row'>
                    {formik.values.ingredients?.map((ing: any, index: number) => (
                        <div className='col-md-6' key={index}>
                            <>
                                <div className={'row mb-4'}>
                                    <FormGroup className='col-md-6' error={formik.errors.ingredients && formik.errors.ingredients[index]?.id}>
                                        <SearchableSelect
                                            name={`ingredients[${index}].id`}
                                            options={getFoodsList()}
                                            value={getFoodsList().filter((f) => ing.id === f.value)}
                                            onChange={(e: any) => {
                                                const updatedIngredients = formik.values.ingredients.map((ing: any, i: number) => {
                                                    if (i === index) {
                                                        return { ...ing, id: e?.value };
                                                    }
                                                    return ing;
                                                });
                                                formik.setFieldValue('ingredients', updatedIngredients);
                                            }}
                                            onBlur={formik.handleBlur}
                                            placeholder='ingrediente'
                                        />
                                    </FormGroup>

                                    <FormGroup className='col-md-4' error={formik.errors.ingredients && formik.errors.ingredients[index]?.quantity}>
                                        <Input
                                            name={`ingredients[${index}].quantity`}
                                            type='number'
                                            value={ing.quantity}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FormGroup>

                                    <div className="col-md-1 d-flex justify-content-end align-items-end">
                                        <Button
                                            color="danger"
                                            size='sm'
                                            onClick={() => { formik.setFieldValue('ingredients', formik.values.ingredients.filter((fg: any, i: number) => i !== index)) }}
                                        >
                                            <FaRegTrashAlt />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </div>
                    ))}
                </div>
            </div>

            <div className={classNames('row', { 'border-top border-grey-50 pt-4': formik.values.ingredients?.length > 0 })}>
                <FormGroup label='Nombre del ingrediente' className='col-md-3'>
                    <SearchableSelect
                        name='id'
                        isClearable
                        options={getFoodsList()}
                        value={getFoodsList().filter((f) => ingredient.id === f.value)}
                        onChange={(e: any) => setIngredient({ ...ingredient, id: e?.value })}
                        placeholder='ingrediente'
                    />
                </FormGroup>

                <FormGroup label={`Peso sin desperdicio (g)`} className='col-md-2'>
                    <Input
                        name='quantity'
                        type='number'
                        value={ingredient.quantity}
                        onChange={(e: any) => setIngredient({ ...ingredient, quantity: Number(e.target.value) })}
                        onBlur={formik.handleBlur}
                    />
                </FormGroup>

                <div className='col-md-1 d-flex justify-content-end align-items-end'>
                    <Button
                        color='secondary'
                        size='sm'
                        isDisable={ingredient.id === "" || ingredient.quantity === 0}
                        onClick={() => {
                            formik.setFieldValue('ingredients', [...formik.values.ingredients, ingredient]);
                            setIngredient({ id: [], quantity: 0 });
                        }}
                    >
                        <MdAdd className='h-5 w-5' />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RecipeIngredients;