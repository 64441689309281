import { FC, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Card, { CardTabItem } from "../../../../components/bootstrap/Card";
import { Loader } from "../../../../components/bootstrap/SpinnerLogo";
import ClientChat from "../../../../components/chat/ClientChat";
import Documents from "../../../../components/document/Documents";
import useFetch from "../../../../hooks/useFetch";
import { changeClientActiveTab } from "../../../../redux/clientSlice";
import { PatientService } from "../../../../services/patients/patientService";
import { Label, Patient } from "../../../../type/patients-type";
import FormsTab from "../../../forms/patient-forms/FormsTab";
import Labels from "../labels/Labels";
import PatientHasMenus from "../menus/PatientHasMenus";
import Notes from "../notes/Notes";
import ListPatientTips from "../tips/ListPatientTips";
import PatientHeader from "./PatientHeader";
import AppointmentList from "../appointment/AppointmentList";
import EvolutivePhotos from "../../../../components/document/EvolutionPhotos";

const PatientCardTab: FC = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const activeTab = useSelector((state: any) => state.client_profile.activeTab);

    const [patientData, loading] = useFetch(useCallback(async () => {
        const response = await (new PatientService()).getPatientById(id as string);
        return response.getResponseData() as Patient;
    }, []));

    const [labels] = useFetch(useCallback(async () => {
        const response = await (new PatientService()).getActivePatientTags(patientData?.clientRoles[0].company.id);
        return response.getResponseData();
    }, []));

    const [patientTags, fetchingTags, tagsError, refetchPatientTags] = useFetch(useCallback(async () => {
        const response = await (new PatientService()).getPatientTags(id);
        return response.getResponseData();
    }, [labels]));

    const handleSaveTags = async (tags: string[]) => {
        const oldTags = patientTags?.map((tag: Label) => tag.name);
        const newTags = [...(patientTags || []).map((tag: Label) => tag.name), ...tags.filter((tag) => !oldTags.includes(tag))];
        const removedTags = oldTags.filter((tag: any) => !tags.includes(tag));
        const finalTags = newTags.filter((tag) => !removedTags.includes(tag));
        try {
            const response = await (new PatientService()).editPatientTags(id, finalTags, patientData?.clientRoles[0].company.id);
            const responseData = response.getResponseData();
            if (responseData.success) {
                refetchPatientTags();
                toast.success('Etiquetas actualizadas');
            } else {
                toast.error(responseData.data.errors[0].message || 'Error al actualizar las etiquetas');
            }
        } catch (error: any) {
            toast.error(error || "Error al actualizar las etiquetas");
        }
    };

    const getLabelsOptions = () => {
        // Guardar los nombres de todas las etiquetas
        if (labels && patientTags) {
            const allLabelsNames = labels?.map((label: Label) => label.name);

            return allLabelsNames?.map((tagName: string) => {
                // Comprobar si la etiqueta está seleccionada
                const isTagSelected = patientTags?.findIndex((tag: Label) => tag.name === tagName) !== -1;

                return {
                    value: tagName,
                    label: tagName,
                    isSelected: isTagSelected,
                };
            });
        }
    };

    const showPatientTags = useCallback(patientTags?.map((tag: any) => (
        <span
            key={tag.name}
            className="badge text-center w-auto mb-3 me-4 p-2 ps-3 pe-3 position-relative"
            style={
                {
                    backgroundColor: tag.color || '#d7d7d7',
                    color: tag.color == '#3c3c3b' || tag.color == '#b81e53' || tag.color == '#e5133d' ? 'white' : 'black',
                }}>
            {tag.name}
        </span>
    )), [patientTags]);

    const dispatchSelectedTab = (tabId: string) => {
        dispatch(changeClientActiveTab(tabId));
    };

    if (loading) return <Loader />;

    return (
        <Fragment>
            <PatientHeader data={patientData} />

            <Card hasTab>
                <CardTabItem id='forms' title='Formularios' icon='DynamicForm' onSelected={dispatchSelectedTab} isActive={activeTab === "forms"}>
                    <FormsTab />
                </CardTabItem>

                <CardTabItem id='menu' title='Menús' icon='Menu' onSelected={dispatchSelectedTab} isActive={activeTab === "menu"} permission={{ action: 'view-all-client-menus', group: 'clients' }}>
                    <PatientHasMenus id={id} />
                </ CardTabItem>

                <CardTabItem id='tips' title='Consejos' icon='TipsAndUpdates' onSelected={dispatchSelectedTab} isActive={activeTab === "tips"} >
                    <ListPatientTips />
                </CardTabItem>

                <CardTabItem id='documents' title='Documentos' icon='Article' onSelected={dispatchSelectedTab} isActive={activeTab === "documents"}>
                    <Documents id={id} isPatient />
                </CardTabItem>

                <CardTabItem id='tags' title='Etiquetas' icon='Tag' onSelected={dispatchSelectedTab} isActive={activeTab === "tags"}>
                    <Labels
                        labels={labels}
                        getLabelsOptions={getLabelsOptions}
                        handleSaveTags={handleSaveTags}
                    />
                </CardTabItem>

                <CardTabItem id='notes' title='Notas' icon='Note' onSelected={dispatchSelectedTab} isActive={activeTab === "notes"}>
                    <Notes />
                </CardTabItem>

                <CardTabItem id='chat' title='Chat' icon='Chat' onSelected={dispatchSelectedTab} isActive={activeTab === "chat"}>
                    <ClientChat client={id} />
                </CardTabItem>

                <CardTabItem id='appointments' title='Gestión de citas' icon='CalendarToday' onSelected={dispatchSelectedTab} isActive={activeTab === "appointments"}>
                    <AppointmentList user={patientData?.user.id} />
                </CardTabItem>

                <CardTabItem id='photos' title='Fotos Evolutivas' icon='Photo' onSelected={dispatchSelectedTab} isActive={activeTab === "photos"}>
                    <EvolutivePhotos id={id} isPatient type={"Fotos Evolutivas"} />
                </CardTabItem>
            </Card>
        </Fragment>
    )
}

export default PatientCardTab;