import MenusTab from "../../../components/menu/MenusTab";
import useFilters from "../../../hooks/useFilters";
import { IMenuFilters } from "../../menus/menu-list/MenusList";

const UserHasMenus = (id: any) => {

    const menuFilters: IMenuFilters = {
        active: 1,
        user_id: id.id,
        client: null
    };

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(menuFilters);

    return (
        <MenusTab
            page='user'
            filters={filters}
            updateFilters={updateFilters}
            updateFilterOrder={updateFilterOrder}
            updatePage={updatePage}
            updatePageSize={updatePageSize}
        />
    )
}

export default UserHasMenus;