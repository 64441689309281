import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../components/bootstrap/Button';
import { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import { Loader } from '../../../components/bootstrap/SpinnerLogo';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import useFetch from '../../../hooks/useFetch';
import { CompanyService } from '../../../services/companies/organizationService';
import useHandleErrors from '../../../utils/hooks/useHandleErrors';

interface GCInformedConsentProps { }
interface GCForm {
    textField: string;
}

const initialValues: GCForm = {
    textField: '',
}

const schema = yup.object({
    textField: yup.string().required('Este campo es obligatorio'),
});

const GCInformedConsent: React.FC<GCInformedConsentProps> = ({ }) => {

    const { handleErrors } = useHandleErrors();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new CompanyService()).getInformedConsent();
        return response.getResponseData();
    }, []));

    const handleSubmit = async (values: GCForm) => {
        setIsLoading(true);
        try {
            const response = await (await (new CompanyService()).setInformedConsent(values)).getResponseData();
            if (response.success) {
                toast.success("Consentimiento informado editado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar el consentimiento informado");
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik<GCForm>({
        initialValues: data || initialValues,
        validationSchema: schema,
        onSubmit: values => { handleSubmit(values) }
    });

    useEffect(() => {
        if (data) {
            formik.setFieldValue('textField', data.textField);
        }
    }, [data]);

    const verifyClass = (inputFieldID: keyof GCForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof GCForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>; }

    if (loading) return <Loader />;

    return (
        <>
            <CardHeader>
                <CardLabel>
                    <CardTitle>Consentimiento Informado</CardTitle>
                </CardLabel>
            </CardHeader>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody>
                    <FormGroup label="Texto para la firma de consentimiento">
                        <textarea
                            id="textField"
                            onChange={formik.handleChange}
                            rows={5}
                            value={formik.values.textField}
                            className={verifyClass('textField') + " mt-1"}
                            style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 0 3px rgb(245, 156, 57, 0.3)',
                                backgroundColor: '#f8f9fa',
                                width: '100%',
                                minHeight: '400px',
                                padding: '15px',
                                border: '0'
                            }}
                        />
                        {showErrors('textField')}
                    </FormGroup>
                </CardBody>

                <CardFooter className='d-flex justify-content-end'>
                    <Button
                        type="submit"
                        isDisable={isLoading}
                        color='primary'
                    >
                        {isLoading ? <Spinner color={'dark'} /> : 'Guardar'}
                    </Button>
                </CardFooter>
            </form>
        </>
    );
};

export default GCInformedConsent;