import { useCallback, useEffect } from "react";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { BasalMetabolismService } from "../../../services/basal-metabolism/basalMetabolismService";
import SearchableSelect from "../../../components/select/SearchableSelect";



interface GCBasalMetabolismProps { 

}

interface FormValues {
    id: string,
    option: number
}

let initialFormikValues = {
    id: '',
    option: 1,
}

const GCBasalMetabolism: React.FC<GCBasalMetabolismProps> = () => {

    // STATES

    // HOOKS

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();

    // FETCH DATA
    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await (new BasalMetabolismService()).getBasalMetabolismByCompany());
        return response.getResponseData();
    }, []));

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------
    /**
     * @ES Función para editar un calibrador.
     * @EN Function to edit a calibrator.
     * 
     * @param values 
     */
    //-------------------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        try {
            const response = await (await (new BasalMetabolismService()).editBasalMetabolism(values)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Fórmula del metabolismo basal empleada editada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar la fórmula del metabolismo basal empleada');
        }
    };
    //-------------------------------------------------------------------------------------------------------------


    // USE EFFECT

    useEffect(() => {
        if (data) {
            formik.setFieldValue('id', data?.id);
            formik.setFieldValue('option', data?.option);
        }
    }, [data]);


    // FORMIK

    const validationSchema = Yup.object({
        option: Yup.number().required('Campo requerido'),
    });


    const formik = useFormik<FormValues>({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleEdit(values);
        }
    });


    // RENDER

    return (
        <>
            <CardHeader>
                <SubHeaderLeft>
                    <CardTitle>Metabolismo basal</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <></>
                </SubHeaderRight>
            </CardHeader>
            <CardBody>
                <div className="row">
                    <div className="col-4 mb-3">
                        <span className="fs-5" >Opciones</span>
                    </div> 

                    <div className="col-8 mb-3">
                       <span className="fs-5"> Formulas de metabolismo basal </span>
                    </div> 
                </div>
                <div className="row">
                    <div className="col-4">
                    <SearchableSelect
                            name="option"
                            value={formik.values.option
                                ? { value: formik.values.option, label: formik.values.option === 1 ? "Formula de Harris-Benedict" : "Formula de Mifflin-St Jeor" }
                                : null
                            }
                            onChange={(value: any) => {
                                formik.setFieldValue('option', value);
                                handleEdit({ id: formik.values.id, option: value.value });
                            }}
                            options={[
                                { value: 1, label: 'Fórmula de Harris-Benedict' },
                                { value: 2, label: 'Fórmula de Mifflin-St Jeor' },
                            ]}
                            placeholder="Seleccione una fórmula"
                        />
                        {formik.touched.option && formik.errors.option ? (
                            <div className="text-danger">{formik.errors.option}</div>
                        ) : null}
                    </div>
                    <div className="col-8 p-4 bg-secondary rounded">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <span className="fw-bold">{data?.name}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-1">
                                <span className="fw-bold">Hombres</span>
                            </div>
                            <div className="col-12">
                                <span>{data?.formulaMan}</span>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12 mb-1">
                                <span className="fw-bold">Mujeres</span>
                            </div>
                            <div className="col-12">
                                <span>{data?.formulaWoman}</span>
                            </div>
                        </div>
                    </div>
                </div>
                        
            </CardBody>
        </>
    );

}

export default GCBasalMetabolism;