import { useCallback, useEffect, useState } from 'react';
import { CompanyService } from '../../services/companies/organizationService';
import { usePrivilege } from '../../components/priviledge/PriviledgeProvider';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage workplaces fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de centros de trabajo.
----------------------------------------------------------------------------------------*/

const useWorkplaces = (initialFilters = {}) => {

    const { userCan } = usePrivilege();
    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchWorkplaces = useCallback(async () => {
        if (!userCan('list_workplace', 'workplace')) return;
        try {
            const response = await (new CompanyService()).getWorkplaces(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.workPlaces) {
                const mappedData = fetchedData.workPlaces?.map((data: { id: string; name: string }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching workplaces:', error);
        }
    }, [filters]);

    useEffect(() => {
        fetchWorkplaces();
    }, []);

    const updateWorkplacesHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getWorkplacesList = () => {
        return data;
    };

    return { fetchWorkplaces, getWorkplacesList, updateWorkplacesHookFilters };
}

export default useWorkplaces;